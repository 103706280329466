import { apiInstance, http, wellControlInstance } from "../../../config/HttpCommon";
import { getFerrariAPIBaseURL } from "../../../utilities/BaseURLUtility";
import { WellControlAction } from "../../navigation/components/ControlAction";


export const getErrorToastMessage = (controlActionType: number, wellName = 'well') => {
    let returnMessage = '';
    switch (controlActionType) {
        case WellControlAction.Start:
            returnMessage = `The transaction failed to start ${wellName}`;
            break;
        case WellControlAction.Stop:
            returnMessage = `The transaction failed to stop ${wellName}`;
            break;
        case WellControlAction.ClearAlarms:
            returnMessage = `The transaction failed to clear alarms for ${wellName}`;
            break;
        case WellControlAction.Scan:
            returnMessage = `The transaction failed to scan ${wellName}`;
            break;

        default:
            returnMessage = `Transaction failed for ${wellName ?? 'well'}`;
    }
    return returnMessage;
};

const getToastMessage = (label: string, assetName: string | undefined) => {
    if (label && assetName) {
        return label + ' transaction sent for ' + (assetName ?? 'well');
    } else {
        return 'Transaction sent succesffuly';
    }
};

export const executeWellControlActionAsync = async (
    assetId: string,
    wellControlActionId: string,
    socketId: string,
    controlActionName: string,
    wellName: string | undefined,
) => {
    const params = {
        assetId: assetId, // asset Id
        controlType: wellControlActionId, // enum of control types
        socketId: socketId, // web socket id
    };

    return await wellControlInstance.post('WellControl', params).then(function (response: any) {
        if (response && response.data) {
            return getToastMessage(controlActionName, wellName);
        }
    });
};

export const getXspocToken = async () => {
    const baseUrl = getFerrariAPIBaseURL();
    const getXspocTokenUrl = `${baseUrl}/GetXSPOCAccessToken?referencetoken=test`;
    try {
        const response1 = await http.get(getXspocTokenUrl);
        if (response1.status == 200) {
            localStorage.setItem("xspocAccessToken", response1.data)
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
    }
}

export const getWellControlActionAsync = async (assetId: string) => {
    const response = await wellControlInstance.get(`WellControl/GetWellControlActions?assetId=${assetId}`)

    return response.data;
};

export const getAssetContolStatus = async (wellName: string) => {
    const response = await http.get(`/GetAssetControlStatus/${encodeURIComponent(wellName)}`);
    return response.data;
}

export const getOperatingMode = async (assetId: string, pocType: string) => {
    const response = await http.get(`/GetOperatingmode/${assetId}/${pocType}`);
    return response.data;
}

export const getAssetControl = async (wellName: string) => {
    const response = await http.get(`/GetAssetControl/${encodeURIComponent(wellName)}`);
    return response.data;
}

export const getPingDetail = async (wellName: string, cancelToken: any) => {
    const response = await http.get(`/GetPingDetails/${encodeURIComponent(wellName)}`, { cancelToken });
    return response.data;
}

export const getLookupValue = async (key: string) => {
    const response = await apiInstance.get(`/v1/lookups/lookup-by-key/${key}`);
    return response.data;
}

// const getToastMessage = (label: string, assetName: string | undefined) => {
//     if (label && assetName) {
//         return label + ' transaction sent for ' + assetName ?? 'well';
//     } else {
//         return 'Transaction sent succesffuly';
//     }
// };