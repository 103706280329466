import React, { useLayoutEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface UpTimeChartProps {
  data: { date: string | number; uptime: number, downtime: number, isDown?: boolean }[];
}


const UpTimeChart: React.FC<UpTimeChartProps > = ({ data }) => {
  
  const chartRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {

    // Filter data to ensure unique dates
    const uniqueData = Array.from(
      new Map(
        data?.map((item) => [item.date, item]) // Use `date` as the key to ensure uniqueness
      ).values()
    );

    const transformedData = uniqueData?.map(item => ({
      ...item,
      date: new Date(item.date).getTime() // Convert to timestamp
    }));

    const root = am5.Root.new(chartRef.current!);
    root.setThemes([am5themes_Animated.new(root)]);
  
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        wheelX: "none",
        wheelY: "none",
        pinchZoomX: false,
        pinchZoomY: false,
        cursor:undefined,
        interactive: false
      })
    );

  // Explicitly remove zoom buttons
    chart.zoomOutButton.set("forceHidden", true); // Hides the zoom out button
  
  
    // X-Axis: Date Axis
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );
  
    // Y-Axis: Value Axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0, // Start Y-axis from 0
        strictMinMax: false, // Allow dynamic scaling
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );
 
    // Hide x-axis and y-axis labels and grid
    xAxis.get("renderer").labels.template.set("visible", false);
    xAxis.get("renderer").grid.template.set("visible", false);
    yAxis.get("renderer").labels.template.set("visible", false);
    yAxis.get("renderer").grid.template.set("visible", false);
  
    // Uptime Series (Green)
    const uptimeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Uptime",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "uptime",
        valueXField: "date",
        stacked: true,
        tooltip: am5.Tooltip.new(root, { labelText: "Uptime: {valueY}%" }),
      })
    );
  
    uptimeSeries.columns.template.setAll({
      width: 8,
      minWidth: 8,
      strokeWidth: 0,
      fill: am5.color("#12B76A"),
      stroke: am5.color("#12B76A"),
    });

    uptimeSeries.columns.template.adapters.add("cornerRadiusTL", (radius, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime <= 0) {
        return 5; 
      }
      return dataItem?.dataContext.downtime === 0 ? 5 : 0;
    });
  
    uptimeSeries.columns.template.adapters.add("cornerRadiusTR", (radius, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime <= 0) {
        return 5; 
      }
      return dataItem?.dataContext.downtime === 0 ? 5 : 0;
    });


    // Downtime Series (Red)
    const downtimeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Downtime",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "downtime",
        valueXField: "date",
        stacked: true,
        tooltip: am5.Tooltip.new(root, { labelText: "Downtime: {valueY}%" }),
      })
    );
  
    downtimeSeries.columns.template.setAll({
      width: 8,
      minWidth: 8,
      strokeWidth: 0,
      fill: am5.color("#F97066"),
      stroke: am5.color("#F97066"),
    });

    downtimeSeries.columns.template.adapters.add("cornerRadiusTL", (radius, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
        return 5; 
      }
      return dataItem?.dataContext.uptime === 0 ? 5 : 0;
    });
  
    downtimeSeries.columns.template.adapters.add("cornerRadiusTR", (radius, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
        return 5; 
      }
      return dataItem?.dataContext.uptime === 0 ? 5 : 0;
    });

    // Add corner radius logic to avoid radius between uptime and downtime if both are non-zero
  downtimeSeries.columns.template.adapters.add("cornerRadiusBL", (radius, target) => {
    const dataItem: any = target.dataItem;
    if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
      return 0; // No corner radius between uptime and downtime
    }
    return 5; // Apply radius if only downtime
  });

  uptimeSeries.columns.template.adapters.add("cornerRadiusBL", (radius, target) => {
    const dataItem: any = target.dataItem;
    if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
      return 5; 
    }
    return 5; // Apply radius if only uptime
  });

  downtimeSeries.columns.template.adapters.add("cornerRadiusBR", (radius, target) => {
    const dataItem: any = target.dataItem;
    if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
      return 0; // No corner radius between uptime and downtime
    }
    return 5; // Apply radius if only downtime
  });

  uptimeSeries.columns.template.adapters.add("cornerRadiusBR", (radius, target) => {
    const dataItem: any = target.dataItem;
    if (dataItem && dataItem.dataContext.uptime > 0 && dataItem.dataContext.downtime > 0) {
      return 5; 
    }
    return 5; // Apply radius if only uptime
  });


  // Apply opacity to uptime series bars
    uptimeSeries.columns.template.adapters.add("opacity", (opacity, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem?.dataContext.uptime > 0) {
        return 1; // Full opacity if uptime > 0
      } else {
        return 0; // Reduced opacity if uptime <= 0
      }
    });

    // Apply opacity to downtime series bars
    downtimeSeries.columns.template.adapters.add("opacity", (opacity, target) => {
      const dataItem: any = target.dataItem;
      if (dataItem?.dataContext.downtime > 0) {
        return 1; // Full opacity if downtime > 0
      } else {
        return 0; // Reduced opacity if downtime <= 0
      }
    });

  
    // Set data for both series
    uptimeSeries.data.setAll(transformedData);
    downtimeSeries.data.setAll(transformedData);
  
    // Clean up
    return () => {
      root.dispose();
    };
  }, [data]);
  
  

  return <div ref={chartRef} style={{ width: "150px", height: "70px" }} className='flex items-center relative top-[-10px]' />;
};

export default UpTimeChart;