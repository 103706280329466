import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import {
  // updateAssetsDisplayState,
  updateSelectedAsset,
  updateAssetList,
  updateSelectedGroupName,
  updateSearchGroups,
} from '../AssetGroupsSlice';
import Dataflow from '../../../images/data-flow.svg';
/* import colorDataflow from '../../../images/data-flow-color.svg'; */
// import Icon from '../../../images/Icon.png';
import Carrot from '../../../images/chevron-down.svg';
/* import ChevronUp from '../../../images/chevron-up.svg'; */
import SearchIcon from '../../../images/search-icon.svg';
import TrackingDecorator from '../../common/Logging/TrackingDecorator';
import { useNavigate } from 'react-router-dom';

// import AssetMockData from '../../../AssetMockData.json';
import { getAssetGroup } from '../../asset-hierarchy/AssetHierarchyService';
import { setAssetGroupState } from '../../asset-hierarchy/AssetGroupSlice';
// import PageLoader from '../../common/page-loader/PageLoader';
import DrawerItem from './DrawerItem';
import './NavigationDrawerItem.scss';
// import PageLoader from '../../common/page-loader/PageLoader';
import Loader from '../../common/page-loader/ComponentLoader';
import NoData from '../../dashboard/components/no-data/NoData';
//import { array } from '@amcharts/amcharts5';

interface TrackingProps {
  onClick: () => void;
  tracking: {
    capture: (eventName: string, eventData: object) => void;
  };
}

interface ChildGroup {
  assets?: ChildGroup[];
  assetId: string;
  assetName: string;
  industryApplicationId: number;
}

interface AssetGroupValues {
  assets: ChildGroup[] | null;
  childGroups: ChildGroup[] | null;
  groupName: string;
}

const NavigationDrawerItem: React.FC<TrackingProps | any> = ({ tracking }) => {
  console.log(tracking);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const storedUserData = localStorage.getItem('jwtAccessGroups');
  const selectedWellName: string[] | null = storedUserData ? JSON.parse(storedUserData) : null;
  const wellName: string = selectedWellName?.length ? selectedWellName[0] : 'Well Groups';
  const [expandedGroups, setExpandedGroups] = useState<string[]>([wellName]);
  const [assetGroups, setAssetGroups] = useState<AssetGroupValues[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<AssetGroupValues[]>([]);
  const [initialAssetGroups, setInitialAssetGroups] = useState<AssetGroupValues[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getAssetGroupListData = useAppSelector((state) => state?.assetGroup);
  const [contextMenu, setContextMenu] = useState<string>('');
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const storedUserGroup = localStorage.getItem('jwtAccessGroups');
  const accessGroups: string[] | null = storedUserGroup ? JSON.parse(storedUserGroup) : null;



  const getAssetGroupData = async () => {
    try {
      const data = await getAssetGroup("AssetGroupName");
      if (data !== "Authorization failed.") {
        dispatch(setAssetGroupState(data));
        setAssetGroups(data?.values);
        setInitialAssetGroups(data?.values);
        setFilteredGroups(data?.values);
      }
    } catch (error) {
      console.error("Error fetching asset groups:", error);
    } finally {
      setLoading(false);
    }
  };

  const setAssetGroupsState = () => {
    setAssetGroups(getAssetGroupListData.values ?? []);
    setInitialAssetGroups(getAssetGroupListData?.values ?? []);
    setFilteredGroups(getAssetGroupListData.values ?? []);
    // setLoading(false);
  };

  useEffect(() => {
    setAssetGroupsState();
  }, [getAssetGroupListData]);

  useEffect(() => {
    if (getAssetGroupListData?.values?.length === 0 || getAssetGroupListData?.values === undefined || getAssetGroupListData?.values === null) {
      getAssetGroupData();
    } else {
      setLoading(false)
    }
  }, []);

  // useEffect(() => {
  //   tracking.capture('Assets', 'Page Loaded');
  //   dispatch(fetchInitialStateAsync());
  // }, [dispatch]);


  const filterDataByName = (items: AssetGroupValues[], searchText: string) => {
    if (searchText === "") {
      return items.map((item: any) => ({
        ...item
      }));
    }

    const expanded = new Set<string>();

    // Dispatch the action to update the search term in the Redux store
    dispatch(updateSearchGroups({ searchString: searchText, assetId: undefined, assetGroupName: undefined }));

    const filteredItems = items
      .map((item: any) => {
        const groupNameMatches =
          item?.groupName &&
          item?.groupName?.toLowerCase().includes(searchText.toLowerCase());


        let visibleChildGroups: any[] = [];

        if (item?.childGroups) {
          const [filteredChildren, expandedChildren] = filterDataByName(
            item.childGroups,
            searchText
          );
          visibleChildGroups = filteredChildren;
          expandedChildren.forEach((name: any) => expanded.add(name));
        }

        const visibleAssets = item?.assets &&
          item?.assets.filter((val: any) =>
            val?.assetName?.toLowerCase().includes(searchText.toLowerCase())
          ) || [];

        const hasVisibleChildren = visibleChildGroups.length > 0 || visibleAssets.length > 0;

        if (
          groupNameMatches || hasVisibleChildren
        ) {
          expanded.add(item.groupName);
          return { ...item, assets: visibleAssets, childGroups: visibleChildGroups };
        }
        return null;
      })
      .filter(Boolean) as any[];

    return [filteredItems, Array.from(expanded)];
  };


  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    const [filtered, expanded] = filterDataByName(assetGroups, searchString);
    if (searchString !== '') {
      setFilteredGroups(filtered);
      setExpandedGroups(expanded);
    } else {
      setFilteredGroups(assetGroups);
      setExpandedGroups([wellName]);
    }
  };


  const handleAssetGroupClick = (assetGroupName: string) => {
    /* if (expandedGroups.includes(assetGroupName)) {
      setExpandedGroups(expandedGroups.filter((group) => group !== assetGroupName));
    } else {
      setExpandedGroups([...expandedGroups, assetGroupName]);
    } */
    setExpandedGroups(prevState => {
      const isCurrentlyExpand = prevState.includes(assetGroupName);
      if (isCurrentlyExpand) {
        return prevState.filter(itemName => itemName !== assetGroupName);
      } else {
        return [...prevState, assetGroupName];
      }

    });
  };

  const filterGroupByName = (initialData: any, groupName: string): any | null => {
    if (initialData.groupName === groupName) {
      return initialData;
    }
    if (initialData.childGroups) {
      for (const childGroup of initialData.childGroups) {
        const foundGroup = filterGroupByName(childGroup, groupName);
        if (foundGroup) {
          return foundGroup;
        }
      }
    }
    return null;
  }


  const handleAssetIdClick = (
    assetName: string,
    assetId: string | undefined,
    assetGroupName: string,
    // groupList?: ChildGroup[],
  ) => {
    const groupLists = filterGroupByName(initialAssetGroups[0], assetGroupName);
    const selectedAsset = {
      assetGroupName: assetGroupName,
      assetId: assetId,
      assetName: assetName,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupLists) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupLists?.assets));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupLists?.assets ?? []));
    if (assetId) {
      navigate(`/layout/assets/`);
    }
    if (accessGroups && accessGroups.length > 0) {
      sessionStorage.setItem('selectedGroupName', assetGroupName);
    }
  };

  const handleRightClick = (e: any, assetID: string) => {
    e.preventDefault();
    setContextMenu(assetID);
  };

  const handleOptionClick = (
    action: string,
    assetName: string,
    assetId: string | undefined,
    assetGroupName: string,
  ) => {
    const groupLists = filterGroupByName(initialAssetGroups[0], assetGroupName);
    const selectedAsset = {
      assetGroupName: assetGroupName,
      assetId: assetId,
      assetName: assetName,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupLists) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupLists?.assets));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupLists?.assets ?? []));
    if (action === "open-new-tab" && assetId) {
      const targetPath = `/layout/assets/`;
      const fullUrl = `${window.location.origin}${targetPath}`;
      window.open(fullUrl, "_blank");
    }
    setContextMenu(''); // Close the context menu
  };

  const handleContextMenuClickOutside = (event: MouseEvent) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
      setContextMenu('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleContextMenuClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleContextMenuClickOutside);
    };
  }, []);

  const handleAssetGroupNameClick = (assetGroupName: string) => {
    const selectedAsset = {
      assetGroupName: assetGroupName,
      assetId: undefined,
      assetName: undefined,
      searchString: undefined,
    };
    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateSelectedGroupName(assetGroupName));
    navigate('/');
  };

  const handleSecondLevelGroupNameClick = (assetGroupName: string, GroupName: string) => {
    const selectedAsset = { assetGroupName: assetGroupName, assetId: undefined, assetName: undefined, searchString: undefined }
    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateSelectedGroupName(assetGroupName));
    localStorage.setItem("selectedGroupName", GroupName);
    sessionStorage.setItem("selectedGroupName", GroupName);
    navigate('/layout/group-dashboard', { state: { groupNameSet: GroupName } });
  }


  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='navigation-bar-items'>
        <div className='navigation-bar-items-content'>
          <div className='navigation-content-headline'>Assets</div>
          <div className='navigation-content-search-and-filter'>
            <div className='navigation-content-search'>
              <div className='navigation-content-search-input-div'>
                <img src={SearchIcon} alt='Search Icon' className='search-icon' />
                <input className='input-with-icon' placeholder='Search' onChange={handleSearchInputChange} />
              </div>
            </div>
          </div>
          <div className='navigation-content-frame'>
            {
              loading ?
                <div className='flex items-center justify-content' style={{ height: '200px' }}>
                  <Loader />
                </div>
                :

                filteredGroups?.length > 0 ? filteredGroups?.map((group: any) => (
                  <div key={group.groupName} className='level0'>
                    <div
                      className={`navigation-header ${expandedGroups.includes(group.groupName) ? 'expanded sticky top-0 z-9999' : ''
                        }`}
                      onClick={() => handleAssetGroupClick(group.groupName)}
                    >
                      <div className='navigation-content-frame-item-content'>
                        <div className='navigation-content-frame-item-dataflow'>
                          <img src={Dataflow} alt='Dataflow' className='data-flow-icon' />
                        </div>
                        <div
                          data-testid={`navbar-${group.groupName}`}
                          className='navigation-content-frame-item-text'
                          onClick={() => handleAssetGroupNameClick(group.groupName)}
                        >
                          {group.groupName}
                        </div>
                      </div>
                      <div className='navigation-content-frame-item-carrot'>
                        {group.childGroups && <span className='child-count'>{group.childGroups.length}</span>}
                        {/* {expandedGroups.includes(group.groupName) ? (
                      <img className='chevron' src={ChevronUp} alt='Chevron Up' />
                    ) : ( */}
                        <img src={Carrot} alt='Carrot' className='chevron-down' />
                        {/* )} */}
                      </div>
                    </div>
                    {expandedGroups.includes(group.groupName) &&
                      group.childGroups &&
                      group.childGroups.map((childGroupLevel1: any) => (
                        <div key={childGroupLevel1.groupName} className='level1'>
                          <DrawerItem
                            groupData={childGroupLevel1}
                            expandedGroups={expandedGroups}
                            handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                            handleAssetGroupClick={handleAssetGroupClick}
                            handleAssetIdClick={handleAssetIdClick}
                            paddingGroup={'20px'}
                            paddingAsset={'40px'}
                            stickyClass={'sticky top-12 z-9999'}
                            handleRightClick={handleRightClick}
                            handleOptionClick={handleOptionClick}
                            contextMenuRef={contextMenuRef}
                            contextMenu={contextMenu}
                          />
                          <div className='navigation-child-container level2'>
                            {expandedGroups.includes(childGroupLevel1.groupName) &&
                              childGroupLevel1.childGroups &&
                              childGroupLevel1.childGroups.map((childGroupLevel2: any) => (
                                <div key={childGroupLevel2.groupName}>
                                  <DrawerItem
                                    groupData={childGroupLevel2}
                                    expandedGroups={expandedGroups}
                                    handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                    handleAssetGroupClick={handleAssetGroupClick}
                                    handleAssetIdClick={handleAssetIdClick}
                                    paddingGroup={'40px'}
                                    paddingAsset={'60px'}
                                    stickyClass={''}
                                    handleRightClick={handleRightClick}
                                    handleOptionClick={handleOptionClick}
                                    contextMenuRef={contextMenuRef}
                                    contextMenu={contextMenu}
                                  />
                                  <div className='navigation-child-container level3'>
                                    {expandedGroups.includes(childGroupLevel2.groupName) &&
                                      childGroupLevel2.childGroups &&
                                      childGroupLevel2.childGroups.map((childGroupLevel3: any) => (
                                        <div key={childGroupLevel3.groupName}>
                                          <DrawerItem
                                            groupData={childGroupLevel3}
                                            expandedGroups={expandedGroups}
                                            handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                            handleAssetGroupClick={handleAssetGroupClick}
                                            handleAssetIdClick={handleAssetIdClick}
                                            paddingGroup={'60px'}
                                            paddingAsset={'80px'}
                                            stickyClass={''}
                                            handleRightClick={handleRightClick}
                                            handleOptionClick={handleOptionClick}
                                            contextMenuRef={contextMenuRef}
                                            contextMenu={contextMenu}
                                          />
                                          <div className='navigation-child-container level4'>
                                            {expandedGroups.includes(childGroupLevel3.groupName) &&
                                              childGroupLevel3.childGroups &&
                                              childGroupLevel3.childGroups.map((childGroupLevel4: any) => (
                                                <div key={childGroupLevel4.groupName}>
                                                  <DrawerItem
                                                    groupData={childGroupLevel4}
                                                    expandedGroups={expandedGroups}
                                                    handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                                    handleAssetGroupClick={handleAssetGroupClick}
                                                    handleAssetIdClick={handleAssetIdClick}
                                                    paddingGroup={'80px'}
                                                    paddingAsset={'100px'}
                                                    stickyClass={''}
                                                    handleRightClick={handleRightClick}
                                                    handleOptionClick={handleOptionClick}
                                                    contextMenuRef={contextMenuRef}
                                                    contextMenu={contextMenu}
                                                  />
                                                  <div className='navigation-child-container level5'>
                                                    {expandedGroups.includes(childGroupLevel4.groupName) &&
                                                      childGroupLevel4.childGroups &&
                                                      childGroupLevel4.childGroups.map((childGroupLevel5: any) => (
                                                        <div key={childGroupLevel5.groupName}>
                                                          <DrawerItem
                                                            groupData={childGroupLevel5}
                                                            expandedGroups={expandedGroups}
                                                            handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                                            handleAssetGroupClick={handleAssetGroupClick}
                                                            handleAssetIdClick={handleAssetIdClick}
                                                            paddingGroup={'100px'}
                                                            paddingAsset={'120px'}
                                                            stickyClass={''}
                                                            handleRightClick={handleRightClick}
                                                            handleOptionClick={handleOptionClick}
                                                            contextMenuRef={contextMenuRef}
                                                            contextMenu={contextMenu}
                                                          />
                                                          <div className='navigation-child-container level6'>
                                                            {expandedGroups.includes(childGroupLevel5.groupName) &&
                                                              childGroupLevel5.childGroups &&
                                                              childGroupLevel5.childGroups.map((childGroupLevel6: any) => (
                                                                <div key={childGroupLevel6.groupName}>
                                                                  <DrawerItem
                                                                    groupData={childGroupLevel6}
                                                                    expandedGroups={expandedGroups}
                                                                    handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                                                    handleAssetGroupClick={handleAssetGroupClick}
                                                                    handleAssetIdClick={handleAssetIdClick}
                                                                    paddingGroup={'120px'}
                                                                    paddingAsset={'140px'}
                                                                    stickyClass={''}
                                                                    handleRightClick={handleRightClick}
                                                                    handleOptionClick={handleOptionClick}
                                                                    contextMenuRef={contextMenuRef}
                                                                    contextMenu={contextMenu}
                                                                  />
                                                                  <div className='navigation-child-container level7'>
                                                                    {expandedGroups.includes(childGroupLevel6.groupName) &&
                                                                      childGroupLevel6.childGroups &&
                                                                      childGroupLevel6.childGroups.map((childGroupLevel7: any) => (
                                                                        <div key={childGroupLevel7.groupName}>
                                                                          <DrawerItem
                                                                            groupData={childGroupLevel7}
                                                                            expandedGroups={expandedGroups}
                                                                            handleSecondLevelGroupNameClick={handleSecondLevelGroupNameClick}
                                                                            handleAssetGroupClick={handleAssetGroupClick}
                                                                            handleAssetIdClick={handleAssetIdClick}
                                                                            paddingGroup={'140px'}
                                                                            paddingAsset={'160px'}
                                                                            stickyClass={''}
                                                                            handleRightClick={handleRightClick}
                                                                            handleOptionClick={handleOptionClick}
                                                                            contextMenuRef={contextMenuRef}
                                                                            contextMenu={contextMenu}
                                                                          />
                                                                        </div>
                                                                      ))}
                                                                  </div>
                                                                </div>
                                                              ))}
                                                          </div>
                                                        </div>
                                                      ))}
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}

                    {expandedGroups.includes(group.groupName) &&
                      group.assets &&
                      group.assets.map((asset: any) => (
                        <div
                          data-testid={`navbar-${asset.assetId}`}
                          key={asset.assetId}
                          className='navigation-content-frame-asset'
                          onClick={() => {
                            handleAssetIdClick(asset.assetName, asset.assetId, asset.assetName
                              // group.groupName
                            );
                          }}
                        >
                          <div className='navigation-content-frame-item-text'>{asset.assetName}</div>
                        </div>
                      ))}
                  </div>
                ))
                  :
                  <NoData heading='No data found' />
            }
          </div>
        </div>
      </div>
    </>
  );
};

const TrackedNavigationDrawerItem = TrackingDecorator(NavigationDrawerItem);
export default TrackedNavigationDrawerItem;
