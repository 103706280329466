import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './Security.scss';
import { fetchPrivileges, fetchUserById, updateUserPermissions, updateUsers } from '../../UserSlice';
import { Privilege, Role } from '../../../../models/Role';
import { ToastContainer } from 'react-toastify';
import { AppUser } from '../../model/AppUser';
import Loader from '../../../common/page-loader/ComponentLoader';

const AdminSecurity: React.FC<{ value?: string }> = ({ value }) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state?.user?.loading);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const userList = useAppSelector((state) => state.user.users);
  const privileges = useAppSelector((state) => state?.user?.privileges);
  const [currentRoles, setCurrentRoles] = useState<Role[]>([]);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  useEffect(() => {
    const fetchUser = async () => {
      if (value) {
        await dispatch(fetchUserById(value));
      }
    };
    currentUser && !currentUser.id && fetchUser();
  }, [dispatch, value]);

  useEffect(() => {
    if (currentUser?.roles) {
      setCurrentRoles(currentUser.roles);
    }
  }, [currentUser?.roles]);

  useEffect(() => {
    if (!privileges)
      dispatch(fetchPrivileges())
  }, [])

  const isRoleActive = (role: Role) => {
    if (currentRoles?.length) {
      return Math.min(...currentRoles) === role
    }
  }


  const handleRoleClick = async (roleNumber: Role) => {
    setCurrentRoles((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleNumber)
        ? prevRoles.filter((role) => role !== roleNumber)
        : [roleNumber];
      if (currentUser?.id) {
        dispatch(
          updateUserPermissions({
            id: currentUser.id,
            roles: updatedRoles,
          }),
        ).then(() => {
          let tempUsers = [...userList]
          tempUsers = tempUsers?.map((user) => user.id === currentUser.id ? { ...user, roles: updatedRoles, rolesNames: Privilege[Role[roleNumber] as keyof typeof Privilege] } : user)
          dispatch(updateUsers(tempUsers))
          if (currentUser.id === storedUser.id) {
            dispatch(fetchUserById(storedUser.id))
            localStorage.setItem('loggedInUser', JSON.stringify({ ...currentUser, roles: updatedRoles }))
          }
        });
      }

      return updatedRoles;
    });
  };

  return (
    <>
      <div className='profile-info ui pt-6'>
        {/* <div className='p-1 w-100'>
          <div className='card' style={{ margin: '0', width: '100%', maxWidth: 'unset' }}>
            <button className='btn reset btn-primary' disabled>
              Reset Password
            </button>
          </div>
        </div> */}
        {loading ? (
          <div className='flex items-center loader-margin'>
            <Loader />
          </div>
        ) : currentUser ? (
          <div className='security-info'>
            <div className='p-1 w-100'>
              <div className='card' style={{ margin: '0', width: '100%' }}>
                <div className='toggle-wrapper'>
                  <ToastContainer position='bottom-right' autoClose={3000} />
                  {privileges?.map((item) => {
                    const roleKey = item.value.replace(" ", "")
                    const role = Role[roleKey as keyof typeof Role] as number;
                    return (
                      <div className='flex flex flex-column mb-3' key={roleKey}>
                        <label className={`radio-container ${isRoleActive(role) ? 'active-radio-label' : ''}`} >
                          <input
                            type='radio'
                            name={item.value}
                            onChange={() => handleRoleClick(role)} checked={isRoleActive(role)}
                          />
                          <span className='radio-checkmark'></span>
                          {item.value}
                        </label>
                        <span className='description'>{item.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : ''}
      </div>
    </>
  );
};

export default AdminSecurity;
