
export interface HealthScoreImpact {
    afterHealthScore: number,
    beforeHealthScore: number,
    healthScoreDifference: number,
    trend: number
    trendName: string,
    trendPoints: number
}

export interface HealthScoreHistory {
    eventDate: string,
    eventType: string,
    healthScore: number,
    impact: HealthScoreImpact,
    healthScoreImpactStatus: string
}

export interface HealthScore {
    afterHealthScore: number,
    beforeHealthScore: number,
    healthScoreDifference: number,
    trend: number,
    trendName: string,
    trendPoints: number
}
export interface HealthScoreTarget {
    healthScore: number,
    healthScoreStatus: number,
    healthScoreStatusName: string,
    lastUpdated: string,
    healthTargets: HealthTarget[]
}


export interface HealthTarget {
    name: string,
    value: number
}

export enum HealthScoreStatus {
    bad = 'Bad',
    good = 'Good',
    better = 'Better'
}

export enum HealthScoreTrend {
    Up = 1,
    Down = 2
}