import React, { useEffect, useRef, useState } from 'react';
import mockData from "../data/harmonicDistortion.json";
import { HarmonicDetailsProps, HarmonicDistortionData } from './interfaces/harmonic.interfaces';
import HarmonicChart from './charts/HarmonicChart';
import HarmonicModal from './modals/HarmonicModal';
import WIP from '../../../../images/wip1.svg';


const HarmonicDistortionDetails: React.FC<HarmonicDetailsProps> = ({ heading }) => {
    const loading = false;  // Set loading based on your data fetching logic
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<HarmonicDistortionData>({ a: [], b: [], c: [] });
    const hElement = document.getElementById('main-root');

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    const [selectedDateRange,] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });

    const fetchData = async () => {
        const transformedData: HarmonicDistortionData = {
            a: mockData.harmonicDistortionData.a.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            b: mockData.harmonicDistortionData.b.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            c: mockData.harmonicDistortionData.c.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    useEffect(() => {
        fetchData();
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);


    return (
        <>
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                </div>
                <hr />
                <div id="harmonicDistortion-container" className='wellChartSection harmonicDistortionChartSection '>
                    <div className=' flex flex-col justify-center items-center h-full'>
                        <img className='h-[170px]' src={WIP} alt='work in progress' />
                        <p className='mt-6'>Coming soon</p>
                    </div>
                </div>
            </div>
            <HarmonicModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Harmonic distortion'}
                loading={loading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal'}
                innerComponent={
                    <>
                        <HarmonicChart chartID={'harmonicDistortionChartdiv'} chartRef={modalChartRef} data={data} />
                    </>
                }
            />
        </>
    );
};

export default HarmonicDistortionDetails;