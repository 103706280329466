import React, { useEffect, useState } from 'react';
import DashboardHeader from './components/dashboard-header/DashboardHeader';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import TicketDetails from './components/ticket-details/TicketDetails';
import EventSchdule from './components/event-schdule/EventSchdule';
import WellByStatusGraph from './components/graph/well-by-status/WellByStatusGraph';
import ShutdownByReasonGraph from './components/graph/shutdown-by-reason/ShutdownByReasonGraph';
import WellPerformanceTrends from './components/graph/well-performance-trends/WellPerformanceTrends';
import WellProductionTrends from './components/graph/well-production-trends/WellProductionTrends';
import './Dashboard.scss';
import PerformanceSnapshot from './components/performance-snapshot/PerformanceSnapshot';
import AssetLocation from './components/asset-location/AssetLocation';
import UptimeDowntimeGraph from './components/graph/uptime-downtime/UptimeDowntimeGraph';
import { fetchGetPerformanceSnapshot, fetchUrls } from './DashboardSlice';
import { useAppDispatch } from '../../hooks/storeHooks';

interface IntialGraphSetup {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH: number;
  minW: number;
}

const UserDashboard: React.FC = () => {
  const initialLayout: IntialGraphSetup[] = [
    { i: 'Well By Status', x: 0, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5 }, // Adjusted y value
    { i: 'Uptime Downtime', x: 4, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5 }, // Adjusted y value
    { i: 'Shutdown By Reason', x: 8, y: 4, w: 4, h: 4, minH: 1.7, minW: 2.5 }, // Adjusted y value
    { i: 'Well Performance Trends', x: 0, y: 8, w: 6, h: 4 , minH:1.7, minW: 3.7}, // Adjusted y value
    { i: 'Well Production Trends', x: 6, y: 8, w: 6, h: 4, minH:1.7, minW: 3.7 }, // Adjusted y value
  ];

  const dispatch = useAppDispatch();
  const [layout, setLayout] = useState(initialLayout);
  const [dynamicWidth, setDynamicWidth] = useState(calculateDynamicWidth);
  const [isJobsTodayOpen, setIsJobsTodayOpen] = useState(false)
  const [zIndex, setZIndex] = useState(9);
  const groupName = 'All Wells';
  const groupId = '1';

  useEffect(() => {
      dispatch(fetchGetPerformanceSnapshot({ groupName: groupName }));
    
    dispatch(fetchUrls(groupId));
  }, []);

  function calculateDynamicWidth() {
    return window.innerWidth - 130;
  }

  useEffect(() => {
    const handleResize = () => {
      // Update the width based on the calculated value of 100vw - 80px
      setDynamicWidth(calculateDynamicWidth());
    };
    // Initial call to set the initial width
    handleResize();

    window.addEventListener('resize', handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, []);

  const onLayoutChange = (newLayout: IntialGraphSetup[]) => {
    setLayout(newLayout);
  };
  const hadndleEvendSchedulePointerEvent = (todayEventOpenStatus: boolean) => {
    setIsJobsTodayOpen(todayEventOpenStatus)
  }

  const handlePerformanceZindex = (item: any) => {
    if (item.i.startsWith('Well Performance Trends')) {
      setZIndex(15);
    }
  };

  const handleProductionZindex = (item: any) => {
    if (item.i.startsWith('Well Production Trends')) {
      setZIndex(9);
    }
  };

  return (
    <>
      <div className='main-content'>
        <DashboardHeader hadndleEvendSchedulePointerEvent={hadndleEvendSchedulePointerEvent} groupName='My dashboard' />
        <div className='grid grid-cols-2 gap-6 w-100 pb-0 p-9'>
          <div>
            <TicketDetails />
          </div>
          <div>
            <EventSchdule jobScheduleOpenStatus={isJobsTodayOpen} />
          </div>
        </div>
        <div className='w-100 pb-0 p-9'>
          <div className='performanceSnapshot'>
            <PerformanceSnapshot />
            <div className='p-0 grid-container'>
              <GridLayout
                className='layout'
                layout={layout}
                draggableCancel={`.cancelSelectorName, .ReactModalPortal, .noDrag`}
                cols={12}
                rowHeight={75}
                margin={[24, 24]}
                onLayoutChange={onLayoutChange}
                isDraggable={true}
                width={dynamicWidth - 5}
                useCSSTransforms={false}
              >
                {layout.map((item) => (
                  <div key={item.i} className='grid-item dashboard-graphs-wrapper' data-grid={item} 
                  style={{zIndex: item.i.startsWith('Well Performance Trends') ? zIndex : 9}}>
                    {item.i.startsWith('Well By Status') && <div className='grid-item-text h-full graph-card'> <WellByStatusGraph groupName={groupName} /></div>}
                    {item.i.startsWith('Uptime Downtime') && <div className='grid-item-text h-full graph-card'> <UptimeDowntimeGraph groupName={groupName} /> </div>}
                    {item.i.startsWith('Shutdown By Reason') && <div className='grid-item-text h-full graph-card'> <ShutdownByReasonGraph groupName={groupName} /></div>}
                    {item.i.startsWith('Well Performance Trends') && <div className='grid-item-text trends-input h-full graph-card'> <WellPerformanceTrends groupName={groupName} showZindex={() => handlePerformanceZindex(item)} /></div>}
                    {item.i.startsWith('Well Production Trends') && <div className='grid-item-text h-full graph-card'> <WellProductionTrends groupName={groupName} showZindex={() => handleProductionZindex(item)} /></div>}
                  </div>
                ))}
              </GridLayout>
            </div>
          </div>
        </div>
        <div className='w-100 p-9'>
          <AssetLocation groupName={groupName} />
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
