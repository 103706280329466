import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { clearAssetEventSchedule, clearAssetListErrorMessage, fetchAssetListById} from '../../DashboardSlice';
import { AppUser } from '../../../user/model/AppUser';
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import './AssetList.scss';
import NoData from '../no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import axios from 'axios';
import { setFilterTerm } from './AlarmSlice';
import { toast } from 'react-toastify';
import { CalculateRunDays, ConvertTimeToLocal } from '../../../../utilities/CommonFunctions';
import { ReactComponent as SortIconDefault } from '../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../images/Chevron-down-icon.svg';
import { useNavigate } from 'react-router-dom';
import UpTimeChart from './assetTableCharts/UpTimeChart';
import expandIcon from '../../../../images/expand-icon20x20.svg';
import UpTimeModal from './UptimeModal/UpTimeModal';

interface AssetEventSchedule {
  wellName: string;
  lastGoodScan: string;
  alarms: string[];
  runStatus: string;
  comms: string;
  speed: string;
  current: string;
  motorTemp: string;
  intakePressure: string;
  tubingPressure: string;
  vibration: string;
  driveLoad: string;
  installationDate: string;  
  [key: string]: number | string | string[];
}

type SortableKeys = keyof AssetEventSchedule;
interface TabsProps {
  groupName?: string;
}

export const showToaster = (message: string, type: 'success' | 'error' | 'warning' | 'info', autoClose?: boolean | number, infoColor = '#F1C40F') => {
  toast.dismiss();
  toast.clearWaitingQueue();
  toast(message, {
    type,
    autoClose: typeof autoClose === 'boolean' ? (autoClose ? 5000 : false) : autoClose,
    style: {
      color: type === "info" ? infoColor : undefined,
    },
    icon: type === "info" ? <svg width="20" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 10.5V8M7.5 5.5H7.50625M13.75 8C13.75 11.4518 10.9518 14.25 7.5 14.25C4.04822 14.25 1.25 11.4518 1.25 8C1.25 4.54822 4.04822 1.75 7.5 1.75C10.9518 1.75 13.75 4.54822 13.75 8Z" stroke={infoColor} stroke-width="1.33" stroke-linecap="round" stroke-linejoin="round" />
    </svg> : undefined
  })
}

// Function to extract the numeric value from the 'X days' string returned by CalculateRunDays
export const getNumericRunDays = (date: string): number => {
  const runDaysString = CalculateRunDays(date);
  const runDaysNumber = parseInt(runDaysString, 10);
  return isNaN(runDaysNumber) ? 0 : runDaysNumber;
};

export const getValueByKey = (item: AssetEventSchedule, key: keyof AssetEventSchedule) => {
  const value = item[key];
  if (value === null || value === undefined) {
    return ''; // Return empty string if value is null or undefined
  }
  if (Array.isArray(value)) {
    return value.join(', '); // Convert arrays to a string for comparison
  }
  return value; // Convert non-null values to string to ensure safe operations
};

const AssetList: React.FC<TabsProps> = ({ groupName }) => {
  const navigate = useNavigate();
  const loggedInUser = useAppSelector((state) => state?.user?.loggedInUser);
  const storedUserData = localStorage.getItem('loggedInUser');
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  const initialUserState = loggedInUser || storedUser || undefined;
  const dispatch = useAppDispatch();
  const assetEventSchedule = useAppSelector((state) => state.dashboard.assetEventSchedule || []);
  const assetEventTotalCount = useAppSelector((state) => state.dashboard.assestEventTotalCount);
  const listLoading = useAppSelector((state) => state.dashboard.assetListLoading);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState<{ key: SortableKeys; direction: 'asc' | 'desc' } | null>({
    key: 'wellName',
    direction: 'asc',
  });
  const filterTerm = useAppSelector((state) => state?.alarms.filterTerm);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const errorMessage = useAppSelector((state) => state.dashboard.assetListErrorMessage);
  const [contextMenu, setContextMenu] = useState<string>('');
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [upTimeModalOpen, setUpTimeModalOpen] = useState<boolean>(false);
  const [selectedUptimeData, setSelectedUptimeData] = useState<any>([]);
  const hElement = document.getElementById('main-root');

  const handleUptimeModal = (event: React.MouseEvent,dailyUpDowntimeData: any) => {
    event.preventDefault();
    event.stopPropagation();

    setUpTimeModalOpen(true);
    setSelectedUptimeData(dailyUpDowntimeData);
  };

  const handleUpTimeClose = () => {
    setUpTimeModalOpen(false);
    setSelectedUptimeData([]);
    hElement && hElement?.classList.remove('modal-open');
  };

  useEffect(() => {
    if (initialUserState?.id && groupName) {
      setLoading(true);

      cancelTokenSource.current = axios.CancelToken.source();
      dispatch(clearAssetEventSchedule());
      setTimeout(() => {
        const trimmedFilterTerm = filterTerm?.trim();

        if (!trimmedFilterTerm) {
          dispatch(fetchAssetListById({
            groupName, startIndex: 0, endIndex: 25,
            searchQuery: ""
            , cancelToken: cancelTokenSource?.current?.token
          })).then(() => {           
            setLoading(false);
          })
        }
      },);

    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Component unmounted or route changed.');
      }
    }
  }, [groupName, initialUserState?.id]);

  useEffect(() => {
    dispatch(setFilterTerm(""));
  }, [])



  useEffect(() => {
    if (assetEventSchedule.length == assetEventTotalCount) {
      setAllDataLoaded(true);
    } else {
      setAllDataLoaded(false);
    }
  }, [assetEventSchedule.length])

  const sortedAssetEventSchedule = useMemo(() => {
    const sortableItems = [...assetEventSchedule];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = getValueByKey(a as unknown as AssetEventSchedule, sortConfig.key);
        const bValue = getValueByKey(b as unknown as AssetEventSchedule, sortConfig.key);
        if (aValue == null || bValue == null) return 0;
        // If the sort key is 'installationDate', calculate run days and compare
        if (sortConfig.key === 'installationDate') {
          const aRunDays = getNumericRunDays(aValue.toString());
          const bRunDays = getNumericRunDays(bValue.toString());
          return (aRunDays - bRunDays) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
            return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else {
            return String(aValue).localeCompare(String(bValue)) * (sortConfig.direction === 'asc' ? 1 : -1);
        }
      });
    }
    return sortableItems;
  }, [assetEventSchedule.length, sortConfig]);


  const observer = useRef<IntersectionObserver>();
  const assetlistTable = document.getElementById('assetlistTable');
  const lastDocumentElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || allDataLoaded) return;
      if (observer.current) observer.current.disconnect();
      if (errorMessage) {
        if (assetlistTable) {
          assetlistTable.scrollTop = assetlistTable.scrollTop - 100;
        }
        dispatch(clearAssetListErrorMessage());
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && initialUserState && groupName && assetEventTotalCount >= assetEventSchedule.length) {
          setLoading(true);
          if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel('Operation canceled due to new request.');
          }
          cancelTokenSource.current = axios.CancelToken.source();
          const trimmedFilterTerm = filterTerm?.trim();
          dispatch(fetchAssetListById({

            groupName,
            startIndex: assetEventSchedule?.length + 1,
            endIndex: assetEventSchedule?.length + 25,
            searchQuery: trimmedFilterTerm ? `?filter=${encodeURIComponent(trimmedFilterTerm)}` : "",
            cancelToken: cancelTokenSource.current.token
          }))
            .then(() => {
              setLoading(false);
            });

        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, assetEventSchedule?.length, groupName, initialUserState, allDataLoaded],
  );

  const useDebouncedEffect = (effect: React.EffectCallback, deps: any[], delay: number) => {
    const firstRender = useRef(true);

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }

      const handler = setTimeout(() => {
        effect();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [...deps, delay]);
  };

  useDebouncedEffect(() => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel('Operation canceled due to new request.');
    }
    cancelTokenSource.current = axios.CancelToken.source();
    dispatch(clearAssetEventSchedule())
    setTimeout(() => {
      if (cancelTokenSource && initialUserState && groupName && assetEventTotalCount >= assetEventSchedule.length) {
        setAllDataLoaded(false);
        const trimmedFilterTerm = filterTerm?.trim();
        dispatch(fetchAssetListById({

          groupName,
          startIndex: 0,
          endIndex: 25,
          searchQuery: trimmedFilterTerm ? "?filter=" + encodeURIComponent(trimmedFilterTerm) : "",
          cancelToken: cancelTokenSource?.current?.token,
        })).then(() => {          
          setLoading(false);
        })
      }
    },);


  }, [filterTerm], 1000);

  const requestSort = (key: SortableKeys) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }

    const groupList = sortedAssetEventSchedule?.map((well) => ({
      assetId: well?.assetID,
      assetName: well?.wellName,
      industryApplicationId: 4,
    }));

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate(`/layout/assets/?tab=details&wellName=${wellName}`);
  };

  const handleRightClick = (e: any, assetID: string) => {
    e.preventDefault();
    setContextMenu(assetID);
  };

  const handleOptionClick = (action: string, wellName: string, assetID: string) => {
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }

    const groupList = sortedAssetEventSchedule?.map((well) => ({
      assetId: well?.assetID,
      assetName: well?.wellName,
      industryApplicationId: 4,
    }));

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    if (action === "open-new-tab") {
      const targetPath = `/layout/assets/?tab=details&wellName=${wellName}`;
      const fullUrl = `${window.location.origin}${targetPath}`;
      window.open(fullUrl, "_blank");
    }
    setContextMenu(''); // Close the context menu
  };

  const handleContextMenuClickOutside = (event: MouseEvent) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
      setContextMenu('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleContextMenuClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleContextMenuClickOutside);
    };
  }, []);

  return (
    <div className='w-100 p-5 pt-3'>
      <div className={`asset-list__card relative`}>
        {loading && (
          <Loader />
        )}
        {assetEventSchedule?.length && (
          <>
            <div className='asset-list__header'>
              <div className='asset-list__row'>
                <div className='asset-list__cell-one head' onClick={() => requestSort('wellName')}>
                  Well name{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'wellName' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one uptimehead' onClick={() => requestSort('uptime')}>
                  7-day uptime{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'uptime' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('api')}>
                  API{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'api' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('longitude')}>
                  Lat/Long{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'longitude' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one lat' onClick={() => requestSort('lastGoodScan')}>
                  Last communication{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'lastGoodScan' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('operator')}>
                  Operator{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'operator' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('route_Field')}>
                  Route/Field{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'route_Field' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('alarms')}>
                  Alarms{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'alarms' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('installationDate')}>
                  Run days
                  <span className='sort-icon'>
                    {sortConfig?.key === 'installationDate' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('runStatus')}>
                  Status{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'runStatus' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('frequency')}>
                  Frequency{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'frequency' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('motorTemp')}>
                  Motor temp
                  <span className='sort-icon'>
                    {sortConfig?.key === 'motorTemp' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('motorCurrent')}>
                  Motor current
                  <span className='sort-icon'>
                    {sortConfig?.key === 'motorCurrent' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}{' '}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('casingPressure')}>
                  Casing pressure{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'casingPressure' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('tubingPressure')}>
                  Tubing pressure{' '}
                  <span className='sort-icon'>
                    {sortConfig?.key === 'tubingPressure' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('intakePressure')}>
                  Intake pressure
                  <span className='sort-icon'>
                    {sortConfig?.key === 'intakePressure' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('intakeTemperature')}>
                  Intake temp
                  <span className='sort-icon'>
                    {sortConfig?.key === 'intakeTemperature' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one powerCon' onClick={() => requestSort('powerConsumption')}>
                  Power consumption
                  <span className='sort-icon'>
                    {sortConfig?.key === 'powerConsumption' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('incomingHarmonic')}>
                  Input harmonic
                  <span className='sort-icon'>
                    {sortConfig?.key === 'incomingHarmonic' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
                <div className='asset-list__cell-one' onClick={() => requestSort('outputHarmonic')}>
                  Output harmonic
                  <span className='sort-icon'>
                    {sortConfig?.key === 'outputHarmonic' ? (
                      sortConfig.direction === 'asc' ? (
                        <SortIconUp alt='sort-asc' className='sort-img' />
                      ) : (
                        <SortIconDown alt='sort-dsc' className='sort-img' />
                      )
                    ) : (
                      <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className={`asset-list__table ${loading || listLoading ? 'table-opacity' : ''}`} >

              <div id="assetlistTable" className='asset-list__main-content'>
                {sortedAssetEventSchedule?.map((event, index) => (
                  <div className='asset-list__row' ref={index + 1 == sortedAssetEventSchedule?.length ? lastDocumentElementRef : null}>
                    <div
                      key={index}
                      className='asset-list__cell-two relative'
                    >
                      <div
                        className='flex items-center h-full w-full'
                        onContextMenu={(e) => handleRightClick(e, event?.assetID ?? '')}
                        onClick={() => handleWellName(event.wellName ?? '', event?.assetID ?? '')}
                      >
                        {event.wellName ?? '-'}
                      </div>
                      {contextMenu === event?.assetID && (
                        <div
                          ref={contextMenuRef}
                          style={{
                            position: "absolute",
                            top: '25px',
                            left: '67%',
                            width: '200px',
                          }}
                          className='dropdown-options'
                        >
                          <button
                            onClick={() => handleOptionClick("open-new-tab", event.wellName ?? '', event?.assetID ?? '')}
                          >
                            Open in new tab
                          </button>
                        </div>
                      )}
                    </div>
                    <div className='asset-list__cell uptime-chart-body'>
                      <div className='flex flex-row asset-table-uptime-chart'>
                        <div className='flex flex-row'>
                          {<span className='flex items-center w-[40px]'>{event?.uptime + '%'}</span>}
                          <UpTimeChart data={event?.dailyUpDowntimeData  || []} />                        
                        </div>
                        <button
                            className='expand-btn'
                            onClick={(e) => (handleUptimeModal(e, event?.dailyUpDowntimeData))}
                            style={{ cursor: 'pointer', zIndex: 0 }}
                        >
                          <img src={expandIcon} alt='Expand' className='img-border' />
                        </button>
                      </div>
                    </div>
                    <div className='asset-list__cell'>{event.api ?? '-'}</div>
                    <div className='asset-list__cell'>
                      {event.latitude}{' '} {event.longitude ?? '-'}
                    </div>
                    <div className='asset-list__cell lat'>{event?.lastGoodScan ? ConvertTimeToLocal(event?.lastGoodScan) : "-"}</div>
                    <div className='asset-list__cell'>{event.operator ?? '-'}</div>
                    <div className='asset-list__cell'>{event.route_Field ?? '-'}</div>
                    <div className='asset-list__cell'>{event.alarms ?? '-'}</div>
                    <div className='asset-list__cell'>{event?.installationDate ? CalculateRunDays(event?.installationDate) : "-"}</div>
                    <div className='asset-list__cell'>{event.runStatus ?? '-'}</div>
                    <div className='asset-list__cell'>{event.frequency ?? '-'}</div>
                    <div className='asset-list__cell'>{event.motorTemp ?? '-'}</div>
                    <div className='asset-list__cell'>{event.motorCurrent ?? '-'}</div>
                    <div className='asset-list__cell'>{event.casingPressure ?? '-'}</div>
                    <div className='asset-list__cell'>{event.tubingPressure ?? '-'}</div>
                    <div className='asset-list__cell'>{event.intakePressure ?? '-'}</div>
                    <div className='asset-list__cell'>{event.intakeTemperature ?? '-'}</div>
                    <div className='asset-list__cell powerCon'>{event.powerConsumption ?? '-'}</div>
                    <div className='asset-list__cell'>{event.incomingHarmonic ?? '-'}</div>
                    <div className='asset-list__cell'>{event.outputHarmonic ?? '-'}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        <div style={{ display: (listLoading || loading) || assetEventSchedule?.length ? 'none' : 'block' }}>
          <NoData heading='No data found' />
        </div>
      </div>
      <UpTimeModal 
      isOpen={upTimeModalOpen}
      onRequestClose={handleUpTimeClose}
      selectedUptimeData={selectedUptimeData}
      heading={'Uptime / Downtime'}
      modelWidth={'70rem'}
      modelClass={'uptimeModal'}
      />
    </div>
  );
};

export default AssetList;
