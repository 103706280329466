import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchDropdown, addNewUser, fetchPrivileges } from './AddUserSlice';
import Dropdown from './common/dropdown/dropdown';
import { UserFormData } from './AddUserTypes';
import { validateForm } from './ValidateForm';
import './addUser.scss';
import { fetchOperators, fetchUsers } from '../UserSlice';
import { showToaster } from '../../dashboard/components/asset-location/AssetList';

const spinnerHTML = (
  <svg
    aria-hidden='true'
    className='inline w-6 h-6 text-white animate-spin dark:text-gray-600 fill-gray-700'
    viewBox='0 0 100 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
      fill='currentColor'
    />
    <path
      d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
      fill='currentFill'
    />
  </svg>
);
interface AddUserProps {
  handleCloseUserAddModal: () => void;
}

const AddUser = ({ handleCloseUserAddModal }: AddUserProps) => {
  const dispatch = useAppDispatch();
  const { dropdownOptions, addUserLoading, privileges } = useAppSelector((state) => state.addUserSlice);

  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    customerID: '',
    jobTitle: '',
    officeNumber: '',
    mobileNumber: '',
    phoneExtension: '',
    pager: '',
    countryCodeMobile: '',
    countryCodeOffice: '',
    roles: [] as number[],
  };

  const [formData, setFormData] = useState<UserFormData>(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('Select a customer');

  useEffect(() => {
    dispatch(fetchDropdown());
    dispatch(fetchPrivileges());
  }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (touched[name]) {
      const validationErrors = validateForm(formData, selectedCustomer);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    }
  };

  const handleBlur = (fieldName: string) => {
    setTouched((prev) => ({ ...prev, [fieldName]: true }));
    const validationErrors = validateForm(formData, selectedCustomer);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: validationErrors[fieldName],
    }));
  };

  const handleDropdownChange = (item: any) => {
    setSelectedCustomer(item.customerName);
    setFormData((prevData) => ({
      ...prevData,
      company: item.customerName,
      customerID: String(item.customerId),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formErrors = validateForm(formData, selectedCustomer);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const payload = { 
        ...formData ,
        status: 'Active',
        lastLoginDate: null,
      };

      await dispatch(addNewUser(payload)).then((res) => {
        if (addNewUser.fulfilled.match(res)) {
          showToaster('User created successfully', 'success');
          handleCloseUserAddModal();
          dispatch(fetchUsers());
          dispatch(fetchOperators());

        } else {
          if (res.payload && typeof res.payload === 'object' && 'apiMessage' in res.payload) {
            showToaster((res.payload as { apiMessage: string }).apiMessage, 'error');
          } else {
            showToaster('An error occurred', 'error');
          }
        }
      });
    } else {
      setIsFormValid(false);
    }
  };

  useEffect(() => {
    const formErrors = validateForm(formData, selectedCustomer);
    setErrors(formErrors);
    setIsFormValid(Object.keys(formErrors).length === 0);
  }, [formData]);

const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, role: number) => {
  setFormData((prevData) => ({
    ...prevData,
    roles: [role],
  }));
};

  const handleCancelClick = () => {
    setFormData(initialFormData);
    setErrors({});
    setSelectedCustomer('Select customer');
    handleCloseUserAddModal();
  };

  return (
    <>
      <div style={{ position: 'relative' }} className='onboard-modal'>
        <form className='add-user-form onboarding-form' onSubmit={handleSubmit}>
          <div className='onboard-modal-header'>
            <label className='onboard-modal-title'>Add new user</label>
          </div>
          <div className='onboard-modal-content'>
            <div className='onboard-form-row grid grid-cols-2 gap-4'>
              <div className='onboard-form-group'>
                <label>
                  <span>Customer name</span>
                  <span className='required-asterisk'>*</span>
                </label>
                <Dropdown
                  items={dropdownOptions.customerID}
                  value={selectedCustomer}
                  onChange={(item) => handleDropdownChange(item)}
                />
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>First name</span>
                  <span className='required-asterisk'>*</span>
                </label>
                <input
                  type='text'
                  name='firstName'
                  value={formData.firstName}
                  onChange={handleInputChange}
                  onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^A-Za-z]/g, '')}
                  autoComplete='off'
                  onBlur={() => handleBlur('firstName')}
                />
                  {touched.firstName && errors.firstName && <span className="error">{errors.firstName}</span>}
              </div>
              <div className='onboard-form-group'>
                <label>
                  <span>Last name</span>
                  <span className='required-asterisk'>*</span>
                </label>
                <input
                  type='text'
                  name='lastName'
                  value={formData.lastName}
                  onChange={handleInputChange}
                  onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^A-Za-z]/g, '')}
                  autoComplete='off'
                  onBlur={() => handleBlur('lastName')}
                />
                 {touched.lastName && errors.lastName && <span className="error">{errors.lastName}</span>}
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Job title</span>
                  <span className='required-asterisk'>*</span>
                </label>
                <input
                  type='text'
                  name='jobTitle'
                  value={formData.jobTitle}
                  onChange={handleInputChange}
                  autoComplete='off'
                  onBlur={() => handleBlur('jobTitle')}
                />
                {touched.jobTitle && errors.jobTitle && <span className="error">{errors.jobTitle}</span>}
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Email address</span>
                  <span className='required-asterisk'>*</span>
                </label>
                <input
                  type='text'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  autoComplete='off'
                  onBlur={() => handleBlur('email')}
                />
                {touched.email && errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Mobile phone number</span>
                </label>
                <div className='onboard-number-input'>
                  <input
                    type='text'
                    name='countryCodeMobile'
                    value={formData.countryCodeMobile}
                    onChange={handleInputChange}
                    autoComplete='off'
                    className='code-input'
                    onBlur={() => handleBlur('countryCodeMobile')}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^+\d]/g, '');
                      if (!input.value.startsWith('+')) {
                        input.value = input.value.replace(/\+/g, '');
                      }
                      if (input.value.length > 4) {
                        input.value = input.value.slice(0, 4);
                      }
                    }}
                  />
                  <input
                    type='text'
                    name='mobileNumber'
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    autoComplete='off'
                    className='number-input'
                    onBlur={() => handleBlur('mobileNumber')}
                    onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '')}
                  />
                  {touched.mobileNumber && errors.mobileNumber && <span className="error">{errors.mobileNumber}</span>}
                  {touched.countryCodeMobile && errors.countryCodeMobile && (<span className="error country-code-error">{errors.countryCodeMobile}</span>)}
                </div>
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Office phone number</span>
                </label>
                <div className='onboard-number-input'>
                  <input
                    type='text'
                    name='countryCodeOffice'
                    value={formData.countryCodeOffice}
                    onChange={handleInputChange}
                    autoComplete='off'
                    className='code-input'
                    onBlur={() => handleBlur('countryCodeOffice')}
                    onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^\d+]/g, '')}
                  />
                  <input
                    type='text'
                    name='officeNumber'
                    value={formData.officeNumber}
                    onChange={handleInputChange}
                    autoComplete='off'
                    className='number-input'
                    onBlur={() => handleBlur('officeNumber')}
                    onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '')}
                  />
                  {touched.officeNumber && errors.officeNumber && <span className="error">{errors.officeNumber}</span>}
                  {touched.countryCodeOffice && errors.countryCodeOffice && (<span className="error country-code-error">{errors.countryCodeOffice}</span>)}
                </div>
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Extension</span>
                </label>
                <input
                  type='text'
                  name='phoneExtension'
                  value={formData.phoneExtension}
                  onChange={handleInputChange}
                  autoComplete='off'
                  onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '')}
                />
              </div>

              <div className='onboard-form-group'>
                <label>
                  <span>Pager</span>
                </label>
                <input
                  type='text'
                  name='pager'
                  value={formData.pager}
                  onChange={handleInputChange}
                  autoComplete='off'
                  onInput={(e) => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.replace(/[^0-9]/g, '')}
                />
              </div> 
            </div>

            <div className='onboard-user-security'>
              <label>
                <span>User security settings</span>
                <span className='required-asterisk'>*</span>
              </label>
              <span className='form-user-roles'>
              {privileges && Object.entries(privileges).map(([roleId, roleName]) => {
                const numericRoleId = roleId;
                return (
                  <div className='settings-form-group flex gap-x-1' key={numericRoleId}>
                    <input
                      id={`roles-${numericRoleId}`}
                      type='radio'
                      name='userRole'
                      className='settings-radio-input'
                      onChange={(e) => handleRadioChange(e, +numericRoleId)}
                      checked={formData.roles.includes(+numericRoleId)}
                    />
                    <label htmlFor={`roles-${numericRoleId}`} className='settings-label'>
                      {roleName}
                    </label>
                  </div>
                );
              })}
              </span>
            </div>

            <div className='form-mandatory-message'>
              <p className='mandatory-fields-message'>
                Fields marked with<span className='required-asterisk'>*&nbsp;</span> are mandatory
              </p>
            </div>
          </div>
          <div className='onboard-modal-footer'>
            <button type='button' className='cancel-button btn btn-lg' onClick={handleCancelClick}>
              Cancel
            </button>

            <button
              type='submit'
              disabled={!isFormValid || addUserLoading}
              className={`add-user-button btn btn-primary btn-lg ${
                isFormValid || addUserLoading ? '' : 'opacity-50 cursor-not-allowed'
              }`}
            >
              {addUserLoading && spinnerHTML}
              <span className='ml-2'>Add User</span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUser;
