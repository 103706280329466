import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { setFilterTerm, toggleShowAlarms, toggleShowWeather } from './AlarmSlice';
import SearchIcon from '../../../../images/search-icon.svg';
import cloudSun from '../../../../images/weather-icon.svg';
import alerttriangle from '../../../../images/alert-triangle.png';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import './AssetTab.scss';
import { slugify } from '../../../../utilities/Slugify';
import { useNavigate } from 'react-router-dom';

interface Tab {
  label: string;
  component: React.ComponentType<any>;
}

interface TabsProps {
  tabs: Tab[];
  assetDetails?: boolean;
  groupName?: string;
}

const AssetTabs: React.FC<TabsProps> = ({ tabs, assetDetails = false, groupName }) => {
  const defaultTabIndex = 0;
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const showAlarms = useAppSelector((state) => state.alarms.showAlarms);
  const showWeather = useAppSelector((state) => state.alarms.showWeather);
  const filterTerm = useAppSelector((state) => state.alarms.filterTerm);
  const navigate = useNavigate();
  const [forceUpdate, setForceUpdate] = useState(0);

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
    if (currentTabFromQuery) {
      const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      } else {
        setActiveTabIndex(defaultTabIndex);
        if (activeTabIndex === defaultTabIndex)
          setForceUpdate((prev) => prev + 1)
      }
    } else {
      setActiveTabIndex(defaultTabIndex);
      if (activeTabIndex === defaultTabIndex)
        setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, tabs]);

  useEffect(() => {

    if (activeTabIndex === null) return;

    const newTabSlug = slugify(tabs[activeTabIndex].label);
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('innerTab');
    // Only navigate if the tab slug in the URL is differents
    if (currentTabSlug !== newTabSlug) {
      params.set('innerTab', newTabSlug)
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [activeTabIndex, forceUpdate]);

  const handleShowAlarmsToggle = () => {
    dispatch(toggleShowAlarms());
  };

  const handleFilterTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterTerm(e.target.value));
  };

  const handleShowWeatherToggle = () => {
    dispatch(toggleShowWeather());
  };



  const renderSearchInput = () => {
    return (
      <div
        className='input-group'
        style={{ width: '325px', marginRight: '24px', marginTop: (tabs[activeTabIndex || defaultTabIndex].label === 'List' || tabs[activeTabIndex || defaultTabIndex].label === 'Alarms') ? '1rem' : '0' }}
      >
        <div className='icon-block'>
          <img src={SearchIcon} alt='Search Icon' style={{ pointerEvents: 'visible', cursor: 'pointer' }} />
        </div>
        <input
          className='custom-input search'
          placeholder='Search'
          value={filterTerm}
          onChange={handleFilterTermChange}
          style={{ padding: '10px 14px 10px 32px' }}
        />
      </div>
    );
  };

  const renderAdditionalBlocks = () => {
    if (tabs[activeTabIndex || defaultTabIndex].label === 'List' || tabs[activeTabIndex || defaultTabIndex].label === 'Alarms') {
      return renderSearchInput();
    } else {
      return (
        <>
          <div className='block' style={{ display: 'none' }}>
            <img src={alerttriangle} alt='' className='me-2' />
            <p>Show Alarms Only</p>
            <ToggleSwitch checked={showAlarms} onChange={handleShowAlarmsToggle} label='' value='' />
          </div>
          <div className='block'>
            <img src={cloudSun} alt='' className='me-2' />
            <p>Show weather</p>
            <ToggleSwitch checked={showWeather} onChange={handleShowWeatherToggle} label='' value='' />
          </div>
          <div className='input-group' style={{ width: '325px', marginRight: '10px', marginTop: '10px' }}>
            <div className='icon-block'>
              <img src={SearchIcon} alt='Search Icon' />
            </div>
            {renderSearchInput()}
          </div>
        </>
      );
    }
  };

  const mapToggle = () => {
    if (tabs[activeTabIndex || defaultTabIndex].label === 'Map') {
      return (
        <>
          <div className='block' style={{ display: 'none' }}>
            <img src={alerttriangle} alt='' className='me-2' />
            <p>Show Alarms Only</p>
            <ToggleSwitch checked={showAlarms} onChange={handleShowAlarmsToggle} label='' value='' />
          </div>
          <div className='block'>
            <img src={cloudSun} alt='' className='me-2' />
            <p>Show weather</p>
            <ToggleSwitch checked={showWeather} onChange={handleShowWeatherToggle} label='' value='' />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className={`wrapper-location ${assetDetails && 'asset-details'}`}>
        <div className='ui-button-toolbar'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button ${index === activeTabIndex ? 'ui-button-toolbar-button-active' : ''
                }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <div className='show-alram-traffic'>
          {!assetDetails && renderAdditionalBlocks()}
          {assetDetails && mapToggle()}
        </div>
      </div>
      <div className='tab-content'>
        {tabs.map((tab, index) => {
          return (
            <div key={index} style={{ display: index === activeTabIndex ? 'block' : 'none' }}>
              {React.createElement(tab?.component, { groupName, } as React.Attributes)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AssetTabs;
