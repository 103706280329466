import React from 'react'
import { GasLockRecoveryVariable, setPointParamStandardTypeId } from '../../model/ManageAssetModel'
import SetpointBackupValue from '../SetpointBackupValue'
import { useManageAsset } from '../hook/ManageAssetHook'


interface GasLockRecoveryProps {
    groupName: keyof typeof setPointParamStandardTypeId
}


const GasLockRecovery: React.FC<GasLockRecoveryProps> = ({ groupName }) => {


    const { getSetpointItem } = useManageAsset()

    const getSetpointInput = (placeholder: string, groupName: keyof typeof setPointParamStandardTypeId, setpointName: string, isTimeInput?: boolean) => {

        const setpointItem = getSetpointItem(groupName, setpointName);

        return setpointItem ? (
            <SetpointBackupValue setPointItem={setpointItem} groupName={groupName} setpointName={setpointName} isTimeInput={isTimeInput} />
        ) : (
            <input
                type='text'
                className='time-input-field disabled'
                placeholder={placeholder}
            />
        );
    }

    return (
        <div className='gas-lock-recovery-container'>
            <div className='flex flex-col space-y-4'>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Start delay [HH:MM:SS]</label>
                        {
                            getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.StartDelay, true)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Low amperage setpoint [A]</label>
                        {
                            getSetpointInput('0', groupName, GasLockRecoveryVariable.LowAmperageSetpoint)
                        }
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Trigger delay [HH:MM:SS]</label>
                        {
                            getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.TriggerDelay, true)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Reset delay [HH:MM:SS]</label>
                        {
                            getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.ResetDelay, true)
                        }
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Attempts allowed</label>
                        {
                            getSetpointInput('0', groupName, GasLockRecoveryVariable.AttemptsAllowed)
                        }
                    </div>
                    {/* <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Attempts</label>
                        <input
                            type='text'
                            className='time-input-field disabled'
                            placeholder='0'
                        />
                    </div> */}
                </div>

                <div className='steps-table'>
                    <div className='table-row grid grid-cols-3 gap-2'>
                        <div className='table-col'></div>
                        <div className='table-col'>Frequency [Hz]</div>
                        <div className='table-col'>Time [HH:MM:SS]</div>
                    </div>
                    <div className='table-row grid grid-cols-3 gap-2'>
                        <div className='table-col'>Step 1</div>
                        <div className='table-col'>
                            {
                                getSetpointInput('0', groupName, GasLockRecoveryVariable.Step1Frequency)
                            }
                        </div>
                        <div className='table-col'>
                            {
                                getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.Step1Duration, true)
                            }
                        </div>
                    </div>

                    <div className='table-row grid grid-cols-3 gap-2'>
                        <div className='table-col'>Step 2</div>
                        <div className='table-col'>
                            {
                                getSetpointInput('0', groupName, GasLockRecoveryVariable.Step2Frequency)
                            }
                        </div>
                        <div className='table-col'>
                            {
                                getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.Step2Duration, true)
                            }
                        </div>
                    </div>

                    <div className='table-row grid grid-cols-3 gap-2'>
                        <div className='table-col'>Step 3</div>
                        <div className='table-col'>
                            {
                                getSetpointInput('0', groupName, GasLockRecoveryVariable.Step3Frequency)
                            }
                        </div>
                        <div className='table-col'>
                            {
                                getSetpointInput('00:00:00', groupName, GasLockRecoveryVariable.Step3Duration, true)
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default GasLockRecovery