import { http } from "../../../../config/HttpCommon";

export const getAnalysisTabDetails = async ( projectId: string, analysisType: string) => {
    const response = await http.get(`/GetAnalysisTabDetails/${encodeURIComponent(projectId)}/${analysisType}`);
    return response.data;
};

export const GetMainReportData = async (wellApiNumber: string) => {
    const response = await http.get(`/GetMainReportData?wellApiNumber=${wellApiNumber}`);
    return response.data;
}