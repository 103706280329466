import React, { useEffect, useRef, useState } from 'react';
// import infinity from '../../../../images/infinity.svg';

// import refresh from '../../../../images/refresh-ccw-01.svg';
import trendup from '../../../../images/trend-up-01.svg';
import okIcon from '../../../../images/check-circle.svg';
import assetControl from '../../../../images/slider-05.svg';
import chevronDown from '../../../../images/chevron-down.svg';
import ticket from '../../../../images/ticket-02.svg';
import infoCircle from '../../../../images/info-circle.svg';
import tool from '../../../../images/tool-02.svg';
import { Options } from '../../data/Options';
import SchduledSpeedChangeFrequency from '../schduled-speed-change/SchduledSpeedChangeFrequency';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchControllerDetails } from './AssetDetailsHeaderSlice';
import { AppUser } from '../../../user/model/AppUser';
import { ControllerDetails } from './model/ControllerDetails';
import { ToastContainer, toast } from 'react-toastify';
import Tooltip from '../../../common/tooltip/ToolTip';
import { fetchAssetControl, fetchWebSocketTimeoutValue, resetAssetControlActionStatus } from '../../../asset/AssetControlSlice';
import AssetControlActions from './AssetControlActions';
import SuccessfullToast from '../../../common/toast/SuccessfullToast';
import ErrorToast from '../../../common/toast/ErrorToast';
import { fetchUserById } from '../../../user/UserSlice';
import { Role } from '../../../../models/Role';
import { looupKeys } from './AssetDetailsHeaderService';



interface WellControlOptionsProps {
  onSelectOption: (option: Options) => void;
  selectedOption: Options;
}

export enum Action {
  Unknown = 0,
  ProcessDeviceScan = 1,
  StartWell = 2,
  StopWell = 3,
  IdleWell = 4,
  ClearAlarms = 5,
  CommunicationLog = 18,
  ConstantRunMode = 6,
  PocMode = 7,
  PercentTimerMode = 8,
  SetClock = 9,
  UpdatePocData = 10,
  GetCard = 11,
  DownloadEquipment = 12,
  UploadEquipment = 13,
  EnablePid = 14,
  DisablePid = 15,
  GetData = 16,
  GetCardDirect = 17,
  CaptureRegisterLogs = 19,
  OnOffCycles = 20,
  StartPortLogging = 21,
  StopPortLogging = 22,
}

const WellControlOptions: React.FC<WellControlOptionsProps> = ({ onSelectOption, selectedOption }) => {
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;
  let initialUserState = useAppSelector((state) => state?.user?.currentUser);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSchduledSpeedChangeFrequency, setSchduledSpeedChangeFrequency] = useState(false);
  const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName)
  const assetControlData = useAppSelector((state) => state?.assetControl)

  const wellStatus = useAppSelector((state) => state?.assetControl?.wellStatus)
  const selectedAssetNameRef = useRef<string | undefined>();
  const { assetDetail } = useAppSelector((state) => state?.assetDetail);
  const pocType = assetDetail?.assetDetails.pocType || ''

  const systemControllerPocType = ['529', '38']
  const harmonicControllerPocType = ['529', '528', '38', '36']


  if (initialUserState?.id != storedUser?.id)
    initialUserState = storedUser


  useEffect(() => {
    const fetchUser = async () => {
      if (storedUser?.id)
        if (initialUserState?.id != storedUser?.id) {
          dispatch(fetchUserById(storedUser?.id))
        }
    };

    fetchUser();

    if (!assetControlData.webSocketTimoutValue) {
      dispatch(fetchWebSocketTimeoutValue(looupKeys.webSocketTimeOut))
    }
  }, []);
  useEffect(() => {
    if (selectedAssetNameRef.current !== selectedAssetName) {
      if (selectedAssetName) {
        dispatch(fetchAssetControl(selectedAssetName))
      }
      selectedAssetNameRef.current = selectedAssetName;
      if (assetControlData.activeAssetControlActionAssetName !== selectedAssetName) {
        if (assetControlData.assetControlScanStatus || assetControlData.stopActionProgressStatus || assetControlData.startActionProgressStatus || assetControlData.clearAlarmActionProgressStatus) {
          dispatch(resetAssetControlActionStatus())
        }
      }
    }
  }, [selectedAssetName])





  const openDropdown = async () => {
    setIsDropdownOpen(true);
  };
  const closeModal = () => {
    setSchduledSpeedChangeFrequency(false);
  };

  const handleSchduledSpeedChangesSelect = () => {
    setSchduledSpeedChangeFrequency(true);
    setIsDropdownOpen(false);
  };

  const handleHarmonicController = async (option: Options) => {
    if (initialUserState?.id && option?.wellName) {
      const resultAction = await dispatch(fetchControllerDetails({
        nodeId: option.wellName,
        controllerType: 'Harmonic'
      }));

      if (fetchControllerDetails.fulfilled.match(resultAction)) {
        const controllerDetails = resultAction.payload as ControllerDetails;
        if (controllerDetails.ipAddress && controllerDetails.port) {
          window.open(`https://${controllerDetails.ipAddress}:${controllerDetails.port}`, '_blank');
        } else {
          toast.error('Controller details are not available.!');
        }
      } else {
        toast.error('Failed to fetch controller details.!');
      }
    }
    setIsDropdownOpen(false);
    onSelectOption(option);
  };

  const handleSystemController = async (option: Options) => {
    if (initialUserState?.id && option?.wellName) {
      const resultAction = await dispatch(fetchControllerDetails({
        nodeId: option.wellName,
        controllerType: 'System'
      }));

      if (fetchControllerDetails.fulfilled.match(resultAction)) {
        const controllerDetails = resultAction.payload as ControllerDetails;
        if (controllerDetails.ipAddress && controllerDetails.port) {
          window.open(`${pocType === '38' ? 'https' : 'http'}://${controllerDetails.ipAddress}:${controllerDetails.port}`, '_blank');
        } else {
          toast.error('Controller details are not available.!');
        }

      } else {
        toast.error('Failed to fetch controller details.!');
      }
    }
    setIsDropdownOpen(false);
    onSelectOption(option);
  };


  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement && event.target.closest('.dropdown-container')) return;
      setIsDropdownOpen(false);
    };
    document.body.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.body.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleOpenTicket = () => {
    const email = 'optimizationhelp@championx.com';
    const subject = `LOOKOUT Support -- ${selectedAssetName}`;

    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    // Open the email client
    window.location.href = mailtoLink;
  }

  return (
    <>

      {
        assetControlData?.executeControlActionShowSuccess &&
        <SuccessfullToast
          message={assetControlData?.executeControlActionMessage}
          isShowEnabled={assetControlData?.executeControlActionShowSuccess}
          property={'executeControlActionShowSuccess'}
        />
      }
      {
        assetControlData?.executeControlActionOnReturnSuccess &&
        <SuccessfullToast
          message={assetControlData?.executeControlActionOnReturnSuccessMessage}
          isShowEnabled={assetControlData?.executeControlActionOnReturnSuccess}
          property={'executeControlActionOnReturnSuccess'}
        />
      }
      {
        assetControlData?.executeControlActionShowFailure &&
        <ErrorToast
          message={assetControlData?.executeControlActionErrorMessage}
          isShowEnabled={assetControlData?.executeControlActionShowFailure}
          property={'executeControlActionShowFailure'}
        />
      }
      {
        assetControlData?.executeControlActionOnReturnError &&
        <ErrorToast
          message={assetControlData?.executeControlActionOnReturnMessage}
          isShowEnabled={assetControlData?.executeControlActionOnReturnError}
          property={'executeControlActionOnReturnError'}
        />
      }
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='dropdown-container'>
        <Tooltip content='Asset control' direction='down'>
          <button className='dropdown-btn' onClick={openDropdown}>
            <div className={`navigation-image-container filter-img`}>
              <img src={assetControl} alt='asset control' />
              <img src={chevronDown} alt='chevron' className={`${isDropdownOpen ? 'rotate-180' : ''}`} />
            </div>
          </button>
        </Tooltip>
        {isDropdownOpen && (
          <div className='dropdown-options well-option'>
            <button className='asset-control-heading'>Asset control options</button>
            <div className='border-bottom'>
              <button className='flex-btn'>
                <img src={infoCircle} alt='' />
                Status
                <span className={`well-status ${assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ? (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run') ? 'running' : (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) ? 'idle' : 'blank') : 'blank'}`}>{assetControlData?.assetControlLoading ? '' : wellStatus?.runStatus ? (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run') ? 'Running' : (wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) ? 'Shutdown' : '-') : '-'}</span>
              </button>
              <button className='flex-btn'>
                <img src={tool} alt='' />
                Operating mode
                <span className={`operating-mode ${(assetControlData?.operatingMode && !assetControlData?.assetControlLoading) ? 'exist' : ''}`}>{assetControlData?.assetControlLoading ? '' : assetControlData?.operatingMode ?? '-'}</span>
              </button>
            </div>
            <div className='border-bottom'>
              {
                assetControlData?.wellControlActions?.length > 0 &&
                <AssetControlActions
                  supportedControlActionIds={assetControlData?.wellControlActions.map((x) => x.id)}
                  userRoles={initialUserState?.roles}
                />
              }
              <button className={`flex-btn ${(!initialUserState?.roles?.includes(Role.Assetcontrol) && !initialUserState?.roles?.includes(Role.Admin)) || wellStatus?.enabled !== 1 ? 'disabled' : ''}`}
                onClick={() => {
                  if (wellStatus?.enabled === 1 && (initialUserState?.roles?.includes(Role.Assetcontrol) || initialUserState?.roles?.includes(Role.Admin)))
                    handleSchduledSpeedChangesSelect()
                }}>
                <img src={trendup} alt='' />
                Schedule speed change
              </button>
            </div>


            <button className={`flex-btn ${(!initialUserState?.roles?.includes(Role.Assetcontrol) && !initialUserState?.roles?.includes(Role.Admin)) || wellStatus?.enabled !== 1 || !systemControllerPocType?.includes(pocType) ? 'disabled' : ''}`} onClick={() => {
              if (wellStatus?.enabled === 1 && (initialUserState?.roles?.includes(Role.Assetcontrol) || initialUserState?.roles?.includes(Role.Admin)) && systemControllerPocType?.includes(pocType))
                handleSystemController(selectedOption)
            }}>
              <img src={okIcon} alt='' />
              System controller
            </button>


            <button className={`flex-btn ${(!initialUserState?.roles?.includes(Role.Assetcontrol) && !initialUserState?.roles?.includes(Role.Admin)) || wellStatus?.enabled !== 1 || !harmonicControllerPocType?.includes(pocType) ? 'disabled' : ''}`} onClick={() => {
              if (wellStatus?.enabled === 1 && (initialUserState?.roles?.includes(Role.Assetcontrol) || initialUserState?.roles?.includes(Role.Admin)) && harmonicControllerPocType?.includes(pocType))
                handleHarmonicController(selectedOption)
            }}>
              <img src={okIcon} alt='' />
              Harmonic controller
            </button>


            {/* <button className='flex-btn'>
                <img src={mode} alt='' />
                Mode
              </button> */}
            <button className='flex-btn' onClick={handleOpenTicket}>
              <img src={ticket} alt='' />
              Open ticket
            </button>
          </div>
        )}
      </div>
      {isSchduledSpeedChangeFrequency && (
        <SchduledSpeedChangeFrequency selectedOptions={selectedOption} onClose={closeModal} />
      )}
    </>
  );
};

export default WellControlOptions;
