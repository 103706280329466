import { apiInstance } from "../../config/HttpCommon";


export const getManageAssetSetpoints = async (wellId: string, wellName: string) => {
    const response = await apiInstance.get(`/v1/setpoints/asset-control`, {
        params: {
            wellId: wellId,
            wellName: wellName,
        },
    });
    return response.data;
};