import React, { useEffect, useState } from 'react';
import './analysis-asset-tabs.scss';
import { slugify } from '../../../../../utilities/Slugify';
import { useNavigate } from 'react-router-dom';

interface Tab {
  label: string;
  component: React.ComponentType<any>;
}

interface TabsProps {
  tabs: Tab[];
  assetDetails?: boolean;
}

const AnalysisAssetTabs: React.FC<TabsProps> = ({ tabs, assetDetails = false }) => {
  const defaultTabIndex = 0;
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
  const [forceUpdate, setForceUpdate] = useState(0);

  const navigate = useNavigate()

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
    if (currentTabFromQuery) {
      const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      } else {
        setActiveTabIndex(defaultTabIndex);
        if (activeTabIndex === defaultTabIndex)
          setForceUpdate((prev) => prev + 1)
      }
    } else {
      setActiveTabIndex(defaultTabIndex);
      if (activeTabIndex === defaultTabIndex)
        setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, tabs]);

  useEffect(() => {
    if (activeTabIndex === null) return;

    const newTabSlug = slugify(tabs[activeTabIndex].label);
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('innerTab');
    // Only navigate if the tab slug in the URL is differents
    if (currentTabSlug !== newTabSlug) {
      params.set('innerTab', newTabSlug)
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [activeTabIndex, forceUpdate]);



  return (
    <>
      <div className={`wrapper-location ${assetDetails && 'asset-details'}`}>
        <div className='ui-button-toolbar tab-header'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button padding-left-0 ${index === activeTabIndex ? 'ui-button-toolbar-button-active' : ''
                }`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className='tab-content'>
        {tabs.map((tab, index) => (
          <div key={index} style={{ display: index === activeTabIndex ? 'block' : 'none' }}>
            {React.createElement(tab.component)}
          </div>
        ))}
      </div>
    </>
  );
};

export default AnalysisAssetTabs;
