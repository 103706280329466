import React, { useEffect, useMemo, useState } from 'react';
import ProjectsTab from './tabs/Projects';
import AnalysisAssetTabs from './analysis-asset-tabs/AnalysisAssetTabs';
import TubingTab from './tabs/Tubing';
import WellTab from './tabs/Well';
import FluidTab from './tabs/Fluid';
import OtherTab from './tabs/Other';
import './analysis.scss';
import CapacityCurveGraph1 from './graphs/CapacityCurveGraph1.';
import CapacityCurveGraph2 from './graphs/CapacityCurveGraph2';
import CapacityCurveGraph3 from './graphs/CapacityCurveGraph3';
import IPRData from './graphs/IPRData';
import DepletionData from './graphs/DepletionData';
import { clearMainReportData, fetchMainReport } from './AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { updateAssetControlScanStatus } from '../../../asset/AssetControlSlice';

interface TabsProps {
  value?: string | undefined;
}

const Analysis: React.FC<TabsProps> = () => {

  const [selectedOption, setSelectedOption] = useState('Capacity');
  const dispatch = useAppDispatch();
  const projectTabDetails = useAppSelector((state) => state.analysisTab.project);
  const assetControlData = useAppSelector((state) => state?.assetControl)


  useEffect(() => {
    projectTabDetails?.wellAPI && dispatch(fetchMainReport(projectTabDetails?.wellAPI ?? ''));

    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

    return () => {
      dispatch(clearMainReportData());
    }

  }, [projectTabDetails, assetControlData?.assetControlScanStatus == true]);

  const tabs = [
    { label: 'Projects', component: ProjectsTab },
    { label: 'Tubing', component: TubingTab },
    { label: 'Well', component: WellTab },
    { label: 'Fluid', component: FluidTab },
    { label: 'Other', component: OtherTab },
  ];

  const capacityGraphs = useMemo(() => (
    <div className='capacity relative'>
      {/* <div className='capacity-curve'>Capacity curves</div> */}
      <CapacityCurveGraph1 />
      <CapacityCurveGraph2 />
      <CapacityCurveGraph3 />
    </div>
  ), []);

  const iprGraphs = useMemo(() => (
    <div className='ipr'>
      {/* <div className='capacity-curve'>Well inflow performance</div> */}
      <IPRData />
      <DepletionData />
    </div>
  ), []);

  return (
    <>
      <div className='flex flex-row flex-grow profile-block !p-9'>
        <div className='card profile-card !m-0'>
          <div className='header'>
            <span className='asset-analysis-title'>Asset Analysis</span>
          </div>
          <div className='py-5 px-6 w-100 flex flex-row justify-between pt-0'>
            <div className='left-section cust-ui'>
              <AnalysisAssetTabs tabs={tabs} assetDetails={true} />
            </div>
            <div className='right-section pt-5'>
              <div className='flex flex-row justify-end pt-2'>
                <div className='chart-container flex-row'>
                  <div className='select-buttons'>
                    <button
                      className={`select-button ${selectedOption === 'Capacity' ? 'active' : ''}`}
                      onClick={() => setSelectedOption('Capacity')}
                    >
                      Capacity
                    </button>
                    <button
                      className={`select-button ${selectedOption === 'IPR' ? 'active' : ''}`}
                      onClick={() => setSelectedOption('IPR')}
                    >
                      IPR
                    </button>
                  </div>
                </div>
              </div>
              {selectedOption === 'Capacity' && capacityGraphs}
              {selectedOption === 'IPR' && iprGraphs}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analysis;

