import { useEffect } from 'react';
import { clearTabDetails, fetchProjectTabDetails } from '../AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import PageLoader from '../../../../common/page-loader/PageLoader';
// import { ProjectTabDetailsProps } from '../analysisProps';
import '../analysis.scss';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';

const ProjectsTab = () => {
  // const { loading }  = useAppSelector((state)=> state.analysisTab);
  const dispatch = useAppDispatch();
  const projectTabDetails = useAppSelector((state) => state.analysisTab.project);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '') return;
    dispatch(fetchProjectTabDetails({ projectId: wellName, analysisType: 'project' }))
    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false));

    return () => {
      dispatch(clearTabDetails());
    }
  }, [dispatch, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus === true]);


  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='analysis-tab-content'>
        <div>Project name</div>
        <div>{projectTabDetails?.projectName}</div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Customer information</div>
        <div className='cus-details'>
          <div>Name</div>
          <div>{projectTabDetails?.customerName}</div>
        </div>

        <div className='cus-details'>
          <div>Customer contact</div>
          <div>{projectTabDetails?.customerContact}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Location</div>
        <div className='cus-details'>
          <div>Well name</div>
          <div>{projectTabDetails?.wellName}</div>
        </div>

        <div className='cus-details'>
          <div>Well API #</div>
          <div>{projectTabDetails?.wellAPI}</div>
        </div>

        <div className='cus-details'>
          <div>Country</div>
          <div>{projectTabDetails?.country}</div>
        </div>

        <div className='cus-details'>
          <div>State</div>
          <div>{projectTabDetails?.state}</div>
        </div>

        <div className='cus-details'>
          <div>County</div>
          <div>{projectTabDetails?.county}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>ChampionX representative</div>
        <div className='cus-details'>
          <div>Name</div>
          <div>{projectTabDetails?.representativeName}</div>
        </div>
      </div>
    </>
  );
};

export default ProjectsTab;
