import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cancelScheduleById, createScheduleById, GetFreqSetPointAddress, getScheduleById, updateScheduleById } from './ScheduleSpeedChangeService';
import { ScheduleSpeedChange } from './model/ScheduleSpeedChange';
import { SimpleSpeedChange } from './model/SimpleSpeedChange';
import { SteppedSpeedChange } from './model/SteppedSpeedChange';

interface ScheduledState {
  loading: boolean;
  getSimpleSpeedChangeDataLoading: boolean,
  getSteppedSpeedChangeDataLoading: boolean,
  setPointAddressLoading: boolean;
  message: string | null;
  scheduleSpeedChange: ScheduleSpeedChange | null;
  simpleSpeedChangeDataArray: ScheduleSpeedChange[];
  steppedSpeedChangeDataArray: ScheduleSpeedChange[];
  simpleSpeedChangeTotalCount: number;
  steppedSpeedChangeTotalCount: number;
  currentFreqAddress: string | null,
  setPointFreqAddress: string | null,
  wellName: any;
}

const initialState: ScheduledState = {
  loading: false,
  getSimpleSpeedChangeDataLoading: false,
  getSteppedSpeedChangeDataLoading: false,
  setPointAddressLoading: false,
  message: null,
  scheduleSpeedChange: null,
  simpleSpeedChangeDataArray: [],
  steppedSpeedChangeDataArray: [],
  simpleSpeedChangeTotalCount: 0,
  steppedSpeedChangeTotalCount: 0,
  wellName: '',
  currentFreqAddress: null,
  setPointFreqAddress: null
};

export const fetchSteppedSpeedChangeData: any = createAsyncThunk(
  'scheduleSpeedChange/fetchSteppedSpeedChangeData',
  async ({ wellId, deviceId, pageNumber, pageSize }: { wellId: string, deviceId: string, pageNumber: number, pageSize: number }) => {
    const response = await getScheduleById(wellId, deviceId, pageNumber, pageSize, true);
    return response;
  },
);

export const fetchSimpleSpeedChangeData: any = createAsyncThunk(
  'scheduleSpeedChange/fetchSimpleSpeedChangeData',
  async ({ wellId, deviceId, pageNumber, pageSize }: { wellId: string, deviceId: string, pageNumber: number, pageSize: number }) => {
    const response = await getScheduleById(wellId, deviceId, pageNumber, pageSize, false);
    return response;
  },
);

export const fetchFreqSetPointAddress = createAsyncThunk('user/getFreqSetPointAddress', async (wellName: string) => {
  const response = await GetFreqSetPointAddress(wellName);
  return response;
});

export const deleteScheduleById: any = createAsyncThunk(
  'scheduleSpeedChange/deleteScheduleById',
  async (id: string) => {
    const response = await cancelScheduleById(id);
    return response;
  },
);

const scheduledSpeedChangeSlice = createSlice({
  name: 'scheduleSpeedChange',
  initialState,
  reducers: {
    removeScheduleSpeedChangeData(state) {
      state.scheduleSpeedChange = null;
    },
    addWellName(state, action) {
      state.wellName = action
    },
  },
  extraReducers: (builder) => {
    // Handle the fetchSteppedSpeedChangeData fulfilled action
    builder.addCase(fetchSteppedSpeedChangeData.pending, (state) => {
      state.getSteppedSpeedChangeDataLoading = true;
    });
    builder.addCase(fetchSteppedSpeedChangeData.fulfilled, (state, action) => {
      const pageNumber = action.meta.arg.pageNumber
      const data = action.payload?.data?.results
      if (pageNumber === 1) {
        state.steppedSpeedChangeDataArray = data
      } else {
        state.steppedSpeedChangeDataArray = [...state.steppedSpeedChangeDataArray, ...data]
      }
      state.steppedSpeedChangeTotalCount = action.payload?.data?.totalResultsCount
      state.getSteppedSpeedChangeDataLoading = false;
    });
    builder.addCase(fetchSteppedSpeedChangeData.rejected, (state) => {
      state.steppedSpeedChangeDataArray = []
      state.getSteppedSpeedChangeDataLoading = false;
    });

    // Handle the fetchSimpleSpeedChangeData
    builder.addCase(fetchSimpleSpeedChangeData.pending, (state) => {
      state.getSimpleSpeedChangeDataLoading = true;
    });
    builder.addCase(fetchSimpleSpeedChangeData.fulfilled, (state, action) => {
      const pageNumber = action.meta.arg.pageNumber
      const data = action.payload?.data?.results
      if (pageNumber === 1) {
        state.simpleSpeedChangeDataArray = data
      } else {
        state.simpleSpeedChangeDataArray = [...state.simpleSpeedChangeDataArray, ...data]
      }
      state.simpleSpeedChangeTotalCount = action.payload?.data?.totalResultsCount
      state.getSimpleSpeedChangeDataLoading = false;
    });
    builder.addCase(fetchSimpleSpeedChangeData.rejected, (state) => {
      state.simpleSpeedChangeDataArray = []
      state.getSimpleSpeedChangeDataLoading = false;
    });

    // Handle the CreateScheduleSpeed fulfilled action
    builder.addCase(createScheduleSpeed.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createScheduleSpeed.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createScheduleSpeed.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle Update Schedule Speed Change
    builder
      .addCase(updateScheduleSpeedChange.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScheduleSpeedChange.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateScheduleSpeedChange.rejected, (state, action) => {
        state.loading = false;
        state.message = action.error.message || 'Failed to update schedule speed change';
      });

    // Handle the fetchFreqSetPointAddress fulfilled action
    builder.addCase(fetchFreqSetPointAddress.pending, (state) => {
      state.setPointAddressLoading = true;
    });
    builder.addCase(fetchFreqSetPointAddress.fulfilled, (state, action) => {
      state.setPointAddressLoading = false;
      state.currentFreqAddress = action.payload.currentAddress?.address || null
      state.setPointFreqAddress = action.payload.setpointAddress?.address || null
    });
    builder.addCase(fetchFreqSetPointAddress.rejected, (state) => {
      state.setPointAddressLoading = false;
      state.currentFreqAddress = null
      state.setPointFreqAddress = null
    });
    // Handle the deleteScheduleById fulfilled action
    // builder
    //   .addCase(deleteScheduleById.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(deleteScheduleById.fulfilled, (state) => {
    //     state.loading = false;
    //   })
    //   .addCase(deleteScheduleById.rejected, (state, action) => {
    //     state.loading = false;
    //     state.message = action.error.message || 'Failed to delete schedule speed change';
    //   });
  },
});

// Create ScheduleById
export const createScheduleSpeed = createAsyncThunk(
  'user/createScheduleSpeed',
  async (scheduleData: {
    wellId: string;
    wellName: string;
    groupName: string;
    deviceId: string;
    setpointFrequencyAddress: string,
    simpleSpeedChanges: SimpleSpeedChange[];
    steppedSpeedChanges: SteppedSpeedChange[];
  }) => {
    const response = await createScheduleById(scheduleData);
    return response;
  },
);

// Async thunk for updating schedule speed change
export const updateScheduleSpeedChange = createAsyncThunk(
  'user/updateScheduleSpeedChange',
  async ({
    id,
    speedChanges,
  }: {
    id: string;
    speedChanges: {
      simpleSpeedChanges: SimpleSpeedChange[];
      steppedSpeedChanges: SteppedSpeedChange[];
    };
  }) => {
    return updateScheduleById(id, speedChanges);
  },
);

export const { removeScheduleSpeedChangeData, addWellName } = scheduledSpeedChangeSlice.actions;

export default scheduledSpeedChangeSlice.reducer;
