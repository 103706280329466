import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ChevronUp } from "../../../../images/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../images/chevron-down.svg";
import "./dropdown.scss";

export interface DropdownItems {
  id: string;
  name: string;
  lookupType?: string;
  legacyId?: string;
  description?: string;
}

interface DropdownProps {
  items?: DropdownItems[];
  defaultOption?: string;
  onChange?: (item: DropdownItems) => void;
  noResultsMessage?: React.ReactNode;
  disabled?: boolean;
}

const CustomerDropdown: React.FC<DropdownProps> = ({
  items,
  defaultOption = "Choose one",
  onChange,
  noResultsMessage,
  disabled = false,
}) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItems | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = (item: DropdownItems) => {
    setSelectedItem(item);
    setIsActive(false);
    if (onChange) {
      onChange(item);
    }
  };

  const filteredItems = items?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const exactMatch = filteredItems?.some(
    (item) => item.name.toLowerCase() === searchTerm.toLowerCase()
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${disabled ? "disabled" : ""}`}
    >
      <div
        onClick={() => !disabled && setIsActive(!isActive)}
        className="dropdown-btn"
      >
        <span className="dropdown-input">
          {selectedItem ? selectedItem.name : defaultOption}
        </span>
        <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      {isActive && !disabled && (
        <div
          className="dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="dropdown-search"
            disabled={disabled}
          />
          {searchTerm && !exactMatch && noResultsMessage && (
            <div className="no-results">{noResultsMessage}</div>
          )}
          {filteredItems?.map((item, index) => (
            <div
              key={index}
              className={`item ${selectedItem?.name === item.name ? "item-selected" : ""
                }`}
              onClick={() => handleClick(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerDropdown;
