import { createAsyncThunk, createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { TimeseriesData } from "./interfaces/timeSeries.interface";
import { AssetTimeseriesData } from "./interfaces/timeSeriesChannelIdsData.interface";
import { getAssetTimeseriesChannelsData, getMasterVariablesData, getTimeseriesData, getWellProductionTrendsByAssetName } from "./services/wellControlroom.service";
import { ParameterType } from "./interfaces/masterVariables.interface";

interface WellControlroomCharts {
    channelIDs: string[];
    variables: string[];
    channelIDsObj: { [key: string]: string };
    masterVariables: ParameterType[];
    timeSeriesData: { [key: string]: TimeseriesData };
    channelData: { [key: string]: AssetTimeseriesData };
    loading: boolean;
    error: { [key: string]: string | null };
}

const initialState: WellControlroomCharts = {
    channelIDs: [],
    variables: [],
    channelIDsObj: {},
    masterVariables: [],
    timeSeriesData: {},
    channelData: {},
    loading: true,
    error: {},
};

export const fetchTimeSeriesDataVariables = createAsyncThunk(
    "wellControlroomCharts/fetchTimeSeriesDataVariables",
    async (
        { assetName, startDate, endDate, variables, chartName, isExpandedView }: { assetName: string; startDate: string; endDate: string; variables: string[], chartName: string, isExpandedView: boolean }
    ) => {
        const response = await getTimeseriesData(assetName, startDate, endDate, variables.join(","));
        return { data: response, chartName: chartName, isExpandedView };
    }
);

export const fetchTimeSeriesChannelData = createAsyncThunk(
    "wellControlroomCharts/fetchTimeSeriesChannelData",
    async (
        { assetName, startDate, endDate, channelIds, chartName, isExpandedView }: { assetName: string; startDate: string; endDate: string; channelIds: string[], chartName: string, isExpandedView: boolean }
    ) => {
        const response = await getAssetTimeseriesChannelsData(assetName, startDate, endDate, channelIds.join(","));
        return { data: response, chartName: chartName, isExpandedView };
    }
);


export const fetchProductionTrendsByWell = createAsyncThunk(
    'wellControlroomCharts/fetchProductionTrendsByWell',
    async ({ startDate, endDate, assetName }: { startDate: string, endDate: string, assetName: string }) => {
        return await getWellProductionTrendsByAssetName(
            startDate,
            endDate,
            assetName
        );
    }
);

export const fetchMasterVariablesByWellname = createAsyncThunk(
    'events/fetchMasterVariablesByWellname',
    async ({ wellName }: { wellName: string; }) => {
        const response = await getMasterVariablesData(wellName);
        return response;
    },
);

const wellControlroomSlice = createSlice({
    name: "wellControlroomCharts",
    initialState,
    reducers: {
        setChannelIDs: (state, action: PayloadAction<string[]>) => {
            state.channelIDs = [...new Set([...state.channelIDs, ...action.payload])];
        },
        setVariables: (state, action: PayloadAction<string[]>) => {
            state.variables = [...new Set([...state.variables, ...action.payload])];
        },
        setChannelIDsObj: (state, action: PayloadAction<{ [key: string]: string }>) => {
            state.channelIDsObj = { ...state.channelIDsObj, ...action.payload };
        },
        setChartsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        // clear all the data
        clearData: (state) => {
            state.channelIDs = [];
            state.variables = [];
            state.channelIDsObj = {};
            state.masterVariables = [];
            state.timeSeriesData = {};
            state.channelData = {};
            state.error = {};
            state.loading = true;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchTimeSeriesDataVariables.pending, (state, action) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = true;
            }
        });
        builder.addCase(fetchTimeSeriesDataVariables.fulfilled, (state, action) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = false;
                state.timeSeriesData = {
                    ...state.timeSeriesData, ...action.payload.data?.data?.reduce((acc: { [key: string]: TimeseriesData }, item: TimeseriesData) => {
                        acc[item.variable] = item;
                        return acc;
                    }, {})
                };
                state.error = { ...state.error, timeSeries: null };
            }

        });

        builder.addCase(fetchTimeSeriesDataVariables.rejected, (state, action) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = false;
                state.error = { ...state.error, timeSeries: action.error.message || "Failed to fetch time series data" };
                state.timeSeriesData = {};
            }
        });

        builder.addCase(fetchTimeSeriesChannelData.pending, (state, action) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = true;
            }
        });
        builder.addCase(fetchTimeSeriesChannelData.fulfilled, (state, action) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = false;
                state.error = { ...state.error, channelData: null };
                state.channelData = {
                    ...state.channelData, ...action.payload.data.reduce((acc: { [key: string]: AssetTimeseriesData }, item: AssetTimeseriesData) => {
                        acc[item.trendName] = item;
                        return acc;
                    }, {})
                };
            }
        });

        builder.addCase(fetchTimeSeriesChannelData.rejected, (state, action: any) => {
            const isExpandedView = action.meta.arg.isExpandedView;
            if (!isExpandedView) {
                state.loading = false;
                state.error = { ...state.error, channelData: action.error.message || "Failed to fetch channel data" };
                state.channelData = {};
            }
        });
        builder.addCase(fetchMasterVariablesByWellname.fulfilled, (state, action) => {
            state.masterVariables = action.payload;
        });
    }
});

export const { setChannelIDs, setVariables, setChannelIDsObj, clearData, setChartsLoading } = wellControlroomSlice.actions;

export default wellControlroomSlice.reducer;

export const selectTimeSeriesData = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.timeSeriesData;

export const selectChannelData = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.channelData;

export const selectLoadingState = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.loading;

export const selectErrors = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.error;

export const getTimeSeriesDataByKey = (key: string) =>
    createSelector(selectTimeSeriesData, (timeSeriesData) => timeSeriesData[key]);

export const getChannelDataByKey = (key: string) => {
    return createSelector(selectChannelData, (channelData) => channelData[key]);
}

export const selectMasterVariables = (state: { wellControlroomCharts: WellControlroomCharts }) =>
    state.wellControlroomCharts.masterVariables;


export const getMasterVariableByChannelId = (channelId: string) =>
    createSelector(selectMasterVariables, (masterVariables) => masterVariables.find((item) => item.channelId === channelId));


