import Play from '../../images/play.svg'
import Stop from '../../images/stop.svg'
import leftArrow from '../../images/arrow-left.svg'
import StopGray from '../../images/stop-gray.svg'
import leftArrowGray from '../../images/arrow-left-gray.svg'
import { useState } from 'react'
import { useAppSelector } from '../../hooks/storeHooks'
import { allControlActions, confirmationModalProps, WebSocketActionType } from '../asset-details/components/asset-details-header/AssetControlActions'
import ConfirmationModal from '../asset-details/components/asset-details-header/modal/ConfirmationModal'
import { WellControlAction } from '../navigation/components/ControlAction'
import { useWebSocket } from '../../hooks/webSocketHook'
import { selectedWellName } from '../controlroom/components/well-dashboard-header/WellDashboardHeader'



interface AssetControlProps {
    supportedControlActionIds: number[];
}

const AssetControl: React.FC<AssetControlProps> = ({ supportedControlActionIds }) => {    
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalProps, setConfirmationModalProps] = useState<confirmationModalProps>({
        action: '',
        heading: '',
        description: '',
        controlType: ''
    })
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const { assetDetail } = useAppSelector((state) => state?.assetDetail);
    const selectedWellNameLS: any = sessionStorage.getItem('selectedWellName');
    const selectedWellName: selectedWellName | null = selectedWellNameLS ? JSON.parse(selectedWellNameLS) : null;
    const assetId = assetDetail?.assetDetails.assetId ? assetDetail?.assetDetails.assetId : selectedWellName?.assetId ? selectedWellName?.assetId : ''
    const wellStatus = assetControlData?.wellStatus

    const { establishWebSocketConnection } = useWebSocket()


    const handleAssetControlClick = (action: string, heading: string, description: string, controlType: string) => {
        setConfirmationModalProps({
            action: action,
            heading: heading,
            description: description,
            controlType: controlType
        })
        setIsConfirmationModalOpen(true)
    }

    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false)
    }
    const handleConfirm = () => {
        const type = confirmationModalProps?.controlType
        if (type) {
            const controlActionId = WellControlAction[type as keyof typeof WellControlAction];
            const label = allControlActions.find(item => item.controlActionType === controlActionId)?.label;
            establishWebSocketConnection({ webSocketActionType: WebSocketActionType.AssetControl, type: controlActionId.toString(), label: label ? label : type })
        }
        setIsConfirmationModalOpen(false)
    }


    return (
        <>
            <div className='asset-control flex space-x-4'>
                {
                    supportedControlActionIds?.map((controlActionId) => {
                        if (controlActionId == WellControlAction.Start || controlActionId == WellControlAction.Stop || controlActionId == WellControlAction.ClearAlarms) {
                            let btnClassName = 'enabled'
                            let icon = ''
                            let label = ''
                            if (wellStatus?.enabled === 1) {
                                btnClassName = 'enabled'
                                if (controlActionId == WellControlAction.Start) {
                                    icon = Play
                                    label = 'Start'
                                    if ((assetControlData.startActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run')) {
                                        btnClassName = 'disabled'
                                    }
                                } else if (controlActionId === WellControlAction.Stop) {
                                    icon = Stop
                                    label = 'Stop'
                                    if ((assetControlData.stopActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) {
                                        btnClassName = 'disabled'
                                        icon = StopGray
                                    }
                                } else if (controlActionId === WellControlAction.ClearAlarms) {
                                    icon = leftArrow
                                    label = 'Clear alarms'
                                    if ((assetControlData.clearAlarmActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) {
                                        btnClassName = 'disabled'
                                        icon = leftArrowGray
                                    }
                                }
                            } else {
                                btnClassName = 'disabled'
                            }

                            if (controlActionId == WellControlAction.Start) {
                                icon = Play
                                label = 'Start'
                            } else if (controlActionId === WellControlAction.Stop) {
                                icon = Stop
                                label = 'Stop'
                                if ((assetControlData.stopActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) {
                                    icon = StopGray
                                }
                            } else if (controlActionId === WellControlAction.ClearAlarms) {
                                icon = leftArrow
                                label = 'Clear alarms'
                                if ((assetControlData.clearAlarmActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle')) {
                                    icon = leftArrowGray
                                }
                            }

                            return <button className={`button ${btnClassName} ${controlActionId === WellControlAction.Stop ? 'stop' : ''}`}
                                onClick={() => {
                                    if (wellStatus?.enabled === 1) {
                                        switch (controlActionId) {
                                            case WellControlAction.Start:
                                                if (!(assetControlData.startActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) && !wellStatus?.runStatus?.toLocaleLowerCase()?.includes('run')) {
                                                    handleAssetControlClick('Start', 'Start', 'Are you sure you want to start this well?', 'Start');
                                                }
                                                break;
                                            case WellControlAction.Stop:
                                                if (!(assetControlData.stopActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) && !(wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))) {
                                                    handleAssetControlClick('Shutdown', 'Shutdown', 'Are you sure you want to shutdown this well?', 'Stop');
                                                }
                                                break;
                                            case WellControlAction.ClearAlarms:
                                                if (!(assetControlData.clearAlarmActionProgressStatus && assetControlData.activeAssetControlActionAssetId === assetId) && !(wellStatus?.runStatus?.toLocaleLowerCase()?.includes('shut') || wellStatus?.runStatus?.toLocaleLowerCase()?.includes('idle'))) {
                                                    handleAssetControlClick('Clear', 'Clear Alarms', 'Are you sure you want to clear all alarms?', 'ClearAlarms');
                                                }
                                                break;
                                        }
                                    }
                                }}
                            >
                                <img src={icon} />{label}
                            </button>
                        }
                    })
                }
            </div >
            {
                isConfirmationModalOpen && (
                    // <div style={{ height: '100vh', position: 'absolute' }}>
                    <ConfirmationModal assetControlProps={confirmationModalProps} isModalClose={closeConfirmationModal} isModalOk={handleConfirm} />
                    // </div>
                )
            }
        </>
    )
}

export default AssetControl