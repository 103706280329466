import { http } from '../../config/HttpCommon';


export const getJobSchedule = async () => {
  const response = await http.get(`/getJobEventSchedule`);
  return response.data;
};

export const getTicketsById = async () => {
  const response = await http.get(`/getTicketChart`);
  return response.data;
};

export const getAssetListById = async (groupName: string, startIndex: number, endIndex: number, searchQuery: string, cancelToken: any) => {

  const url = `/getDashboardAssetDetails/${groupName}/${startIndex}/${endIndex}${searchQuery}`;

  const response = await http.get(url, { cancelToken });
  return response.data;
};

export const getPerformanceSnapshot = async (groupName: string) => {
  const response = await http.get(`/getPerformanceSnapshot/${groupName}`);
  return response.data;
};

// export const getWellPerformanceTrends = async (, fiilter: string, startDate: string, endDate: string, groupName: string, cancelToken:any) => {
//   const response = await http.get(`/getWellPerformanceTrends/${fiilter}/${startDate}/${endDate}/${groupName}`,{cancelToken});
//   return response.data;
// };

export const getWellPerformanceTrends = async (startDate: string, endDate: string, groupName: string, cancelToken: any, includeAssetCount?: boolean) => {
  const response = await http.get(`/getMultipleWellPerformanceTrends/${startDate}/${endDate}/${groupName}?includeAssetCount=${includeAssetCount}`, { cancelToken });
  return response.data;
};

export const getWellProductionTrends = async (startDate: string, endDate: string, groupName: string) => {
  const response = await http.get(`/getWellProductionTrends/${startDate}/${endDate}/${groupName}`);
  return response.data;
};

export const getPerformanceSnapshotByName = async (name: string, groupName: string) => {
  const response = await http.get(`/getPerformanceSnapshotByName/${name}/${groupName}`);
  return response.data;
};

// export const downloadDashboardPDF = async (pdf: { ; widgetName: string, groupName: string }) => {
//   const { userId, widgetName, groupName } = pdf;
//   const url = `/downloadDashboardPDF/${widgetName}/${groupName}`;
//   const response = await http.get(url, { responseType: 'blob' });
//   return response.data;
// };

export const downloadDashboardCSV = async (csv: { widgetName: string, groupName: string, filterTerm?: string }) => {
  const { widgetName, groupName } = csv;
  const url = `/downloadDashboardCSV/${widgetName}/${groupName}${csv.filterTerm ? ("?filter=" + csv.filterTerm) : ''}`;
  const response = await http.get(url, { responseType: 'blob' });
  return response.data;
};

// export const downloadWellTrendsPDF = async (pdf: { ; filter: string, startTime: string, endTime: string, groupName?: string }) => {
//   const { userId, filter, startTime, endTime, groupName } = pdf;
//   const url = `/downloadWellTrends/${filter}/${startTime}/${endTime}/${groupName}`;
//   const response = await http.get(url, { responseType: 'blob' });
//   return response.data;
// };

export const getUrls = async (groupId: string) => {
  const response = await http.get(`/UrlsLookup/?groupId=${groupId}`);
  return response.data;
};
