import React, { useEffect, useRef, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import Event from '../../../../images/event.svg';
import { formatStringDate } from '../../../common/DateRangeSelector/DefaultRanges';
import './EventSchdule.scss';
import { updateAssetList, updateSelectedAsset } from '../../../navigation/AssetGroupsSlice';
import { useNavigate } from 'react-router-dom';
// import { fetchJobSchedule } from '../../DashboardSlice';

interface JobSchedule {
  assetID?: string | undefined;
  wellName?: string | undefined;
  operator?: string | undefined;
  date?: string | Date | undefined;
  jobType?: string | undefined;
  assignedTech?: string | undefined;
  created?: Date | undefined;
}

const JobsSchedule: React.FC = () => {
  const navigate= useNavigate();
  const dispatch = useAppDispatch();
  const [sortConfig, setSortConfig] = useState<{ key: keyof JobSchedule; direction: 'asc' | 'desc' } | null>(null);
  const jobsScheduleData = useAppSelector((state) => state.dashboard.filteredJobScheduleData) as JobSchedule[] | null;
  const jobsCount = useAppSelector((state) => state.dashboard.jobsCount);
  const activeTab = sessionStorage.getItem('activeTabIndex');
  const [contextMenu, setContextMenu] = useState<string | number | null>(null);
  const [modeContextMenu, setModeContextMenu] = useState<string>('');
  const contextMenuRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   dispatch(fetchJobSchedule('userId'));
  // }, [dispatch]);

  const requestSort = (key: keyof JobSchedule) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedEventSchedule = React.useMemo(() => {
    const sortableItems = jobsScheduleData ? [...jobsScheduleData] : [];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (typeof aValue === 'undefined' || typeof bValue === 'undefined') {
          return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [jobsScheduleData, sortConfig]);

  const handleWellName = (wellName: string, assetID: string) => {
    window.scrollTo(0, 0);
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = jobsScheduleData ? jobsScheduleData.map((well) => ({
      assetId: well.assetID,
      assetName: well.wellName,
      industryApplicationId: 4,
    })) : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate(`/layout/assets/?tab=details`);
  };

  const handleRightClick = (e:any, assetID: number | string, name: string) => {
    e.preventDefault();
    setContextMenu(assetID);   
    setModeContextMenu(name); 
  };

  const handleOptionClick = (action:string, wellName: string, assetID: string) => {
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }
    const groupList = jobsScheduleData ? jobsScheduleData.map((well) => ({
      assetId: well.assetID,
      assetName: well.wellName,
      industryApplicationId: 4,
    })) : [];

    const selectedAsset = {
      assetName: wellName,
      assetGroupName: 'Well Groups',
      assetId: assetID,
      searchString: undefined,
    };

    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    if (action === "open-new-tab") {
      const targetPath = `/layout/assets/?tab=details`;
      const fullUrl = `${window.location.origin}${targetPath}`;
      window.open(fullUrl, "_blank");
    }
    setContextMenu(null); // Close the context menu
  };

  const handleContextMenuClickOutside = (event: MouseEvent) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
      setContextMenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleContextMenuClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleContextMenuClickOutside);
    };
  }, []);

  return (
    <>
      <div className='job-schedule-card' style={{ paddingBottom: jobsCount > 0 ? '2rem' : '0' }}>
        <div className='table-header-section'>
          <img src={Event} alt='' />
          <div className='title'>Events today</div>
        </div>
        <p className='ticket-count'>You currently have {jobsCount} event{jobsCount !== 1 ? 's' : ''} scheduled.</p>
        {jobsCount > 0 && (
          <div id='scheduleWidgetDiv' className='table-container-dashboard'>
            <table className='w-100 job-schedule'>
              <thead className='job-schedule-head'>
                <tr className='table-header-cell'>
                  <th className='table-header-item well-name' onClick={() => requestSort('wellName')}>
                    Well name {sortConfig?.key === 'wellName' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item operator' onClick={() => requestSort('operator')}>
                    Operator {sortConfig?.key === 'operator' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item job-date' onClick={() => requestSort('date')}>
                    Date {sortConfig?.key === 'date' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item job-type' onClick={() => requestSort('jobType')}>
                    Job type {sortConfig?.key === 'jobType' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item assigned' onClick={() => requestSort('assignedTech')}>
                    Assigned tech {sortConfig?.key === 'assignedTech' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                  <th className='table-header-item job-created' onClick={() => requestSort('created')}>
                    Created {sortConfig?.key === 'created' ? (sortConfig.direction === 'asc' ? '↑' : '↓') : '↑'}
                  </th>
                </tr>
              </thead>
              <tbody className='job-schedule-body'>
                {sortedEventSchedule?.map((job, index) => {
                  const dateFormat = job.date && job.date.toString().split(' ')[0];
                  const createdDate = job.created && job.created.toString().split('T')[0];

                  return (
                    <tr key={index} className='table-row-general'>
                      <td className='well-name relative'>
                        <div
                          className='flex items-center h-full w-full'
                          onContextMenu={(e)=> handleRightClick(e, index ?? '', 'jobtable')}
                          onClick={() => handleWellName(job.wellName ?? '', job?.assetID ?? '')}
                          >
                          {job.wellName}
                        </div>
                        {modeContextMenu === 'jobtable' && contextMenu === index && (
                            <div
                              ref={contextMenuRef}
                              style={{
                                position: "absolute",
                                top: '0px',
                                left: '60%',
                                width: '200px',
                              }}
                              className='dropdown-options'
                            >
                              <button
                                onClick={() => handleOptionClick("open-new-tab", job.wellName ?? '', job?.assetID ?? '')}
                              >
                                Open in new tab
                              </button>
                            </div>
                          )}
                      </td>
                      <td className='operator' data-title={job.operator}>{job.operator && job.operator?.length >= 20
                        ? `${job.operator.substring(0, 15)}...`
                        : job.operator}</td>
                      <td className='job-date'>{job.date instanceof Date ? job.date.toISOString() : formatStringDate(dateFormat) ?? 'N/A'}</td>
                      <td className='job-type'>{job.jobType ?? '-'}</td>
                      <td className='assigned'>{
                        job.assignedTech && job.assignedTech.toLowerCase() !== 'n/a' && job.assignedTech.trim() !== '' ? job.assignedTech.toLowerCase() : 'Pending Assignment'
                      }</td>
                      <td className='job-created'>{job.created instanceof Date ? job.created.toISOString() : formatStringDate(createdDate) ?? 'N/A'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default JobsSchedule;