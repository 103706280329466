import { Column, ColumnInstance, useTable } from "react-table";
import { ReactComponent as SortIconDefault } from '../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../images/Chevron-down-icon.svg';
import React, { useEffect, useMemo, useRef, useState } from "react";
import NoData from "../../../../dashboard/components/no-data/NoData";
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { Ticket, TicketAction } from "../interfaces/ticketInterface";
import Loader from "../../../../common/page-loader/ComponentLoader";
import { closeTicketThunk } from "../slices/ticketSlice";
import ReassignTicketModal from "./reassignTicketModal/ReassignTicketModal";
import { showToaster } from "../../../../dashboard/components/asset-location/AssetList";
import TicketModelOpen from "../TicketModelOpen";
import moment from "moment-timezone";
import { SelectedTicket } from "./ControlRoomTickets";

interface RenderCellContentProps {
    cell: any;
    row: any;
}

interface TicketTableProps {
    selectedTickets: SelectedTicket[];
    handleTicketSelect: ({ ticketId, status }: { ticketId: number, status: string }) => void;
    loading: boolean;
    selectedFilterValues: { [key: string]: string[] };
    searchTerm: string;
    activeTicketTag: string;
    handleModalCloseOnSuccess: () => void;
    selectedFilter: any;
    fetchData: (pageNum?: number) => void;
}

const TicketTable: React.FC<TicketTableProps> = ({ selectedTickets, handleTicketSelect, loading, handleModalCloseOnSuccess, fetchData }) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch()
    const [isContextMenuOpen, setIsContextMenuOpen] = useState<number | null>(null);
    const { ticketTableData, ticketTableLoading, ticketListRateLimitedError } = useAppSelector((state) => state.ticketTableSlice);
    const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState<number | null>(null);
    const [selectedTicketForUpdate, setSelectedTicketForUpdate] = useState<number | null>(null);

    const ticketData = ticketTableData && ticketTableData?.results || []
    const ticketTableRef = React.createRef<HTMLDivElement>();

    const [sortConfig, setSortConfig] = useState<{ key: keyof Ticket; direction: 'asc' | 'desc' } | null>({
        key: 'id',
        direction: 'asc',
    });

    const openContextMenuOpen = (index: number) => {
        setIsContextMenuOpen((prevIndex: any) => (prevIndex === index ? null : index));
    };

    const handleReassignModalClose = () => {
        setIsReassignModalOpen(false);
    };


    const handleCloseTicket = async (ticketId: number) => {
        await dispatch(
            closeTicketThunk({
                ticketId: ticketId,
            })
        ).then((res) => {
            if (closeTicketThunk.fulfilled.match(res)) {
                showToaster('Ticekt closed successfully', 'success');
                setIsContextMenuOpen(null);
                handleModalCloseOnSuccess()
            } else {
                if (res.payload && typeof res.payload === 'object' && 'apiMessage' in res.payload) {
                    showToaster((res.payload as { apiMessage: string }).apiMessage, 'error');
                } else {
                    showToaster('An error occurred', 'error');
                }
            }
        })

    };


    const columns: Column<Ticket>[] = useMemo(
        () => [
            {
                Header: '',
                accessor: 'checkbox' as keyof Ticket,
                width: 51,
                minWidth: 51,
                maxWidth: 51,
            },
            {
                Header: 'Ticket#',
                accessor: 'id' as keyof Ticket,
                width: 93,
                minWidth: 93,
                maxWidth: 93,
            },
            {
                Header: 'Status',
                accessor: 'status' as keyof Ticket,
                width: 93,
                minWidth: 93,
                maxWidth: 93,
            },
            {
                Header: 'Assigned',
                accessor: 'assignee' as keyof Ticket,
                width: 124,
                minWidth: 124,
                maxWidth: 124,
            },

            {
                Header: 'Subject',
                accessor: 'subject' as keyof Ticket,
                width: 255,
                minWidth: 255,
                maxWidth: 255,
            },
            {
                Header: 'Well name',
                accessor: 'wellName' as keyof Ticket,
                width: 255,
                minWidth: 255,
                maxWidth: 255,
            },
            {
                Header: 'Requestor',
                accessor: 'requester' as keyof Ticket,
                width: 157,
                minWidth: 157,
                maxWidth: 157,
            },
            {
                Header: 'Requested',
                accessor: 'createdDate' as keyof Ticket,
                width: 161,
                minWidth: 161,
                maxWidth: 161,
            },
            {
                Header: 'Operator',
                accessor: 'operator' as keyof Ticket,
                width: 115,
                minWidth: 115,
                maxWidth: 115,
            },
            {
                Header: 'Last updated',
                accessor: 'updatedDate' as keyof Ticket,
                width: 140,
                minWidth: 140,
                maxWidth: 140,
            },
            {
                Header: '',
                accessor: 'action' as keyof Ticket,
                width: 66,
                minWidth: 66,
                maxWidth: 66,
            },
        ],
        [],
    );

    const sortedData = React.useMemo(() => {
        if (sortConfig === null) return ticketData;
        return [...ticketData].sort((a, b) => {
            const aValue = a[sortConfig.key];
            const bValue = b[sortConfig.key];
            if (aValue == null || bValue == null) return 0;
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.localeCompare(bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
            }
            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
            }
            return aValue < bValue
                ? sortConfig.direction === 'asc'
                    ? -1
                    : 1
                : aValue > bValue
                    ? sortConfig.direction === 'asc'
                        ? 1
                        : -1
                    : 0;
        });
    }, [ticketData, sortConfig]);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns: columns || [],
        data: sortedData || [],
    });


    useEffect(() => {
        const handleOutSideClick = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsContextMenuOpen(null);
            }
        };
        if (isContextMenuOpen) {
            document.addEventListener('mousedown', handleOutSideClick);
        }
        return () => document.addEventListener('mousedown', handleOutSideClick);
    }, [isContextMenuOpen]);

    const handleSort = (column: ColumnInstance<Ticket>) => {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key: column.id as keyof Ticket, direction });
    };


    const fetchMoreOnBottomReached = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                if (
                    ((scrollHeight - scrollTop - clientHeight) < 50) &&
                    (!ticketTableLoading && ticketTableData?.hasNextPage)
                ) {
                    fetchData(ticketTableData?.nextPageNumber || ticketTableData?.pageNumber + 1)
                }
            }
        },
        [ticketData?.length, ticketTableLoading])


    const formatRelativeTime = (dateString: string): string => {
        const now = moment();
        const createdMoment = moment(dateString);

        if (now.diff(createdMoment, "minutes") < 1) {
            return "Just now";
        } else if (now.diff(createdMoment, "hours") < 1) {
            return `${now.diff(createdMoment, "minutes")} minutes ago`;
        } else if (now.isSame(createdMoment, "day")) {
            return `Today at ${createdMoment.format("hh:mm A")}`;
        } else if (now.diff(createdMoment, "days") === 1) {
            return `Yesterday at ${createdMoment.format("hh:mm A")}`;
        } else if (now.diff(createdMoment, "days") < 7) {
            return `${now.diff(createdMoment, "days")} days ago`;
        } else if (now.diff(createdMoment, "weeks") < 4) {
            return `${now.diff(createdMoment, "weeks")} week${now.diff(createdMoment, "weeks") > 1 ? "s" : ""} ago`;
        } else if (now.diff(createdMoment, "months") < 12) {
            return `${now.diff(createdMoment, "months")} month${now.diff(createdMoment, "months") > 1 ? "s" : ""} ago`;
        } else {
            return `${now.diff(createdMoment, "years")} year${now.diff(createdMoment, "years") > 1 ? "s" : ""} ago`;
        }
    };


    const renderCellContent = ({ cell }: RenderCellContentProps) => {
        const { id } = cell.column;
        const row = cell.row
        const ticketId = row.original.id
        const ticketStatus = row.original.status
        switch (id) {
            case 'checkbox':
                return (
                    <label className='checkbox-container'>
                        <input
                            type='checkbox'
                            checked={selectedTickets.some((item) => item.ticketId === ticketId)}
                            onClick={() => handleTicketSelect({ ticketId: ticketId, status: ticketStatus })}

                        />
                        <span className='checkbox-checkmark'></span>
                    </label>
                )
            case 'action': {
                const diabledStatus = (row?.original?.status?.toLowerCase() === "closed" || row?.original?.status?.toLowerCase() === "solved") ? true : false;
                const closeDiabledStatus = (row?.original?.status?.toLowerCase() === "closed") ? true : false;

                return (
                    <div className='dropdown-container p-2'>
                        <button className={`dropdown-btn ticket-action`} onClick={() => openContextMenuOpen(row.index)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isContextMenuOpen === row.index && (
                            <div className='dropdown-options' ref={dropdownRef}>
                                <button disabled={diabledStatus} className={`dropdown-option show-element ${diabledStatus ? 'pointer-events-none btn-disabled' : ''}`}
                                    onClick={() => {
                                        setIsReassignModalOpen(true);
                                        setSelectedTicket(row.original.id);
                                        setIsContextMenuOpen(null);
                                    }}>Reassign ticket</button>
                                {/* <button className='dropdown-option show-element'>Add a task / reminder</button> */}
                                <button disabled={closeDiabledStatus} className={`dropdown-option show-element  ${closeDiabledStatus ? 'pointer-events-none btn-disabled' : ''}`} onClick={() => handleCloseTicket(row.original.id)}>Close ticket</button>
                            </div>
                        )}
                    </div>
                );
            }

            case 'wellName':
                return (
                    <div className='well-name-link relative'>
                        <div
                            className='flex items-center h-full w-full' >
                            {cell.render('Cell')}
                        </div>
                    </div>
                )

            case 'id':
                return (
                    <div
                        className='well-name-link flex items-center h-full w-full' onClick={
                            () => {
                                setSelectedTicketForUpdate(row.original.id)
                            }
                        }>
                        {cell.render('Cell')}
                    </div>
                )

            case 'subject':
                return (
                    <div
                        className='h-full w-[255px] truncate overflow-hidden text-ellipsis whitespace-nowrap' >
                        {cell.render('Cell')}
                    </div>
                )
            case 'requestor':
                return (
                    <div
                        className='h-full w-[157px]  overflow-hidden text-ellipsis whitespace-nowrap' >
                        {cell.render('Cell')}
                    </div>
                )
            case 'createdDate':
                return (
                    <div
                        className='flex items-center h-full w-full' >
                        {formatRelativeTime(row?.original.createdAt)}
                    </div>
                )
            case 'updatedDate':
                return (
                    <div
                        className='flex items-center h-full w-full' >
                        {formatRelativeTime(row?.original.updatedAt)}
                    </div>
                )
            default:
                return cell.render('Cell');
        }
    }


    return (
        <>

            <div className="ticket-table-wrapper relative">
                <div
                    className={`table-container w-full !rounded-[8px] ticket-table-container  ${loading ? 'pointer-events-none' : ''}`}
                    ref={ticketTableRef}
                    onScroll={() => fetchMoreOnBottomReached(ticketTableRef.current)}
                >
                    {(
                        <table className='document-table' {...getTableProps()} key='table'>
                            <thead className='document-head'>
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id + 'header'}>
                                        {headerGroup.headers.map((column) => (
                                            <th
                                                {...column.getHeaderProps({
                                                    style: { width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth },
                                                })}
                                                onClick={() => {
                                                    //   if (column.id !== 'uptime' && column.id !== 'intakePressure' && column.id !== 'dropdown') {
                                                    handleSort(column as ColumnInstance<Ticket>);
                                                    //   }
                                                }}
                                                key={column.id}
                                            >
                                                <div>
                                                    {column.render('Header')}
                                                    {
                                                        (column.id !== 'checkbox' && column.id !== 'action') ?
                                                            sortConfig?.key === column.id ? (
                                                                sortConfig.direction === 'asc' ? (
                                                                    <SortIconUp alt='sort-asc' className='sort-img' />
                                                                ) : (
                                                                    <SortIconDown alt='sort-dsc' className='sort-img' />
                                                                )
                                                            ) : (
                                                                <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                                                            )
                                                            : ''
                                                    }
                                                </div>
                                            </th>
                                        ))}
                                        {/* <th></th> */}
                                    </tr>
                                ))}
                            </thead>

                            {loading ? <Loader /> : null}

                            <tbody
                                className={`document-body ${loading ? 'blur-background pointer-events-none' : ''}`}
                                {...getTableBodyProps()}
                            >
                                {
                                    (ticketData?.length === 0 && !loading) ?

                                        <tr>
                                            <td className='!border-none' colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                                                <div className='flex justify-center items-center h-[370px]'>
                                                    <NoData heading={ticketListRateLimitedError ? ticketListRateLimitedError : 'No data found'} />
                                                </div>
                                            </td>
                                        </tr>
                                        :

                                        rows.map((row) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={row.id}>
                                                    {row.cells.map((cell: any) => (
                                                        <td
                                                            // {...cell.getCellProps({
                                                            //     style: {
                                                            //         width: cell.column.width,
                                                            //         minWidth: cell.column.minWidth,
                                                            //         maxWidth: cell.column.maxWidth,
                                                            //     },
                                                            // })}
                                                            key={cell.column.id}
                                                        >
                                                            {renderCellContent({ cell, row })}
                                                        </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                            </tbody>
                        </table>)}
                </div>
            </div>



            {isReassignModalOpen &&
                <ReassignTicketModal
                    selectedTicket={selectedTicket !== null ? [selectedTicket] : []}
                    isModalOpen={isReassignModalOpen}
                    handleModalClose={handleReassignModalClose}
                    handleModalCloseOnSuccess={handleModalCloseOnSuccess}
                    action={TicketAction.SINGLE}
                />
            }
            {
                selectedTicketForUpdate && <TicketModelOpen ticketId={selectedTicketForUpdate} isOpen={true} closeModal={() => setSelectedTicketForUpdate(null)} fetchData={fetchData} />
            }
        </>
    )
}

export default TicketTable;