import { apiInstance } from '../../../config/HttpCommon';
import { UserFormData } from './AddUserTypes';

export const addUser = async (user: UserFormData) => {
  try {
    const response = await apiInstance.post('/v1/users', user);
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const fetchUserAndCustomerIDs = async () => {
  try {
    const response = await apiInstance.get('/v1/users/customers');
    return response.data;
  } catch (error) {
    console.error('Error fetching User and Customer IDs:', error);
    throw error;
  }
};

export const getPrivileges = async () => {
  const response = await apiInstance.get(`/v1/users/privileges`);
  return response.data;
};
