

import React, { useEffect, useState } from 'react';
import { Setpoint } from '../../asset-details/components/setpoints/model/ApiResponse';
import chevrondown from '../../../images/chevron-down.svg';
import { setPointParamStandardTypeId } from '../model/ManageAssetModel';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { setChangedSetpointValues } from '../ManageAssetSlice';
import TimeInput from './TimeInput';
import { useManageAsset } from './hook/ManageAssetHook';

interface BackUpLookupValue {
    text: string;
    value: number;
}

interface SetpointBackupValueProps {
    setPointItem: Setpoint;
    groupName: keyof typeof setPointParamStandardTypeId,
    setpointName: string,
    // setpointAddress: string
    isTimeInput?: boolean,
    inputDisabledStatus?: boolean

}

const SetpointBackupValue: React.FC<SetpointBackupValueProps> = ({ setPointItem, isTimeInput, groupName, setpointName, inputDisabledStatus = false }) => {
    const dispatch = useAppDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [selectedOption, setSelectedOption] = useState<BackUpLookupValue | null>(null)
    const [inputValue, setInputValue] = useState<string>("")
    const manageAssetSliceData = useAppSelector((state) => state?.manageAsset)
    const [timeInputValue, setTimeInputValue] = useState<number>(0)

    const { getSetpointsDisabledStatus } = useManageAsset()

    const setpointDisabledStatus = (getSetpointsDisabledStatus(groupName, setpointName) || inputDisabledStatus)

    useEffect(() => {
        let matchingOne = null
        if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
            const matchingOption = setPointItem.backUpLookUpValues.find(
                (option) => {
                    return option.value === parseInt(setPointItem.backupValue)
                }
            );
            matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];
        }
        if (matchingOne) {
            setSelectedOption(matchingOne)
        } else {
            if (isTimeInput) {
                setTimeInputValue(+setPointItem.backupValue)
            } else {
                setInputValue(setPointItem.backupValue)
            }
        }
    }, [setPointItem])

    useEffect(() => {
        if (manageAssetSliceData.deviceValue && manageAssetSliceData.deviceReadStatus === true) {
            const matchedDeviceValue = manageAssetSliceData.deviceValue?.find((item) => item.address.toString() === setPointItem.parameter)
            if (matchedDeviceValue) {
                let matchingOne = null
                if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
                    const matchingOption = setPointItem.backUpLookUpValues.find(
                        (option) => {
                            return option.value === matchedDeviceValue.value
                        }
                    );
                    matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];
                }

                if (matchingOne) {
                    setSelectedOption(matchingOne)
                } else {
                    if (isTimeInput) {
                        setTimeInputValue(matchedDeviceValue.value)
                    } else {
                        setInputValue(matchedDeviceValue.value.toString())
                    }
                }
            }
        }
    }, [manageAssetSliceData.deviceReadStatus, manageAssetSliceData.deviceValue])

    useEffect(() => {
        if (manageAssetSliceData.changedBackupValues && Object.keys(manageAssetSliceData.changedBackupValues).length !== 0) {
            const matchedValue = manageAssetSliceData.changedBackupValues[setPointItem.parameter]
            if (matchedValue) {
                let matchingOne = null
                if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
                    const matchingOption = setPointItem.backUpLookUpValues.find(
                        (option) => {
                            return option.value === matchedValue
                        }
                    );
                    matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];
                }

                if (matchingOne) {
                    setSelectedOption(matchingOne)
                } else {
                    if (isTimeInput) {
                        setTimeInputValue(matchedValue)
                    } else {
                        setInputValue(matchedValue.toString())
                    }
                }
            }
        }

    }, [manageAssetSliceData.changedBackupValues])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const formattedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setInputValue(formattedValue);
    };
    const handleInputBlur = () => {
        if (inputValue) {
            const setpointValues = {
                [setPointItem.parameter]: +inputValue,
            };

            dispatch(setChangedSetpointValues(setpointValues))
        }
    };

    const handleTimeInputChange = (value: number) => {
        const setpointValues = {
            [setPointItem.parameter]: +value,
        };

        dispatch(setChangedSetpointValues(setpointValues))
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option: BackUpLookupValue, setPointItem: Setpoint) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        const setpointValues = {
            [setPointItem.parameter]: option.value,
        };

        dispatch(setChangedSetpointValues(setpointValues))
    };

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            if (event.target instanceof HTMLElement && event.target.closest('.select-name')) return;
            setIsDropdownOpen(false);
        };
        document.body.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.body.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);


    return (
        <div className='select-name backup-container'>
            {setPointItem.backUpLookUpValues?.length ? (
                <div className={`custom-dropdown backup-dropdown ${isDropdownOpen ? 'open' : ''} ${setpointDisabledStatus ? 'disabled' : ''}`} onClick={toggleDropdown}>
                    <div className='selected-option flex justify-between'>
                        {selectedOption?.text}
                        <img src={chevrondown} alt='chevron-down' className={`${isDropdownOpen ? 'rotate-180' : ''}`} />
                    </div>
                    {isDropdownOpen && (
                        <ul className='dropdown-list'>
                            {setPointItem?.backUpLookUpValues?.map((option, index) => (
                                <li key={index} onClick={() => handleOptionClick(option, setPointItem)}>
                                    {option.text}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ) :
                !isTimeInput ?
                    <input
                        type='text'
                        className={`time-input-field ${setpointDisabledStatus ? 'disabled' : ''}`}
                        disabled={!manageAssetSliceData.deviceReadStatus || manageAssetSliceData.readDeviceLoading}
                        value={inputValue as string}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        placeholder='0'
                    />
                    :
                    <TimeInput value={timeInputValue} handleTimeInputChange={handleTimeInputChange} disabledStaus={setpointDisabledStatus} />
            }
        </div>
    );
};

export default SetpointBackupValue;
