import React, { useEffect, useState } from 'react'
import './BuildWatchlistFiltersModal.scss'
import CloseIcon from '../../../../../images/CloseIcon.svg'
import TrashIcon from '../../../../../images/trash-03.svg'
import ArrowUp from '../../../../../images/arrow-up-4.svg'
import ArrowDown from '../../../../../images/arrow-down-4.svg'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks'
import { SelectedFilters } from '../ControlRoomWells'
import { Parameter, WellWatchList } from '../../interfaces/ControlRoomWells.interface'
import { showToaster } from '../../../../dashboard/components/asset-location/AssetList'
import {
    fetchAssetLocations,
    fetchAssetOperators,
    fetchAssetParameters,
    fetchAssetStatuses,
    getWatchListById,
    removeWatchListForUpdate,
} from '../ControlRoomWellsSlice'
import Loader from '../../../../common/page-loader/ComponentLoader'

const spinnerHTML = <svg aria-hidden="true" className="inline w-6 h-6 text-white animate-spin dark:text-gray-600 fill-gray-700" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
</svg>


function convertToSentenceCase(input: string): string {
    const words: string[] = input.split(' ');

    const result: string[] = words.map((word: string, index: number) => {
        if (word === word.toUpperCase()) {
            return word;
        } else if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
            return word.toLowerCase();
        }
    });

    return result.join(' ');
}

interface BuildWatchlistFiltersModalProps {
    closeModal: () => void;
    createBtnHandler: (payload: SelectedFilters) => void;
    updateBtnHandler: (payload: SelectedFilters) => void;
    activeWell: WellWatchList;
}

const BuildWatchlistFiltersModal = ({ closeModal, createBtnHandler, updateBtnHandler, activeWell }: BuildWatchlistFiltersModalProps) => {
    const dispatch = useAppDispatch();
    // const [isFormValid, setIsFormValid] = useState(false);
    const assetFilters = useAppSelector((state) => ({
        operators: state.wellWatchList.assetOperators as Parameter[],
        locations: state.wellWatchList.assetLocations as { counties: Parameter[], states: Parameter[] },
        statuses: state.wellWatchList.assetStatuses as Parameter[],
        parameters: state.wellWatchList.assetParameters as Parameter[],
        watchList: state.wellWatchList.wellsWatchListById as WellWatchList,
        watchListCreateUpdateLoading: state.wellWatchList.watchListCreateUpdateLoading,

    }));

    const wellsWatchListById = useAppSelector((state) => state.wellWatchList.wellsWatchListById);
    const wellWatchListByIdLoading = useAppSelector((state) => state.wellWatchList.wellWatchListByIdLoading);
    const { assetParametersLoading, assetOperatorsLoading, assetStatusesLoading, assetLocationsLoading } = useAppSelector((state) => state.wellWatchList);



    const flattenLocations = (locations: { counties: Parameter[], states: Parameter[] }) => {
        return [
            ...locations.counties.map(c => ({ ...c, type: 'county' as const, isChecked: c?.isChecked ?? false })),
            ...locations.states.map(s => ({ ...s, type: 'state' as const, isChecked: s?.isChecked ?? false }))
        ];
    };
    const [operators, setOperators] = useState<Parameter[]>(assetFilters.operators)

    const [statuses, setStatuses] = useState<Parameter[]>(assetFilters.statuses)
    const [parameters, setParameters] = useState<Parameter[]>(assetFilters.parameters)
    const [locations, setLocations] = useState<Parameter[]>(() => flattenLocations(assetFilters.locations));
    
    const [filteredOperators, setFilteredOperators] = useState<any[]>([]);
    const [filteredLocations, setFilteredLocations] = useState<Parameter[]>([]);

    const [filteredStatuses, setFilteredStatuses] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [filteredParameters, setFilteredParameters] = useState<Parameter[]>([]);

    const [expandedFilters, setExpandedFilters] = useState({
        operators: false,
        location: false,
        statuses: false,
        parameters: false,
    })

    const [selectedFilters, setSelectedFilters] = useState({
        operators: [] as Parameter[],
        locations: [] as Parameter[],
        statuses: [] as Parameter[],
        parameters: [] as Parameter[],
        name: '',
        description: '',
    });
    
    useEffect(() => {
        if (activeWell?.id) {
            dispatch(getWatchListById(activeWell?.id))
        }
    }, [activeWell])
    

    // useEffect(() => {
    //     validateWatchlist();
    // }, [selectedFilters]);

    const updateSelectedFilters = (items: Parameter[], type: 'operators' | 'locations' | 'statuses' | 'parameters') => {
        const selectedItems = items.filter(item => item?.isChecked);

        const finalSelectedItems = type === 'parameters'
            ? selectedItems.map(item => ({
                ...item,
                from: selectedFilters?.parameters?.find(p => p?.id === item?.id)?.from || null,
                to: selectedFilters?.parameters?.find(p => p?.id === item?.id)?.to || null,
            }))
            : selectedItems;

        setSelectedFilters(prev => ({
            ...prev,
            [type]: finalSelectedItems,
        }));
    };

    const renderLocationCheckboxes = (filteredItems: Parameter[]) => {
        const counties = filteredItems?.filter(item => item?.type === 'county');
        const states = filteredItems?.filter(item => item?.type === 'state');

        return (
            <div className="">
                {counties.length > 0 && (
                    <div className="county-group filter-checkboxes">
                        <h3 className='text-xl font-bold'>Counties</h3>
                        {counties.map(item => (
                            <label key={item?.id} className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={item?.isChecked}
                                    onChange={e => handleCheckboxChange(item?.id, e.target.checked, setLocations, setFilteredLocations, 'locations')}
                                />
                                <span className="checkbox-checkmark"></span>
                                <p className='capitalize'>{item?.name?.toLocaleLowerCase()}</p>
                            </label>
                        ))}
                    </div>
                )}
                {states.length > 0 && (
                    <div className="state-group filter-checkboxes">
                        <h3 className='text-xl font-bold mt-3'>States</h3>
                        {states.map(item => (
                            <label key={item?.id} className="checkbox-container">
                                <input
                                    type="checkbox"
                                    checked={item?.isChecked}
                                    onChange={e => handleCheckboxChange(item?.id, e.target.checked, setLocations, setFilteredLocations, 'locations')}
                                />
                                <span className="checkbox-checkmark"></span>
                                <p className='capitalize'>{item?.name?.toLocaleLowerCase()}</p>
                            </label>
                        ))}
                    </div>
                )}
            </div>
        );
    };



    const handleCheckboxChange = (
        id: string,
        isChecked: boolean,
        setItems: React.Dispatch<React.SetStateAction<Parameter[]>>,
        setFilteredItems: React.Dispatch<React.SetStateAction<Parameter[]>>,
        type: 'operators' | 'locations' | 'statuses' | 'parameters'
    ) => {
        setItems(items => {
            const updatedItems = items.map(item =>
                item?.id === id ? { ...item, isChecked } : item
            );

            // Sync the filtered list state with the updated items
            setFilteredItems(filtered =>
                filtered.map(item =>
                    item?.id === id ? { ...item, isChecked } : item
                )
            );

            // Update the selected filters
            const updatedSelectedItems = updatedItems.filter(item => item?.isChecked);
            updateSelectedFilters(updatedSelectedItems, type);

            return updatedItems;
        });
    };



    const handleSelectAll = (
        setItems: React.Dispatch<React.SetStateAction<Parameter[]>>,
        filteredItems: Parameter[],
        select: boolean,
        type: 'operators' | 'locations' | 'statuses' | 'parameters',
        setFilteredItems?: React.Dispatch<React.SetStateAction<Parameter[]>>,
    ) => {
        setItems(items => {
            // Update only the filtered items
            const updatedItems = items.map(item =>
                filteredItems?.some(filtered => filtered?.id === item?.id)
                    ? { ...item, isChecked: select }
                    : item
            );

            // Update the filtered items to reflect the selection
            setFilteredItems && setFilteredItems(filtered =>
                filtered.map(item => ({
                    ...item,
                    isChecked: select,
                }))
            );

            updateSelectedFilters(
                updatedItems?.filter(item => item?.isChecked),
                type
            );

            return updatedItems;
        });
    };


    const toggleFilterSection = (section: 'operators' | 'location' | 'statuses' | 'parameters') => {
        setExpandedFilters(prev => ({
            operators: section === 'operators',
            location: section === 'location',
            statuses: section === 'statuses',
            parameters: section === 'parameters',
            [section]: !prev[section],
        }));
        setSearchTerm("")
    };

    const renderFilterCheckboxes = (
        filteredItems: Parameter[],
        handleChange: (id: string, checked: boolean) => void
    ) => {

        return (
            <div className="filter-checkboxes">
                {filteredItems?.map(item => (
                    <label key={item?.id} className="checkbox-container">
                        <input
                            type="checkbox"
                            checked={item?.isChecked}
                            onChange={e => handleChange(item?.id, e.target.checked)}
                        />
                        <span className="checkbox-checkmark"></span>
                        <p>
                            {item.name ? convertToSentenceCase(item.name) : ""}
                        </p>
                    </label>
                ))}
            </div>
        );
    };    

    const getSelectedCount = (itemName: "locations" | "operators" | "statuses" | "parameters"): number => {
        return selectedFilters[itemName]?.length;
    }

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: 'name' | 'description') => {
        setSelectedFilters(prev => ({
            ...prev,
            [type]: e.target.value
        }));
    }

    const CloseButtonHandler = () => {
        setSelectedFilters({} as SelectedFilters);
        closeModal();
    }

    useEffect(() => {
        if (activeWell?.id && wellsWatchListById?.id) {
            const mapCheckedItems = (items: Parameter[] = [], activeIds: string[] = []) =>
                items.map(item => ({
                    ...item,
                    isChecked: activeIds.includes(String(item.id)),
                }));

            const operators = mapCheckedItems(assetFilters?.operators, wellsWatchListById?.operators || []);
            const locations = flattenLocations(assetFilters?.locations).map(item => ({
                ...item,
                isChecked: wellsWatchListById?.locations?.includes(item.id) ?? false,
            }));
            const statuses = mapCheckedItems(assetFilters?.statuses, wellsWatchListById?.statuses || []);

       setFilteredOperators(operators);
          setOperators(operators);
      
          setFilteredLocations(locations);
          setLocations(locations);
      
          setFilteredStatuses(statuses);
          setStatuses(statuses);
      
          const parameters = (assetFilters?.parameters || [])?.map(item => {
            const parameter = wellsWatchListById?.parameters?.find(
                param => String(param?.id) === String(item?.id)
            );
            return {
              ...item,
              isChecked: !!parameter,
              from: parameter?.from ?? null,
              to: parameter?.to ?? null,
            };
          });        
      
          setParameters(parameters);
          setFilteredParameters(parameters);
          const selectedParameters = parameters?.filter(param => param?.isChecked);
          setSelectedFilters({
              ...selectedFilters,
              operators: operators.filter(item => item?.isChecked),
              locations: locations.filter(item => item?.isChecked),
              statuses: statuses.filter(item => item?.isChecked),
              name: wellsWatchListById?.name,
              description: wellsWatchListById?.description,
              parameters: selectedParameters,
          });
      }
  }, [wellsWatchListById,
      assetFilters?.operators,
      assetFilters?.locations,
      assetFilters?.statuses,
      assetFilters?.parameters,]);

  useEffect(() => {
      if (!assetFilters?.parameters?.length) dispatch(fetchAssetParameters());
      if (!assetFilters?.operators?.length) dispatch(fetchAssetOperators());
      if (!assetFilters?.statuses?.length) dispatch(fetchAssetStatuses());
      if (!assetFilters?.locations?.states?.length && !assetFilters?.locations?.counties?.length) dispatch(fetchAssetLocations());

  }, [dispatch]);

  useEffect(() => {
      dispatch(removeWatchListForUpdate());
      setSelectedFilters({} as SelectedFilters);
  }, []);


  const isFirstRender = React.useRef(true);
  useEffect(() => {
      if (assetFilters?.operators?.length === 0 &&
          assetFilters?.locations?.counties?.length === 0 &&
          assetFilters?.locations?.states?.length === 0 &&
          assetFilters?.statuses?.length === 0 &&
          assetFilters?.parameters?.length === 0
      ) return;
      if (isFirstRender?.current) {
          isFirstRender.current = false;
          return;
      }

      setOperators(assetFilters?.operators);
      setStatuses(assetFilters?.statuses);
      setParameters(assetFilters?.parameters);
      setLocations(flattenLocations(assetFilters?.locations));
  }
      , [assetFilters?.operators, assetFilters?.locations, assetFilters?.statuses, assetFilters?.parameters]);




      const handleParameterChange = (id: string, value: string, type: 'from' | 'to') => {

        setSelectedFilters(prev => ({
            ...prev,
            parameters: prev.parameters?.map(parameter => parameter?.id === id ? {
                ...parameter,
                [type]: value
            } : { ...parameter })
        }));
    }

    const validateWatchlist = () => {
        if (selectedFilters?.name === '') {
            // setIsFormValid(false);
            showToaster('Please fill the name field', 'error');
            return false;
        } else if (selectedFilters?.description === '') {
            // setIsFormValid(false);
            showToaster('Please fill the description field', 'error');
            return false;
        } else if (selectedFilters?.parameters?.length > 0) {
            const invalidParameters = selectedFilters?.parameters?.filter(parameter => !parameter?.from || !parameter?.to);
            if (invalidParameters?.length > 0) {
                // setIsFormValid(false);
                showToaster('Please fill all the parameters values', 'error');
                return false;
            }
        }
        // setIsFormValid(true);
        return true;
    }

    const clearAllhandler = () => {
        setSelectedFilters({
            ...selectedFilters,
            operators: [],
            locations: [],
            statuses: [],
            parameters: [],
        });
        setFilteredOperators(assetFilters?.operators);
        setFilteredLocations(flattenLocations(assetFilters?.locations));
        setFilteredStatuses(assetFilters?.statuses);
        setFilteredParameters(assetFilters?.parameters);
        setOperators(assetFilters?.operators);
        setLocations(flattenLocations(assetFilters?.locations));
        setStatuses(assetFilters?.statuses);
        setParameters(assetFilters?.parameters);
        setSearchTerm("");
    };


    useEffect(() => {
        const lowercasedSearchTerm = searchTerm?.toLowerCase();

        // Update filteredOperators and filteredStatuses as before
        setFilteredOperators(
            operators?.filter(operator =>
                operator?.name?.toLowerCase()?.includes(lowercasedSearchTerm)
            )
        );
        setFilteredStatuses(
            statuses?.filter(status =>
                status?.name?.toLowerCase()?.includes(lowercasedSearchTerm)
            )
        );

        setFilteredParameters(
            parameters?.filter(parameter =>
                parameter?.name?.toLowerCase()?.includes(lowercasedSearchTerm)
            )
        );

        // For locations: filter counties and states separately
        setFilteredLocations(flattenLocations({
            counties: locations?.filter(location => location?.type === 'county' && location?.name?.toLowerCase()?.includes(lowercasedSearchTerm)),
            states: locations?.filter(location => location?.type === 'state' && location?.name?.toLowerCase()?.includes(lowercasedSearchTerm))
        }));
    }, [searchTerm, operators, locations, statuses, parameters]);


    const handleSearch = (searchValue: string, setFilteredItems: React.Dispatch<React.SetStateAction<Parameter[]>>, items: Parameter[]) => {
        const lowerCaseSearch = searchValue?.toLowerCase();
        setSearchTerm(searchValue);
        const filtered = items?.filter(item =>
            item?.name?.toLowerCase()?.includes(lowerCaseSearch)
        );

        setFilteredItems(filtered);
    };

    return (
        <div className="build-watchlist-filters-modal modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">Build watchlist</h2>
                    <button className="close-btn" onClick={CloseButtonHandler}>
                        <img src={CloseIcon} alt="close" />
                    </button>
                </div>
                <div className="modal-body">
                    <div className="asset-count-div" style={{ display: 'none' }}>
                        {/* <p className="asset-count">10</p> */}
                        <span className='asset-count-label !text-5xl'>0</span>
                        <p className="asset-count-label">Total Assets</p>
                    </div>

                    <div className="filters-form-container">
                        <div className="watchlist-filters">

                            <div className="watchlist-filters-heading">
                                Criteria
                            </div>

                            {/* Operators Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('operators')}>
                                <div className="watchlist-filters-title">
                                    Operators
                                      </div>

                                <div className='flex items-center gap-5'>
                                    {
                                        assetOperatorsLoading && <span className='filters-loading'><Loader /></span>
                                    }
                                    <span className="arrow-icon">
                                        <img src={expandedFilters?.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                    </span>
                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters?.operators ? 'expanded' : ''}`}>
                               <div className='operators-search-filter'>
                                    <div className='w-100 pb-4'>
                                        <label className='relative flex items-center mr-0'>
                                            <span className='sr-only'>Search</span>
                                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                                        clipRule='evenodd'
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className='custom-text-input'
                                                placeholder='Search'
                                                type='text'
                                                name='search'
                                                value={searchTerm}
                                                onChange={e => handleSearch(e.target.value, setFilteredOperators, operators)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-buttons">
                                    <div onClick={() =>
                                        handleSelectAll(
                                            setOperators,
                                            filteredOperators,
                                            true,
                                            'operators',
                                            setFilteredOperators,
                                        )
                                    } className="filter-button">Select all</div>
                                    <div onClick={() =>
                                        handleSelectAll(
                                            setOperators,
                                            filteredOperators,
                                            false,
                                            'operators',
                                            setFilteredOperators,
                                        )
                                    } className="filter-button">Clear all</div>
                                </div>
                                {filteredOperators?.length && renderFilterCheckboxes(filteredOperators, (id, checked) =>
                                    handleCheckboxChange(id, checked, setOperators, setFilteredOperators, 'operators')
                                )}
                            </div>

                            {/* Location Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('location')}>
                                <div className="watchlist-filters-title">
                                    Location
                                </div>
                               
                                <div className='flex items-center gap-5'>
                                    {
                                        assetLocationsLoading && <span className='filters-loading'><Loader /></span>
                                    }
                                    <span className="arrow-icon">
                                        <img src={expandedFilters?.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                    </span>
                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters.location ? 'expanded' : ''}`}>
                                <div className='operators-search-filter'>
                                    <div className='w-100 pb-4'>
                                        <label className='relative flex items-center mr-0'>
                                            <span className='sr-only'>Search</span>
                                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                                        clipRule='evenodd'
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className='custom-text-input'
                                                placeholder='Search'
                                                type='text'
                                                name='search'
                                                value={searchTerm}
                                                onChange={e => handleSearch(e.target.value, setFilteredLocations, locations)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setLocations, filteredLocations, true, "locations", setFilteredLocations)} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setLocations, filteredLocations, false, "locations", setFilteredLocations)} className="filter-button">Clear all</div>
                                </div>
                                {filteredLocations?.length && renderLocationCheckboxes(filteredLocations)}
                            </div>

                            {/* Status Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('statuses')}>
                                <div className="watchlist-filters-title">
                                    Status
                                </div>

                                <div className='flex items-center gap-5'>
                                    {
                                        assetStatusesLoading && <span className='filters-loading'><Loader /></span>
                                    }
                                    <span className="arrow-icon">
                                        <img src={expandedFilters?.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                    </span>
                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters?.statuses ? 'expanded' : ''}`}>
                                <div className='status-search-filter'>
                                    <div className='w-100 pb-4'>
                                        <label className='relative flex items-center mr-0'>
                                            <span className='sr-only'>Search</span>
                                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                                        clipRule='evenodd'
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className='custom-text-input'
                                                placeholder='Search'
                                                type='text'
                                                name='search'
                                                value={searchTerm}
                                                onChange={e => handleSearch(e.target.value, setFilteredStatuses, statuses)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setStatuses, filteredStatuses, true, "statuses", setFilteredStatuses)} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setStatuses, filteredStatuses, false, "statuses", setFilteredStatuses)} className="filter-button">Clear all</div>
                                </div>
                                {filteredStatuses?.length && renderFilterCheckboxes(filteredStatuses, (id, checked) =>
                                    handleCheckboxChange(id, checked, setStatuses, setFilteredStatuses, 'statuses')
                                )}

                            </div>

                            {/* Parameters Section */}
                            <div className="filter-header" onClick={() => toggleFilterSection('parameters')}>
                                <div className="watchlist-filters-title">
                                    Parameters
                                </div>

                                <div className='flex items-center gap-5'>
                                    {
                                        assetParametersLoading && <span className='filters-loading'><Loader /></span>
                                    }
                                    <span className="arrow-icon">
                                        <img src={expandedFilters?.operators ? ArrowUp : ArrowDown} alt="arrow" />

                                    </span>
                                </div>
                            </div>
                            <div className={`expanded-filter ${expandedFilters?.parameters ? 'expanded' : ''}`}>
                                <div className='parameter-search-filter'>
                                    <div className='w-100 pb-4'>
                                        <label className='relative flex items-center mr-0'>
                                            <span className='sr-only'>Search</span>
                                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                                <svg className='h-5 w-5 fill-slate-300' viewBox='0 0 20 20'>
                                                    <path
                                                        fillRule='evenodd'
                                                        d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
                                                        clipRule='evenodd'
                                                    ></path>
                                                </svg>
                                            </span>
                                            <input
                                                className='custom-text-input'
                                                placeholder='Search'
                                                type='text'
                                                name='search'
                                                value={searchTerm}
                                                onChange={e => handleSearch(e.target.value, setFilteredParameters, parameters)}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className="filter-buttons">
                                    <div onClick={() => handleSelectAll(setParameters, filteredParameters, true, "parameters",
                                        setFilteredParameters
                                    )} className="filter-button">Select all</div>
                                    <div onClick={() => handleSelectAll(setParameters, filteredParameters, false, "parameters",
                                        setFilteredParameters
                                    )} className="filter-button">Clear all</div>
                                </div>
                                {filteredParameters?.length && renderFilterCheckboxes(filteredParameters, (id, checked) => handleCheckboxChange(id, checked, setParameters, setFilteredParameters, "parameters"))}
                            </div>





                        </div>

                        <div className="watchlist-form">
                            <div className="name-description-form-group">
                                <div className="form-group name">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input autoComplete='off' value={selectedFilters?.name} onChange={(e) => onChangeInput(e, 'name')} type="text" id="name" className="form-control" placeholder='Name' />
                                </div>
                                <div className="form-group description">
                                    <label htmlFor="description" className="form-label">Description</label>
                                    <input autoComplete='off' value={selectedFilters?.description} onChange={(e) => onChangeInput(e, 'description')} type="text" id="description" className="form-control" placeholder='Description' />
                                </div>
                            </div>

                            <div className="selected-filters-sec">

                            {wellWatchListByIdLoading && activeWell?.id ? <div className='build-watchlist-loader mt-3'>
                                    <Loader />
                                </div> : <>

                                {getSelectedCount("operators") ? <div className="selected-filter-div h-[64px]">
                                    <h4>Operators</h4>
                                    <div className="count-del-btn pr-[18px]">
                                        <p>{getSelectedCount("operators")}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                    setOperators(operators?.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                })))
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    operators: []
                                                })
                                                setFilteredOperators(assetFilters.operators)
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                {getSelectedCount("locations") ? <div className="selected-filter-div h-[64px]">
                                    <h4>Location</h4>
                                    <div className="count-del-btn pr-[18px]">
                                        <p>{getSelectedCount("locations")}</p>
                                        <button className="del-btn" onClick={
                                            () => {
                                                setLocations(locations?.map(item => ({
                                                    ...item,
                                                    isChecked: false
                                                }))
                                                )
                                                setSelectedFilters({
                                                    ...selectedFilters,
                                                    locations: []
                                                })
                                                setFilteredLocations(flattenLocations(assetFilters.locations))
                                            }
                                        } >
                                            <img src={TrashIcon} alt="delete" />
                                        </button>
                                    </div>
                                </div> : ""}

                                {
                                    getSelectedCount("statuses") ? <div className="selected-filter-div h-[64px]">
                                        <h4>Status</h4>
                                        <div className="count-del-btn pr-[18px]">
                                            <p>{getSelectedCount("statuses")}</p>
                                            <button className="del-btn" onClick={
                                                () => {
                                                    setStatuses(statuses?.map(item => ({
                                                        ...item,
                                                        isChecked: false
                                                    }))
                                                    )
                                                    setSelectedFilters({
                                                        ...selectedFilters,
                                                        statuses: []
                                                    })
                                                    setFilteredStatuses(assetFilters?.statuses)
                                                }
                                            } >
                                                <img src={TrashIcon} alt="delete" />
                                            </button>
                                        </div>
                                    </div> : ""
                                }

                                {
                                    getSelectedCount("parameters") ? selectedFilters?.parameters?.map((item, index) => (
                                        <div key={index} className='selected-filter-div h-[64px] '>
                                            <h4>{item?.name}</h4>
                                            <div className='count-del-btn fre-filter form-group pr-[18px]'>
                                                <div className='pr-[27px]'>
                                                    <div className='flex items-center border-col w-[85px] h-[44px] px-3 rounded '>
                                                    <input type='text' id={`from${item}`} onChange={
                                                                (e) => { handleParameterChange(item?.id, e.target.value, 'from') }}
                                                                value={item?.from !== null ? item?.from : ''}
                                                                className=' py-3  text-md border fre-input' placeholder=''
                                                            />
                                                        <span className='unit ml-[-20px]'>{item?.short_UnitOfMeasure}</span>
                                                    </div>
                                                    <p className='gap'>-</p>
                                                   <div className='flex items-center border-col px-3 w-[85px] rounded '>
                                                        <input type='text' id={`to${item}`}
                                                            onChange={
                                                                (e) => {
                                                                    handleParameterChange(item?.id, e.target.value, 'to');
                                                                }}
                                                            value={item?.to !== null ? item?.to : ''}
                                                            className=' py-3 mr-3 border text-md fre-input '
                                                            placeholder=' '
                                                        />
                                                        <span className='unit ml-[-20px]'>{item?.short_UnitOfMeasure}</span>
                                                   </div>
                                                </div>
                                                <button className="del-btn" onClick={
                                                        () => {
                                                            setFilteredParameters(parameters?.map(parameter => {
                                                                return parameter.id.toString() === item?.id ? {
                                                                    ...parameter,
                                                                    isChecked: false
                                                                } : parameter
                                                            }))
                                                            setParameters(parameters?.map(parameter => {
                                                                return parameter.id.toString() === item?.id ? {
                                                                    ...parameter,
                                                                    isChecked: false
                                                                } : parameter
                                                            }))
                                                            setSelectedFilters(prev => ({
                                                                ...prev,
                                                                parameters: prev.parameters?.filter(parameter => parameter?.id !== item?.id)
                                                            })
                                                            )
                                                        }

                                                    }>
                                                        <img src={TrashIcon} alt="delete" />
                                                    </button>
                                                </div>


                                            </div>
                                        )) : ""
                                    }
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button onClick={clearAllhandler} className="btn btn-secondary btn-lg CR-btn">Clear all</button>
                    <button
                        disabled={assetFilters?.watchListCreateUpdateLoading}
                        onClick={() => {
                            validateWatchlist() &&
                                (activeWell?.id ? updateBtnHandler(selectedFilters) :
                                    createBtnHandler(selectedFilters))
                        }
                        }
                        className={`btn btn-primary btn-lg CR-btn ${!assetFilters?.watchListCreateUpdateLoading ? '' : 'opacity-50 cursor-not-allowed'}`}>
                        {assetFilters?.watchListCreateUpdateLoading && spinnerHTML}
                        <span className='ml-2'>

                            {activeWell?.id ? "Update Watchlist" : "Save watchlist"}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BuildWatchlistFiltersModal
