import { useState, useRef, useEffect } from 'react';


interface TimeInputProps {
    value: number,
    handleTimeInputChange: (timeValue: number) => void,
    disabledStaus: boolean
}

function TimeInput({ value, handleTimeInputChange, disabledStaus }: TimeInputProps) {
    const [formattedTime, setFormattedTime] = useState('');
    const [totalSeconds, setTotalSeconds] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    const secondsToTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            secs.toString().padStart(2, '0'),
        ].join(':');
    };

    // Initialize formattedTime when the component mounts or defaultSeconds changes
    useEffect(() => {
        setFormattedTime(secondsToTime(value));
    }, [value]);

    interface HandleChangeEvent {
        target: {
            value: string;
            selectionStart: number | null;
        };
    }

    const handleChange = (e: HandleChangeEvent) => {
        const inputValue = e.target.value;
        const cursorPosition = e.target.selectionStart; // Get cursor position

        // Remove all non-digit characters
        const rawDigits = inputValue.replace(/\D/g, '');

        // Split into time parts
        const hours = rawDigits.slice(0, 2);
        const minutes = rawDigits.slice(2, 4);
        const seconds = rawDigits.slice(4, 6);


        // Format based on input length
        let newFormattedTime;
        if (rawDigits.length <= 2) {
            newFormattedTime = hours; // No padding for single value
        } else if (rawDigits.length <= 4) {
            newFormattedTime = `${hours}:${minutes}`;
        } else {
            newFormattedTime = `${hours}:${minutes}:${seconds}`;
        }

        setFormattedTime(newFormattedTime);

        // Calculate total seconds (only if there are no errors)
        const hoursNum = parseInt(hours, 10) || 0;
        const minutesNum = parseInt(minutes, 10) || 0;
        const secondsNum = parseInt(seconds, 10) || 0;

        setTotalSeconds(
            hoursNum * 3600 +
            minutesNum * 60 +
            secondsNum
        );


        // Restore cursor position after state update
        setTimeout(() => {
            if (inputRef.current) {
                let newCursorPosition = cursorPosition ?? 0;

                // Adjust cursor position if colons are added/removed
                if (newFormattedTime.length > inputValue.length) {
                    // A colon was added, so move cursor forward
                    newCursorPosition += 1;
                } else if (newFormattedTime.length < inputValue.length) {
                    // A colon was removed, so move cursor backward
                    newCursorPosition -= 1;
                }

                inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
    };

    return (
        <div>
            <input
                type="text"
                className={`time-input-field ${disabledStaus ? 'disabled' : ''}`}
                value={formattedTime}
                onChange={handleChange}
                placeholder="00:00:00"
                maxLength={8} // Allows for HH:MM:SS format
                ref={inputRef}
                onBlur={() => handleTimeInputChange(totalSeconds)}
            />
        </div>
    );
}

export default TimeInput;