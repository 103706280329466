import * as am5 from "@amcharts/amcharts5";

interface ZoomOverlayOptions {
  text?: string;
  fontSize?: number;
  fillOpacity?: number;
  overlayTimeout?: number;
}

export function addZoomOverlay(
  chart: any,
  root: am5.Root,
  options: ZoomOverlayOptions = {}
): am5.Container {
  const overlay = root.container.children.push(
    am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layer: 100,
      visible: false,
    })
  );

  overlay.children.push(
    am5.Rectangle.new(root, {
      width: am5.p100,
      height: am5.p100,
      fill: am5.color(0x000000),
      fillOpacity: options.fillOpacity || 0.3,
    })
  );

  overlay.children.push(
    am5.Label.new(root, {
      text: options.text || "Use CTRL + Scroll to zoom",
      fontSize: options.fontSize || 30,
      x: am5.p50,
      y: am5.p50,
      centerX: am5.p50,
      centerY: am5.p50,
    })
  );

  chart.plotContainer.events.on("wheel", function (ev: any) {
    if (ev.originalEvent.ctrlKey) {
      ev.originalEvent.preventDefault();
      chart.set("wheelX", "panX");
      chart.set("wheelY", "zoomX");
    } else {
      chart.set("wheelX", "none");
      chart.set("wheelY", "none");
      overlay.show();
      overlay.setTimeout(() => {
        overlay.hide();
      }, options.overlayTimeout || 800);
    }
  });

  return overlay;
}

export const syncBulletSpritesOnCursorMove = (
  chart: any,
  chartsToSync: any[],
  previousBulletSprites: any[]
) => {
  {
    for (let i = 0; i < previousBulletSprites?.length; i++) {
      previousBulletSprites[i].unhover();
      previousBulletSprites[i].set("visible", false); // Hide bullets when not hovered
    }
    previousBulletSprites.length = 0; // Clear the array
    let currentIndex: any = null;

    chart.series.each((series: any) => {
      const dataItem = series?.get("tooltip")?.dataItem;
      if (dataItem) {
        const bulletSprite = dataItem?.bullets?.length
          ? dataItem.bullets[0]?.get("sprite")
          : null;
        if (bulletSprite) {
          bulletSprite.hover();
          bulletSprite.set("visible", true);
          previousBulletSprites.push(bulletSprite);
          currentIndex = series.dataItems.indexOf(dataItem);
        }
      }
    });

    if (currentIndex !== null) {
      chartsToSync.forEach((syncChart: any) => {
        syncChart.series.each((series: any) => {
          const targetDataItem = series.dataItems[currentIndex];
          if (targetDataItem) {
            const bulletSprite = targetDataItem?.bullets?.length
              ? targetDataItem.bullets[0]?.get("sprite")
              : null;
            if (bulletSprite) {
              bulletSprite.hover();
              bulletSprite.set("visible", true);
              previousBulletSprites.push(bulletSprite);
            }
          }
        });
      });
    }
  }
}
