import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Column, ColumnInstance, useTable } from 'react-table';
import { AllWellsTableType } from './AllWellsTableType';
import './AllWellsTable.scss';
import { ReactComponent as SortIconDefault } from '../../../../../../images/Chevron-default-icon.svg';
import { ReactComponent as SortIconUp } from '../../../../../../images/Chevron-up-icon.svg';
import { ReactComponent as SortIconDown } from '../../../../../../images/Chevron-down-icon.svg';
import { ReactComponent as StarIconEmpty } from '../../../../../../images/star-empty.svg';
import { ReactComponent as StarIconGold } from '../../../../../../images/star-gold.svg';
import ArrowUp from '../../../../../../images/arrow-narrow-up.svg';
import DownArrow from '../../../../../../images/arrow-down-1.svg'
import UpTimeChart from '../all-wells-chart/UpTimeChart';
import IntakePressureChart from '../all-wells-chart/IntakePressureChart';
import ProductionChart from '../all-wells-chart/ProductionChart';
import WellWatchlistModal from '../wellWatchlistModal/wellWatchlistModal';
import thinArrow from '../../../../../../images/thinArrow.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { addAssetToWatchList, fetchAssetList, fetchAssetListTimeSeries, removeAssetFromWatchList } from '../AllWellSlice';
import { AssetData } from '../../../interfaces/AssetList.interface';
import Loader from '../../../../../common/page-loader/ComponentLoader';
import PerformanceSnapshotModal from '../../performanceSnapshotModal/PerformanceSnapshotModal';
import { WellWatchList } from '../../../interfaces/ControlRoomWells.interface';
import { getDefaultWatchList, updateWatchListById } from '../../ControlRoomWellsSlice';
import WellControlroomCharts from '../../../../../well-controlroom-charts/WellControlroomCharts';
import { clearAssetDetails, fetchAssetInfo } from '../../../../../asset-info/AssetInfoSlice';
import { fetchDefaultUserPrefrence } from '../../../../../controlroom/controlroom.slice';
import html2canvas from 'html2canvas';
import NoData from '../../../../../dashboard/components/no-data/NoData';
import { showToaster } from '../../../../../dashboard/components/asset-location/AssetList';
import { updateAssetList, updateSelectedAsset } from '../../../../../navigation/AssetGroupsSlice';
import { useNavigate } from 'react-router-dom';
import UpTimeModal from '../UptimeModal/UpTimeModal';
import expandIcon from '../../../../../../images/expand-icon20x20.svg';
import { HealthScoreTrend } from '../../../../../health-score/model/HealthScoreHistory';

interface AllWellsTableProps {
  toggleModal: (modalType: 'watchlist' | 'filters', isOpen: boolean) => void;
}

interface SnapshotWellProps {
  assetName: string;
  assetGroupName: string;
  assetId: string | number;
  searchString: undefined | string;
}

const AllWellsTable = ({ toggleModal }: AllWellsTableProps) => {
  const navigate = useNavigate();
  const assetListLoading = useAppSelector((state) => state.AssetList?.loading);
  const assetList = useAppSelector((state) => state.AssetList?.AllWellAssetList).results as unknown as AllWellsTableType[];
  const assetListData = useAppSelector((state) => state.AssetList?.AllWellAssetList) as unknown as AssetData;
  const assetListTimeSeries = useAppSelector((state) => state.AssetList?.AllWellAssetListTimeSeries).results as unknown as AllWellsTableType[];
  const assetListDataTimeSeries = useAppSelector((state) => state.AssetList?.AllWellAssetListTimeSeries) as unknown as AssetData;
  const allWellTimeseriesLoading = useAppSelector((state) => state.AssetList.allWellTimeseriesLoading);
  const allWellLoading = useAppSelector((state) => state.AssetList.allWellLoading);
  const error = useAppSelector((state) => state.AssetList.error);
  const [tableData, setTableData] = useState<AllWellsTableType[]>(assetList);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{ key: keyof AllWellsTableType; direction: 'asc' | 'desc' } | null>({
    key: 'wellName',
    direction: 'asc',
  });
  const [selectedWell, setSelectedWell] = useState('');
  const [selectedIntakeOption, setSelectedIntakeOption] = useState('7-day intake pressure');
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSnapshotWell, setSelectedSnapshotWell] = useState<SnapshotWellProps>();
  const dispatch = useAppDispatch();
  const wellTableRef = React.createRef<HTMLDivElement>();
  const defaultWatchList = useAppSelector(getDefaultWatchList);
  const wellControlLoading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');
  const [snapshot, setSnapshot] = useState<any>(null);
  const hiddenRef = useRef<HTMLDivElement>(null);
  const selectedWatchList = useAppSelector((state) => state.wellWatchList.selectedWatchList);
  const [contextMenu, setContextMenu] = useState<string>('');
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const [upTimeModalOpen, setUpTimeModalOpen] = useState<boolean>(false);
  const [selectedUptimeData, setSelectedUptimeData] = useState<any>([]);

  const handleUptimeModal = (event: React.MouseEvent, dailyUpDowntimeData: any) => {
    event.preventDefault();
    event.stopPropagation();

    setUpTimeModalOpen(true);
    setSelectedUptimeData(dailyUpDowntimeData);
  };

  const handleUpTimeClose = () => {
    setUpTimeModalOpen(false);
    setSelectedUptimeData([]);
    hElement && hElement?.classList.remove('modal-open');
  };

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        if (
          (scrollHeight - scrollTop - clientHeight < 500) &&
          (!allWellLoading && !allWellTimeseriesLoading && assetListData.hasNextPage)
        ) {
          dispatch(fetchAssetList({
            includeTimeseriesFields: false,
            pageSize: 25,
            pageNumber: assetListData.nextPageNumber || assetListData.pageNumber + 1,
            watchListId: selectedWatchList?.id
          })).then(() => {
            dispatch(fetchAssetListTimeSeries({
              includeTimeseriesFields: true,
              pageSize: 25,
              pageNumber: assetListDataTimeSeries.nextPageNumber || assetListDataTimeSeries.pageNumber + 1,
              watchListId: selectedWatchList?.id
            }))
          })
        }
      }
    },
    [assetList?.length, assetListTimeSeries?.length, allWellLoading, allWellTimeseriesLoading, dispatch, assetListData])

  // const selectOptions = ['7-day intake pressure', 'Production'];
  const selectOptions = ['7-day intake pressure'];

  const toggleDropdwon = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleItemClick = (item: string) => {
    setSelectedIntakeOption(item);
    setIsSelectOpen(!isSelectOpen);
  };

  const handleCloseModal = () => {
    setSelectedWell('');
  };

  const createBtnHandler = () => {
    setSelectedWell('');
    toggleModal('filters', true);
  };
  // Toggle star status for a particular row
  const handleStarClick = async (wellName: string, isStared: boolean) => {
    try {
      const action = isStared ? removeAssetFromWatchList : addAssetToWatchList;
      const successMessage = isStared ? 'Well removed from watchlist' : 'Well added to watchlist';
      const errorMessage = isStared ? 'Failed to remove well from watchlist' : 'Failed to add well to watchlist';

      const res = await dispatch(action(wellName));

      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(updateWatchListById({ id: defaultWatchList?.id ?? '', count: res.payload.data?.count } as WellWatchList));

        showToaster(successMessage, 'success');

        setTableData((prevData) =>
          prevData
            .map((item) =>
              item.wellName === wellName ? { ...item, isStared: !item.isStared } : item
            )
            .filter((item) => selectedWatchList?.id === defaultWatchList?.id ? item.isStared : true)
        );
      } else {
        showToaster(errorMessage, 'error');
      }
    } catch (error) {
      showToaster('An unexpected error occurred', 'error');
    } finally {
      setIsContextMenuOpen(null);
    }
  };


  useEffect(() => {
    if (allWellTimeseriesLoading === true) {
      setTableData(assetList);
    } else {
      setTableData(assetListDataTimeSeries.results);
    }
  }, [assetList, assetListTimeSeries, allWellTimeseriesLoading]);


  useEffect(() => {
    const handleOutSideClick = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        (event.target.closest('.dropdown-options') || event.target.closest('.dropdown-btn'))
      ) {
        return;
      }
      setIsContextMenuOpen(null);
    };
    if (isContextMenuOpen) {
      document.addEventListener('mousedown', handleOutSideClick);
    }
    return () => document.addEventListener('mousedown', handleOutSideClick);
  }, [isContextMenuOpen]);

  const columns: Column<AllWellsTableType>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'isStared' as keyof AllWellsTableType,
        width: 50,
        minWidth: 50,
        maxWidth: 50,
      },
      {
        Header: 'Well name',
        accessor: 'wellName' as keyof AllWellsTableType,
        width: 290,
        minWidth: 290,
        maxWidth: 290,
      },
      {
        Header: 'Health score',
        accessor: 'healthScore' as keyof AllWellsTableType,
        width: 180,
        minWidth: 180,
        maxWidth: 180,
      },
      {
        Header: 'Operator',
        accessor: 'operator' as keyof AllWellsTableType,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: '7-day uptime',
        accessor: 'uptime' as keyof AllWellsTableType,
        width: 265,
        minWidth: 265,
        maxWidth: 265,
      },
      {
        Header: () => (
          <div>
            <div className='intake-pressure__dropdown'>
              <div className='intake-pressure__dropdown-input-container' onClick={toggleDropdwon}>
                <input type='text' className='intake-pressure__dropdown-input' value={selectedIntakeOption} readOnly />
                <img
                  src={thinArrow}
                  alt={isSelectOpen ? 'Up Arrow' : 'Down Arrow'}
                  className={`mr-3 ${isSelectOpen ? 'rotate-180' : ''}`}
                />
              </div>
              {isSelectOpen && (
                <ul className='intake-pressure__dropdown-list'>
                  {selectOptions.map((item, index) => (
                    <li key={index} onClick={() => handleItemClick(item)} className='intake-pressure__dropdown-item'>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ),
        accessor: 'intakePressure' as keyof AllWellsTableType,
        width: 300,
        minWidth: 300,
        maxWidth: 300,
      },
      {
        Header: '7-day changes',
        accessor: 'sevenDaysChangeTrend' as keyof AllWellsTableType,
        width: 110,
        minWidth: 110,
        maxWidth: 110,
        id: 'healthScoreImpact.trend'
      },
      {
        Header: '',
        accessor: 'dropdown' as keyof AllWellsTableType,
        width: 65,
        minWidth: 65,
        maxWidth: 65,
      },
    ],
    [tableData, selectedIntakeOption, isSelectOpen],
  );

  const getValueByKey = (obj: any, key: string) => {
    if (key.includes('.')) {
      return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    }
    return obj[key];
  }

  const sortedData = useMemo(() => {
    if (assetListLoading) {
      return [];
    }
    if (sortConfig === null) return tableData;
    return (
      tableData &&
      [...tableData]?.sort((a, b) => {
        const aValue = getValueByKey(a as unknown as AllWellsTableType, sortConfig.key);
        const bValue = getValueByKey(b as unknown as AllWellsTableType, sortConfig.key);
        if (aValue == null || bValue == null) return 0;

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.toLowerCase().localeCompare(bValue.toLowerCase()) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else if (typeof aValue === 'number' && typeof bValue === 'number') {
          return (aValue - bValue) * (sortConfig.direction === 'asc' ? 1 : -1);
        } else {
          return String(aValue).localeCompare(String(bValue)) * (sortConfig.direction === 'asc' ? 1 : -1);
        }

      })
    );
  }, [tableData, sortConfig]);


  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns || [],
    data: sortedData || [],
  });

  const handleSort = (column: ColumnInstance<AllWellsTableType>) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === column.id && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column.id as keyof AllWellsTableType, direction });
  };

  const openContextMenuOpen = (index: number) => {
    setIsContextMenuOpen((prevIndex: any) => (prevIndex === index ? null : index));
  };

  const getBorderColor = (value: number) => {
    return value > 85 ? 'var(--Success-Green500, #12B76A)' : (value <= 85 && value > 15) ? 'var(--Yellow-500, #FFBF3F)' : 'var(--Error-Red400, #F97066)'

  };

  const activeTab = sessionStorage.getItem('activeTabIndex');

  const handleWellNameClick = (selectedWell: AllWellsTableType) => {
    if (activeTab !== '0') {
      sessionStorage.setItem('activeTabIndex', '0');
    }

    const groupList = tableData?.map((well) => ({
      assetId: well?.assetID,
      assetName: well?.wellName,
      industryApplicationId: 4,
    }));

    const selectedAsset = {
      assetName: selectedWell.wellName,
      assetGroupName: 'Well Groups',
      assetId: selectedWell.assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }

    if (groupList) {
      sessionStorage.setItem('wellNameList', JSON.stringify(groupList));
    }

    dispatch(updateSelectedAsset(selectedAsset));
    dispatch(updateAssetList(groupList));
    navigate(`/layout/well-control-room`);
  };

  const handleRightClick = (e: any, assetID: string) => {
    e.preventDefault();
    setContextMenu(assetID);
  };

  const handleOptionClick = (action: string, selectedWell: any) => {
    const selectedAsset = {
      assetName: selectedWell.wellName,
      assetGroupName: 'Well Groups',
      assetId: selectedWell.id,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
    if (action === "open-new-tab") {
      const targetPath = `/layout/well-control-room`;
      const fullUrl = `${window.location.origin}${targetPath}`;
      window.open(fullUrl, "_blank");
    }
    setContextMenu(''); // Close the context menu
  };

  const handleContextMenuClickOutside = (event: MouseEvent) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
      setContextMenu('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleContextMenuClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleContextMenuClickOutside);
    };
  }, []);

  const captureSnapshot = async () => {
    if (hiddenRef.current) {
      const canvas = await html2canvas(hiddenRef.current);
      const image = canvas.toDataURL('image/svg');
      setSnapshot(image); // Save the snapshot as a base64 image
    }
  };

  const openModal = (event: React.MouseEvent, selectedWell: any) => {
    event.preventDefault();
    event.stopPropagation();
    const selectedAsset = {
      assetName: selectedWell.wellName,
      assetGroupName: 'Well Groups',
      assetId: selectedWell.assetID,
      searchString: undefined,
    };
    if (selectedAsset) {
      sessionStorage.setItem('selectedWellName', JSON.stringify(selectedAsset));
    }
    setSelectedSnapshotWell(selectedAsset);
    setModalIsOpen(true);
    hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
    setSnapshot(null);
  };

  useEffect(() => {
    if (selectedSnapshotWell) {
      dispatch(fetchAssetInfo({ assetName: selectedSnapshotWell?.assetName || '' }));
      dispatch(fetchDefaultUserPrefrence('asset-control-room'));
    }
    return () => {
      dispatch(clearAssetDetails());
    };
  }, [selectedSnapshotWell]);


  useEffect(() => {
    if (!wellControlLoading) {
      const timer: any = setTimeout(() => {
        captureSnapshot();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [wellControlLoading]);

  interface RenderCellContentProps {
    cell: any;
    row: any;
  }

  const renderCellContent = ({ cell, row }: RenderCellContentProps) => {
    const { id } = cell.column;
    const { original } = row;

    switch (id) {
      case 'isStared':
        return (
          <span className='favourite-icon' onClick={() => handleStarClick(original?.wellName, original?.isStared)}>
            {original?.isStared ? <StarIconGold /> : <StarIconEmpty />}
          </span>
        );

      case 'wellName':
        return (
          <div className='well-name-link relative'>
            <div
              className='flex items-center h-full w-full'
              onContextMenu={(e) => handleRightClick(e, original?.assetID)}
              onClick={() => handleWellNameClick(original)}                                >
              {cell.render('Cell')}
            </div>
            {contextMenu === original?.assetID && (
              <div
                ref={contextMenuRef}
                style={{
                  position: "absolute",
                  top: '0px',
                  left: '50%',
                  width: '200px',
                }}
                className='dropdown-options'
              >
                <button
                  onClick={() => handleOptionClick("open-new-tab", original)}
                >
                  Open in new tab
                </button>
              </div>
            )}
          </div>
        );

      case 'healthScore':
        return (
          <div className='flex justify-left align-center'>
            <div
              className='health-score-circle flex justify-center align-center items-center'
              style={{ border: `2px solid ${getBorderColor(Number(original?.healthScore))}` }}
            >
              {original?.healthScore}
            </div>
          </div>
        );

      case 'uptime':
        return (
          <div className='flex flex-row uptime-downtime-graph-block'
          >
            <div className='flex flex-row uptime-chart-area'>
              {<span className='flex items-center w-[40px]'>{original?.uptime + '%'}</span>}
              <UpTimeChart data={original?.dailyUpDowntimeData || []} />
            </div>
            <button
              className='expand-btn'
              onClick={(e) => (handleUptimeModal(e, original?.dailyUpDowntimeData))}
              style={{ cursor: 'pointer', zIndex: 0 }}
            >
              <img src={expandIcon} alt='Expand' className='img-border' />
            </button>
          </div>
        )

      case 'intakePressure':
        return selectedIntakeOption === '7-day intake pressure' ? (
          <IntakePressureChart data={original?.intakePressureData || []} />
        ) : (
          <ProductionChart data={original?.productionData || []} />
        );

      case 'healthScoreImpact.trend':
        return (
          <div className='all-wells-day-change'>
            <div className={`square-bracket-style ${original?.healthScoreImpact?.trend === HealthScoreTrend.Up ? 'up' : original?.healthScoreImpact?.trend === HealthScoreTrend.Down ? 'down' : 'none'}`}>
              {original?.healthScoreImpact?.trend === HealthScoreTrend.Up ? <img src={ArrowUp} className='img-points' /> : original?.healthScoreImpact?.trend === HealthScoreTrend.Down ? <img src={DownArrow} className='img-points' /> : ''}
              <span className='points'><span className='pvalue'>{original?.healthScoreImpact?.trendPoints}</span> <span>%</span></span>
            </div>
          </div>

        );

      case 'dropdown':
        return (
          <div className='dropdown-container p-2'>
            <button className='dropdown-btn' onClick={() => openContextMenuOpen(row.index)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isContextMenuOpen === row.index && (
              <div className='dropdown-options' ref={dropdownRef}>
                <button className='dropdown-option show-element' onClick={(e) => openModal(e, row.original)}>
                  System dashboard snapshot
                </button>
                {
                  (row?.original?.isStared ? (
                    <button
                      onClick={() => handleStarClick(row?.original?.wellName, row?.original?.isStared)}
                      className='dropdown-option show-element'>Remove well from my well watchlist
                    </button>
                  ) : (
                    <button
                      onClick={() => handleStarClick(row?.original?.wellName, row?.original?.isStared)}
                      className='dropdown-option show-element'>Add well to my well watchlist
                    </button>
                  ))}
              </div>
            )}
          </div>
        );

      default:
        return cell.render('Cell');
    }
  };

  return (
    <>
      <div className='doc-wrapper multiwell-table-wrapper'>
        <div
          className='table-container w-full !rounded-[8px]'
          ref={wellTableRef}
          onScroll={() => fetchMoreOnBottomReached(wellTableRef.current)}
        >
          {(
            <table className='document-table' {...getTableProps()} key='table'>
              <thead className='document-head'>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id + 'header'}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps({
                          style: { width: column.width, minWidth: column.minWidth, maxWidth: column.maxWidth },
                        })}
                        onClick={() => {
                          if (column.id !== 'intakePressure' && column.id !== 'dropdown') {
                            handleSort(column as ColumnInstance<AllWellsTableType>);
                          }
                        }}
                        key={column.id}
                      >
                        <div>
                          {column.render('Header')}
                          {column.id !== 'intakePressure' && column.id !== 'dropdown' ? (
                            sortConfig?.key === column.id ? (
                              sortConfig.direction === 'asc' ? (
                                <SortIconUp alt='sort-asc' className='sort-img' />
                              ) : (
                                <SortIconDown alt='sort-dsc' className='sort-img' />
                              )
                            ) : (
                              <SortIconDefault alt='sort-asc' className='sort-img sort-img-show-hover' />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {allWellLoading ? <Loader /> : null}
              <tbody
                className={`document-body ${assetListLoading ? 'blur-background pointer-events-none' : ''}`}
                {...getTableBodyProps()}
              >
                {tableData?.length === 0 || Number(error) === 500 || Number(error) === 404 ?

                  <tr>
                    <td className='!border-none' colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                      <div className='flex justify-center items-center h-[370px]'>
                        <NoData heading='No data found' />

                      </div>
                    </td>
                  </tr>
                  : rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell) => (
                          <td
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.width,
                                minWidth: cell.column.minWidth,
                                maxWidth: cell.column.maxWidth,
                              },
                            })}
                            key={cell.column.id}
                          >
                            {renderCellContent({ cell, row })}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>)}
        </div>
      </div>

      {selectedWell && <WellWatchlistModal handleCloseModal={handleCloseModal} createBtnHandler={createBtnHandler} />}

      {selectedSnapshotWell && <div className='controlroom-snapblock mt-12 min-h-20' ref={hiddenRef}>
        <WellControlroomCharts />
      </div>}

      <PerformanceSnapshotModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={selectedSnapshotWell?.assetName ?? 'Performance Snapshot'}
        loading={wellControlLoading}
        modelWidth={'120rem'}
        modelClass={'performaceSnapshotModal'}
        innerComponent={
          <>
            {wellControlLoading ? (
              <Loader />
            ) : (
              <div className='w-full h-full'>
                {snapshot && <img src={snapshot} alt={'Screenshot'} className={`pb-2 w-full max-w-full`} />}
              </div>
            )}
          </>
        }
      />

      <UpTimeModal
        isOpen={upTimeModalOpen}
        onRequestClose={handleUpTimeClose}
        selectedUptimeData={selectedUptimeData}
        heading={'Uptime / Downtime'}
        modelWidth={'70rem'}
        modelClass={'uptimeModal'}
      />
    </>
  );
};

export default AllWellsTable;
