import React, { useState, useRef, useEffect } from 'react';
import HistoryChart from './SmoothedXLineChart';
import SleHistoryChart from './SleSmoothedXLineChart'
import { addDays, endOfDay, startOfDay } from 'date-fns';
import './History.scss';
import { ToastContainer } from 'react-toastify';
import SleHistoryChartFilter from '../sle-time-series/SleHistoryChartFilter';
import { useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';
import HistoryChartFilter from './HistoryChartFilter';
import SparklineChart from './sparkline-chart/SparklineChart';
import SleSparklineChart from './sparkline-chart/SleSparklineChart';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../../../../utilities/Slugify';

const tabs = ['Chart', 'SparkLines']
const History = () => {
  const defaultOption = 'Chart'
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
  });
  const [filteredVal, setFilteredVal] = useState<string[]>([]);
  const [prevFilteredVal, setPrevFilteredVal] = useState<string[]>([]);

  const toasterRef = useRef<HTMLDivElement>(null);
  const { assetDetailLoading, assetDetail } = useAppSelector((state) => state?.assetDetail);
  const isSleStatus = assetDetail?.assetDetails.isSleAsset
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(0);


  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
    if (currentTabFromQuery) {
      const tab = tabs.find((tab) => slugify(tab) === currentTabFromQuery);
      if (tab) {
        setSelectedOption(tab);
      } else {
        setSelectedOption(defaultOption);
        if (selectedOption === defaultOption)
          setForceUpdate((prev) => prev + 1)
      }
    } else {
      setSelectedOption(defaultOption);
      if (selectedOption === defaultOption)
        setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, tabs]);

  useEffect(() => {

    if (selectedOption === null) return;
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('innerTab');
    // Only navigate if the tab slug in the URL is differents
    if (currentTabSlug !== slugify(selectedOption)) {
      params.set('innerTab', slugify(selectedOption))
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [selectedOption, forceUpdate]);

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} ref={toasterRef} />

      <div className='flex flex-row flex-grow profile-block !p-9'>
        <div className='card profile-card m-0 asset-history'>
          <div className='header'>
            <span className='title'>Asset history</span>
          </div>
          <div className='divider'></div>
          <>
            {
              assetDetailLoading ?
                <div className='flex items-center justify-center w-100'>
                  <Loader />
                </div>
                :
                <>
                  {
                    isSleStatus ?
                      <SleHistoryChartFilter filteredVal={filteredVal} setFilteredVal={setFilteredVal} selectedOption={selectedOption || defaultOption} setSelectedOption={setSelectedOption} setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} setPrevFilteredVal={setPrevFilteredVal} />
                      :
                      <HistoryChartFilter filteredVal={filteredVal} setFilteredVal={setFilteredVal} selectedOption={selectedOption || defaultOption} setSelectedOption={setSelectedOption} setSelectedDateRange={setSelectedDateRange} selectedDateRange={selectedDateRange} />
                  }
                  <div className='body'>
                    {selectedOption === 'Chart' && (
                      <>
                        {
                          isSleStatus ?
                            <SleHistoryChart
                              filteredValues={prevFilteredVal}
                              selectedOption={selectedOption}
                              selectedDateRange={selectedDateRange}
                              selectedVariables={filteredVal}
                            />
                            :
                            <HistoryChart
                              filteredValues={filteredVal}
                              selectedOption={selectedOption}
                              selectedDateRange={selectedDateRange}
                            />
                        }
                      </>
                    )}
                    {selectedOption === 'SparkLines' && (isSleStatus ? <SleSparklineChart /> : <SparklineChart />)}
                  </div>
                </>


            }
          </>




        </div>
      </div>
    </>
  );
};

export default History;
