import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getManageAssetSetpoints } from "./ManageAssetService";
import { Setpoint } from "../asset-details/components/setpoints/model/ApiResponse";
import { getDeviceValue, updateSetpointValuesData } from "../asset-details/components/setpoints/SetPointsService";
import { ChangedBackupValue } from "../asset-details/components/setpoints/SetPointsSlice";
import { ConvertTimeToLocal } from "../../utilities/CommonFunctions";


interface DeviceValue {
    address: number,
    value: number
}

interface ManageAssetState {
    loading: boolean,
    fetchSetPointLoading: boolean,
    setPoints: Setpoint[] | null,
    readDeviceLoading: boolean,
    readDeviceSuccessStatus: boolean,
    readDeviceErrorStatus: boolean,
    readDeviceReturnErrorStatus: boolean,
    deviceReadStatus: boolean,
    deviceValue: DeviceValue[];
    deviceAction: string,
    changedBackupValues: ChangedBackupValue | null,
    lastUpdatedOn: string

}

const initialState: ManageAssetState = {
    loading: false,
    fetchSetPointLoading: false,
    setPoints: null,
    readDeviceLoading: false,
    readDeviceSuccessStatus: false,
    readDeviceErrorStatus: false,
    readDeviceReturnErrorStatus: false,
    deviceReadStatus: false,
    deviceValue: [],
    deviceAction: '',
    changedBackupValues: null,
    lastUpdatedOn: ''
}


export const fetchManageAssetSetpoints: any = createAsyncThunk('manageAsset/setpoints', async ({ wellId, wellName }: { wellId: string, wellName: string }) => {
    const response = await getManageAssetSetpoints(wellId, wellName);
    return response;
}
);

export const fetchManageAssetSetpointValues = createAsyncThunk('manageAsset/readDevice', async ({ wellId, setPointAddresses, socketId }: { wellId: string, setPointAddresses: string, socketId: string }) => {
    const response = await getDeviceValue(wellId, setPointAddresses, socketId);
    return response;
});


export const updateManageAssetSetpointValues = createAsyncThunk(
    'manageAsset/updateSetpointValues',
    async ({ wellId, setpointValues, socketId }: { wellId: string, setpointValues: any, socketId: string }) => {
        const response = await updateSetpointValuesData(wellId, setpointValues, socketId);
        return response.data; // Assuming your response has a 'data' property
    }
);

const manageAssetSlice = createSlice({
    name: 'manageAsset',
    initialState: initialState,
    reducers: {
        handleReadSetpointReturnSuccess: (state, action) => {
            const tempDeviceValue = action.payload

            if (state.deviceAction === 'Read')
                state.deviceValue = tempDeviceValue

            if (state.deviceAction === 'Update') {
                const prevDeviceValue = state.deviceValue ? [...state.deviceValue] : []
                const updatedDeviceValue = prevDeviceValue?.map(prevDevice => {
                    const matchingDevice = tempDeviceValue.find((tempDevice: DeviceValue) => prevDevice.address === tempDevice.address)
                    return matchingDevice ? { ...prevDevice, value: matchingDevice.value } : prevDevice
                })
                state.deviceValue = updatedDeviceValue
            }

            state.readDeviceLoading = false;
            state.readDeviceSuccessStatus = true
            state.deviceReadStatus = true

            state.changedBackupValues = {}
            state.lastUpdatedOn = ConvertTimeToLocal(new Date().toISOString())

        },
        handleReadSetpointReturnError: (state) => {
            state.readDeviceLoading = false;
            state.readDeviceReturnErrorStatus = true
            if (state.deviceAction === 'Read')
                state.deviceReadStatus = false

        },
        resetReadSetpointStatus: (state, action) => {
            state.readDeviceLoading = false;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.readDeviceReturnErrorStatus = false
            if (action.payload === 'All') {
                state.deviceReadStatus = false
                state.deviceValue = []
                state.setPoints = null
                state.lastUpdatedOn = ''
            }
        },
        resetSetpointValues: (state) => {
            state.setPoints = null
        },
        setChangedSetpointValues: (state, action) => {
            const passedPayload: ChangedBackupValue = action.payload
            const passedKey = Object.keys(passedPayload)[0]
            const passedValue = Object.values(passedPayload)[0]
            let hasValueChanged = false
            const tempDeviceValue = [...state.deviceValue]
            const matchingTempDevice = tempDeviceValue.find(device => device.address.toString() === passedKey)
            if (matchingTempDevice) {

                if (matchingTempDevice.value !== passedValue)
                    hasValueChanged = true
            }


            if (!state.changedBackupValues) {
                state.changedBackupValues = {};
            }
            if (hasValueChanged) {
                state.changedBackupValues[passedKey] = passedValue;
            } else {
                // If value is same as the original, remove it from changedBackupValues
                delete state.changedBackupValues[passedKey];

                // If no changed values remain, reset changedBackupValues
                if (Object.keys(state.changedBackupValues).length === 0) {
                    state.changedBackupValues = null;
                }
            }
        },

        clearChangedSetpointValues: (state) => {
            state.changedBackupValues = {}
        }
    },
    extraReducers: (builder) => {
        // Handle the fetchUsers fulfilled action
        builder.addCase(fetchManageAssetSetpoints.pending, (state) => {
            state.setPoints = []
            state.fetchSetPointLoading = true;
        })
        builder.addCase(fetchManageAssetSetpoints.fulfilled, (state, action) => {
            state.fetchSetPointLoading = false;
            state.setPoints = action.payload.data
        })
        builder.addCase(fetchManageAssetSetpoints.rejected, (state) => {
            state.fetchSetPointLoading = false;
            state.setPoints = null
        })

        //Handle read device action
        builder.addCase(fetchManageAssetSetpointValues.pending, (state) => {
            state.deviceValue = []
            state.readDeviceLoading = true;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.readDeviceReturnErrorStatus = false
            state.deviceReadStatus = false
            state.deviceAction = 'Read'
        });
        builder.addCase(fetchManageAssetSetpointValues.rejected, (state) => {
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true;
            state.deviceReadStatus = false
        });

        // Handle the loginUser fulfilled action
        builder.addCase(updateManageAssetSetpointValues.pending, (state) => {
            state.readDeviceLoading = true;
            state.readDeviceSuccessStatus = false
            state.readDeviceErrorStatus = false
            state.readDeviceReturnErrorStatus = false
            state.deviceAction = 'Update'

        });

        builder.addCase(updateManageAssetSetpointValues.rejected, (state) => {
            state.readDeviceLoading = false;
            state.readDeviceErrorStatus = true
        });
    }

})


export const { handleReadSetpointReturnSuccess, handleReadSetpointReturnError, resetReadSetpointStatus, resetSetpointValues, setChangedSetpointValues, clearChangedSetpointValues } = manageAssetSlice.actions
export default manageAssetSlice.reducer