import React, { useEffect, useState } from 'react'
import ChevronDown from '../../../images/chevron-down.svg'
import ChevronUp from '../../../images/chevron-up.svg'

import Heart from '../../../images/heart.svg'
import ArrowUp from '../../../images/arrow-up-black.svg'
import SpeedoMeter from '../../../images/speedometer-01.svg'
import LineChartUp from '../../../images/line-chart-up-01.svg'
import Lightning from '../../../images/lightning-01.svg'
import '../components/AssetControlRoomHeader.scss'
//import Tooltip from '../../common/tooltip/ToolTip'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { updateAssetControlRoomDrawerProps } from '../../asset-control-room-navigation-drawer/AssetControlRoomDrawerSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchHealthScore } from '../../health-score/HealthScoreSlice'
import MyTicketList from '../../control-room-dashboard/components/tickets/components/myTicketList/MyTicketList'
import { clearTicketAssetDetails, getTicketTable } from '../../control-room-dashboard/components/tickets/slices/ticketSlice'
import { resetReadSetpointStatus } from '../../manage-asset/ManageAssetSlice'
import { resetAssetControlActionStatus } from '../../asset/AssetControlSlice'
import Loader from '../../common/page-loader/ComponentLoader'

const AssetControlRoomHeader = () => {
    const totalResultsCount = useAppSelector((state) => state.ticketTableSlice.ticketTableData?.totalResultsCount);
    const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
    const [activeBtn, setActiveBtn] = useState('assetDetail');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { healthScore, healthScoreLoading } = useAppSelector((state) => state.healthScore)
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const [assetId, setAssetId] = useState<string>('');
    const assetName = selectedAsset.selectedAssetName ?? (selectedWellName && JSON.parse(selectedWellName || '')?.assetName || '');
    const [isTicketSectionOpened, setIsTicketSectionOpened] = useState(false)
    const assetControlData = useAppSelector((state) => state?.assetControl)

    useEffect(() => {
        if (assetName) {
            dispatch(fetchHealthScore(assetName));
        }
    }, [assetName, dispatch])


    const handleControlRoomsClick = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: 'controlRoom' }));
        navigate("/layout/well-control-room");
    }
    const handleHealthScoreClick = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'healthScore' }))
        navigate("/layout/well-control-room")
    }
    const handleAssetDetailsClick = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: null }))
        navigate("/layout/assets");
    }
    const handlemanageAssetClick = () => {

        if (assetControlData.activeAssetControlActionAssetName !== assetName) {
            if (assetControlData.assetControlScanStatus || assetControlData.stopActionProgressStatus || assetControlData.startActionProgressStatus || assetControlData.clearAlarmActionProgressStatus) {
                dispatch(resetAssetControlActionStatus())
            }
        }

        dispatch(resetReadSetpointStatus('All'))
        dispatch(updateAssetControlRoomDrawerProps({ openState: true, selectedComponent: 'manageAsset' }))
        navigate("/layout/well-control-room/")
    }

    useEffect(() => {
        if (location.pathname === '/layout/well-control-room' && activeBtn !== 'manageAsset') {
            setActiveBtn('controlRoom');
        } else if (location.pathname === '/layout/assets' && activeBtn !== 'manageAsset') {
            setActiveBtn('assetDetail');
        }

    }, [location])

    useEffect(() => {
        const selectedWellName = sessionStorage.getItem('selectedWellName');
        const selectedAssetId = selectedWellName && JSON.parse(selectedWellName || '');
        setAssetId(selectedAssetId?.assetId);
    });

    useEffect(() => {
        dispatch(clearTicketAssetDetails());
        if (assetId) {
            const params = { PageSize: 25, PageNumber: 1 };
            dispatch(getTicketTable(params))
        }
    }, [assetId]);

    const getHealthScoreStatusClass = () => {

        switch (healthScore?.trend) {

            case 0:
                return 'gray';
            case 1:
                return 'green';
            case 2:
                return 'red';
            default:
                return 'border-only';
        }
    };

    const getHealthScoreIcon = () => {
        switch (healthScore?.trend) {
            case 0:
                return 'hidden';
            case 1:
                return 'up';
            case 2:
                return 'down';
            default:
                return 'hidden';
        }
    };

    return (
        <div className={`asset-control-room-header m-0 ${assetControlRoomDrawer.openState ? 'asset-header' : ""}`}>
            <div className='content card m-0 w-100 flex flex-wrap flex-row justify-between items-center'>
                <div className='left-header-section flex flex-wrap items-center'>
                    <div className={`ticket-count-card card ${isTicketSectionOpened ? 'expanded' : ''}`} onClick={() => {
                        setIsTicketSectionOpened(!isTicketSectionOpened);
                        // dispatch(clearAssetDetails());
                    }}>
                        <div className="flex justify-between items-center" >
                            <div className='ticket-number'>
                                Tickets
                            </div>
                            <div className='ticket-count flex justify-center items-center'>
                                {totalResultsCount ? totalResultsCount : '0'}
                            </div>
                            <span><img src={isTicketSectionOpened ? ChevronUp : ChevronDown} /></span>
                        </div>
                    </div>
                    <div className='health-score-card card flex justify-between items-center' onClick={handleHealthScoreClick}>
                        <span><img src={Heart} /></span>
                        <span className='health-score'>{(healthScoreLoading && !healthScore) ? <Loader /> : typeof healthScore?.afterHealthScore === 'number' ? healthScore?.afterHealthScore : '-'}</span>
                        <span className='health-score-label'>Health score</span>

                        <div className={`health-score-status justify-center flex items-center ${getHealthScoreStatusClass()}`}>
                            <img src={ArrowUp} className={`health-img ${getHealthScoreIcon()}`} />
                            {(healthScoreLoading && !healthScore) ? <Loader /> : typeof healthScore?.trendPoints === 'number' ? healthScore?.trendPoints : '__'} points last 7 days
                        </div>
                    </div>

                </div>
                <div className={`right-header-section flex flex-row items-center mr-2 ${assetControlRoomDrawer.openState ? 'squeez-btngroup' : ""}`}>
                    <div className='toggle-section card flex flex-row items-center'>
                        <div className={`button ${activeBtn === 'controlRoom' ? 'active' : ''}`} onClick={() => { handleControlRoomsClick(); setActiveBtn('controlRoom') }}>
                            <img src={SpeedoMeter} />{assetControlRoomDrawer.openState ? '' : "Control room"}
                        </div>
                        <div className='vertical-divider'></div>
                        <div className={`button ${activeBtn === 'assetDetail' ? 'active' : ''}`} onClick={() => { handleAssetDetailsClick(); setActiveBtn('assetDetail') }}>
                            <img src={LineChartUp} /> {assetControlRoomDrawer.openState ? '' : 'Asset details'}
                        </div>
                    </div>
                    <div className='manage-asset card opacity-[0.8]'>
                        <div className={`button ${activeBtn === 'manageAsset' ? 'active' : ''}`} onClick={() => { handlemanageAssetClick(); setActiveBtn('manageAsset') }} >
                            <img className='imgbuttoncard' src={Lightning} />{assetControlRoomDrawer.openState ? '' : 'Manage asset'}
                        </div>
                    </div>
                </div>

                {
                    isTicketSectionOpened &&
                    <MyTicketList />
                }
            </div>
        </div>
    )
}

export default AssetControlRoomHeader