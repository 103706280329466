import React, { useEffect, useState, useRef } from 'react';
import AssetsTabsSupport from './AssetsTabsSupport';
import AssetListSupport from './AssetsListSupport';
import download from '../../../images/download-icon.svg';
import { useAppSelector } from '../../../../src/hooks/storeHooks';
import moment from 'moment-timezone';
import { downloadAssetsCSV } from '../supportService';
import { showToaster } from '../../dashboard/components/asset-location/AssetList';

const Assets: React.FC = () => {
  const tabs = [
    { label: 'Support List', component: AssetListSupport },
  ];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const assetLists = useAppSelector((state) => state.SupportSlice.assetsLists);
  const filterTerm = useAppSelector((state) => state.SupportSlice.filterTerm);
  
  const handleDownloadCSV = () => {
    showToaster('Assets CSV download is in progress!', 'info', false);
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    downloadAssetsCSV(filterTerm).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Lookout-Support_AssetEventSchedule_' + currentTime + '.csv');
      document.body.appendChild(link);
      link.click();
    }).then(() => {
      showToaster('Assets CSV download is completed!', 'success');
    }).catch((error) => {
      if (error?.message !== '') {
        showToaster('Assets CSV download is failed. Please try after sometime!', 'error');
      }
    });

    setIsDropdownOpen(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='w-100 py-6 px-8'>
      <div className='performanceSnapshot'>
        <div className='dashboard-first-block'>
          <div className='card-performance dashboard-card-first'>
            <div className='block-wrap'>
              <div className='dash-content'>
                <div className='para-dashboard '>
                  <p className='text-para-dash'>Assets</p>
                </div>
              </div>
              <div className='dashboard-count'>              
                <div className='header-icon'>
                  <div className='dropdown-container' ref={dropdownRef}>
                    <button className='dropdown-btn' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                      <span className='dot'></span>
                      <span className='dot'></span>
                      <span className='dot'></span>
                    </button>
                    {isDropdownOpen && (
                      <div className='dropdown-options'>
                        <button>Actions</button>
                        <button className={`btn btn-default ${assetLists?.results.length === 0 ? 'btn-disabled' : ''} `} onClick={handleDownloadCSV}>
                          <img src={download} alt='icon' className='btn-icon' />
                          Download CSV
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='second-header'>
          <AssetsTabsSupport tabs={tabs} assetDetails={false} />
        </div>
      </div>
    </div>
  );
};

export default Assets;
