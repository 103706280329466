import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeng/resources/themes/saga-blue/theme.css';
import 'primeng/resources/primeng.min.css';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import '../../schduledSpeedChangeFrequency.scss';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css';
import DatePicker from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import '../../schduledSpeedChangeFrequency.scss';
import { convertFromISO, SimpleSpeedChange } from '../../model/SimpleSpeedChange';
import 'primeng/resources/themes/saga-blue/theme.css';
import 'primeng/resources/primeng.min.css';
import { createScheduleById } from '../../ScheduleSpeedChangeService';
import { useDispatch } from 'react-redux';
import { updateScheduleSpeedChange } from '../../ScheduleSpeedChangeSlice';
import { AppDispatch } from '../../../../../../store';
import { useAppSelector } from '../../../../../../hooks/storeHooks';
import Info_Circle from '../../../../../../../src/images/error-info-icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Back_Left from '../../../../../../../src/images/arrow-narrow-left.svg';
import Question from '../../../../../../../src/images/question-mark.svg';
import Calendar from '../../../../../../../src/images/calendar.svg';
import { AppUser } from '../../../../../user/model/AppUser';
import Loader from '../../../../../../images/preloading.gif';
import { WebSocketActionType } from '../../../asset-details-header/AssetControlActions';
import { useWebSocket } from '../../../../../../hooks/webSocketHook';
import { resetReadCurrentFrequencyErrorStatus } from '../../../setpoints/SetPointsSlice';
import { fetchScheduleSpeedChangeStatus } from '../../../asset-details-header/AssetDetailsHeaderSlice';

interface SimpleSpeedChangeProps {
  isOpen: boolean;
  onClose: () => void;
  simpleSpeedChange: SimpleSpeedChange | null;
  editMode: boolean;
  scheduleId: string
}

const ScheduledSimpleSpeedChange: React.FC<SimpleSpeedChangeProps> = ({
  isOpen,
  onClose,
  simpleSpeedChange,
  editMode,
  scheduleId
}) => {

  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  const [showHeader] = useState(true);
  const selectedGroupName = '';

  const [updatedScheduleId, setUpdatedScheduleId] = useState<string>(scheduleId);
  const [updatedNewFrequency, setupdatedNewFrequency] = useState<string>(simpleSpeedChange?.newFrequency || '');
  const [updatedScheduledDate, setupdatedScheduledDate] = useState<Date | null>(null);
  const ToastContainer = () => <div></div>;
  const [newFrequencyErrorMessage, setNewFrequencyErrorMessage] = useState('');
  const [scheduleTimeErrorMessage, setScheduleTimeErrorMessage] = useState('')
  const [timeInput, setTimeInput] = useState<string>('');
  const [ampmInput, setAmPmInput] = useState<string>('');
  const setPointData = useAppSelector((state) => state.setPoint)
  const [updatedScheduledDescription, setupdatedScheduledDescription] = useState<string>('');
  const wellName = useAppSelector((state) => state.scheduleSpeedChange.wellName.payload);
  const dispatch: AppDispatch = useDispatch();
  const selectedAsset = useAppSelector((state) => state.assetGroups) ?? '';
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false);

  const scheduleSpeedChangeData = useAppSelector((state) => state.scheduleSpeedChange)
  const { establishWebSocketConnection } = useWebSocket()

  useEffect(() => {
    if (simpleSpeedChange) {
      const convertedDateTime = convertFromISO(simpleSpeedChange?.scheduleDateTime);
      setTimeInput(convertedDateTime ? convertedDateTime?.time : '');
      setAmPmInput(convertedDateTime ? convertedDateTime?.ampm : '');
      setupdatedScheduledDate(convertedDateTime ? new Date(convertedDateTime?.date) : null)
      setupdatedScheduledDescription(simpleSpeedChange?.description);
    }
  }, [simpleSpeedChange]);

  useEffect(() => {
    if (scheduleSpeedChangeData?.currentFreqAddress) {
      establishWebSocketConnection({ webSocketActionType: WebSocketActionType.ReadCurrentFrequency, selectedSetPointAddress: scheduleSpeedChangeData?.currentFreqAddress })
    } else {
      setIsLoading(false)
      toast.error("Reading current frequency is Failed")
      onClose()
    }
  }, [isOpen])

  useEffect(() => {
    if (setPointData?.readCurrentFreqLoading)
      setIsLoading(false)
  }, [setPointData?.readCurrentFreqLoading])

  useEffect(() => {
    if (simpleSpeedChange?.scheduleDateTime) {
      setupdatedNewFrequency(simpleSpeedChange.newFrequency || '');
      // setupdatedScheduledDate(new Date(simpleSpeedChange.scheduleDate));
      // setupdatedScheduledTime(simpleSpeedChange?.scheduleTime ? new Date(simpleSpeedChange.scheduleTime[0]) : null);
      setupdatedScheduledDescription(simpleSpeedChange?.description || '');
    }
    setUpdatedScheduleId(scheduleId || '')

  }, [simpleSpeedChange, scheduleId]);

  useEffect(() => {
    const isScheduleTimeValid = checkScheduleTimeValidity()
    if (isScheduleTimeValid) setScheduleTimeErrorMessage('')
  }, [updatedScheduledDate, timeInput, ampmInput])

  useEffect(() => {
    if (setPointData.readCurrentFreqErrorStatus || setPointData.readCurrentFreqReturnErrorStatus) {
      toast.dismiss()
      toast.error("Reading current frequency is Failed")
      onClose()
      dispatch(resetReadCurrentFrequencyErrorStatus())
    }
  }, [setPointData.readCurrentFreqErrorStatus, setPointData.readCurrentFreqReturnErrorStatus])

  const handleCreateOrUpdate = async () => {
    if (isSaving) return
    setIsSaving(true)

    try {
      const isNewFrequencyValid = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/.test(updatedNewFrequency);
      const isScheduleTimeValid = checkScheduleTimeValidity()
      if (!isNewFrequencyValid || !isScheduleTimeValid) {
        if (!isNewFrequencyValid)
          if (updatedNewFrequency === '')
            setNewFrequencyErrorMessage('Frequency must not be null')
          else
            setNewFrequencyErrorMessage('Frequency must be a positive number')
        if (!isScheduleTimeValid)
          setScheduleTimeErrorMessage('Schedule time cannot be in the past')
        return;
      }
      const currentDate = new Date();
      const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

      const selectedScheduleTime = new Date(
        `${updatedScheduledDate ? updatedScheduledDate.toLocaleDateString() : formattedDate} ${timeInput ? (timeInput.includes(':') ? timeInput : `${timeInput}:00`) : ''} ${ampmInput}`,
      );

      const updatedSimpleSpeedChange = {
        currentFrequency: setPointData?.currentFreqValue || '0',
        newFrequency: updatedNewFrequency,
        scheduleDateTime: selectedScheduleTime?.toISOString(),
        description: updatedScheduledDescription,
      };

      let response;
      if (editMode && simpleSpeedChange) {
        response = await dispatch(
          updateScheduleSpeedChange({
            id: updatedScheduleId || '',
            speedChanges: {
              simpleSpeedChanges: [updatedSimpleSpeedChange],
              steppedSpeedChanges: [],
            },
          }),
        );
      } else {
        response = await createScheduleById({
          wellId: selectedAsset?.selectedAssetId ?? '',
          groupName: selectedGroupName,
          deviceId: storedUser?.id,
          setpointFrequencyAddress: scheduleSpeedChangeData.setPointFreqAddress || '',
          simpleSpeedChanges: [updatedSimpleSpeedChange],
          steppedSpeedChanges: [],
        });
      }

      if (response) {
        toast.success('Scheduled speed change applied', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (selectedAsset?.selectedAssetId) {
          dispatch(fetchScheduleSpeedChangeStatus({ wellId: selectedAsset?.selectedAssetId }));
        }
        onClose();
      }
    } catch (error: any) {
      console.log("error", error)
      toast.error((error?.response?.data?.apiMessage && error?.response?.data?.apiMessage?.includes('exist')) ? error?.response?.data?.apiMessage?.replace('.', '') : 'Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error creating or updating schedule:', error);
    }
    finally {
      setIsSaving(false)
    }
  };

  const handleInputChange = (fieldName: string, value: string | Date | null) => {
    switch (fieldName) {
      case 'newFrequency':
        if (value !== null && typeof value === 'string') {
          const regex = /^\d{0,3}(\.\d{0,1})?$/
          // const regex = /^(?:\d{1,3}(?:\.\d*)?|\.\d+)$/;
          if (value.trim() === '') {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else if (!regex.test(value)) {
            setNewFrequencyErrorMessage('Frequency must be a positive number');
          } else {
            setNewFrequencyErrorMessage('');
          }
          setupdatedNewFrequency(value);
        }
        break;
      case 'description':
        setupdatedScheduledDescription(value as string);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    onClose();
  };

  const validateTimeFormat = (time: string): boolean => {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    if (!regex.test(time)) return false;
    const [hours, minutes] = time.split(':').map(Number);
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
  };

  const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredKey = (e.nativeEvent as InputEvent).data;
    const isNumber = enteredKey && /^[0-9]$/.test(enteredKey);

    let value = e.target.value;
    value = value.replace(/[^0-9:]/g, '');

    if (value.length > 5) {
      value = value.slice(0, 5);
    }
    if (value.length > 1 && !value.includes(':') && isNumber) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5 && !validateTimeFormat(value)) {
      toast.error('Time must be in HH:MM format with exactly 2 digits for hours and minutes');
    } else {
      const splitedArray = value?.split(':')
      if (splitedArray?.length === 1 && Number(splitedArray[0]) <= 12) {
        setTimeInput(value)
      } else if (splitedArray?.length === 2 && Number(splitedArray[0]) <= 12 && Number(splitedArray[1]) < 60) {
        setTimeInput(value)
      }
    }
  };
  const handleAmPmChange = (value: string) => {
    setAmPmInput(value);
  };

  const handleDateSelection = (date: DateObject | DateObject[] | null) => {
    if (date) {
      const selectedDate = Array.isArray(date) ? date[0].toDate() : date.toDate();
      setupdatedScheduledDate(selectedDate);
    } else {
      setupdatedScheduledDate(null);
    }
  };


  const checkScheduleTimeValidity = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    const selectedScheduleTime = new Date(
      `${updatedScheduledDate ? updatedScheduledDate.toLocaleDateString() : formattedDate} ${timeInput} ${ampmInput}`,
    );
    if (selectedScheduleTime < currentDate) return false
    else return true
  }


  const datePickerRef = useRef<any>(null);
  const iconRef = useRef<any>(null);

  const handleButtonClick = () => {
    if (datePickerRef.current) {
      datePickerRef?.current?.openCalendar();
    }
  };


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the ref is valid and the click is outside
      if ((datePickerRef.current !== null && !datePickerRef.current.contains(event.target as Node)) && (iconRef.current && !iconRef.current.contains(event.target as Node))) {
        try {
          datePickerRef.current.closeCalendar();
        } catch (_) {
          // Ignore the error
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return isOpen ? (
    <>
      <ToastContainer />
      {
        (setPointData?.readCurrentFreqLoading || isLoading) ?
          <div className='body d-flex align-items-center'>
            <div className='card m-0'>
              <div className='body'>
                <p className='loader-scan'>
                  <img src={Loader} alt='Loading ...' className='loader-icon' />
                  Reading current frequency ...
                </p>
              </div>
            </div>
          </div>
          :
          <div className='scheduleSpeed__add-block'>
            {showHeader && (
              <div className='scheduleSpeed__header-bar'>
                <button onClick={handleBack}>
                  <img src={Back_Left} alt='close' />
                  <span>Back to scheduled speed change</span>
                </button>
              </div>
            )}

            <div className='scheduleSpeed__profile-block'>
              <div className='scheduleSpeed__notify-card'>
                <div className='scheduleSpeed__header'>
                  <span className='title'>Create new scheduled speed change</span>
                  <div>{wellName}</div>
                </div>
                <div className='scheduleSpeed__form-body'>
                  <div className='time-block'>
                    <div className='scheduleSpeed__form-control relative'>
                      <label className='label'>Current frequency</label>
                      <input
                        type='text'
                        className='time-input-field'
                        placeholder={`${setPointData.currentFreqValue} Hz`}
                        disabled={true}
                      />
                      <img src={Question} alt='question-mark' className='absolute right-9 top-4' />
                    </div>
                    <div className='scheduleSpeed__form-control'>
                      <label className='label'>Target frequency</label>
                      <div className={`input-field__container ${newFrequencyErrorMessage ? 'error-container' : ''}`}>
                        <input
                          type='text'
                          className='time-input-field'
                          placeholder='Enter target frequency'
                          value={updatedNewFrequency}
                          onChange={(e) => { handleInputChange('newFrequency', e.target.value) }}
                        />
                        {newFrequencyErrorMessage && <img src={Info_Circle} alt='Info_Circle' className='error-icon' />}
                        {newFrequencyErrorMessage && <p className='error-message'>{newFrequencyErrorMessage}</p>}
                      </div>
                    </div>
                    <div className='scheduleSpeed__form-control'>
                      <label className='label'>Schedule time</label>
                      <div className='date-time-picker'>
                        <div className={`date-time-container ${scheduleTimeErrorMessage ? 'error-container' : ''}`}>
                          <DatePicker
                            mobileLabels={{ OK: 'Apply', CANCEL: 'Cancel' }}
                            format='MM/DD/YYYY'
                            className='date-input-field rmdp-mobile bg-dark'
                            placeholder='MM/DD/YYYY'
                            weekDays={["Su", "Mo", "Tu", "We", "Th", "Fr", "Sat"]}
                            value={updatedScheduledDate ? new Date(updatedScheduledDate) : new Date()}
                            onChange={(selectedDate) => {
                              handleDateSelection(selectedDate);
                            }}
                            plugins={[<Toolbar position='top' sort={['today']} />]}
                            minDate={new Date().setHours(0, 0, 0, 0)}
                            showOtherDays={true}
                            ref={datePickerRef}
                          />
                          <div className='calendar-icon' onClick={handleButtonClick} ref={iconRef}>
                            <img src={Calendar} alt='calendar' className='calendar-img' />
                          </div>
                          <div className='hours-mints-input'>
                            <input
                              type='text'
                              className='time-input-field hours-mints'
                              placeholder='HH : MM'
                              value={timeInput}
                              maxLength={5}
                              onChange={handleTimeInputChange}
                            />
                          </div>
                          <div className='ampm-button-group'>
                            <button
                              type='button'
                              className={`ampm-button ${ampmInput === 'AM' ? 'active' : ''}`}
                              onClick={() => handleAmPmChange('AM')}
                            >
                              AM
                            </button>
                            <span className='scheduleSpeed__seperator'></span>
                            <button
                              type='button'
                              className={`ampm-button ${ampmInput === 'PM' ? 'active' : ''}`}
                              onClick={() => handleAmPmChange('PM')}
                            >
                              PM
                            </button>
                          </div>
                        </div>
                        {scheduleTimeErrorMessage && <p className='error-message'>{scheduleTimeErrorMessage}</p>}
                      </div>
                    </div>
                    <div className='scheduleSpeed__form-control'>
                      <label className='label'>Description</label>
                      <textarea
                        className='time-input-field description-input-field'
                        placeholder='Enter description about scheduled speed change'
                        value={updatedScheduledDescription}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='divider'></div>
                  <div className='scheduleSpeed__btn-notify'>
                    <button type='button' className='btn btn-secondary' onClick={onClose}>
                      Cancel
                    </button>
                    {!editMode && (
                      <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                        Create
                      </button>
                    )}
                    {editMode && (
                      <button type='button' className='btn btn-primary' onClick={handleCreateOrUpdate}>
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  ) : null;
};

export default ScheduledSimpleSpeedChange;
