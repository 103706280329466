import wifiSm from '../../../../../images/wifi-sm.svg'
import WifiLg from '../../../../../images/wifi-lg.svg'
import { hidePingCommLoader } from '../../../../asset/AssetControlSlice';
import { useAppDispatch } from '../../../../../hooks/storeHooks';

interface PingProcessingModalProps {
    wellName: string | undefined
    handleCancel: () => void
    ipAddress: string | undefined

}

const PingProcessingModal: React.FC<PingProcessingModalProps> = ({ wellName, handleCancel, ipAddress }) => {
    const dispatch = useAppDispatch()
    return (
        <>
            <div className='modal-overlay ping-processing-modal'>
                <div className='modal'>
                    <div className='flex flex-row flex-grow profile-block'>
                        <div className='notify-card'>
                            <div className='content-div'>
                                <div className='header-div'>
                                    <span className='icon'>
                                        <img src={wifiSm} alt='' />
                                    </span>
                                    <span className='heading asset-control-modal-heading'>
                                        Pinging asset...
                                    </span>
                                    <span className='asset-name'>
                                        {wellName}
                                    </span>
                                    <span className='connection-description'>
                                        TCP connect to {ipAddress ?? ''}
                                    </span>
                                </div>
                                <div className='main-icon'>
                                    <img src={WifiLg} alt='' />
                                </div>
                            </div>
                            <div className='button-div' onClick={() => { handleCancel(); dispatch(hidePingCommLoader()) }}>
                                <button>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PingProcessingModal;
