import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { slugify } from '../utilities/Slugify';

interface Tab {
  label: string;
  component: React.ComponentType;
  disabled?: boolean;
}

interface TabsProps {
  initialTab?: {
    tab: string;
  };
  tabs: Tab[];
  value: string | undefined;
  className?: string;
}

const TabWithUrlComponent: React.FC<TabsProps> = ({ tabs, value,  className = ""}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultTabIndex = 0;
  const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null)
  const [forceUpdate, setForceUpdate] = useState(0);


  const handleTabClick = (index: number) => {
    if (tabs[index].disabled) {
      return;
    }
    setActiveTabIndex(index);
  };

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('tab');
    if (currentTabFromQuery) {
      const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
      if (tabIndex !== -1) {
        setActiveTabIndex(tabIndex);
      } else {
        if (!activeTabIndex) {
          setActiveTabIndex(defaultTabIndex);
          setForceUpdate((prev) => prev + 1)
        }
      }
    } else {
      if (!activeTabIndex) {
        setActiveTabIndex(defaultTabIndex);
      }
      setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, tabs]);

  useEffect(() => {

    if (activeTabIndex === null) return;

    const validIndex = activeTabIndex >= 0 && activeTabIndex < tabs?.length && !tabs[activeTabIndex]?.disabled
      ? activeTabIndex
      : defaultTabIndex;

    const newTabSlug = slugify(tabs[validIndex].label);
    const currentTabSlug = new URLSearchParams(location.search).get('tab');
    // Only navigate if the tab slug in the URL is differents
    if (location.pathname !== '/layout/well-control-room' && currentTabSlug !== newTabSlug) {
      if (!currentTabSlug) {
        navigate(`${location.pathname}?tab=${newTabSlug}`, { replace: true });
      } else {
        navigate(`${location.pathname}?tab=${newTabSlug}`);
      }
    }
  }, [activeTabIndex, navigate, location.pathname, tabs, defaultTabIndex, forceUpdate]);



  return (
    <>
      <div className={`justify-between  ui-button-toolbar ${className}`}>
        <div className='flex flex-grow'>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`ui-button-toolbar-button ${index === activeTabIndex
                ? 'ui-button-toolbar-button-active semi-bold'
                : 'semi-bold'
                } ${tab.disabled ? 'opacity-50' : ''
                }`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>

      </div>
      <div className='tab-content'>
        {React.createElement(tabs[activeTabIndex ?? 0]?.component || tabs[defaultTabIndex].component, {
          value,
        } as React.Attributes)}
      </div>
    </>
  );
};

export default TabWithUrlComponent;
