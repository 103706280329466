import React from 'react';
import ErrorIcon from '../../../../images/error-icon.png';
import ErrorYellowIcon from '../../../../images/error-yellow-icon.svg'
import './NoData.scss';

interface NoDataProps {
    heading: string,
    errorImgType?: string,
    description?: string,
}

const NoData: React.FC<NoDataProps> = ({ errorImgType, heading, description }) => {
    return (
        <div className='no-data'>
            <div className='icon'>
                <img src={errorImgType === "warning" ? ErrorYellowIcon : ErrorIcon} alt='' />
            </div>
            <div className='heading nodata-heading '>
                {heading}
            </div>
            <div className='description'>
                {description}
            </div>

        </div>
    )
}

export default NoData