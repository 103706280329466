import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import ClearAlarmAlert from '../../../../../images/clear-alarm-alert.svg'
import { disablePingCommDataShowStatus } from '../../../../asset/AssetControlSlice';

interface PingCommDataModalProps {
    wellName: string | undefined
    ipAddress: string | undefined
}

const PingCommDataModal: React.FC<PingCommDataModalProps> = ({ wellName, ipAddress }) => {
    const pingCommData = useAppSelector((state) => state?.assetControl.pingCommData)
    const dispatch = useAppDispatch()
    const numberPattern = /\d+/;

    return (
        <>
            <div className='modal-overlay ping-data-modal'>
                <div className='modal'>
                    <div className='flex flex-row flex-grow profile-block'>
                        <div className='notify-card'>
                            <div className='content-div'>
                                <div className='header-div'>
                                    <span className='icon'>
                                        <img src={ClearAlarmAlert} alt='' />
                                    </span>
                                    <span className='heading asset-control-modal-heading'>
                                        Completed
                                    </span>
                                    <span className='asset-name'>
                                        {wellName}
                                    </span>
                                    <span className='connection-description'>
                                        TCP connect to {ipAddress ?? ''}
                                    </span>
                                </div>
                                <div className='connection-div'>
                                    <div className='connection'>
                                        <span className='heading'>Connection 1</span> <span className='value'>{pingCommData?.connection1 ? (numberPattern.test(pingCommData?.connection1) ? `${pingCommData?.connection1} ms` : pingCommData?.connection1) : '- ms'}</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 2</span> <span className='value'>{pingCommData?.connection2 ? (numberPattern.test(pingCommData?.connection2) ? `${pingCommData?.connection2} ms` : pingCommData?.connection2) : '- ms'}</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 3</span> <span className='value'>{pingCommData?.connection3 ? (numberPattern.test(pingCommData?.connection3) ? `${pingCommData?.connection3} ms` : pingCommData?.connection3) : '- ms'}</span>
                                    </div>
                                    <div className='connection'>
                                        <span className='heading'>Connection 4</span> <span className='value'>{pingCommData?.connection4 ? (numberPattern.test(pingCommData?.connection4) ? `${pingCommData?.connection4} ms` : pingCommData?.connection4) : '- ms'}</span>
                                    </div>
                                </div>
                                <div className='summary-div'>
                                    <div className='summary-heading'>
                                        Summary
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Connection Attempts</span> <span className='value'>{pingCommData?.summary?.connectionAttempts}</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Connections Made</span> <span className='value'>{pingCommData?.summary?.connectionsMade}</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Min Time</span> <span className='value'>{pingCommData?.summary?.minTime ? `${pingCommData?.summary?.minTime} ms` : '- ms'}</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Max Time</span> <span className='value'>{pingCommData?.summary?.maxTime ? `${pingCommData?.summary?.maxTime} ms` : '- ms'}</span>
                                    </div>
                                    <div className='data'>
                                        <span className='heading'>Avg Time</span> <span className='value'>{pingCommData?.summary?.avgTime ? `${pingCommData?.summary?.avgTime} ms` : '- ms'}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='button-div' onClick={() => { dispatch(disablePingCommDataShowStatus()) }}>
                                <button>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PingCommDataModal;
