import React, { useEffect, useCallback, useRef, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { ReactComponent as AttachmentIcon } from '../../../../images/attachment.svg';
import CloseIcon from '../../../../images/x-close-3.svg';
import Loader from "../../../common/page-loader/ComponentLoader";
import { showToaster } from "../../../dashboard/components/asset-location/AssetList";
import SelectFilterDropDown from "../tickets/Selectdropdown/SelectDropdown";
import MultiSelectDropdown from "./Selectdropdown/MultiSelectDropdown";
// import Heardertimeshow from "./Selectdropdown/Heardertimeshow";
import MessageCommects from "./MessageCommects/MessageCommects";
import {
    clearTicketAssetDetails,
    clearTicketFields,
    clearTicketUsers,
    createTicket,
    fetchCommentsByTicketId,
    fetchTicketById,
    getAssigneeList,
    getCustomFields,
    getTicketForm,
    getTicketStatus,
    getTicketTable,
    getTicketTags,
    getTicketUsers,
    ticketUploadFile,
    updateTicket
} from "./slices/ticketSlice";
import "./TicketModelOpen.scss";
import { TicketFields, TicketForm, TicketTagsApiResponse, TicketUser, TicketUsersResponse } from "./interfaces/ticketInterface";
import SelectDropdown from "../tickets/Selectdropdown/SelectDropdown";
import { createDebouncedFunction, formatRelativeTime } from "../../../../utilities/CommonFunctions";
import StatusDropdown from "./Selectdropdown/StatusDropdown";

interface TicketModelOpenProps {
    isOpen: boolean;
    closeModal: () => void;
    ticketId?: number;
    fetchData?: (pageNum?: number) => void;
}

interface TicketState {
    subject: string;
    selectedOption: string;
    selectedFilterDropDown: string | null;
    selectedTicketFilter: { [key: string]: any };
    selectedTags: any[];
    selectedCCs: any[];
    comment: string;
    attachment: { token: string; fileName: string } | null;
    status: string
}

const initialState: TicketState = {
    subject: "",
    selectedOption: 'Pending',
    selectedFilterDropDown: null,
    selectedTicketFilter: {},
    selectedTags: [],
    selectedCCs: [],
    comment: "",
    attachment: null,
    status: ''

};

const getTwoLetter = (name: string) => {
    if (!name) return ''
    return name.split('')[0]?.charAt(0).toUpperCase() + name.split('')[1]?.charAt(0).toUpperCase()
}


const TicketModelOpen: React.FC<TicketModelOpenProps> = ({ isOpen, closeModal, ticketId, fetchData }) => {
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {
        ticketForm,
        TicketFields,
        ticketUsers,
        assigneeList,
        ticketUserAPIresponse,
        ticketAssigneeAPIresponse,
        ticketUserLoading,
        ticketTagsData,
        ticketTagsApiResponse,
        ticketFieldsLoading,
        ticketAssigneeLoading,
        createUpdateTicketLoading,
        fileUploadLoading,
        ticketTagsLoading
    } = useAppSelector((state) => state.ticketTableSlice);

    const [state, setState] = React.useState<TicketState>(initialState);
    const [selectedTicketForUpdate, setSelectedTicketForUpdate] = React.useState<any>(null);
    const [fetchedComments, setFetchedComments] = React.useState<any[]>([]);
    const transformedTags = useMemo(() =>
        ticketTagsData.map((tag, index) => ({ id: index, name: tag.name })),
        [ticketTagsData]
    );
    const [componentLoading, setComponentLoading] = useState(true)
    const handleApiCall = useCallback(async (action: any, params: any, successMessage: string) => {
        try {
            const result = await dispatch(action(params)).unwrap();
            showToaster(successMessage, 'success');
            return result;
        } catch (error) {
            showToaster((error as any).message || 'Operation failed', 'error');
            throw error;
        }
    }, [dispatch]);

    useEffect(() => {
        if (assigneeList?.length === 0) dispatch(getAssigneeList({
            PageNumber: 1,
            PageSize: 100,
            SearchQuery: ''
        }))
        if (ticketUsers?.length === 0) dispatch(getTicketUsers({ PageNumber: 1, PageSize: 25, SearchQuery: '' }))

    }, [])

    useEffect(() => {
        if (isOpen) {
            ticketForm.length === 0 && dispatch(getTicketForm());
            if (ticketId) {
                setComponentLoading(true)
                handleApiCall(fetchTicketById, { ticketId }, 'Ticket loaded')
                    .then((res) => { setSelectedTicketForUpdate(res?.data?.ticket) })
                    .finally(() => setComponentLoading(false))
                handleApiCall(fetchCommentsByTicketId, { ticketId }, 'Comments loaded')
                    .then((res) => setFetchedComments(res?.data?.comments || []));
            } else {
                setComponentLoading(false)
            }
        }
    }, [isOpen, ticketId, dispatch, handleApiCall]);

    const isFirstRenderTU = useRef(true);
    useEffect(() => {
        if (selectedTicketForUpdate && TicketFields && !ticketTagsLoading && !ticketUserLoading && !ticketFieldsLoading && !ticketAssigneeLoading) {

            if (isFirstRenderTU.current) {
                isFirstRenderTU.current = false;
            } else {
                return;
            }

            const updateTicketDetails = () => {
                const newState: Partial<TicketState> = {
                    selectedOption: selectedTicketForUpdate.status || "",
                    selectedTags: selectedTicketForUpdate.tags?.map((tag: string, index: number) => ({
                        id: index,
                        name: tag
                    })) || [],
                    selectedCCs: selectedTicketForUpdate?.follower_users || [],
                    selectedTicketFilter: { ...state.selectedTicketFilter }, // Ensure we preserve previous state

                };

                // subject: selectedTicketForUpdate.subject
                if (selectedTicketForUpdate?.subject) {
                    setState(prev => ({
                        ...prev,
                        subject: selectedTicketForUpdate.subject || "", // Set subject from existing ticket
                    }));

                }
                //update status

                if (selectedTicketForUpdate?.status) {
                    setState(prev => ({
                        ...prev,
                        status: selectedTicketForUpdate.status || "",
                    }));
                }

                if (selectedTicketForUpdate?.requesterUser) {
                    newState.selectedTicketFilter = {
                        ...newState.selectedTicketFilter, // Preserve previous values
                        "Requester*": { name: selectedTicketForUpdate?.requesterUser.name || "", email: selectedTicketForUpdate?.requesterUser.email || "" }
                    };
                }

                if (selectedTicketForUpdate?.assigneeUser) {
                    newState.selectedTicketFilter = {
                        ...newState.selectedTicketFilter,
                        "Assignee*": selectedTicketForUpdate?.assigneeUser
                    };
                }

                if (selectedTicketForUpdate.ticket_form_id && ticketForm) {
                    const form = ticketForm.find((f: any) => f.id === selectedTicketForUpdate.ticket_form_id);
                    if (form) {
                        newState.selectedTicketFilter = {
                            ...newState.selectedTicketFilter,
                            "Form*": form
                        };
                    }
                }

                TicketFields?.forEach((field: any) => {
                    const customField = selectedTicketForUpdate.custom_fields?.find((cf: any) => cf.id === field.id);
                    // check if custom field is not undefined or it's not an array
                    if (customField?.value && !Array.isArray(customField.value)) {
                        newState.selectedTicketFilter = {
                            ...newState.selectedTicketFilter,
                            [field.title]: field.custom_field_options?.find((option: any) => option.value === customField.value)
                        };
                    }
                });

                setState(prev => ({
                    ...prev,
                    selectedTicketFilter: newState.selectedTicketFilter || {}, // Ensure `selectedTicketFilter` is never undefined
                    ...newState
                }));
            };

            updateTicketDetails();
        }
    }, [selectedTicketForUpdate, TicketFields, ticketForm, ticketUsers]);



    useEffect(() => {
        const loadFormFields = async () => {
            if (!selectedTicketForUpdate || !ticketForm) return;

            // Get the form from ticketForm data
            const form = ticketForm.find((f: any) => f.id === selectedTicketForUpdate.ticket_form_id);
            if (!form) return;

            setState(prev => ({
                ...prev,
                selectedTicketFilter: {
                    ...prev.selectedTicketFilter,
                    "Form*": form
                }
            }));

            // Update form selection in state
            setState(prev => ({
                ...prev,
                selectedTicketFilter: {
                    ...prev.selectedTicketFilter,
                    "Form*": form
                }
            }));
        };

        loadFormFields();
    }, [selectedTicketForUpdate, ticketForm, dispatch]);

    useEffect(() => {
        const currentForm = state.selectedTicketFilter["Form*"];
        if (currentForm?.ticket_field_ids) {
            dispatch(getCustomFields({ fieldIds: currentForm.ticket_field_ids }));
        }
    }, [state.selectedTicketFilter["Form*"], dispatch]);

    // Update the form selection handler
    const handleFilterClick = useCallback((key: string, option: any) => {
        if (key === 'Form*' && ticketId) {

            setState(prev => ({
                ...prev,
                selectedTicketFilter: {
                    [key]: prev.selectedTicketFilter[key]?.id === option.id ? null : option,

                    "Requester*": prev.selectedTicketFilter["Requester*"],
                    "Assignee*": prev.selectedTicketFilter["Assignee*"],
                },
                selectedFilterDropDown: null
            }));

            setSelectedTicketForUpdate((prev: any) => ({
                ...prev,
                ticket_form_id: option?.id,
                custom_fields: []
            }));

            dispatch(getCustomFields({ fieldIds: option?.ticket_field_ids }));

        } else {

            setState(prev => ({
                ...prev,
                selectedTicketFilter: {
                    ...prev.selectedTicketFilter,
                    [key]: prev.selectedTicketFilter[key]?.id === option.id ? null : option,

                },
                selectedFilterDropDown: null
            }));
        }

    }, [dispatch]);

    const handleFileUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            const file = event.target.files[0];
            try {
                const result = await dispatch(ticketUploadFile({
                    fileName: file.name,
                    file
                })).unwrap();

                setState(prev => ({
                    ...prev,
                    attachment: { token: result.data?.upload?.token, fileName: file.name }
                }));
            } catch (error) {
                console.error('File upload failed:', error);
            }
        }
    }, [dispatch]);

    const constructPayload = useCallback(() => ({
        ticket: {
            subject: state.subject,
            comment: {
                body: state.comment,
                uploads: state.attachment?.token ? [state.attachment.token] : []
            },
            requester: {
                name: state.selectedTicketFilter?.["Requester*"]?.name || "",
                email: state.selectedTicketFilter?.["Requester*"]?.email || ""
            },
            assignee_id: state.selectedTicketFilter?.["Assignee*"]?.id || null,
            ticket_form_id: state.selectedTicketFilter?.["Form*"]?.id || null,
            tags: state.selectedTags.map(tag => tag.name),
            custom_fields: Object.keys(state.selectedTicketFilter)
                .filter(key => !["Requester*", "Assignee*", "Tags", "CCs", "Form*"].includes(key))
                .map(key => ({
                    id: TicketFields?.find((field: any) => field.title === key)?.id,
                    value: state.selectedTicketFilter[key]?.value
                })),
            email_ccs: state.selectedCCs?.map((user: any) => ({ user_email: user.email })) || [],
            ...(ticketId) && { status: state.status },
        }

    }), [state, TicketFields]);

    const validateTicket = (isEditMode: boolean) => {
        if (!state.subject.trim()) {
            showToaster('Please fill the Subject field', 'error');
            return false;
        }

        const requester = state.selectedTicketFilter?.["Requester*"];
        if (!requester?.name || !requester?.email) {
            showToaster('Please select a valid Requester*', 'error');
            return false;
        }

        const assignee = state.selectedTicketFilter?.["Assignee*"];
        if (!assignee?.id) {
            showToaster('Please select a valid Assignee*', 'error');
            return false;
        }

        const form = state.selectedTicketFilter?.["Form*"];
        if (!form?.id) {
            showToaster('Please select a valid Form*', 'error');
            return false;
        }

        // Validate Comment (only for creating a ticket)
        if (!isEditMode && !state.comment.trim()) {
            showToaster('Please fill the Comment field', 'error');
            return false;
        }

        return true;
    };


    const handleCreateClick = useCallback(() => {
        if (!validateTicket(false)) return;

        const payload = constructPayload();
        dispatch(createTicket({ ticketData: payload }))
            .then((res) => {
                if (createTicket.fulfilled.match(res)) {
                    closeModal();
                    setState(initialState);
                    showToaster('Ticket created successfully', 'success');
                    resetState()
                }
            });
    }, [constructPayload, dispatch, closeModal]);

    const onUpdate = useCallback(() => {
        if (!validateTicket(true)) return;

        const payload = constructPayload();
        dispatch(updateTicket({ ticketData: payload, ticketId: ticketId || 0 }))
            .then((res) => {
                if (updateTicket.fulfilled.match(res)) {
                    closeModal();
                    setState(initialState);
                    showToaster('Ticket updated successfully', 'success');
                    if (fetchData) {
                        fetchData(1)
                    } else {

                        dispatch(clearTicketAssetDetails())
                        dispatch(getTicketTable({ PageNumber: 1, PageSize: 25 }));
                    }
                    resetState()
                }
            });
    }, [constructPayload, dispatch, closeModal, ticketId]);

    const resetState = () => {
        setState(initialState);
        dispatch(clearTicketFields());
    };

    if (!isOpen) return null;


    const handleStatusSelect = (selectedStatus: string) => {
        setState(prev => ({
            ...prev,
            status: selectedStatus || "",
        }));
    }


    return (
        <div className="Tickets-modal">
            <div className="modal-content">
                <Header
                    ticketId={selectedTicketForUpdate?.id}
                    onClose={() => {
                        closeModal();
                        resetState();
                    }}
                    status={state.status}
                    updatedAt={selectedTicketForUpdate?.updated_at}
                    assigneeName={selectedTicketForUpdate?.assigneeUser?.name}
                    handleStatusSelect={handleStatusSelect}
                    loading={componentLoading}
                />

                <div className="modal-content-body grid grid-cols-[1fr_1fr]">
                    <FormSection
                        ticketUsers={ticketUsers}
                        ticketForm={ticketForm}
                        TicketFields={TicketFields}
                        ticketFieldsLoading={ticketFieldsLoading}
                        transformedTags={transformedTags}
                        state={state}
                        handleFilterClick={handleFilterClick}
                        setState={setState}
                        ticketAssigneeUsers={assigneeList}
                        ticketUserAPIresponse={ticketUserAPIresponse}
                        ticketAssigneeAPIresponse={ticketAssigneeAPIresponse}
                        ticketUserLoading={ticketUserLoading}
                        ticketTagsApiResponse={ticketTagsApiResponse}
                        ticketId={ticketId ?? 0}
                        ticketTagsLoading={ticketTagsLoading}
                    />

                    <MessageSection
                        comments={fetchedComments}
                        comment={state.comment}
                        attachment={state.attachment}
                        fileUploadLoading={fileUploadLoading}
                        onCommentChange={(value) => setState(prev => ({ ...prev, comment: value }))}
                        onFileUpload={handleFileUpload}
                        onCreate={handleCreateClick}
                        onUpdate={onUpdate}
                        isLoading={createUpdateTicketLoading}
                        fileInputRef={fileInputRef}
                        validateTicket={validateTicket}
                        ticketId={ticketId}
                        closeModal={closeModal}
                        setState={setState}
                        initialState={initialState}
                        resetState={resetState}
                    />
                </div>
            </div>
        </div>
    );
};

const Header: React.FC<{
    ticketId?: number;
    status?: string;
    updatedAt?: string;
    assigneeName: string;
    onClose: () => void;
    handleStatusSelect: (status: string) => void,
    loading: boolean
}> = ({ ticketId, onClose, status, updatedAt, assigneeName, handleStatusSelect, loading }) => {
    const { ticketStatus, ticketStatusLoading } = useAppSelector((state) => state.ticketTableSlice)
    const dispatch = useAppDispatch()

    useEffect(() => {
        return
        if (ticketStatus?.length === 0) dispatch(getTicketStatus());
    }, [])

    return (<>
        <div className="modal-header">
            <div className="modal-left">
                <h2 className="modal-title">
                    {ticketId !== undefined ? `# ${ticketId}` : loading ? <Loader /> : 'New Ticket'}
                </h2>
                {
                    ticketId && ticketStatus &&
                    <div className="ticket-status">

                        <StatusDropdown
                            selectedOption={status || ''}
                            handleOptionSelect={(option: string) => handleStatusSelect(option)}
                            options={ticketStatus}
                            loading={ticketStatusLoading}
                            disabled={true}
                        />

                    </div>
                }
            </div>
            <div className="header-right-actions">
                {
                    ticketId &&
                    <div className="flex gap-x-4">
                        {
                            assigneeName &&
                            <div className="ticket-assignee">
                                {getTwoLetter(assigneeName)}
                            </div>
                        }
                        <div className="ticket-updated-at">
                            {updatedAt ? formatRelativeTime(updatedAt) : ''}
                        </div>
                    </div>
                }
                <button className="close-button" onClick={onClose}>
                    <img src={CloseIcon} alt="close" />
                </button>
            </div>
        </div>
        <hr />
    </>
    )
};

const FormSection: React.FC<{
    ticketUsers: TicketUser[];
    ticketAssigneeUsers: TicketUser[];
    ticketForm: TicketForm[];
    TicketFields: TicketFields[];
    ticketFieldsLoading: boolean;
    transformedTags: any[];
    state: TicketState;
    handleFilterClick: (key: string, option: any, type?: string) => void;
    setState: React.Dispatch<React.SetStateAction<TicketState>>;
    ticketUserAPIresponse: TicketUsersResponse;
    ticketTagsApiResponse: TicketTagsApiResponse;
    ticketAssigneeAPIresponse: TicketUsersResponse;
    ticketUserLoading: boolean;
    ticketId: number;
    ticketTagsLoading: boolean;
}> = ({
    ticketUsers,
    ticketForm,
    TicketFields,
    ticketFieldsLoading,
    transformedTags,
    state,
    handleFilterClick,
    setState,
    ticketAssigneeUsers,
    ticketUserAPIresponse,
    ticketTagsApiResponse,
    ticketUserLoading,
    ticketTagsLoading,

}) => {
        const handleDropDownClick = useCallback((dropDownName: string) => {
            setState(prev => ({
                ...prev,
                selectedFilterDropDown: prev.selectedFilterDropDown === dropDownName ? null : dropDownName
            }));
        }, [setState]);
        const dispatch = useAppDispatch();


        const [usersQuery, setUsersQuery] = React.useState('');

        const debouncedSearchForTickets = useCallback(
            createDebouncedFunction((query: string) => {
                dispatch(getTicketUsers({
                    PageNumber: 1,
                    PageSize: 25,
                    SearchQuery: query,
                }));
            }, 500),
            [dispatch]
        );

        const debouncedSearchForTags = useCallback(
            createDebouncedFunction((query: string) => {
                dispatch(getTicketTags({
                    pageNumber: 1,
                    pageSize: 25,
                    SearchQuery: query,
                }));
            }, 500),
            [dispatch]
        );


        return (
            <div className="selected-tickets-field">
                <div className="selected-tickets-all-div">
                    <div className="selected-tickets-div flex-col text-left sujectField-area">
                        <label htmlFor="flex subject text-left w-[100%]">Subject*</label>
                        <input
                            className='custom-text-input !pl-3 sujectField'
                            type='text'
                            name='subject'
                            value={state.subject || ""}
                            onChange={(e) => setState(prev => ({ ...prev, subject: e.target.value }))}
                        />
                    </div>
                    <div className="selected-tickets-div">
                        <SelectDropdown
                            name="Requester*"
                            options={ticketUsers}
                            selectedFilter={state.selectedTicketFilter['Requester*'] || []}
                            handleFilterClick={handleFilterClick}
                            isDropDownOpen={state.selectedFilterDropDown === 'Requester*'}
                            handleDropDownClick={handleDropDownClick}
                            idKey="id"
                            nameKey="name"
                            hasMore={ticketUserAPIresponse?.hasNextPage}
                            loadMore={() => dispatch(getTicketUsers({
                                PageNumber: ticketUserAPIresponse?.pageNumber + 1,
                                PageSize: 25,
                                SearchQuery: usersQuery,
                            }))}
                            loading={ticketUserLoading}
                            onSearch={(query) => {
                                debouncedSearchForTickets(query);
                            }}
                            setSearchTerm={setUsersQuery}
                            searchTerm={usersQuery}
                        />

                    </div>

                    <div className="selected-tickets-div">
                        <SelectDropdown
                            name='Assignee*'
                            nameKey="name"
                            idKey="id"
                            isDropDownOpen={state.selectedFilterDropDown === 'Assignee*'}
                            handleDropDownClick={handleDropDownClick}
                            options={ticketAssigneeUsers}
                            selectedFilter={state.selectedTicketFilter['Assignee*'] || []}
                            handleFilterClick={handleFilterClick}
                            hasMore={false}
                            loading={ticketUserLoading}
                            loadMore={() => void 0} // No load more for form
                            onSearch={() => void 0}
                            setSearchTerm={setUsersQuery}
                            searchTerm={usersQuery}
                        />
                    </div>

                    <div className="selected-tickets-div multipaltab">
                        <MultiSelectDropdown
                            options={ticketUsers}
                            label='CCs'
                            selected={state.selectedCCs}
                            setSelected={(ccs) => setState(prev => {
                                return {
                                    ...prev,
                                    selectedCCs: ccs as any[]
                                }
                            }

                            )}
                            hasMore={ticketUserAPIresponse?.hasNextPage}
                            loadMore={() => dispatch(getTicketUsers({
                                PageNumber: ticketUserAPIresponse?.pageNumber + 1,
                                PageSize: 25,
                                SearchQuery: usersQuery,
                            }))}
                            loading={ticketUserLoading}
                            onSearch={(query) => {
                                dispatch(clearTicketUsers());
                                setUsersQuery(query);
                                debouncedSearchForTickets(query);
                            }}

                        />
                    </div>

                    <div className="selected-tickets-div">
                        <SelectFilterDropDown
                            name='Form*'
                            nameKey="name"
                            idKey="id"
                            isDropDownOpen={state.selectedFilterDropDown === 'Form*'}
                            handleDropDownClick={handleDropDownClick}
                            options={ticketForm}
                            selectedFilter={state.selectedTicketFilter['Form*'] || []}
                            handleFilterClick={handleFilterClick}
                            hasMore={false}
                            loadMore={() => void 0} // No load more for form
                            loading={false}
                            onSearch={() => void 0}
                            key={ticketForm?.length}
                            setSearchTerm={(query) => {
                                setUsersQuery(query);
                            }}
                            searchTerm={usersQuery}
                        />
                    </div>

                    <div className="selected-tickets-div multipaltab">
                        <MultiSelectDropdown
                            options={transformedTags}
                            label="Tags"
                            selected={state.selectedTags}
                            setSelected={(tags) => setState(prev => ({ ...prev, selectedTags: tags as any[] }))}
                            hasMore={!!ticketTagsApiResponse?.next_page}
                            loadMore={() =>
                                dispatch(getTicketTags({
                                    pageNumber: Number(ticketTagsApiResponse?.next_page?.split('page=')[1][0]),
                                    pageSize: 25
                                }))
                            }
                            loading={ticketTagsLoading}
                            onSearch={
                                (query) => {
                                    debouncedSearchForTags(query);
                                }
                            }

                        />
                    </div>
                    {!ticketFieldsLoading ? (
                        TicketFields?.map((option: any) => (
                            option?.active && option?.custom_field_options?.length > 0 && (
                                <div key={option.id} className="selected-tickets-div">
                                    <SelectFilterDropDown
                                        name={option.title}
                                        nameKey="name"  // Changed to match custom field structure
                                        idKey="id"
                                        isDropDownOpen={state.selectedFilterDropDown === option.title}
                                        handleDropDownClick={handleDropDownClick}
                                        options={option.custom_field_options}
                                        selectedFilter={state.selectedTicketFilter[option.title] || []}
                                        handleFilterClick={handleFilterClick}
                                        hasMore={false}
                                        loadMore={() => void 0} // No load more for custom field
                                        loading={false}
                                        onSearch={() => void 0}
                                        setSearchTerm={(query) => {
                                            setUsersQuery(query);
                                        }}
                                        searchTerm={usersQuery}

                                    />
                                </div>
                            )
                        ))
                    ) : (
                        <div className="mt-10">
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        );
    };

const MessageSection: React.FC<{
    comments: any[];
    comment: string;
    attachment: { token: string; fileName: string } | null;
    fileUploadLoading: boolean;
    onCommentChange: (value: string) => void;
    onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onCreate: () => void;
    onUpdate: () => void;
    isLoading: boolean;
    fileInputRef: React.RefObject<HTMLInputElement>;
    validateTicket: any;
    ticketId: number | undefined;
    closeModal: () => void;
    setState: React.Dispatch<React.SetStateAction<TicketState>>;
    initialState: TicketState;
    resetState: () => void
}> = ({
    comments,
    comment,
    attachment,
    fileUploadLoading,
    onCommentChange,
    onFileUpload,
    onCreate,
    onUpdate,
    isLoading,
    fileInputRef,
    validateTicket,
    ticketId,
    closeModal,
    setState,
    initialState,
    resetState
}) => (
        <div className="tickets-textarea-box">
            <div className="tickets-textarea-width">
                <div className="tickets-message-body">
                    {comments.length > 0 && <MessageCommects fetchedComments={comments} />}
                </div>
            </div>
            <div className="tickets-text-area">
                <div className="tickets-textarea">
                    <span className="ticket-title mb-2">Message{ticketId ? "" : "*"}</span>
                    <textarea
                        value={comment}
                        onChange={(e) => onCommentChange(e.target.value)}
                        className="textarea-message"
                        placeholder="Type your message here..."
                    />
                </div>
                <div className='attachment-container'>
                    <button type='button' className='attachment' onClick={() => fileInputRef.current?.click()}>
                        <AttachmentIcon />
                    </button>
                    {fileUploadLoading && (
                        <span className='attachment-loading ml-2'>
                            <Loader />
                        </span>
                    )}
                    <span className='ml-2 w-[300px]'>{attachment?.fileName || ''}</span>
                    <input
                        type='file'
                        ref={fileInputRef}
                        onChange={onFileUpload}
                        style={{ display: 'none' }}
                    />

                    <div className='footer-modal'>
                        <button
                            type='button'
                            className='click-btn btn-secondary'
                            onClick={() => {
                                closeModal();
                                setState(initialState);
                                resetState()
                            }}
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => { (ticketId ? validateTicket(true) && onUpdate() : validateTicket(false) && onCreate()) }}
                            type='button'
                            disabled={isLoading}
                            className={`click-btn btn-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading && (
                                <svg aria-hidden="true" className="inline w-6 h-6 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            )}{ticketId ? "Update" : "Create"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

export default TicketModelOpen;
