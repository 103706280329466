import ChevronDown from '../../../../../../images/chevron-down.svg';
import ChevronUp from '../../../../../../images/chevron-up.svg';
import './TicketFilterDropDown.scss';
import SearchIcon from '../../../../../../images/search-md.svg';
import React, { useMemo, useState } from 'react';
import { Operators, TicketUser, Status } from '../../interfaces/ticketInterface';
import Loader from '../../../../../common/page-loader/ComponentLoader';
import NoData from '../../../../../dashboard/components/no-data/NoData';
import { useAppSelector } from '../../../../../../hooks/storeHooks';

interface TicketFilterDropDownProps {
    options: Operators[] | TicketUser[] | Status[];
    name: string;
    isDropDownOpen: boolean;
    handleDropDownClick: (dropDownName: string) => void;
    selectedFilter: (Operators | TicketUser | Status)[];
    handleFilterClick: (key: string, value: Operators | TicketUser | Status) => void;
    loading?: boolean,
    handleAssigneeScroll?: () => void,
    assigneeSearchValue?: string;
    handleAssigneeSearchChange?: (value: string) => void
}

const TicketFilterDropDown: React.FC<TicketFilterDropDownProps> = ({
    name,
    isDropDownOpen,
    handleDropDownClick,
    options,
    selectedFilter,
    handleFilterClick,
    loading = false,
    handleAssigneeScroll,
    assigneeSearchValue,
    handleAssigneeSearchChange,

}) => {
    const [showMore, setShowMore] = useState(false);
    const [searchString, setSearchString] = useState('');
    const maxVisbleOptionsCount = 5

    const dropdownListRef = React.createRef<HTMLDivElement>();
    const { assigneeListresponse } = useAppSelector((state) => state.ticketTableSlice);

    const filteredOptions = useMemo(() => {
        if (name === 'Assigned to') {
            return options
        } else {
            return (options as (Operators | TicketUser | Status)[])?.filter((option: any) => {
                if (option?.name) {
                    return option.name.toLowerCase().includes(searchString.toLowerCase());
                } else if (option?.status) {
                    return option.status.toLowerCase().includes(searchString.toLowerCase());
                }
                return false;
            });
        }
    }, [options, searchString]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (name === 'Assigned to') {
            if (handleAssigneeSearchChange) {
                handleAssigneeSearchChange(event.target.value)
            }
        } else {
            setSearchString(event.target.value);
        }
    };

    const isOptionSelected = (option: Operators | TicketUser | Status) => {
        return selectedFilter.some(selected => {
            if ('id' in selected && 'id' in option) {
                return selected.id === option.id;
            }
            return false;
        });
    };

    function capitalizeFirstLetter(str: string) {
        if (!str) return str; // Handle empty strings
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }


    const fetchMoreOnBottomReached = React.useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement && name === 'Assigned to') {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                if (
                    ((scrollHeight - scrollTop - clientHeight) < 400) &&
                    (!loading && assigneeListresponse.hasNextPage)
                ) {
                    if (handleAssigneeScroll) handleAssigneeScroll()
                }
            }
        },
        [options, loading])

    return (
        <div className="control-room-ticket-filter-drop-down relative">
            <div onClick={() => handleDropDownClick(name)} className={`ticket-dropdown-btn cursor-pointer`}>
                <span>{name}</span>
                <div className='flex justify-center items-center space-x-6'>

                    <span className='w-6 h-6 bg-[#00789E] text-[#F7F9F9] flex justify-center items-center'>
                        {selectedFilter?.length}
                    </span>
                    <span>
                        {
                            isDropDownOpen ?
                                <img src={ChevronUp} alt="chevron-up" />
                                : <img src={ChevronDown} alt="chevron-down" />
                        }
                    </span>
                </div>
            </div>
            {isDropDownOpen && (
                <>
                    {(name === 'Assigned to' || name === 'Operators') && (
                        <div className='flex flex-row search-div relative ticket-filter-search'>
                            <span className='absolute inset-y-0 left-0 flex items-center pl-2'>
                                <img src={SearchIcon} alt="search" />
                            </span>
                            <input
                                className='custom-text-input'
                                placeholder='Search'
                                type='text'
                                name='search'
                                value={name === 'Assigned to' ? assigneeSearchValue : searchString}
                                onChange={handleSearchChange}
                            />
                        </div>
                    )}
                    {loading && <Loader />}

                    <div className={`filter-option-list ${loading ? 'pointer-events-none' : ''}`}
                        ref={dropdownListRef}
                        onScroll={() => fetchMoreOnBottomReached(dropdownListRef.current)}
                    >                        {
                            filteredOptions?.length > 0 ? filteredOptions?.map((option, index) => {
                                if (showMore || index < maxVisbleOptionsCount) {
                                    const optionName = 'name' in option ? option.name :
                                        capitalizeFirstLetter(option.status)

                                    return (
                                        <div key={index} className={`ticket-filter-options ${loading ? 'blur-background' : ''}`}>
                                            <label className='checkbox-container'>
                                                <input
                                                    type='checkbox'
                                                    checked={isOptionSelected(option)}
                                                    onChange={() => handleFilterClick(name, option)}
                                                    aria-label={optionName}
                                                />
                                                <span className='checkbox-checkmark'></span>
                                                <span className='ms-6'>{optionName}</span>
                                            </label>
                                        </div>
                                    );
                                }
                                return null;
                            })
                                :
                                (filteredOptions?.length === 0 && !loading) &&
                                <NoData heading='No data' />
                        }
                    </div>

                    {(filteredOptions.length > maxVisbleOptionsCount) && (
                        <div className='show-more-div flex justify-start items-center' onClick={() => setShowMore(!showMore)}>
                            Show {showMore ? 'less' : 'more'}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TicketFilterDropDown;
