import React, { useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import TempModal from './modals/TempModal';
import { TemperatureDetailsProps } from './interfaces/temperature.interface';
import TempChart from './charts/TempChart';
import { bandsData } from './constants/temp.constants';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { AssetTimeseriesData } from '../../interfaces/timeSeriesChannelIdsData.interface';
import { TimeseriesData } from '../../interfaces/timeSeries.interface';
import { generateGroupPDF } from '../../common';

const TemperatureDetails: React.FC<TemperatureDetailsProps> = ({ heading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const motorChartdiv1 = useRef<HTMLDivElement | null>(null);
  const intakeChartdiv2 = useRef<HTMLDivElement | null>(null);
  const ambientChartdiv3 = useRef<HTMLDivElement | null>(null);
  const motorChartdiv4 = useRef<HTMLDivElement | null>(null);
  const intakeChartdiv5 = useRef<HTMLDivElement | null>(null);
  const ambientChartdiv6 = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const root = useRef<any>(null);
  const root2 = useRef<any>(null);
  const root3 = useRef<any>(null);
  const root4 = useRef<any>(null);
  const root5 = useRef<any>(null);
  const root6 = useRef<any>(null);

  const [data, setData] = useState<
    {
      ambient: TimeseriesData | AssetTimeseriesData
      intakeTemp: TimeseriesData | AssetTimeseriesData
      motor: TimeseriesData | AssetTimeseriesData
    }
  >({
    ambient: {} as TimeseriesData | AssetTimeseriesData,
    intakeTemp: {} as TimeseriesData | AssetTimeseriesData,
    motor: {} as TimeseriesData | AssetTimeseriesData,
  });
  const [showLoading, setShowLoading] = useState(true);

  const hElement = document.getElementById('main-root');

  const userPrefrenceData = useAppSelector((state) => state.controlroom.userPreference);
  const assetInfoData = useAppSelector((state) => state.assetInfo.assetDetails);
  const tempObject = userPrefrenceData.find(item => item?.title.toLowerCase() === "Temperature".toLowerCase());
  const loading = useAppSelector((state) => state.wellControlroomCharts.loading);
  const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const detailsLoading = useAppSelector((state) => state.assetInfo.detailsLoading);

  useEffect(() => {
    if (detailsLoading && loading) {
      setShowLoading(true);
    } else {
      const timer = setTimeout(() => setShowLoading(false), 100);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const temperatureChannelData = {
    ambient: useAppSelector(getChannelDataByKey(channelIDsObj.ambient || '')),
    intakeTemp: useAppSelector(getChannelDataByKey(channelIDsObj.intakeTemp || '')),
    motor: useAppSelector(getChannelDataByKey(channelIDsObj.motor || '')),
  }

  const temperatureTimeSeriesData = {
    ambient: useAppSelector(getTimeSeriesDataByKey(tempObject?.mappings?.sle?.ambient || '')),
    intakeTemp: useAppSelector(getTimeSeriesDataByKey(tempObject?.mappings?.sle?.intakeTemp || '')),
    motor: useAppSelector(getTimeSeriesDataByKey(tempObject?.mappings?.sle?.motor || '')),
  }

  useEffect(() => {
    if (assetInfoData?.isSleAsset === true) {
      setData(temperatureTimeSeriesData)
    } else {
      setData(temperatureChannelData);
    }
  }, [JSON.stringify(temperatureChannelData), JSON.stringify(temperatureTimeSeriesData), JSON.stringify(assetInfoData), JSON.stringify(userPrefrenceData)]);

  const openModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const printWidgetAsPDF = () => {
    const roots = [root, root2, root3];
    generateGroupPDF(
      roots,
      setIsDropdownOpen,
      currentUser,
      'Temperature',
      { width: 140, hight: 150 }
    );
  }

  return (
    <>
      <div className='grid-item-text h-full graph-card well-graph-card'>
        <div className='table-header-section pie-section'>
          <div className='title'>{heading}</div>
          {tempObject?.expandable && <div className='header-icon'>
            <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
              <img src={annotation_plus} alt='Expand' className='img-border' />
            </button>
          </div>}
          {tempObject?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName ${(data?.ambient === undefined && data?.intakeTemp === undefined && data?.motor === undefined) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                {/* <button className={`btn btn-default cancelSelectorName ${(data?.ambient === undefined && data?.intakeTemp === undefined && data?.motor === undefined) ? 'btn-disabled' : ''}`}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button> */}
              </div>
            )}
          </div>}
        </div>
        <hr />
        <div id="temperature-container" className='flex flex-wrap items-center justify-center wellChartSection tempChart-container-dashboard nodata'>

          {showLoading ? (
            <div className='flex items-center justify-center w-100'>
              <Loader />
            </div>
          ) : (data?.ambient || data?.intakeTemp || data?.motor) ? (
            <>
              <TempChart chartID={'motorChartdiv1'} chartRef={motorChartdiv1} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data.motor} chartName={'Motor'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root} />
              <TempChart chartID={'intakeChartdiv2'} chartRef={intakeChartdiv2} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.intakeTemp} chartName={'Intake'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root2} />
              <TempChart chartID={'ambientChartdiv3'} chartRef={ambientChartdiv3} classValue={'w-[33.33%]'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.ambient} chartName={'Ambient'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root3} />
            </>
          ) : (
            <NoData heading='No data found' description="Please adjust your date range or filter criteria
            and try again."/>
          )}

        </div>
      </div>
      <TempModal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        heading={'Temperature'}
        loading={showLoading}
        modelWidth={'80rem'}
        modelClass={'wellChartModal temperatureChartModal'}
        innerComponent={
          <>
            {showLoading ? (
              <Loader />
            ) : (data?.ambient || data?.intakeTemp || data?.motor) ? (

              <div className='flex justify-center w-full'>
                <TempChart chartID={'motorChartdiv4'} chartRef={motorChartdiv4} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={177} clockHandName={'F'} data={data.motor} chartName={'Motor'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root4} />
                <TempChart chartID={'intakeChartdi5'} chartRef={intakeChartdiv5} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.intakeTemp} chartName={'Intake'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root5} />
                <TempChart chartID={'ambientChartdi6'} chartRef={ambientChartdiv6} classValue={'w-[33.33%] temp-chart'} minValue={50} maxValue={350} clockHandValue={220} clockHandName={"F"} data={data.ambient} chartName={'Ambient'} bandsData={bandsData} spacingValue={4} legendPos={120} root={root6} />
              </div>
            ) : (
              <NoData heading='No data found' description="Please adjust your date range or filter criteria
              and try again."/>
            )}
          </>
        }
      />
    </>
  );
};

export default TemperatureDetails;