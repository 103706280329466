import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { slugify } from '../utilities/Slugify';

interface Tab {
    label: string;
    component: React.ComponentType;
}

interface TabsProps {
    tabs: Tab[];
    value: string | undefined;
}

const InnerTabWithUrl: React.FC<TabsProps> = ({ tabs, value }) => {

    const [activeTabIndex, setActiveTabIndex] = useState<number | null>(null);
    const defaultTabIndex = 0;
    const navigate = useNavigate();

    const handleTabClick = (index: number) => {
        setActiveTabIndex(index);
    };


    useEffect(() => {
        const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
        if (currentTabFromQuery) {
            const tabIndex = tabs.findIndex((tab) => slugify(tab.label) === currentTabFromQuery);
            if (tabIndex !== -1) {
                setActiveTabIndex(tabIndex);
            } else {
                setActiveTabIndex(defaultTabIndex);
            }
        } else {
            setActiveTabIndex(defaultTabIndex);
        }
    }, [location.search, tabs]);

    useEffect(() => {

        if (activeTabIndex === null) return;

        const newTabSlug = slugify(tabs[activeTabIndex].label);
        const params = new URLSearchParams(location.search);
        const currentTabSlug = params.get('innerTab');
        // Only navigate if the tab slug in the URL is differents
        if (currentTabSlug !== newTabSlug) {
            params.set('innerTab', newTabSlug)
            if (!currentTabSlug) {
                navigate(`${location.pathname}?${params.toString()}`, { replace: true });
            } else {
                navigate(`${location.pathname}?${params.toString()}`);
            }
        }
    }, [activeTabIndex]);

    return (
        <>
            <div className='ui-button-toolbar'>
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`ui-button-toolbar-button ${index === activeTabIndex ? 'ui-button-toolbar-button-active semi-bold' : 'semi-bold'
                            }`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            <div className='tab-content'>
                {React.createElement(tabs[activeTabIndex || defaultTabIndex]?.component, {
                    value,
                } as React.Attributes)}
            </div>
        </>
    );
};

export default InnerTabWithUrl;
