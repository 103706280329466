import React from "react";

const EmptyPage = () => {
  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8 bg-darkcolor-800 container w-full max-w-full absolute left-0 right-0 top-0 bottom-0 h-full 2xl:pl-[68px] bg-darkcolor-800 cia-layout">
      <div className="text-center">
        <h1 className="mt-4 text-4xl tracking-tight text-white ">
        </h1>
      </div>
    </main>
  );
};

export default EmptyPage;
