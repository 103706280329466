export interface Setpoint {
    parameter: string;
    description: string;
    backupDate: string;
    backupValue: string;
    isSupported: boolean;
    backUpLookUpValues: LookupValue[] | null;
    device: string;
    paramStdTypeId?: string
}

interface LookupValue {
    text: string;
    value: number;
}

export interface SetpointGroup {
    setpointGroup: number;
    setpointGroupName: string;
    registerCount: number;
    setpoints: Setpoint[];
}

export interface ApiResponse {
    values: SetpointGroup[];
    id: string | null;
    dateCreated: string;
}


export enum DeviceAction {
    Read = "Read",
    Update = "Update",
}