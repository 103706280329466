import { useAppSelector } from "../../../../hooks/storeHooks"
import { allowedPocTypes, setPointParamStandardTypeId } from "../../model/ManageAssetModel"




export const useManageAsset = () => {

    const manageAssetSliceData = useAppSelector((state) => state?.manageAsset)
    const { assetDetail } = useAppSelector((state) => state?.assetDetail);
    const pocType = assetDetail?.assetDetails.pocType || ''

    const getSetpointsDisabledStatus = (groupName: keyof typeof setPointParamStandardTypeId, setpointName: string) => {
        let disabledStatus = true
        const group = setPointParamStandardTypeId[groupName]; // Get the group object
        const matchedParamStandardTypeId = (group as Record<string, string | null>)[setpointName] ?? null;
        if (matchedParamStandardTypeId) {
            const matchedSetpoint = manageAssetSliceData?.setPoints?.find((setpoint) => setpoint.paramStdTypeId === matchedParamStandardTypeId)
            if (matchedSetpoint && allowedPocTypes?.includes(pocType) && manageAssetSliceData.deviceReadStatus && !manageAssetSliceData.readDeviceLoading) {
                disabledStatus = false
            }
        }
        return disabledStatus

    }

    //to get read setpoint value
    const getSetpointValue = (groupName: keyof typeof setPointParamStandardTypeId, setpointName: string) => {
        const group = setPointParamStandardTypeId[groupName]; // Get the group object
        const matchedParamStandardTypeId = (group as Record<string, string | null>)[setpointName] ?? null;
        if (matchedParamStandardTypeId) {
            const matchedItem = manageAssetSliceData?.deviceValue?.find((item) => item.address?.toString() === matchedParamStandardTypeId)
            if (matchedItem) {
                return matchedItem.value
            }
        }

        return null

    }


    //to get setpoint item
    const getSetpointItem = (groupName: keyof typeof setPointParamStandardTypeId, setpointName: string) => {

        const group = setPointParamStandardTypeId[groupName]; // Get the group object
        const matchedParamStandardTypeId = (group as Record<string, string | null>)[setpointName] ?? null;
        if (matchedParamStandardTypeId) {
            const matchedItem = manageAssetSliceData?.setPoints?.find((item) => item.paramStdTypeId === matchedParamStandardTypeId)
            if (matchedItem)
                return matchedItem
            else return null
        }
        return null
    }




    return { getSetpointsDisabledStatus, getSetpointValue, getSetpointItem }

}