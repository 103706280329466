// EquipmentSlice.ts
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEquipment, getEquipmentDetails, getEquipmentVolume } from './EquipmentService';
import { AxiosError } from 'axios';

interface EquipmentState {
  loading: boolean;
  equipmentList: any[];  // Replace `any` with a specific type based on your data structure
  equipmentDetails: any | null;  // Replace `any` with a specific type based on your data structure
  equipmentVolumeDetails: any;
  error: string | null;
}
interface EquipmentItem {
  key: string;
  value: string;
}
const initialState: EquipmentState = {
  loading: false,

  equipmentList: [],
  equipmentDetails: null,
  equipmentVolumeDetails: null,
  error: null,
}

export const fetchEquipment = createAsyncThunk(
  'equipment/fetchEquipment',
  async ({ assetName }: { assetName: string }, { rejectWithValue }) => {
    try {
      return await getEquipment(assetName);
    } catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch equipment details';
      }
      return rejectWithValue(errMessage);
    }
  }
);

export const fetchEquipmentDetails = createAsyncThunk(
  'equipment/fetchEquipmentDetails',
  async ({ wellName, equipmentId, cancelToken }: { wellName: string, equipmentId: string, cancelToken: any }, { rejectWithValue }) => {
    try {
      return await getEquipmentDetails(wellName, equipmentId, cancelToken);
    } catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch equipment';
      }
      return rejectWithValue(errMessage);
    }
  }
);


export const fetchEquipmentVolume = createAsyncThunk(
  'equipment/fetchEquipmentVolume',
  async ({ oracleItemNumber }: { oracleItemNumber: string }, { rejectWithValue }) => {
    try {
      return await getEquipmentVolume(oracleItemNumber);
    } catch (error) {
      let errMessage = 'Unknown error occurred';
      if (error instanceof AxiosError && error.response) {
        errMessage = error.response.data.message || 'Failed to fetch equipment';
      }
      return rejectWithValue(errMessage);
    }
  }
);
const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    clearEquipmentDetails: (state) => {
      state.equipmentDetails = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEquipment.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEquipment.fulfilled, (state, action: PayloadAction<EquipmentItem[]>) => {
        state.equipmentList = action.payload;
        state.loading = false;
      })
      .addCase(fetchEquipment.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
      .addCase(fetchEquipmentDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEquipmentDetails.fulfilled, (state, action) => {
        state.equipmentDetails = action.payload;
        state.loading = false;
      })
      .addCase(fetchEquipmentDetails.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
        state.equipmentDetails = [];
      })
      .addCase(fetchEquipmentVolume.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEquipmentVolume.fulfilled, (state, action) => {
        state.equipmentVolumeDetails = action.payload;
        state.loading = false;
      })
      .addCase(fetchEquipmentVolume.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });

  }
});

export default equipmentSlice.reducer;
export const { clearEquipmentDetails } = equipmentSlice.actions;