
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { HistoryDetailsProps, SparkLineFilterProps, TimeSeriesChannelDataProps } from '../HistoryDetails';
import { fetchAssetTimeseriesChannelsData, fetchMasterVariablesByWellname, fetchSparklineUserPreference, updateHistorySparklineDataSet, updateSparklineUserPreferenceData, updateSparklineWidgetLayout } from '../HistorySlice';
import '../History.scss'
import { defaultStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import calenderIcon from '../../../../../images/calendar-icon.svg';
import filterIcon from '../../../../../images/filter-icon.svg';
import downIcon from '../../../../../images/chevron-down.svg';
import searchIcon from '../../../../../images/search-icon.svg';
import { addDays } from 'date-fns';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { fetchTrendsChartFilterData, LayoutDataItem, UserPreferenceDataItem } from '../../trends/TrendsSlice';
import { ToastContainer } from 'react-toastify';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';
import { showToaster } from '../../../../dashboard/components/asset-location/AssetList';
import CardsDesign from './CardsDesign';
import NoDataCardsDesign from './NoDataCardsDesign';
import axios from 'axios';
import { GetAggregation } from '../../../../../utilities/CommonFunctions';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { debounce } from 'lodash';


interface FilterValue {
  paramTypeId: string,
  channelId: string
}
type HistoriesData = HistoryDetailsProps[]
const ResponsiveGridLayout = WidthProvider(Responsive);


const SparklineChart = () => {
  const dispatch = useAppDispatch();
  const { histories, sparkLineFilterData, initialDataLoading, sparkLineFilterLoading, historySparklineDataSet, sparklineUserPreferences } = useAppSelector((state) => state.history);
  const [preferenceItems, setPreferenceItems] = useState<UserPreferenceDataItem[]>(sparklineUserPreferences);

  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const [historiesData, setHistoriesData] = useState<HistoriesData[] | undefined>([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [noData, setNoData] = useState<any>([]);
  const [searchInputVal, setSearchInputVal] = useState<string>('');
  const { trendsFilterData } = useAppSelector((state) => state.trends);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: addDays(new Date(), -6),
    endDate: new Date(),
    key: 'selection',
  });
  const [filterUomData, setFilterUomData] = useState<any>([]);
  const [filteredValues, setFilteredValues] = useState<FilterValue[]>([]);
  const [prevFilteredValues, setPrevFilteredValues] = useState<FilterValue[]>([]);
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);
  const [filterExpand, setFilterExpand] = useState<string>('');
  const assetControlData = useAppSelector((state) => state?.assetControl)
  const timeSeriesCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);
  const aggregateValues = useAppSelector((state) => state?.assetDetail?.xspocAggregateValue)
  const currentAggregation = useRef('')

  const loading = initialDataLoading || sparkLineFilterLoading
  const [loadingIcon, setLoadingIcon] = useState(true)
  const filteredChannelId = filteredValues?.map(item => item.channelId)
  const prevFilteredChannelId = prevFilteredValues?.map(item => item.channelId)
  const isFilterChanged = prevFilteredChannelId?.some((item) => !filteredChannelId?.includes(item)) || filteredChannelId?.some((item) => !prevFilteredChannelId?.includes(item));
  const [layout, setLayout] = useState<LayoutDataItem[]>([]);
  const [layoutOnDragStart, setLayoutOnDragStart] = useState<LayoutDataItem[]>([]);


  const filterNoData = () => {
    return sparkLineFilterData?.filter((item: any) => {
      if (item?.unitOfMeasure === 'None') return false;
      return filteredValues?.some(filter => {
        return filter?.channelId === item?.channelId
      })
    })
  }
  const noDataPrepare = () => {
    const filteredNoData = filterNoData();
    const groupedData = prepareData(filteredNoData);
    setNoData(groupedData ?? []);
  }




  const prepareData = (response: SparkLineFilterProps[] | TimeSeriesChannelDataProps[]) => {
    const data: any = {};
    response?.forEach((obj) => {
      if (data[obj?.unitOfMeasure]) {
        data[obj?.unitOfMeasure].push(obj);
      } else {
        if (obj?.unitOfMeasure?.toLowerCase() !== 'none' && obj?.unitOfMeasure) {
          data[obj?.unitOfMeasure] = [obj];
        }
      }
    });

    const finalData = [];

    for (const x in data) {
      finalData.push(data[x]);
    }

    return finalData;
  };
  const assetRef = useRef<string | undefined>('')

  useEffect(() => {
    if (assetRef.current !== selectedAsset.selectedAssetName) {
      setFilteredValues([])
      setPrevFilteredValues([])
      const wellName = selectedAsset?.selectedAssetName ?? '';
      dispatch(fetchMasterVariablesByWellname({ wellName: wellName }))

      if (!trendsFilterData) {
        dispatch(fetchTrendsChartFilterData())
      }
      assetRef.current = selectedAsset?.selectedAssetName
    }
  }, [selectedAsset.selectedAssetName])

  useEffect(() => {
    if (sparkLineFilterData?.length !== 0 && trendsFilterData?.length !== 0) {
      const data = prepareData(sparkLineFilterData);
      setFilterUomData(data)

      const filteredArray: FilterValue[] = []
      trendsFilterData?.map((filterData: any) => {
        const matchingOne = sparkLineFilterData?.find((item: any) => {
          return (filterData?.paramStandardType === item?.paramStandardType?.legacyId.ParamStandardTypesId && item.unitOfMeasure.toLowerCase() !== 'none')
        })
        if (matchingOne) {
          filteredArray.push({
            paramTypeId: matchingOne.paramStandardType?.legacyId.ParamStandardTypesId,
            channelId: matchingOne?.channelId
          })
        }
      })

      setFilteredValues(filteredArray)
      setPrevFilteredValues(filteredArray)
      const channelIds = filteredArray.map(item => item.channelId);
      setSelectedChannelIds(channelIds)

    } else {
      setFilterUomData([])
      setFilteredValues([])
      setPrevFilteredValues([])
      setSelectedChannelIds([])
    }

  }, [sparkLineFilterData])

  useEffect(() => {
    if (historySparklineDataSet?.length > 1) {
      dispatch(updateHistorySparklineDataSet([{ ...historySparklineDataSet[0] }]))
    }
  }, [prevFilteredValues])

  useEffect(() => {
    if (historySparklineDataSet?.length > 0) {
      dispatch(updateHistorySparklineDataSet([]))
    }

    if (selectedChannelIds?.length !== 0) {

      setLoadingIcon(true)
      if (timeSeriesCancelTokenSource.current) {
        timeSeriesCancelTokenSource.current.cancel('canceled');
      }

      timeSeriesCancelTokenSource.current = axios.CancelToken.source();

      const wellName = selectedAsset?.selectedAssetName ?? '';
      const startDate = new Date(selectedDateRange.startDate.getFullYear(), selectedDateRange.startDate.getMonth(), selectedDateRange.startDate.getDate(), 0, 0, 0, 0).toISOString();
      const endDate = new Date(selectedDateRange.endDate.getFullYear(), selectedDateRange.endDate.getMonth(), selectedDateRange.endDate.getDate(), 23, 59, 59, 999).toISOString();
      const aggregateValue = GetAggregation(new Date(startDate), new Date(endDate), aggregateValues)

      currentAggregation.current = aggregateValue
      dispatch(fetchAssetTimeseriesChannelsData({ wellName: wellName, startDate: startDate, endDate: endDate, channelIds: selectedChannelIds?.toString(), aggregate: aggregateValue, cancelToken: timeSeriesCancelTokenSource.current.token, granularData: false }))
        .finally(() => {
          setLoadingIcon(false)
        })

    }

    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))

  }, [selectedChannelIds, assetControlData?.assetControlScanStatus === true])
  useEffect(() => {
    if (filteredValues) {
      const channelIds = filteredValues.map(item => item.channelId);
      setSelectedChannelIds(channelIds)
    }
  }, [selectedDateRange])
  useEffect(() => {
    if (histories && histories?.length !== 0) {

      const timeSeriesArray: TimeSeriesChannelDataProps[] = []
      histories?.map((historyData: HistoryDetailsProps) => {
        const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === historyData?.trendName && filterData?.unitOfMeasure.toLowerCase() !== 'none')

        if (matchedData) {
          const matchedDefaultTrend = trendsFilterData?.find((item: any) => matchedData.paramStandardType?.legacyId.ParamStandardTypesId === item.paramStandardType)
          const timeSeriesData = { ...historyData, channelId: matchedData.channelId, trendName: matchedDefaultTrend ? matchedDefaultTrend.name : matchedData.description, unitOfMeasure: matchedData.unitOfMeasure, short_UnitOfMeasure: matchedData.short_UnitOfMeasure }
          timeSeriesArray.push(timeSeriesData)
        }
      })
      const data = prepareData(timeSeriesArray) ?? []
      setHistoriesData(data)
    } else {
      noDataPrepare()
      setHistoriesData([])
    }
  }, [histories, loading === false])

  useEffect(() => {
    if (!sparklineUserPreferences?.length)
      dispatch(fetchSparklineUserPreference('asset-detail-sparklines'))
  }, [])
  useEffect(() => {
    if (sparklineUserPreferences && sparklineUserPreferences.length > 0) {
      setPreferenceItems(sparklineUserPreferences);
    }
  }, [JSON.stringify(sparklineUserPreferences)]);


  useEffect(() => {
    let filteredUserPreferenceItems: UserPreferenceDataItem[] = []
    if (historiesData?.length) {
      filteredUserPreferenceItems = preferenceItems
        .filter(preference =>
          historiesData?.some((data: any) => {
            return preference.title?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase()
          })
        )
    } else if (noData?.length) {
      filteredUserPreferenceItems = preferenceItems
        .filter(preference =>
          noData?.some((data: any) =>
            preference.title?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase()
          )
        )
    }


    const layout = filteredUserPreferenceItems?.map(preference => {
      return {
        i: preference.title,
        x: preference.positions?.lg?.x,
        y: preference.positions?.lg?.y,
        w: 12,
        h: 3,
      }
    }).sort((a, b) => a.y - b.y);// Extract position from filtered results


    setLayout(layout)
  }, [JSON.stringify(preferenceItems), JSON.stringify(historiesData), JSON.stringify(noData)])

  const handleSlideOutClick = (e: any) => {
    if (e.target.id === 'range-calendar-input') return;
    if (showCalendar) {
      setShowCalendar(!showCalendar);
    }
  };

  const handleFilterOutClick = (e: any) => {

    if (e.target.id === 'delete-btn') {
      return
    }
    if (showFilterPopup) {
      setShowFilterPopup(false);
      setFilteredValues([...prevFilteredValues])
      setFilterExpand('')
      setSearchInputVal('')
      const uomData = prepareData(sparkLineFilterData) ?? [];
      setFilterUomData(uomData)
    }
  };

  const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
  const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });

  const handleClearButton = () => {
    setFilteredValues([])
    setSearchInputVal('')
    const uomData = prepareData(sparkLineFilterData) ?? [];
    setFilterUomData(uomData)
  };

  const handleApplyButton = async () => {
    if (filteredValues?.length === 0) {
      showToaster('Please select atleast one filter', 'error');
      return;
    }
    else {
      const channelIds = filteredValues.map(item => item.channelId);

      if (channelIds?.every(value => selectedChannelIds?.includes(value))) {
        if (histories && histories?.length !== 0) {

          const timeSeriesArray: TimeSeriesChannelDataProps[] = []
          histories?.map((historyData: HistoryDetailsProps) => {
            const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === historyData?.trendName && filterData?.unitOfMeasure.toLowerCase() !== 'none' && channelIds.includes(filterData?.channelId))
            if (matchedData) {
              const matchedDefaultTrend = trendsFilterData?.find((item: any) => matchedData.paramStandardType?.legacyId.ParamStandardTypesId === item.paramStandardType)
              const timeSeriesData = { ...historyData, channelId: matchedData.channelId, trendName: matchedDefaultTrend ? matchedDefaultTrend.name : matchedData.description, unitOfMeasure: matchedData.unitOfMeasure, short_UnitOfMeasure: matchedData.short_UnitOfMeasure }
              timeSeriesArray.push(timeSeriesData)
            }
          })
          const data = prepareData(timeSeriesArray) ?? []
          setHistoriesData(data)
        } else {
          setHistoriesData([])
          noDataPrepare()
        }
      } else {
        setSelectedChannelIds(channelIds)
      }


      setPrevFilteredValues([...filteredValues])
    }
    setShowFilterPopup(false)
    setFilterExpand('')
  };


  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const matchedFilter = filteredValues.find(item => item.channelId === value)
    if (matchedFilter) {
      const updatedFilter = filteredValues.filter(item => item.channelId !== value)
      setFilteredValues(updatedFilter)
    } else {
      const matchedData = sparkLineFilterData?.find((filterData: any) => filterData?.channelId === value && filterData?.unitOfMeasure.toLowerCase() !== 'none')
      const updatedFilter = [...filteredValues, { paramTypeId: matchedData?.paramStandardType?.legacyId.ParamStandardTypesId, channelId: matchedData?.channelId }]
      setFilteredValues(updatedFilter)
    }
  };
  const handleSearch = (e: any) => {
    const val = e.target.value;

    setSearchInputVal(val);

    if (val === '') {
      const uomData = prepareData(sparkLineFilterData) ?? [];
      setFilterUomData(uomData)
    } else {
      const filteredSparkLineFilterData = sparkLineFilterData?.filter((item: any) => {
        return (item?.unitOfMeasure?.toLowerCase().includes(val?.toLowerCase()) || item?.description?.toLowerCase().includes(val?.toLowerCase()))
      }) ?? []
      const prepearedUom = filteredSparkLineFilterData?.length ? prepareData(filteredSparkLineFilterData) : [];
      setFilterUomData(prepearedUom)
    }
  };

  // Inside your component
  const debouncedLayoutChangeRef = useRef(
    debounce((mergedItems: UserPreferenceDataItem[]) => {
      dispatch(updateSparklineWidgetLayout({ dashboard: "asset-detail-sparklines", data: mergedItems })).then((res) => {
        if (updateSparklineWidgetLayout.fulfilled.match(res)) {
          showToaster('Layout updated successfully', 'success');
        } else {
          showToaster('Failed to update layout', 'error');
        }
      });
    }, 5000)
  );

  const onLayoutChange = (updatedLayout: LayoutDataItem[]) => {
    const mergedItems = sparklineUserPreferences && sparklineUserPreferences?.map(item => {
      const updatedItem = updatedLayout?.find(layout => layout.i === item.key);
      if (updatedItem) {
        return {
          ...item,
          positions: {
            ...item.positions,
            lg: {
              x: updatedItem.x,
              y: updatedItem.y,
              w: updatedItem.w,
              h: updatedItem.h,
              minH: updatedItem.minH ?? 3,
              minW: updatedItem.minW ?? 12
            },
          },
        };
      }
      return item;
    });

    if (JSON.stringify(updatedLayout) !== JSON.stringify(layoutOnDragStart)) {
      setPreferenceItems(mergedItems); // Update local state with new items
      setLayout(updatedLayout); // Update layout
      dispatch(updateSparklineUserPreferenceData(mergedItems))
      debouncedLayoutChangeRef.current(mergedItems); // Call debounced API update
    }
  };


  const renderdateRange = () => {
    return (
      <div className='history-container-date-range'>
        <div className='input-group hideheader'>
          <img src={calenderIcon} alt='calednar-icon' className='absolute top-3 left-3' />
          <input 
            id='range-calendar-input'
            className='date-range-input'
            value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
            onClick={() => setShowCalendar(!showCalendar)}
          />
        </div>
        <div ref={ref}>
          {showCalendar && (
            <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />)}
        </div>

        <div style={{ position: 'relative', zIndex: '98' }}>
          <div
            id='filter-btn'
            style={{
              border: showFilterPopup
                ? '1px solid var(--Dark-mode-400, #4A5463)'
                : '1px solid var(--Dark-mode-400, #4A5463)',
              background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (isFilterChanged) {
                setFilteredValues([...prevFilteredValues]);
              }
              setShowFilterPopup(!showFilterPopup);
              if (showFilterPopup)
                setFilterExpand('')
            }}         >
            <img src={filterIcon} alt='filterIcon' />
            Filter {`(${filteredValues?.length})`}
            <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
          </div>

          {showFilterPopup && (
            <div className='histry-filter-modal-container' ref={filterRef}>
              <div className='trends-filter-modal-header'>Edit trend group</div>

              <div className='trends-filter-search history-search relative'>
                <img src={searchIcon} placeholder='Search for trend' alt='search' className='history-search-icon' />
                <input
                  type='text'
                  value={searchInputVal}
                  placeholder='Search for trend group'
                  onChange={handleSearch}
                  className='history-filter-search'
                />
              </div>

              <div className='history-filter-modal-body' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                <div className='history-body-container'>
                  {filterUomData?.map((history: any) => {
                    return (
                      <>
                        <div className='history-body-trends' style={{ cursor: 'pointer' }} onClick={() => history[0]?.unitOfMeasure === filterExpand ? setFilterExpand('') : setFilterExpand(history[0]?.unitOfMeasure)}>
                          {history[0]?.unitOfMeasure}
                          <img src={downIcon} className={history[0]?.unitOfMeasure !== filterExpand ? '' : 'rotate-180'} alt='upIcon' />
                        </div>
                        {
                          filterExpand === history[0]?.unitOfMeasure && history.map((item: any) => {
                            return (
                              <div className='history-body-trends' style={{ flexDirection: 'column', alignItems: 'start' }}>
                                <div className='checkbox-main-container'>
                                  <label className='checkbox-container'>
                                    <input
                                      type='checkbox'
                                      id='showCheckbox'
                                      checked={filteredValues?.find((filterItem: any) => filterItem.channelId === item?.channelId) !== undefined}
                                      onChange={handleCheckbox}
                                      value={item?.channelId}
                                    />
                                    <span className='checkbox-checkmark'></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item?.description}&nbsp;
                                  </label>
                                </div>
                              </div>
                            )
                          })
                        }


                      </>
                    );
                  })}
                </div>

              </div>
              <div className='trends-filter-modal-footer'>
                <button
                  className={'footer-btn'}
                  onClick={handleClearButton}
                >
                  Clear
                </button>
                <button
                  className={'footer-btn'}
                  onClick={handleApplyButton}
                >
                  Apply
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };



  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={3000}
      />
      <div
        className='trends-container'
        style={{ position: 'relative', minHeight: showCalendar || showFilterPopup ? '600px' : '300px', maxHeight: 'auto !important' }}
      >
        {renderdateRange()}
        <div style={{ width: '100%' }}>
          {(loading || loadingIcon) ? (
            <div className='spark-line-loader flex items-center justify-content loader-margin'>
              <Loader />
            </div>
          ) :
            <>
              <ResponsiveGridLayout
                className="layout"
                layouts={{ lg: layout || [] }} // Define layout for large screens
                breakpoints={{ lg: 1200, md: 996 }}
                cols={{ lg: 12, md: 12 }}
                rowHeight={100}
                isResizable={false}
                useCSSTransforms={false}
                margin={[0, 2]} // Disable default margins
                containerPadding={[0, -6]}
                onDragStop={onLayoutChange}
                onDragStart={(updatedlayout: LayoutDataItem[]) => setLayoutOnDragStart(updatedlayout)}
              >
                {
                  layout?.map((item, index) => {
                    if (historiesData?.length) {
                      const matchedItem = historiesData?.find((data) =>
                        item.i?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase())
                      if (matchedItem)
                        return (
                          <div key={item.i} style={{ width: '100% !important' }}>
                            <CardsDesign
                              key={`${matchedItem[0]?.date}-index`}
                              trend={matchedItem}
                              index={index}
                              selectedDate={{ startDate: selectedDateRange?.startDate, endDate: selectedDateRange?.endDate }}
                              currentAggregateValue={currentAggregation.current}
                              filteredValues={selectedChannelIds} />
                          </div>)
                    } else if (noData?.length) {
                      const matchedItem = noData?.find((data: any) =>
                        item.i?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase())
                      if (matchedItem)
                        return (
                          <div key={item.i} style={{ width: '100% !important' }}>
                            <NoDataCardsDesign
                              key={`${matchedItem[0]?.date}-index`}
                              trend={matchedItem} />
                          </div>)
                    }
                  })

                }
              </ResponsiveGridLayout>
            </>
          }
        </div>
      </div>
    </>
  );
};

export default SparklineChart;
