import { apiInstance } from '../../../../../config/HttpCommon';

export const fetchTicketTable = async (
  PageSize: number,
  PageNumber: number,
  Tags?: string,
  Assignee?: string,
  Status?: string,
  Operators?: string,
  SearchQuery?: string,
  StartDate?: string,
  EndDate?: string,
  signal: AbortSignal | undefined = undefined


) => {
  try {
    const response = await apiInstance.get(`/v2/ticket`, {
      params: {
        PageSize: PageSize,
        PageNumber: PageNumber,
        Assignee: Assignee,
        Tags: Tags,
        Status: Status,
        Operators: Operators,
        SearchQuery: SearchQuery,
        StartDate: StartDate,
        EndDate: EndDate
      },
      signal
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};


export const fetchTicketTags = async (pageNumber: number, pageSize: number, SearchQuery?: string) => {
  try {
    const response = await apiInstance.get('/v2/ticket/tags', {
      params: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SearchQuery: SearchQuery
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};


export const fetchTicketStatus = async () => {
  try {
    const response = await apiInstance.get('/v2/ticket/statuses');
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};

export const fetchOperators = async () => {
  try {
    const response = await apiInstance.get('/v2/ticket/operators');
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};


export const fetchTicketByIdReq = async (ticketId: number) => {
  try {
    const response = await apiInstance.get(`/v2/ticket/${ticketId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};

export const fetchTicketList = async (
  AssetId: string,
  PageSize: number,
  PageNumber: number,
) => {
  try {
    const response = await apiInstance.get(`/v2/tickets/tickets`, {
      params: {
        // AssetId: AssetId,
        PageSize: PageSize,
        PageNumber: PageNumber,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket table data:', error);
    throw error;
  }
};

export const closeTickets = async (ticketId: number[]) => {
  try {
    const response = await apiInstance.put(`/v2/ticket/bulk-close`, {
      ticketIds: ticketId ? [...ticketId] : []
    });
    return response.data;
  } catch (error) {
    console.error('Error closing ticket:', error);
    throw error;
  }
};

export const downloadTickets = async (ticketId: number[]) => {
  try {
    const response = await apiInstance.post(
      `/v2/ticket/bulk-download`,
      { ticketIds: ticketId ? [...ticketId] : [] },
      { responseType: 'blob' }
    );

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Create a download URL
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'tickets.csv';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    return response.data;
  } catch (error) {
    console.error('Error downloading ticket:', error);
    throw error;
  }
};

export const closeTicket = async (ticketId: number) => {
  try {
    const response = await apiInstance.post(`/v2/ticket/${ticketId}/close`);
    return response.data;
  } catch (error) {
    console.error('Error closing ticket:', error);
    throw error;
  }
};

export const fetchTicketUsers = async (SearchQuery?: string, PageNumber?: number, PageSize?: number, signal?: AbortSignal) => {
  try {
    const response = await apiInstance.get(`/v2/ticket/users?SearchQuery=${SearchQuery}&PageNumber=${PageNumber}&PageSize=${PageSize}`, { signal });
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const fetchTicketAssigneeList = async (SearchQuery: string, PageNumber: number, PageSize: number, signal?: AbortSignal) => {
  try {
    const response = await apiInstance.get(`/v2/ticket/assignees`,
      {
        params: {
          SearchQuery: SearchQuery,
          PageSize: PageSize,
          PageNumber: PageNumber,
        },
        signal
      });
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const fetchTicketAssignee = async (page: number) => {
  try {
    const response = await apiInstance.get(`/v2/ticket/users?page=${page}&roles=agent&roles=admin`);
    return response.data;
  } catch (error) {
    console.error('Error fetching assignee data:', error);
    throw error;
  }
};

export const fetchTicketForms = async () => {
  try {
    const response = await apiInstance.get(`/v2/ticket/forms`);
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket forms data:', error);
    throw error;
  }
};


export const reassignTicketReq = async (ticketId: number, assigneeId: number) => {
  try {
    const response = await apiInstance.put(`/v2/ticket/ticket-reassign?TicketId=${ticketId}&newAssigneeId=${assigneeId}`);
    return response.data;
  } catch (error) {
    console.error('Error reassigning ticket:', error);
    throw error;
  }
};

export const bulkReassignTicketReq = async (ticketIds: number[], assigneeId: number) => {
  try {
    const response = await apiInstance.post(`/v2/ticket/bulk-reassign`, {
      ticketIds: ticketIds,
      newAssigneeId: assigneeId
    });
    return response.data;
  } catch (error) {
    console.error('Error reassigning ticket:', error);
    throw error;
  }
};

export const fetchCustomFields = async (fieldIds: number[]) => {
  try {
    const response = await apiInstance.post(`/v2/ticket/custom-fields?page=1`, {
      fieldIds: fieldIds
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching custom fields:', error);
    throw error;
  }
}

export const ticketUploadFileReq = async (fileName: string, file: any) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await apiInstance.post(`/v2/ticket/upload-file?fileName=${fileName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const createTicketReq = async (ticket: any) => {
  try {
    const response = await apiInstance.post(`/v2/ticket/create`, ticket);
    return response.data;
  } catch (error) {
    console.error('Error creating ticket:', error);
    throw error;
  }
};

export const updateTicketReq = async (ticketId: number, ticket: any) => {
  try {
    const response = await apiInstance.post(`/v2/ticket/update/${ticketId}`, ticket);
    return response.data;
  } catch (error) {
    console.error('Error updating ticket:', error);
    throw error;
  }
}

export const fetchCommentsByTicketIdReq = async (ticketId: number) => {
  try {
    const response = await apiInstance.get(`/v2/ticket/comment/${ticketId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};