import React from 'react'
import refreshIcon from '../../../../images/refresh-cw-02.svg';
import './ControlRoomHeader.scss'

type ControlRoomHeaderProps = {
    controlRoomRefreshHandler: () => void;
}

function ControlRoomHeader({ controlRoomRefreshHandler }: ControlRoomHeaderProps) {
    return (
        <div className='control-room-header'>
            <h4 className='control-room-header-title'>Control room</h4>
            <div className="refresh-icon cursor-pointer" onClick={controlRoomRefreshHandler}>
                <img src={refreshIcon} alt='refresh-icon' />
            </div>
        </div>
    )
}

export default ControlRoomHeader