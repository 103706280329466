import React from 'react'
import { useManageAsset } from '../hook/ManageAssetHook'
import { RockingStartVariable, setPointParamStandardTypeId } from '../../model/ManageAssetModel'
import SetpointBackupValue from '../SetpointBackupValue'
// import fastForward from '../../../../images/fast-forward.svg'
// import fastBackward from '../../../../images/fast-backward.svg'

import fastForwardGray from '../../../../images/fast-forward-gray.svg'
import fastBackwardGray from '../../../../images/fast-backward-gray.svg'

interface RockingStartProps {
    groupName: keyof typeof setPointParamStandardTypeId
}


const RockingStart: React.FC<RockingStartProps> = ({ groupName }) => {

    const { getSetpointItem } = useManageAsset()

    const getSetpointInput = (placeholder: string, groupName: keyof typeof setPointParamStandardTypeId, setpointName: string, inputDisableStatus = false) => {

        const setpointItem = getSetpointItem(groupName, setpointName);

        return setpointItem ? (
            <SetpointBackupValue setPointItem={setpointItem} groupName={groupName} setpointName={setpointName} inputDisabledStatus={inputDisableStatus} />
        ) : (
            <input
                type='text'
                className='time-input-field disabled'
                placeholder={placeholder}
            />
        );
    }
    return (
        <div className='rocking-start-container'>
            <div className='flex flex-row space-x-4'>
                <div className='manage-asset-form-control w-1/2'>
                    <label className='label'>Rocking frequency (Hz)</label>
                    {
                        getSetpointInput('0', groupName, RockingStartVariable.RockingFrequency)
                    }
                </div>
                <div className='manage-asset-form-control w-1/2'>
                    <label className='label'>Direction time [s]</label>
                    {
                        getSetpointInput('0', groupName, RockingStartVariable.DirectionTime)
                    }
                </div>
            </div>

            <div className='flex flex-row space-x-4 mt-4'>
                <div className='manage-asset-form-control w-1/3'>
                    <label className='label'>Cycles allowed</label>
                    {
                        getSetpointInput('0', groupName, RockingStartVariable.CyclesAllowed)
                    }
                </div>
                <div className='manage-asset-form-control w-1/3'>
                    <label className='label'>Cycles remaining</label>
                    {
                        getSetpointInput('0', groupName, RockingStartVariable.CyclesRemaining, true)
                    }
                </div>
                <div className='manage-asset-form-control w-1/3'>
                    <label className='label'>Reset delay (s)</label>
                    {
                        getSetpointInput('0', groupName, RockingStartVariable.ResetDelay, true)
                    }
                </div>
            </div>
            <div className='flex flex-row space-x-4 mt-4 rocking-start-action-div justify-end'>
                <button className='rocking-start-action-btn disabled'>
                    <img src={fastBackwardGray} />Reverse
                </button>
                <button className='rocking-start-action-btn disabled'>
                    Forward<img src={fastForwardGray} />
                </button>
            </div>
        </div>
    )
}

export default RockingStart