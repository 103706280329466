import React, { useEffect, useState } from 'react';
// import '../user-profile/User.scss';
import '../user/User.scss';
import { useAppSelector } from '../../../hooks/storeHooks';
import Loader from '../../common/page-loader/ComponentLoader';
import { capitalizeFirstInWord } from '../../../utilities/CommonFunctions';
import { slugify } from '../../../utilities/Slugify';
import { useNavigate } from 'react-router-dom';

interface SearchTerm {
  searchTerm: string;
  selectedOperators: string[];
  setSelectedUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

const UserList: React.FC<SearchTerm> = ({ searchTerm, selectedOperators, setSelectedUserId }) => {
  const users = useAppSelector((state) => state.user.users);
  const loading = useAppSelector((state) => state.user.usersDataloading);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const navigate = useNavigate()

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('user');
    if (currentTabFromQuery) {
      const user = users?.find((user) => slugify(user.id) === currentTabFromQuery);
      if (user) {
        setSelectedUser(user.id)
        setSelectedUserId(user.id);
      } else {
        setSelectedUser(null)
        setSelectedUserId(null)
      }
    } else {
      setSelectedUser(null)
      setSelectedUserId(null)
    }
  }, [location.search, users]);

  useEffect(() => {
    if (selectedUser === null) return;

    const user = users?.find((tab) => (tab.id) === (selectedUser));
    if (!user) return;
    const newTabSlug = slugify(user?.id);
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('user');
    // Only navigate if the tab slug in the URL is differents
    if (newTabSlug && currentTabSlug !== newTabSlug) {
      params.set('user', newTabSlug)
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [selectedUser]);

  const handleUserClick = (userId: string) => {
    if (userId !== searchTerm) {
      setSelectedUserId(userId);
      setSelectedUser(userId);
    }
  };

  const getFilteredUsers = () => {
    const combinedUsers = users?.length ? users : [];
    return combinedUsers.filter((user: any) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const matchesSearchTerm =
        fullName.includes(searchTerm.toLowerCase()) ||
        user.firstName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        user.lastName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        user.mobileNumber?.includes(searchTerm) ||
        user.company?.toLowerCase()?.includes(searchTerm.toLowerCase());

      const matchesSelectedOperators = selectedOperators.length === 0 || selectedOperators.includes(user.company);

      return matchesSearchTerm && matchesSelectedOperators;
    });
  };

  const filteredUsers = getFilteredUsers();

  return (
    <>
      <div className='user-checklist'>
        <ul>
          {loading ? (
            <div className='flex items-center loader-margin'>
              <Loader />
            </div>
          ) : filteredUsers.length ? (
            filteredUsers.map((user: any, index: any) => (
              <li
                className={`${user.id === selectedUser ? 'active-list' : ''}`}
                key={index}
                onClick={() => handleUserClick(user.id)}
              // onMouseOver={() => setSelectedUser(user.id)}
              >
                <div className='info'>
                  <div className='namedata'>{user.name && capitalizeFirstInWord(user.name)}</div>
                  <div className='companyname'>@{user.company}</div>
                </div>
              </li>
            ))
          ) : (
            <li>
              <div className='notfound'>No users found.</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
export default UserList;
