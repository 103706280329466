import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import { fetchSleTimeSeriesData, fetchSleVariables, updateSleTrendTimeSeriesDataSet, SleTimeSeriesData, SleVariables } from '../../sle-time-series/SleSlice';
import { addDays } from 'date-fns';
import { defaultStaticRanges, defineds, formateDate } from '../../../../common/DateRangeSelector/DefaultRanges';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';
import { HistoryDetailsProps } from '../HistoryDetails';
import SleCardsDesign from './SleCardsDesign';
import NoDataCardsDesign from './NoDataCardsDesign';
import Loader from '../../../../common/page-loader/ComponentLoader';
import { ToastContainer } from 'react-toastify';
import calenderIcon from '../../../../../images/calendar-icon.svg';
import filterIcon from '../../../../../images/filter-icon.svg';
import downIcon from '../../../../../images/chevron-down.svg';
import searchIcon from '../../../../../images/search-icon.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import DateRangeSelector from '../../../../common/DateRangeSelector/DateRangeSelector';
import { showToaster } from '../../../../dashboard/components/asset-location/AssetList';
import axios from 'axios';
import { GetAggregation } from '../../../../../utilities/CommonFunctions';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { LayoutDataItem, UserPreferenceDataItem } from '../../trends/TrendsSlice';
import { fetchSparklineUserPreference, updateSparklineUserPreferenceData, updateSparklineWidgetLayout } from '../HistorySlice';
import { debounce } from 'lodash';

type HistoriesData = HistoryDetailsProps[]

const ResponsiveGridLayout = WidthProvider(Responsive);

const SleSparklineChart = () => {
    const sleVariable = useAppSelector((state) => state.sleTimeSeries.variable)
    const selectedAsset = useAppSelector((state) => state.assetGroups);
    const dispatch = useAppDispatch()
    const { sleTimeSeriesDataSet, sleTimeSeriesData, initialDataLoading } = useAppSelector((state) => state.sleTimeSeries);
    const { sparklineUserPreferences } = useAppSelector((state) => state.history);
    const [preferenceItems, setPreferenceItems] = useState<UserPreferenceDataItem[]>(sparklineUserPreferences);
    const [filteredValues, setFilteredValues] = useState<SleVariables[]>([])
    const [prevFilteredValues, setPrevFilteredValues] = useState<SleVariables[]>([])
    const [selectedVariables, setSelectedVariables] = useState<string[]>([])
    const [filterUomData, setFilterUomData] = useState<any>([]);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: addDays(new Date(), -6),
        endDate: new Date(),
        key: 'selection',
    });
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const [historiesData, setHistoriesData] = useState<HistoriesData[]>([]);
    const [noData, setNoData] = useState<any>([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [filterExpand, setFilterExpand] = useState<string>('');
    const [searchInputVal, setSearchInputVal] = useState<string>('');
    const assetRef = useRef<string | undefined>('')

    const filteredVariable = filteredValues?.map(item => item.variable)
    const prevFilteredVariable = prevFilteredValues?.map(item => item.variable)
    const isFilterChanged = prevFilteredVariable?.some((item) => !filteredVariable?.includes(item)) || filteredVariable?.some((item) => !prevFilteredVariable?.includes(item));

    const sleCancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

    const aggregateValues = useAppSelector((state) => state?.assetDetail?.aggregateValue)
    const [loadingIcon, setLoadingIcon] = useState(true)

    const currentAggregation = useRef('')
    const [layout, setLayout] = useState<LayoutDataItem[]>([]);
    const [layoutOnDragStart, setLayoutOnDragStart] = useState<LayoutDataItem[]>([]);

    useEffect(() => {
        if (filteredValues) {
            const variables = filteredValues.map(item => item.variable);
            setSelectedVariables(variables)
        }
    }, [selectedDateRange])

    useEffect(() => {
        const selectedAssetName = selectedAsset?.selectedAssetName
        if (!selectedAssetName) return
        if (assetRef.current !== selectedAssetName) {
            setFilteredValues([])
            setPrevFilteredValues([])
            dispatch(fetchSleVariables(selectedAssetName))
            assetRef.current = selectedAssetName
        }
    }, [selectedAsset?.selectedAssetName])

    const prepareData = (response: SleTimeSeriesData[] | SleVariables[]) => {
        const data: any = {};
        response?.forEach((obj) => {
            if (data[obj?.unitOfMeasure]) {
                data[obj?.unitOfMeasure].push(obj);
            } else {
                if (obj?.unitOfMeasure?.toLowerCase() !== 'none' && obj?.unitOfMeasure) {
                    data[obj?.unitOfMeasure] = [obj];
                }
            }
        });

        const finalData = [];
        for (const x in data) {
            finalData.push(data[x]);
        }
        return finalData;
    };

    const noDataPrepare = () => {
        const groupedData = prepareData(filteredValues);
        setNoData(groupedData ?? []);
    }

    useEffect(() => {
        if (sleVariable.length) {
            const data = prepareData(sleVariable)
            setFilterUomData(data)
            const filteredVariables = sleVariable.filter((item) => item.unitOfMeasure.toLowerCase() !== 'none')
            setFilteredValues(filteredVariables)
            setPrevFilteredValues(filteredVariables)
            setSelectedVariables(filteredVariables?.map((item) => item.variable))
        } else {
            setFilterUomData([])
            setFilteredValues([])
            setPrevFilteredValues([])
            setSelectedVariables([])
        }
    }, [sleVariable])

    useEffect(() => {
        if (selectedVariables?.length !== 0) {
            setLoadingIcon(true)
            if (sleTimeSeriesDataSet?.length)
                dispatch(updateSleTrendTimeSeriesDataSet([]))

            sleCancelTokenSource.current = axios.CancelToken.source();

            const wellName = selectedAsset?.selectedAssetName ?? '';
            const startDate = new Date(selectedDateRange.startDate.getFullYear(), selectedDateRange.startDate.getMonth(), selectedDateRange.startDate.getDate(), 0, 0, 0, 0).toISOString();
            const endDate = new Date(selectedDateRange.endDate.getFullYear(), selectedDateRange.endDate.getMonth(), selectedDateRange.endDate.getDate(), 23, 59, 59, 999).toISOString();
            const aggregateValue = GetAggregation(new Date(startDate), new Date(endDate), aggregateValues)

            dispatch(fetchSleTimeSeriesData({
                wellName: wellName,
                startDate: startDate,
                endDate: endDate,
                variables: selectedVariables.toString() || '',
                aggregate: aggregateValue,
                cancelToken: sleCancelTokenSource?.current?.token,
                granularData: false
            }))
                .finally(() => {
                    setLoadingIcon(false)
                })
        }

        if (assetControlData?.assetControlScanStatus)
            dispatch(updateAssetControlScanStatus(false))

        return () => {
            if (sleCancelTokenSource.current) {
                sleCancelTokenSource.current.cancel('canceled');
            }
        };
    }, [JSON.stringify(selectedVariables), assetControlData?.assetControlScanStatus === true, selectedDateRange.startDate, selectedDateRange.endDate])

    useEffect(() => {
        if (sleTimeSeriesData?.length) {
            const convertedArray = sleTimeSeriesData?.map((item) => ({
                ...item,
                trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || ''
            }));
            const filteredVariable = prevFilteredValues?.map(item => item.variable)
            const filteredArray = convertedArray?.filter((item) => filteredVariable?.includes(item.variable))
            if (convertedArray) {
                const data = prepareData(filteredArray);
                setHistoriesData(data);
            } else {
                setHistoriesData([]);
                noDataPrepare();
            }
        } else {
            setHistoriesData([]);
            noDataPrepare();
        }

    }, [sleTimeSeriesData, prevFilteredValues])

    useEffect(() => {
        if (sleTimeSeriesDataSet?.length > 1) {
            dispatch(updateSleTrendTimeSeriesDataSet([{ ...sleTimeSeriesDataSet[0] }]))
        }
    }, [prevFilteredValues])

    useEffect(() => {
        if (!sparklineUserPreferences?.length)
            dispatch(fetchSparklineUserPreference('asset-detail-sparklines'))
    }, [])
    useEffect(() => {
        if (sparklineUserPreferences && sparklineUserPreferences.length > 0) {
            setPreferenceItems(sparklineUserPreferences);
        }
    }, [JSON.stringify(sparklineUserPreferences)]);


    useEffect(() => {
        let filteredUserPreferenceItems: UserPreferenceDataItem[] = []
        if (historiesData?.length) {
            filteredUserPreferenceItems = preferenceItems
                .filter(preference =>
                    historiesData?.some((data: any) => {
                        return preference.title?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase()
                    })
                )
        } else if (noData?.length) {
            filteredUserPreferenceItems = preferenceItems
                .filter(preference =>
                    noData?.some((data: any) =>
                        preference.title?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase()
                    )
                )
        }


        const layout = filteredUserPreferenceItems?.map(preference => {
            return {
                i: preference.title,
                x: preference.positions?.lg?.x,
                y: preference.positions?.lg?.y,
                w: 12,
                h: 3,
            }
        }).sort((a, b) => a.y - b.y);// Extract position from filtered results


        setLayout(layout)
    }, [JSON.stringify(preferenceItems), JSON.stringify(historiesData), JSON.stringify(noData)])

    const handleSlideOutClick = (e: any) => {
        if (e.target.id === 'range-calendar-input') return;
        if (showCalendar) {
            setShowCalendar(!showCalendar);
        }
    };

    const handleFilterOutClick = (e: any) => {

        if (e.target.id === 'delete-btn') {
            return
        }
        if (showFilterPopup) {
            setShowFilterPopup(false);
            setFilteredValues([...prevFilteredValues])
            setFilterExpand('')
            setSearchInputVal('')
            const uomData = prepareData(sleVariable) ?? [];
            setFilterUomData(uomData)
        }
    };

    const handleClearButton = () => {
        setFilteredValues([])
        setSearchInputVal('')
        const uomData = prepareData(sleVariable) ?? [];
        setFilterUomData(uomData)
    };

    const handleApplyButton = async () => {
        if (filteredValues?.length === 0) {
            showToaster('Please select atleast one filter', 'error');
            return;
        }
        else {
            const variables = filteredValues.map(item => item.variable);

            if (variables?.every(value => selectedVariables?.includes(value))) {
                if (sleTimeSeriesData && sleTimeSeriesData?.length !== 0) {

                    const filteredArray = sleTimeSeriesData?.filter((item) => variables?.includes(item.variable))?.map((item) => ({ ...item, trendName: sleVariable?.find((sleVariable) => sleVariable.variable === item.variable)?.variableName || '' }))
                    const data = prepareData(filteredArray) ?? []
                    setHistoriesData(data)
                } else {
                    setHistoriesData([])
                    noDataPrepare()
                }
            } else {
                setSelectedVariables(variables)
            }


            setPrevFilteredValues([...filteredValues])
        }
        setShowFilterPopup(false)
        setFilterExpand('')
    };

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const matchedFilter = filteredValues.find(item => item.variable === value)
        if (matchedFilter) {
            const updatedFilter = filteredValues.filter(item => item.variable !== value)
            setFilteredValues(updatedFilter)
        } else {
            const matchedData = sleVariable?.find((filterData: any) => filterData?.variable === value && filterData?.unitOfMeasure.toLowerCase() !== 'none')
            if (matchedData) {
                const updatedFilter = [...filteredValues, matchedData]
                setFilteredValues(updatedFilter)
            }
        }
    };
    const handleSearch = (e: any) => {
        const val = e.target.value;

        setSearchInputVal(val);

        if (val === '') {
            const uomData = prepareData(sleVariable) ?? [];
            setFilterUomData(uomData)
        } else {
            const filteredSparkLineFilterData = sleVariable?.filter((item: any) => {
                return (item?.unitOfMeasure?.toLowerCase().includes(val?.toLowerCase()) || item?.description?.toLowerCase().includes(val?.toLowerCase()))
            }) ?? []
            const prepearedUom = filteredSparkLineFilterData?.length ? prepareData(filteredSparkLineFilterData) : [];
            setFilterUomData(prepearedUom)
        }
    };

    const ref = useDetectClickOutside({ onTriggered: handleSlideOutClick });
    const filterRef = useDetectClickOutside({ onTriggered: handleFilterOutClick });


    const debouncedLayoutChangeRef = useRef(
        debounce((mergedItems: UserPreferenceDataItem[]) => {
            dispatch(updateSparklineWidgetLayout({ dashboard: "asset-detail-sparklines", data: mergedItems })).then((res) => {
                if (updateSparklineWidgetLayout.fulfilled.match(res)) {
                    showToaster('Layout updated successfully', 'success');
                } else {
                    showToaster('Failed to update layout', 'error');
                }
            });
        }, 3000)
    );

    const onLayoutChange = (updatedLayout: LayoutDataItem[]) => {
        const mergedItems = sparklineUserPreferences && sparklineUserPreferences?.map(item => {
            const updatedItem = updatedLayout?.find(layout => layout.i === item.key);
            if (updatedItem) {
                return {
                    ...item,
                    positions: {
                        ...item.positions,
                        lg: {
                            x: updatedItem.x,
                            y: updatedItem.y,
                            w: updatedItem.w,
                            h: updatedItem.h,
                            minH: updatedItem.minH ?? 3,
                            minW: updatedItem.minW ?? 12
                        },
                    },
                };
            }
            return item;
        });

        if (JSON.stringify(updatedLayout) !== JSON.stringify(layoutOnDragStart)) {
            setPreferenceItems(mergedItems); // Update local state with new items
            setLayout(updatedLayout); // Update layout
            dispatch(updateSparklineUserPreferenceData(mergedItems))
            debouncedLayoutChangeRef.current(mergedItems); // Call debounced API update
        }
    };

    const renderdateRange = () => {
        return (
            <div className='history-container-date-range'>
                <div className='input-group hideheader'>
                    <img src={calenderIcon} alt='calednar-icon' className='absolute top-3 left-3' />
                    <input
                        id='range-calendar-input'
                        className='date-range-input'
                        value={`${formateDate(selectedDateRange.startDate)} - ${formateDate(selectedDateRange.endDate)}`}
                        onClick={() => setShowCalendar(!showCalendar)}
                    />
                </div>
                <div ref={ref}>
                    {showCalendar && (
                        <DateRangeSelector setShowCalendar={setShowCalendar} setSelectedDateRange={setSelectedDateRange} staticRanges={defaultStaticRanges} minDate={defineds?.startOfLastYear} maxDate={defineds?.endOfToday} selectedDateRange={selectedDateRange} />)}
                </div>

                <div style={{ position: 'relative', zIndex: '98' }}>
                    <div
                        id='filter-btn'
                        style={{
                            border: showFilterPopup
                                ? '1px solid var(--Dark-mode-400, #4A5463)'
                                : '1px solid var(--Dark-mode-400, #4A5463)',
                            background: showFilterPopup ? 'var(--Dark-mode-800, #001023)' : 'inherit',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (isFilterChanged) {
                                setFilteredValues([...prevFilteredValues]);
                            }
                            setShowFilterPopup(!showFilterPopup);
                            if (showFilterPopup)
                                setFilterExpand('')
                        }}         >
                        <img src={filterIcon} alt='filterIcon' />
                        Filter {`(${filteredValues?.length})`}
                        <img src={downIcon} className={!showFilterPopup ? '' : 'rotate-180'} alt='upIcon' />
                    </div>

                    {showFilterPopup && (
                        <div className='histry-filter-modal-container' ref={filterRef}>
                            <div className='trends-filter-modal-header'>Edit trend group</div>

                            <div className='trends-filter-search history-search relative'>
                                <img src={searchIcon} placeholder='Search for trend' alt='search' className='history-search-icon' />
                                <input
                                    type='text'
                                    value={searchInputVal}
                                    placeholder='Search for trend group'
                                    onChange={handleSearch}
                                    className='history-filter-search'
                                />
                            </div>

                            <div className='history-filter-modal-body' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                <div className='history-body-container'>
                                    {filterUomData?.map((history: any) => {
                                        return (
                                            <>
                                                <div className='history-body-trends' style={{ cursor: 'pointer' }} onClick={() => history[0]?.unitOfMeasure === filterExpand ? setFilterExpand('') : setFilterExpand(history[0]?.unitOfMeasure)}>
                                                    {history[0]?.unitOfMeasure}
                                                    <img src={downIcon} className={history[0]?.unitOfMeasure !== filterExpand ? '' : 'rotate-180'} alt='upIcon' />
                                                </div>
                                                {
                                                    filterExpand === history[0]?.unitOfMeasure && history.map((item: any) => {
                                                        return (
                                                            <div className='history-body-trends' style={{ flexDirection: 'column', alignItems: 'start' }}>
                                                                <div className='checkbox-main-container'>
                                                                    <label className='checkbox-container'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id='showCheckbox'
                                                                            checked={filteredValues?.find((filterItem: any) => filterItem.variable === item?.variable) !== undefined}
                                                                            onChange={handleCheckbox}
                                                                            value={item?.variable}
                                                                        />
                                                                        <span className='checkbox-checkmark'></span>
                                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item?.variableName}&nbsp;
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </>
                                        );
                                    })}
                                </div>

                            </div>
                            <div className='trends-filter-modal-footer'>
                                <button
                                    className={'footer-btn'}
                                    onClick={handleClearButton}
                                >
                                    Clear
                                </button>
                                <button
                                    className={'footer-btn'}
                                    onClick={handleApplyButton}
                                >
                                    Apply
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };


    return (
        <>
            <ToastContainer
                position='top-right'
                autoClose={3000}
            />
            <div
                className='trends-container'
                style={{ position: 'relative', minHeight: showCalendar || showFilterPopup ? '600px' : '300px' }}
            >
                {renderdateRange()}
                <div style={{ width: '100%' }}>
                    {(initialDataLoading || loadingIcon) ? (
                        <div className='spark-line-loader flex items-center justify-content loader-margin'>
                            <Loader />
                        </div>
                    ) :
                        <>
                            <ResponsiveGridLayout
                                className="layout"
                                layouts={{ lg: layout || [] }} // Define layout for large screens
                                breakpoints={{ lg: 1200, md: 996 }}
                                cols={{ lg: 12, md: 12 }}
                                rowHeight={100}
                                isResizable={false}
                                useCSSTransforms={false}
                                margin={[0, 2]} // Disable default margins
                                containerPadding={[0, -6]}
                                onDragStop={onLayoutChange}
                                onDragStart={(updatedlayout: LayoutDataItem[]) => setLayoutOnDragStart(updatedlayout)}
                            >
                                {
                                    layout?.map((item, index) => {
                                        if (historiesData?.length) {
                                            const matchedItem = historiesData?.find((data) =>
                                                item.i?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase())
                                            if (matchedItem)
                                                return (
                                                    <div key={item.i} style={{ width: '100% !important' }}>
                                                        <SleCardsDesign
                                                            key={`${matchedItem[0]?.date}-index`}
                                                            trend={matchedItem}
                                                            index={index}
                                                            selectedCalendarDate={selectedDateRange}
                                                            filteredValues={selectedVariables}
                                                            currentAggregationValue={currentAggregation.current}
                                                        />
                                                    </div>)
                                        } else if (noData?.length) {
                                            const matchedItem = noData?.find((data: any) =>
                                                item.i?.toLowerCase() === data[0].unitOfMeasure?.toLowerCase())
                                            if (matchedItem)
                                                return (
                                                    <div key={item.i} style={{ width: '100% !important' }}>
                                                        <NoDataCardsDesign
                                                            key={`${matchedItem[0]?.date}-index`}
                                                            trend={matchedItem} />
                                                    </div>)
                                        }
                                    })

                                }
                            </ResponsiveGridLayout>
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default SleSparklineChart