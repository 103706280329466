// DocumentationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getDocuments, createDocumentData, downloadAssetDocument, deleteAssetDocument, downloadQbAssetDocumentByName, documentDownloadLookupValues, getDocumentCategories } from './AssetDocumentsService';
import { AssetDocument, DocumentDownloadApiResponse } from './model/AssetDocument';
import { ConvertUTCToLocal } from '../../../../utilities/CommonFunctions';

interface DocumentCategories {
  uploadCategory: string[];
  dataLakeCategory: string[];
}
interface UserState {
  loading: boolean;
  downloadLoading: boolean;
  message: string | null;
  documents: AssetDocument[];
  success: boolean;
  documentCategories: DocumentCategories;
  DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS: number;
  MAX_DOCUMENT_DOWNLOAD_COUNT: number;
  MAX_DOCUMENT_UPLOAD_COUNT: number;
  MAX_DOCUMENT_UPLOAD_SIZE: number;
}

const initialState: UserState = {
  loading: false,
  downloadLoading: false,
  message: null,
  success: false,
  documents: [],
  documentCategories: {
    uploadCategory: [],
    dataLakeCategory: [],
  },
  DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS: 180,
  MAX_DOCUMENT_DOWNLOAD_COUNT: 10,
  MAX_DOCUMENT_UPLOAD_COUNT: 10,
  MAX_DOCUMENT_UPLOAD_SIZE: 10
};

export const documentDownloadLookup = createAsyncThunk('lookups/lookups-by-group/DOCUMENT_DOWNLOAD', async (groupName: string) => {
  const response: DocumentDownloadApiResponse = await documentDownloadLookupValues(groupName);
  return response;
});


// Define your async thunk for fetching a single user
export const fetchDocument = createAsyncThunk('document/fetchDocument', async ({ wellId, dataLakeCategory }: { wellId: string,  dataLakeCategory: any }) => {
  const response = await getDocuments(wellId, dataLakeCategory);
  const formattedResponse = response.map((doc: AssetDocument) => {
    return {
      ...doc,
      eventDate: doc.eventDate ? ConvertUTCToLocal(doc.eventDate, true) : null,
      updatedAt: doc.updatedAt ? ConvertUTCToLocal(new Date(doc.updatedAt), false):  null,
    };
  });
  return formattedResponse;
});

// Define your async thunk for fetching a downloadDocument
export const downloadDocument = createAsyncThunk('document/downloadAssetDocument', async ({ id, cancelToken }: { id: string, cancelToken?: any }) => {
  const response = await downloadAssetDocument(id, cancelToken);
  return response;
});

//download document for data lake source
export const downloadQbAssetDocument = createAsyncThunk('document/downloadQbAssetDocumentByName', async ({ wellId, fileName, cancelToken }: { wellId: string, fileName: string, cancelToken?: any }) => {
  const response = await downloadQbAssetDocumentByName(wellId, fileName, cancelToken);
  return response;
});

// Define your async thunk for fetching a downloadDocument
export const deleteDocument = createAsyncThunk('document/deleteAssetDocument', async ({ id }: { id: string[] }) => {
  const response = await deleteAssetDocument(id);
  return response;
}
);
// Define your async thunk for fetching a single user
// Create userNotification
export const createDocument = createAsyncThunk(
  'document/CreateAssetDocument',
  async (assetDocument: FormData, { rejectWithValue }) => {
    try {
      const response = await createDocumentData(assetDocument);
      return response; // Successful response
    } catch (error: any) {
      console.error('Error in createDocument:', error);
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

export const fetchDocumentCategories = createAsyncThunk(
  'document/fetchDocumentCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getDocumentCategories();
      return response; 
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch document categories');
    }
  }
);


const assetDocumentSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    deleteDocumentSuccess: (state, action: PayloadAction<string>) => {
      state.documents = state.documents.filter(doc => doc.id !== action.payload);
    },
    cleanDocumentList: (state) => {
      state.documents = []
    }
  },
  extraReducers: (builder) => {
    // Handle the fetchUserById fulfilled action
    builder.addCase(fetchDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDocument.fulfilled, (state, action) => {      
      state.documents = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.documents = [];
    });
    builder.addCase(downloadDocument.pending, (state) => {
      state.downloadLoading = true;
    });
    builder.addCase(downloadDocument.fulfilled, (state) => {
      state.downloadLoading = false;
    });
    builder.addCase(downloadDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.downloadLoading = false;
    });
    builder.addCase(deleteDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteDocument.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
    // Handle the createNotification fulfilled action
    builder.addCase(createDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDocument.fulfilled, (state) => {
      //state.schedule = [];
      state.success = true;
      state.loading = false;
    });
    builder.addCase(createDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.success = false;
      state.loading = false;
    });

    //handle download document for data lake source
    builder.addCase(downloadQbAssetDocument.pending, (state) => {
      state.downloadLoading = true;
    });
    builder.addCase(downloadQbAssetDocument.fulfilled, (state) => {
      state.downloadLoading = false;
    });
    builder.addCase(downloadQbAssetDocument.rejected, (state, action) => {
      state.message = action.payload as string;
      state.downloadLoading = false;
    });
    builder.addCase(documentDownloadLookup.fulfilled, (state, action) => {
      state.DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS = action.payload.data.find((item) => item.key === 'DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS')?.value ? parseInt(action.payload.data.find((item) => item.key === 'DOCUMENT_DOWNLOAD_TIMEOUT_SECONDS')?.value || "") : 180;

      state.MAX_DOCUMENT_DOWNLOAD_COUNT = action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_DOWNLOAD_COUNT')?.value ? parseInt(action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_DOWNLOAD_COUNT')?.value || "") : 10;

      state.MAX_DOCUMENT_UPLOAD_COUNT = action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_UPLOAD_COUNT')?.value ? parseInt(action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_UPLOAD_COUNT')?.value || "") : 10;
      state.MAX_DOCUMENT_UPLOAD_SIZE = action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_UPLOAD_SIZE')?.value ? parseInt(action.payload.data.find((item) => item.key === 'MAX_DOCUMENT_UPLOAD_SIZE')?.value || "") : 10;

    });
    builder.addCase(fetchDocumentCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDocumentCategories.fulfilled, (state, action: PayloadAction<DocumentCategories>) => {
      state.documentCategories = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchDocumentCategories.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});

export const { deleteDocumentSuccess, cleanDocumentList } = assetDocumentSlice.actions;

export default assetDocumentSlice.reducer;
