import React, { useEffect, useRef } from 'react';
import '../AssetInfo.scss';
import { useAppSelector, useAppDispatch } from '../../../hooks/storeHooks';
import AssetMap from '../../dashboard/components/asset-location/AssetMap';
import cloudSun from '../../../images//weather-icon.svg';
import ToggleSwitch from '../../common/ToggleSwitch/ToggleSwitch';
import { toggleShowWeather } from '../../asset/AssetSlice';
import axios from 'axios';
import { clearAssetEventSchedule, fetchAssetListById } from '../../dashboard/DashboardSlice';


const Map = () => {

  const dispatch = useAppDispatch();
  const showWeather = useAppSelector((state) => state.alarms.showWeather);
  const selectedAssetName = useAppSelector((state) => state?.assetGroups?.selectedAssetName);
  const cancelTokenSource = useRef<ReturnType<typeof axios.CancelToken.source> | null>(null);

  const handleShowWeatherToggle = () => {
    dispatch(toggleShowWeather());
  };


  useEffect(() => {

  const selectedWellName =sessionStorage.getItem('selectedWellName');
  const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');

    if (selectedAssetNames?.assetId && selectedAssetNames?.assetGroupName) {
      cancelTokenSource.current = axios.CancelToken.source();
      dispatch(clearAssetEventSchedule());
      setTimeout(() => {
        dispatch(
          fetchAssetListById({
            groupName: selectedAssetNames?.assetGroupName,
            startIndex: 0,
            endIndex: 1,
            searchQuery: selectedAssetName ? '?filter=' + selectedAssetName : '',
            cancelToken: cancelTokenSource?.current?.token,
          }),
        );
      });
    }

    return () => {
      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel('Component unmounted or route changed.');
      }
    };
  }, []);

  return (
    <div className='map-section'>
      <div className='weather-toggle-container'>
            <img src={cloudSun} alt='' className='me-2' />
            <p>Show weather</p>
            <ToggleSwitch checked={showWeather} onChange={handleShowWeatherToggle} label='' value='' />
      </div>
      <AssetMap />
    </div>
  )
}

export default Map