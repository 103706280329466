import { http } from '../../../../config/HttpCommon';

export const getAssetDetail = async (wellName: string) => {
  const response = await http.get(`/GetAssetDetails/${encodeURIComponent(wellName)}`);
  return response.data;
};

export const getUserDetailById = async () => {
  const response = await http.get(`/GetUserDetails`);
  return response.data;
};