export const validateForm = (formData: any, selectedCustomer: string) => {
    const errors: { [key: string]: string } = {};

    if (!formData.customerID || !formData.customerID) {
      errors.customerID = 'Customer selection is required';
    }
    
    if (!formData.firstName) {
      errors.firstName = 'First name is required';
    }
  
    if (!formData.lastName) {
      errors.lastName = 'Last name is required';
    }
    
    if (!formData.jobTitle) {
      errors.jobTitle = 'Job title is required';
    } else if (!/^[A-Za-z\s]+(?:\s(I|II|III|IV|V|VI|VII|VIII|IX|X))?$/.test(formData.jobTitle)) {
      errors.jobTitle = 'Invalid job title. Use letters & optional Roman numeral';
    }    

    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      errors.email = 'Email address is invalid';
    } else if (selectedCustomer === 'ChampionX' && !formData.email.endsWith('@championx.com')) {
      errors.email = 'Email must be a ChampionX domain';
    }

    if (formData.mobileNumber && !/^\d{10}$/.test(formData.mobileNumber)) {
      errors.mobileNumber = 'Mobile number must be exactly 10 digits';
    }
  
    if (formData.officeNumber && !/^\d{10}$/.test(formData.officeNumber)) {
      errors.officeNumber = 'Office number must be exactly 10 digits';
    }

    if (formData.countryCodeMobile && !/^\+\d{1,3}$/.test(formData.countryCodeMobile)) {
      errors.countryCodeMobile = 'Country code must start with + and have up to 3 digits';
    }

    if (formData.countryCodeOffice && !/^\+\d{1,3}$/.test(formData.countryCodeOffice)) {
      errors.countryCodeOffice = 'Country code must start with + and have up to 3 digits';
    }
    
    if (formData.roles.length === 0) {
      errors.roles = 'One role must be selected';
    }
    
    return errors;
  };
  