import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ChevronUp } from "../../../../images/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../images/chevron-down.svg";
import "./dropdown.scss";

interface DropdownProps {
    items?: string[];
    defaultOption?: string;
    onChange?: (item: string) => void;
    noResultsMessage?: React.ReactNode;
    disabled?: boolean;
}

const TimeZoneDropdown: React.FC<DropdownProps> = ({
    items,
    defaultOption = "Choose one",
    onChange,
    noResultsMessage,
    disabled = false,
}) => {
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [isActive, setIsActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClick = (item: string) => {
        setSelectedItem(item);
        setIsActive(false);
        if (onChange) {
            onChange(item);
        }
    };

    const filteredItems = items?.filter((item) =>
        item.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const exactMatch = filteredItems?.some(
        (item) => item.toLowerCase() === searchTerm.toLowerCase()
    );

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsActive(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            ref={dropdownRef}
            className={`dropdown ${disabled ? "disabled" : ""}`}
            style={{ width: "100%" }}
        >
            <div
                onClick={() => !disabled && setIsActive(!isActive)}
                className="dropdown-btn"
            >
                <span className="dropdown-input">
                    {selectedItem ? selectedItem : defaultOption}
                </span>
                <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
            </div>
            {isActive && !disabled && (
                <div
                    className="dropdown-content"
                    style={{ display: isActive ? "block" : "none" }}
                >
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="dropdown-search"
                        disabled={disabled}
                    />
                    {searchTerm && !exactMatch && noResultsMessage && (
                        <div className="no-results">{noResultsMessage}</div>
                    )}
                    {filteredItems?.map((item, index) => (
                        <div
                            key={index}
                            className={`item ${selectedItem === item ? "item-selected" : ""
                                }`}
                            onClick={() => handleClick(item)}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TimeZoneDropdown;
