import { useState } from 'react';
import filterIcon from '../../../../../../images/filter-icon.svg';
import './ControlRoomTicketFilter.scss';
import ControlRoomFilterModal from './ControlRoomFilterModal';
import { TicketUser, Operators, Status } from '../../interfaces/ticketInterface';

interface ControlRoomTicketFilterProps {
    handleFilterApply: (selectedFilter: { [key: string]: (Operators | TicketUser | Status | string)[] }) => void;
    selectedFilter: { [key: string]: (Operators | TicketUser | Status | string)[] }; // Updated type
}

const ControlRoomTicketFilter: React.FC<ControlRoomTicketFilterProps> = ({ handleFilterApply, selectedFilter }) => {
    const [isTicketFilterOpened, setIsTicketFilterOpened] = useState(false);

    const handleOnCloseModal = () => {
        setIsTicketFilterOpened(false);
    };

    return (
        <div className="control-room-ticket-filter">
            <div
                id="filter-btn"
                className="filter-btn flex items-center gap-x-2 justify-center cursor-pointer"
                onClick={() => setIsTicketFilterOpened(!isTicketFilterOpened)}
            >
                <img src={filterIcon} alt="filterIcon" />
                Filter ({
                    Object.keys(selectedFilter).reduce((acc, key) => {
                        return acc + selectedFilter[key].length;
                    }, 0)
                })
            </div>

            {isTicketFilterOpened && (
                <ControlRoomFilterModal
                    onClose={handleOnCloseModal}
                    handleFilterApply={handleFilterApply}
                    selectedFilter={selectedFilter}
                />
            )}
        </div>
    );
};

export default ControlRoomTicketFilter;