import TicketCard from './TicketCard';
import { useCallback, useRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks';
import { getTicketTable, getTicketUsers } from '../../slices/ticketSlice';
import { Ticket, TicketTableResponse } from '../../interfaces/ticketInterface';
import Loader from '../../../../../common/page-loader/ComponentLoader';
import NoData from '../../../../../dashboard/components/no-data/NoData';
import './MyTicketList.scss';
import TicketModelOpen from '../../TicketModelOpen';

const MyTicketList = () => {
  const dispatch = useAppDispatch();
  const ticketListData = useAppSelector((state) => state.ticketTableSlice.ticketTableData?.results) as Ticket[];
  console.log("results", ticketListData);

  const ticketListFullData = useAppSelector((state) => state.ticketTableSlice).ticketTableData as TicketTableResponse;
  const loading = useAppSelector((state) => state.ticketTableSlice.loading);
  const ticketListLoading = useAppSelector((state) => state.ticketTableSlice.ticketTableLoading);
  const [isTicketsModalOpen, setIsTicketsModalOpen] = useState(false);
  const [selectedTicketIdForClaim, setSelectedTicketIdForClaim] = useState<number | null>(null);
  const [assetId, setAssetId] = useState<string>('');

  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetId = selectedWellName && JSON.parse(selectedWellName || '');
    setAssetId(selectedAssetId?.assetId);
    dispatch(getTicketUsers({ SearchQuery: '', PageNumber: 1, PageSize: 100 }));
  }, []);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastDocumentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (!ticketListFullData?.hasNextPage) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && ticketListFullData?.hasNextPage && assetId) {
          dispatch(
            getTicketTable({
              PageSize: 25,
              PageNumber: ticketListFullData?.nextPageNumber || ticketListFullData?.pageNumber + 1,
            }),
          );
        }
      });

      if (node) observer.current.observe(node);
    },
    [ticketListData?.length, ticketListFullData?.hasNextPage, loading, dispatch, assetId],
  );


  const handleCloseTcketsAddModal = () => {
    setIsTicketsModalOpen(false);
    setSelectedTicketIdForClaim(null);
  };

  return (
    <div className='relative w-full'>
      {ticketListLoading && (
        <div className='flex items-center justify-center chart-loader absolute top-[50%] z-10 left-[50%] translate-y-[-50%]'>
          <Loader />
        </div>
      )}

      <div className={`my-ticket-list-section card flex flex-row ${ticketListLoading ? 'blur-background pointer-events-none' : ''}`}>
        {ticketListData && ticketListData?.length ? (
          <>
            {ticketListData?.map((ticket, index) => (
              <div
                key={ticket.id + index}
                ref={index === ticketListData.length - 1 ? lastDocumentRef : null}
                className={index === 0 ? 'sticky-card' : ''}
              >
                <TicketCard
                  id={ticket?.id}
                  status={ticket?.status}
                  subject={ticket?.subject}
                  requestor={ticket?.requester}
                  updatedDate={ticket?.updatedAt}
                  assignedTo={ticket?.assignee}
                  isTicketsModalOpen={isTicketsModalOpen}
                  setIsTicketsModalOpen={setIsTicketsModalOpen}
                  setSelectedTicketIdForClaim={setSelectedTicketIdForClaim}
                  selectedTicketIdForClaim={selectedTicketIdForClaim}
                />
              </div>
            ))}
          </>
        ) : (
          !ticketListLoading && (<div className='flex flex-column items-center justify-center no-data-found watchlist-no-data'>
            <NoData heading='No data found' />
          </div>)
        )}
      </div>
      <div className="model-containers">
        {isTicketsModalOpen && selectedTicketIdForClaim && <TicketModelOpen
          ticketId={selectedTicketIdForClaim}
          isOpen={isTicketsModalOpen}
          closeModal={handleCloseTcketsAddModal}
        />}
      </div>
    </div>
  );
};

export default MyTicketList;