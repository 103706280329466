import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createWatchListData, deleteWatchListAPI, getAssetLocations, getAssetOperators, getAssetParameters, getAssetStatuses, getWatchList, getWatchListByIdAPI, getWatchListCount, updateWatchListAPI } from './ControlRoomWellsService';
import { ControlRoomWellsResponse, GetWatchListByIdApiResponse, WellWatchList, WellWatchListData } from '../interfaces/ControlRoomWells.interface';
import { AssetParameterApiResponse, LocationItem, LocationsAPIresponse, Parameter } from '../interfaces/AssetParameters.interface';
import { RootState } from '../../../../store';



interface ControlRoomWellsState {
    wellWatchList: WellWatchListData;
    assetStatuses?: Parameter[];
    assetLocations?: { states: LocationItem[], counties: LocationItem[] };
    assetOperators?: Parameter[];
    assetParameters?: Parameter[];
    wellsWatchListById?: WellWatchList;
    selectedWatchList?: WellWatchList;
    watchListCreateUpdateLoading: boolean;
    loading: boolean;
    error: string | null;
    wellWatchlistLoading: boolean;
    wellWatchListByIdLoading: boolean;
    assetStatusesLoading?: boolean;
    assetLocationsLoading?: boolean;
    assetOperatorsLoading?: boolean;
    assetParametersLoading?: boolean;
    watchListCountLoading?: boolean;
    fetchedVisibleWatchListItems: string[];
}

const initialState: ControlRoomWellsState = {
    wellWatchList: {} as WellWatchListData,
    assetLocations: { states: [], counties: [] },
    assetOperators: [],
    assetParameters: [],
    assetStatuses: [],
    wellsWatchListById: {} as WellWatchList,
    selectedWatchList: {} as WellWatchList,
    watchListCreateUpdateLoading: false,
    loading: false,
    error: null,
    wellWatchlistLoading: true,
    wellWatchListByIdLoading: true,
    assetStatusesLoading: true,
    assetLocationsLoading: true,
    assetOperatorsLoading: true,
    assetParametersLoading: true,
    watchListCountLoading: true,
    fetchedVisibleWatchListItems: [],
};

export const fetchWellsWatchList = createAsyncThunk(
    'controlRoomWells/fetchWellsWatchList',
    async ({ pageSize, pageNumber }: { pageSize: number, pageNumber: number }) => {
        return await getWatchList(pageSize, pageNumber);
    }
);

export const fetchWellsWatchListCount = createAsyncThunk(
    'controlRoomWells/fetchWellsWatchListCount',
    async (ids: string[]) => {
        return await getWatchListCount(ids);
    }
);

export const fetchAssetStatuses = createAsyncThunk(
    'controlRoomWells/fetchAssetStatuses',
    async () => {
        return await getAssetStatuses();
    }
);

export const fetchAssetLocations = createAsyncThunk(
    'controlRoomWells/fetchAssetLocations',
    async () => {
        return await getAssetLocations();
    }
);

export const fetchAssetOperators = createAsyncThunk(
    'controlRoomWells/fetchAssetOperators',
    async () => {
        return await getAssetOperators();
    }
);

export const fetchAssetParameters = createAsyncThunk(
    'controlRoomWells/fetchAssetParameters',
    async () => {
        return await getAssetParameters();
    }
);

export const createWatchList = createAsyncThunk(
    'controlRoomWells/createWatchList',
    async (payload: any, { rejectWithValue }) => {
        try {
            return await createWatchListData(payload);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

export const deleteWatchList = createAsyncThunk(
    'controlRoomWells/deleteWatchList',
    async (id: string) => {
        return await deleteWatchListAPI(id);
    }
);

export const getWatchListById = createAsyncThunk(
    'controlRoomWells/getWatchListById',
    async (id: string) => {
        return await getWatchListByIdAPI(id);
    }
);

export const updateWatchList = createAsyncThunk(
    'controlRoomWells/updateWatchList',
    async ({ id, payload }: { id: string, payload: any }, { rejectWithValue }) => {
        try {
            return await updateWatchListAPI(id, payload);
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const controlRoomWellsSlice = createSlice({
    name: 'controlRoomWells',
    initialState,
    reducers: {
        emptyWellWatchList: (state) => {
            state.wellWatchList = {} as WellWatchListData;
        },
        removeWatchListById: (state, action: PayloadAction<string>) => {
            state.wellWatchList.results = state.wellWatchList.results?.filter((item) => item.id !== action.payload);
        },
        updateWatchListById: (state, action: PayloadAction<WellWatchList>) => {
            state.wellWatchList.results = state.wellWatchList.results?.map((item) => {
                if (item.id === action.payload.id) {
                    return { ...item, ...action.payload };
                }
                return item;
            });
        },
        setSelectedWatchList: (state, action: PayloadAction<WellWatchList>) => {
            state.selectedWatchList = action.payload;
        },
        removeWatchListForUpdate: (state) => {
            state.wellsWatchListById = {} as WellWatchList;
        },
        setVisibilityWatchListItems: (state, action: PayloadAction<string[]>) => {
            const uniqueItems = new Set([...state.fetchedVisibleWatchListItems, ...action.payload]);
            state.fetchedVisibleWatchListItems = Array.from(uniqueItems);
        },
        clearVisibilityWatchListItems: (state) => {
            state.fetchedVisibleWatchListItems = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWellsWatchList.pending, (state) => {
            state.loading = true;
            state.wellWatchlistLoading = true;
        });
        builder.addCase(fetchWellsWatchList.fulfilled, (state, action: PayloadAction<ControlRoomWellsResponse>) => {
            state.wellWatchList = {
                ...state.wellWatchList,
                ...action.payload.data,
                results: state.wellWatchList.results ? [...state.wellWatchList.results, ...action.payload.data.results] : action.payload.data.results,
            };
            state.loading = false;
            state.wellWatchlistLoading = false;
        });
        builder.addCase(fetchWellsWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
            state.wellWatchlistLoading = false;
        });
        builder.addCase(fetchAssetStatuses.pending, (state) => {
            state.assetStatusesLoading = true;
        });
        builder.addCase(fetchAssetStatuses.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetStatuses = action.payload.data;
            state.assetStatusesLoading = false;
        });
        builder.addCase(fetchAssetLocations.pending, (state) => {
            state.assetLocationsLoading = true;
        });
        builder.addCase(fetchAssetLocations.fulfilled, (state, action: PayloadAction<LocationsAPIresponse>) => {
            state.assetLocations = {
                counties: action.payload.data.counties,
                states: action.payload.data.states,
            }
            state.assetLocationsLoading = false;
        });
        builder.addCase(fetchAssetOperators.pending, (state) => {
            state.assetOperatorsLoading = true;
        });
        builder.addCase(fetchAssetOperators.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetOperators = action.payload.data;
            state.assetOperatorsLoading = false;
        });
        builder.addCase(fetchAssetParameters.pending, (state) => {
            state.assetParametersLoading = true;
        });
        builder.addCase(fetchAssetParameters.fulfilled, (state, action: PayloadAction<AssetParameterApiResponse>) => {
            state.assetParameters = action.payload.data;
            state.assetParametersLoading = false;
        });

        builder.addCase(fetchAssetStatuses.rejected, (state, action) => {
            state.error = action.payload as string;
            // state.loading = false;
        });
        builder.addCase(fetchAssetLocations.rejected, (state, action) => {
            state.error = action.payload as string;
            // state.loading = false;
        });
        builder.addCase(fetchAssetOperators.rejected, (state, action) => {
            state.error = action.payload as string;
            // state.loading = false;
        });
        builder.addCase(fetchAssetParameters.rejected, (state, action) => {
            state.error = action.payload as string;
            state.assetParametersLoading = false;
        });
        builder.addCase(createWatchList.pending, (state) => {
            // state.loading = true;
            state.watchListCreateUpdateLoading = true;
        });
        builder.addCase(createWatchList.fulfilled, (state) => {
            // state.loading = false;
            state.watchListCreateUpdateLoading = false;
        });
        builder.addCase(createWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.watchListCreateUpdateLoading = false;
            // state.loading = false;
        });
        builder.addCase(deleteWatchList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteWatchList.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
        });
        builder.addCase(getWatchListById.pending, (state) => {
            state.wellWatchListByIdLoading = true;
            // state.loading = true;
        });
        builder.addCase(getWatchListById.fulfilled, (state, action: PayloadAction<GetWatchListByIdApiResponse>) => {
            state.wellsWatchListById = action.payload.data;
            state.wellWatchListByIdLoading = false;
            // state.loading = false;
        });
        builder.addCase(getWatchListById.rejected, (state, action) => {
            state.error = action.payload as string;
            state.wellWatchListByIdLoading = false;
            // state.loading = false
        });
        builder.addCase(updateWatchList.pending, (state) => {
            // state.loading = true;
            state.watchListCreateUpdateLoading = true;
        });
        builder.addCase(updateWatchList.fulfilled, (state) => {
            // state.loading = false;
            state.watchListCreateUpdateLoading = false;
        });
        builder.addCase(updateWatchList.rejected, (state, action) => {
            state.error = action.payload as string;
            // state.loading = false;
            state.watchListCreateUpdateLoading = false;
        });
    },

});

export const selectWatchList = (state: RootState) => state.wellWatchList.wellWatchList;

export const getDefaultWatchList = createSelector(
    [selectWatchList],
    (watchList: WellWatchListData) => watchList.results?.find((item) => item.isDefault)
);

export default controlRoomWellsSlice.reducer;
export const selectControlRoomWells = (state: { controlRoomWells: ControlRoomWellsState }) => state.controlRoomWells;
export const { emptyWellWatchList, removeWatchListById, updateWatchListById, setSelectedWatchList, removeWatchListForUpdate, setVisibilityWatchListItems, clearVisibilityWatchListItems } = controlRoomWellsSlice.actions;