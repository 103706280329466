import React from 'react'
import HealthScore from '../../health-score/components/HealthScore';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import './AssetControlRoomDrawer.scss'
import ArrowSquareLeft from '../../../images/arrow-square-left.svg'
import { updateAssetControlRoomDrawerProps } from '../AssetControlRoomDrawerSlice';
import { useNavigate } from 'react-router-dom';
import ManageAsset from '../../manage-asset/components/ManageAsset';
import CustomizeControlRoom from '../../controlroom/components/customize-control-room/CustomizeControlRoom';
import AssetInfo from '../../asset-info/AssetInfo';
import Comments from '../../controlroom/components/comments/Comments';

const AssetControlRoomDrawer = () => {
    const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer)
    const manageAssetSliceData = useAppSelector((state) => state.manageAsset)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleCloseDrawer = () => {
        dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: null }))
        navigate("/layout/well-control-room")
    }

    const getHeader = () => {
        if (assetControlRoomDrawer.selectedComponent === 'healthScore')
            return (<span className='title-text'>Health Score</span>)
        else if (assetControlRoomDrawer.selectedComponent === 'manageAsset')
            return (<div className='flex justify-between items-center w-full'><span className='title-text'>Manage asset</span>
                <p className='text-timestamp'>Last updated on {manageAssetSliceData?.lastUpdatedOn ? manageAssetSliceData?.lastUpdatedOn : ' - '}</p>
            </div>)
        else if (assetControlRoomDrawer.selectedComponent === 'customizecontrolRoom')
            return (<span className='title-text'>Customize control room</span>)
        else if (assetControlRoomDrawer.selectedComponent === 'assetInfo')
            return (<span className='title-text'>Asset info</span>)
        else if (assetControlRoomDrawer.selectedComponent === 'comments')
            return (<span className='title-text'>Comments</span>)
    }

    const getComponent = () => {
        if (assetControlRoomDrawer.selectedComponent === 'healthScore')
            return (<HealthScore />)
        else if (assetControlRoomDrawer.selectedComponent === 'manageAsset')
            return (<ManageAsset />)
        else if (assetControlRoomDrawer.selectedComponent === 'customizecontrolRoom')
            return (<CustomizeControlRoom />)
        else if (assetControlRoomDrawer.selectedComponent === 'assetInfo')
            return (<AssetInfo />)
        else if (assetControlRoomDrawer.selectedComponent === 'comments')
            return (<Comments />)
    }

    return (
        <>
            <div id='asset-control-room-drawer' className={`asset-control-room-drawer w-1/2 transition-transform ${assetControlRoomDrawer.openState ? "width-length right-0 transform translate-x-0 assetDrawerOpen" : "transform translate-x-full"}`}>
                <div className='heading w-full ' >
                    <img src={ArrowSquareLeft} onClick={handleCloseDrawer} className='hover:cursor-pointer' />
                    {getHeader()}
                </div>
                <div className='drawer-content'>
                    {
                        getComponent()
                    }
                </div>
            </div>
        </>
    )
}

export default AssetControlRoomDrawer