import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserAndCustomerIDs, addUser, getPrivileges } from './AddUserService';

interface DropdownOption {
  customerId: number;
  customerName: string;
}
type Privilege = Record<string, string>
interface UserState {
  dropdownOptions: { customerID: DropdownOption[] };
  loading: boolean;
  error: string | null;
  privileges: Privilege | null;
  priviledgeLoading: boolean;
  addUserLoading: boolean;
}

const initialState: UserState = {
  dropdownOptions: { customerID: [] },
  loading: false,
  error: null,
  privileges: null,
  priviledgeLoading: false,
  addUserLoading: false,
};

export const fetchDropdown = createAsyncThunk('adduser/fetchDropdown', async () => {
  const options = await fetchUserAndCustomerIDs();
  return options;
});

export const addNewUser = createAsyncThunk(
  'adduser/addNewUser',
  async (formData: any, { rejectWithValue }) => {
      try {
          return await await addUser(formData);
      } catch (error: any) {
          return rejectWithValue(error.response?.data || 'An error occurred');
      }
  }
);

export const fetchPrivileges = createAsyncThunk('adduser/userfetchPrivileges', async () => {
  const response = await getPrivileges();
  return response;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.dropdownOptions.customerID = action.payload.data;
      })
      .addCase(fetchDropdown.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch dropdown options';
      })
      .addCase(addNewUser.pending, (state) => {
        state.loading = true;
        state.addUserLoading = true;
      })
      .addCase(addNewUser.fulfilled, (state) => {
        state.loading = false;
        state.addUserLoading = false;
      })
      .addCase(addNewUser.rejected, (state, action) => {
        state.loading = false;
        state.addUserLoading = false;
        state.error = action.error.message || 'Failed to create user';
      });
      builder.addCase(fetchPrivileges.pending, (state) => {
        state.priviledgeLoading = true;
      });
      builder.addCase(fetchPrivileges.fulfilled, (state, action) => {
        state.priviledgeLoading = false;
        state.privileges = action.payload?.data ?? null
      });
      builder.addCase(fetchPrivileges.rejected, (state) => {
        state.priviledgeLoading = false;
        state.privileges = null;
      });
  },
});

export default userSlice.reducer;
