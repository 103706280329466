import React, { useRef, useEffect, useState } from "react";
import "./MultiSelectDropdown.scss";
import CloseIcon from "../../../../../images/close-icon-2.svg";
import Loader from "../../../../common/page-loader/ComponentLoader";

interface Options {
  id: number;
  name: string;
}

interface MultiSelectDropdownProps {
  options: Options[];
  label: string;
  selected: Options[];
  setSelected: React.Dispatch<React.SetStateAction<Options[]>>;
  hasMore: boolean;
  loadMore: () => void;
  loading: boolean;
  onSearch: (query: string) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  label,
  selected = [],
  setSelected,
  hasMore,
  loadMore,
  loading,
  onSearch
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const selectedArray = Array.isArray(selected) ? selected : [];

  // Handle scroll to load more items
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + clientHeight) < 50 && hasMore && !loading) {
      loadMore();
    }
  };

  useEffect(() => {
    if (!isDropdownOpen) {
      setSearchTerm('');
      if (searchTerm) onSearch('');
    }
  }, [isDropdownOpen]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle selecting/deselecting an option
  const handleSelectChange = (option: Options, e: React.MouseEvent) => {
    e.stopPropagation();
    const currentSelected = Array.isArray(selected) ? selected : [];
    const isSelected = currentSelected.some(item => item.id === option.id);
    const newSelected = isSelected
      ? currentSelected.filter(item => item.id !== option.id)
      : [...currentSelected, option];
    setSelected(newSelected);
  };

  // Remove a selected tag
  const removeTag = (option: Options) => {
    const currentSelected = Array.isArray(selected) ? selected : [];
    const newSelected = currentSelected.filter(item => item.id !== option.id);
    setSelected(newSelected);
  };

  // Filter options based on search term and selected items
  const filteredOptions = options?.filter(option =>
    option?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) &&
    !selectedArray.some(item => item?.id === option?.id)
  );

  return (
    <div className="w-full" ref={dropdownRef}>
      <label>{label}:</label>
      <div
        className="field-dropdown control-room-ticket-dropdown"
        onClick={() => setDropdownOpen(true)}
      >
        {selectedArray.map(tag => (
          <div
            key={tag.id}
            onClick={(e) => e.stopPropagation()}
            className="selected-dropdown-ticket"
          >
            {tag.name}
            <span onClick={() => removeTag(tag)}>
              <img src={CloseIcon} alt="close" />
            </span>
          </div>
        ))}
        {isDropdownOpen && (
          <div
            className=" max-h-[400px] overflow-y-auto w-full"
            ref={listRef}
            onScroll={handleScroll}
          >
            <div className="operators-search-filter mt-3 w-full">
              <div className="w-98 px-1">
                <label className="relative flex items-center mr-0">
                  <span className="sr-only">Search</span>
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <input
                    className="custom-text-input !pl-8"
                    placeholder="Search"
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      onSearch(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="options-list">
              {filteredOptions.map((option) => (
                <div
                  key={option.id}
                  className="ticket-filter-option cursor-pointer rounded-[8px]"
                  onClick={(e) => handleSelectChange(option, e)}
                >
                  <span className="ms-6 break-all w-[95%]">{option.name}</span>
                </div>
              ))}
              {loading && (
                <div className="flex justify-center p-2">
                  <Loader />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;