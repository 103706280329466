import React, { memo, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { HealthScoreChartProps } from '../constants/healthScore.constants';
import { useAppSelector } from '../../../hooks/storeHooks';
import InfoYellow from '../../../images/info-circle-yellow.svg';
import InfoRed from '../../../images/info-circle-red.svg'
import InfoGreen from '../../../images/info-circle-green.svg'
import { HealthScoreStatus } from '../model/HealthScoreHistory';

const HealthChart: React.FC<HealthScoreChartProps> = ({
    chartID,
    chartRef,
    classValue,
    minValue,
    maxValue,
    startAngle,
    endAngle,
    bandsData,
    innerCircleEndvalue,
    spacingValue,
    value,
    root,
}) => {
    const { healthScoreTarget } = useAppSelector((state) => state.healthScore)

    const formatDate = (isoString: string): string => {
        const date = new Date(isoString);
        return date.toLocaleDateString('en-US', {
            month: 'short', // "Feb"
            day: 'numeric', // "15"
            year: 'numeric' // "2025"
        });
    };

    useEffect(() => {
        setTimeout(() => {
            if (chartRef.current) {

                // Dispose of previous root if it exists
                if (root.current) {
                    root.current.dispose();
                    root.current = null;
                }

                root.current = am5.Root.new(chartRef.current);

                root.current.setThemes([am5themes_Animated.new(root.current)]);

                const chart = root.current.container.children.push(
                    am5radar.RadarChart.new(root.current, {
                        panX: false,
                        panY: false,
                        startAngle: startAngle,
                        endAngle: endAngle,
                        radius: am5.percent(90), // Set radius to 80% of the container
                        paddingBottom: 50, // Add padding at the bottom
                    })
                );

                const axisRenderer = am5radar.AxisRendererCircular.new(root.current, {
                    strokeOpacity: 0.1,
                    minGridDistance: 30
                });

                axisRenderer.ticks.template.setAll({
                    visible: false,
                    strokeOpacity: 0.5
                });

                axisRenderer.grid.template.setAll({
                    visible: false
                });

                const axis = chart.xAxes.push(
                    am5xy.ValueAxis.new(root.current, {
                        maxDeviation: 0,
                        min: minValue,
                        max: maxValue,
                        strictMinMax: true,
                        renderer: axisRenderer,

                    })
                );


                // Set label properties to display horizontally and avoid wrapping
                axis.get('renderer').labels.template.setAll({
                    fill: am5.color('#fff'),
                    rotation: 0, // Horizontal labels
                    textAlign: 'center', // Centered horizontally
                    verticalCenter: 'middle', // Centered vertically
                    maxWidth: 100, // Adjust as needed
                    fontSize: "12px", // Smaller font size if necessary
                    fontWeight: "400", // Adjust weight if needed
                    visible: false
                });


                // create inner gray band.
                const createInnerRange = (start: number, end: number, color: am5.Color) => {
                    const rangeDataItem: any = axis.makeDataItem({
                        value: start,
                        endValue: end
                    });

                    axis.createAxisRange(rangeDataItem);

                    rangeDataItem.get("axisFill").setAll({
                        visible: true,
                        fill: color,
                        fillOpacity: 1,
                        innerRadius: -40,
                        //stroke: color,
                        //  strokeOpacity: 0.8,
                        //strokeWidth: 1
                    });

                    rangeDataItem.get("tick").setAll({
                        visible: false
                    });
                }

                createInnerRange(1, innerCircleEndvalue ?? 100, am5.color(0x384252));

                const labelColor = (value <= 15) ? '#F97066' : (value > 15 && value <= 85) ? '#FDB022' : '#12B76A'

                // band creation
                am5.array.each(bandsData, function (data, index) {
                    const axisRange: any = axis.createAxisRange(axis.makeDataItem({}));
                    axisRange.setAll({
                        value: data.lowScore + (index > 0 ? spacingValue : 0), // Add gap if it's not the first range
                        endValue: data.highScore,
                        startAngle: 45, // Rotation starts at 45 degrees
                        endAngle: 90,   // Rotation ends at 90 degrees
                    });

                    axisRange.get("axisFill").setAll({
                        visible: true,
                        fill: am5.color(data.color),
                        fillOpacity: 1,
                        innerRadius: -10,
                        gap: 10,
                        cornerRadius: 30,

                    });

                    axisRange.get("label").setAll({
                        textType: "regular",
                        location: 1,
                        // location: index === bandsData?.length - 1 ? 1 : 1,
                        text: data.highScore,
                        visible: true,
                        centerX: am5.p50,
                        centerY: am5.p50,
                        radius: 20
                    });

                    if (index === 0) {
                        const axisRange1 = axis.createAxisRange(axis.makeDataItem({}));

                        axisRange1.setAll({
                            value: data.lowScore,
                            endValue: data.highScore
                        });

                        axisRange1.get("axisFill").setAll({
                            visible: true,
                            fill: am5.color(data.color),
                            fillOpacity: 1,
                            innerRadius: -10,
                            gap: 10,
                            cornerRadius: 25,
                        });

                        // Set the high label (on the right side of the range)
                        axisRange1.get("label").setAll({
                            textType: "regular",
                            location: 0.1,
                            text: data.lowScore,
                            visible: true,
                            centerX: am5.p50,
                            centerY: am5.p50,
                            radius: 20
                        });
                    }

                    const bulletDataItem: any = axis.makeDataItem({
                        value: value,// Set this to the specific value where you want the bullet
                        location: 0.5
                    });

                    bulletDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                        sprite: am5.Circle.new(root.current, {
                            radius: 9, // Adjust the radius as needed
                            fill: am5.color('#384252'), // Bullet fill color
                            stroke: am5.color(labelColor), // Bullet border color
                            strokeWidth: 2, // Bullet border width
                            centerX: am5.percent(84), // Experiment with setting this to 0 instead of 50
                            centerY: am5.percent(50),
                            // dx: 0, // Horizontal positioning offset
                            // dy: 0  // Vertical positioning offset
                        }),
                    }));

                    axis.createAxisRange(bulletDataItem);
                });


                // Add clock hand
                const createHand = (value: number | undefined, topWidth: number, bottomWidth: number, pinRadius: number, radius: number, color: am5.Color) => {
                    const handDataItem: any = axis.makeDataItem({
                        value: value
                    });

                    const hand = handDataItem.set("bullet", am5xy.AxisBullet.new(root.current, {
                        sprite: am5radar.ClockHand.new(root.current, {
                            topWidth: topWidth,
                            pinRadius: am5.percent(pinRadius),
                            radius: am5.percent(radius),
                            bottomWidth: bottomWidth,
                            //bottomWidth: 200,
                            innerRadius: am5.percent(72),

                        })
                    }));

                    hand.get("sprite").pin.setAll({
                        forceHidden: false,
                        fill: color,
                        fillOpacity: 1,
                    });

                    hand.get("sprite").hand.setAll({
                        fill: color,
                        fillOpacity: 1,
                    });


                    axis.createAxisRange(handDataItem);

                    return hand;
                }
                createHand(50, 0, 0, 70, 78, am5.color(0x5D6675));
                createHand(50, 0, 0, 70, 75, am5.color(0x001023));
                const hand2 = createHand(value, 0, 0, 61, 90, am5.color(0x4A5463));
                const hand1 = createHand(value, 0, 20, 60, 83, am5.color(0x001023));


                hand1.get("sprite").dataItem.animate({
                    key: "value",
                    to: value,
                    duration: 800,
                    easing: am5.ease.out(am5.ease.cubic)
                });
                hand2.get("sprite").dataItem.animate({
                    key: "value",
                    to: value,
                    duration: 800,
                    easing: am5.ease.out(am5.ease.cubic)
                });

                const label = chart.radarContainer.children.push(am5.Label.new(root.current, {
                    fill: am5.color(0xffffff),
                    centerX: am5.percent(50),
                    textAlign: "center",
                    centerY: am5.percent(65),
                    fontSize: "39px",
                    fontWeight: "700"
                }));
                label.set("text", Math.round(value ?? 0).toString());

                const label2 = chart.radarContainer.children.push(am5.Label.new(root.current, {
                    fill: am5.color(labelColor),
                    centerX: am5.percent(65),
                    textAlign: "center",
                    centerY: am5.percent(-10),
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "-10px"
                }));


                const imgSrc = (value <= 15) ? InfoRed : (value > 15 && value <= 85) ? InfoYellow : InfoGreen
                const healthScoreStatus = (value <= 15) ? HealthScoreStatus.bad : (value > 15 && value <= 85) ? HealthScoreStatus.good : HealthScoreStatus.better

                const svgIcon = chart.radarContainer.children.push(
                    am5.Picture.new(root.current, {
                        src: imgSrc, // Base64-encoded SVG
                        width: 16,
                        height: 16,
                        centerX: am5.percent(-95),
                        centerY: am5.percent(-90),
                        visible: false // Initially hidden
                    })
                );
                label2.set("text", `${healthScoreStatus}`);
                svgIcon.set("visible", true); // Show the SVG icon


                // Create a legend
                const legend = chart.children.push(
                    am5.Legend.new(root.current, {
                        contentAlign: "center",  // Align legend content
                        centerX: am5.percent(50), // Ensure the legend is centered properly
                        x: am5.percent(50),       // Center horizontally
                        y: am5.percent(130),      // Adjust for better positioning
                        centerY: am5.p100,        // Anchor to the bottom
                        layout: root.current.horizontalLayout // Arrange items in a row
                    })
                );

                // Set the legend data (name and value)
                legend.data.setAll([
                    {
                        name: "Last updated on:",
                        value: healthScoreTarget?.lastUpdated ? formatDate(healthScoreTarget?.lastUpdated) : ''
                    }
                ]);

                legend.itemContainers.template.setAll({
                    layout: root.current.horizontalLayout,
                    width: am5.percent(100),
                    paddingLeft: 10,
                    paddingRight: 10,
                    valign: "middle",
                });


                legend.labels.template.setAll({
                    text: "{name}",
                    fill: am5.color('#B8C5CC'),
                    fontSize: 10,
                    centerX: am5.percent(350),
                    x: am5.percent(350),
                    width: am5.percent(50),
                    wrap: false
                });

                legend.valueLabels.template.setAll({
                    text: "{value}",
                    fill: am5.color('#B8C5CC'),
                    fontSize: 10,
                    centerX: am5.percent(30),
                    x: am5.percent(30),
                    width: am5.percent(50),
                    wrap: false
                });
                // Clean up chart when the component unmounts
                return () => {
                    root.current.dispose();
                };
            }
        }, 500);
    }, []);


    return (
        <>
            <div id={`${chartID}`} ref={chartRef} className={`${classValue} health-score-chart-wrapper`}></div>
        </>
    );
};

export default memo(HealthChart);