import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as ChevronUp } from '../../../../../../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../../../../../images/chevron-down.svg';
import './dropdown.scss';
import Loader from '../../../../../../common/page-loader/ComponentLoader';
import { useAppSelector } from '../../../../../../../hooks/storeHooks';
import NoData from '../../../../../../dashboard/components/no-data/NoData';

export interface DropdownItems {
  id: number;
  name: string;
}

interface DropdownProps {
  items?: DropdownItems[];
  defaultOption?: string;
  onChange?: (item: DropdownItems) => void;
  value: string;
  disabled?: boolean;
  loading: boolean,
  handleScroll?: () => void,
  searchValue: string,
  handleSearchChange: (value: string) => void
}
const CustomerDropdown: React.FC<DropdownProps> = ({
  items,
  defaultOption = 'Select an assignee',
  onChange,
  value,
  disabled = false,
  loading,
  handleScroll,
  searchValue,
  handleSearchChange
}) => {
  // const [selectedItem, setSelectedItem] = useState<DropdownItems | null>(null);
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownListRef = React.createRef<HTMLDivElement>();
  const { assigneeListresponse } = useAppSelector((state) => state.ticketTableSlice);

  const handleClick = (item: DropdownItems) => {
    setIsActive(false);
    if (onChange) {
      onChange(item);
    }
  };





  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      if (searchValue !== '')
        handleSearchChange('')
    }
  }, [isActive])

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        if (
          ((scrollHeight - scrollTop - clientHeight) < 400) &&
          (!loading && assigneeListresponse.hasNextPage)
        ) {
          if (handleScroll) handleScroll()
        }
      }
    },
    [items, loading])

  return (
    <div ref={dropdownRef} className={`dropdown ${disabled ? 'disabled' : ''}`}>
      <div onClick={() => !disabled && setIsActive(!isActive)} className='dropdown-btn'>
        <span className='dropdown-input flex items-center'>
          {value && value !== defaultOption ? (
            <>
              <span className='initials-badge'>{value.substring(0, 2).toUpperCase()}</span>
              <span className='ml-2 assignee-name'>{value}</span>
            </>
          ) : (
            defaultOption
          )}
        </span>

        <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      {isActive && !disabled && (
        <div className='dropdown-content reassign-dropdown-container relative' style={{ display: isActive ? 'block' : 'none' }}>
          <input
            type='text'
            placeholder='Search'
            value={searchValue}
            onChange={(e) => handleSearchChange(e.target.value)}
            className='dropdown-search'
            disabled={disabled}
          />
          {loading && <Loader />}

          <div className={`reassign-dropdown-list ${loading ? 'blur-background pointer-events-none' : ''}`}
            ref={dropdownListRef}
            onScroll={() => fetchMoreOnBottomReached(dropdownListRef.current)}
          >

            {
              (items && items?.length > 0) ?
                items?.map((item, index) => (
                  <div
                    key={index}
                    className={`item w-full text-left ${value === item.name ? 'item-selected text-left' : ''}`}
                    onClick={() => handleClick(item)}
                  >
                    {item.name}
                  </div>
                ))
                :
                (items?.length === 0 && !loading) ?
                  <NoData heading='No data' />
                  : ''
            }
          </div>        </div>
      )}
    </div>
  );
};

export default CustomerDropdown;
