import React, { useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { IntakeDetailsProps, IntakeDischargeData } from './interfaces/intake.interfaces';
import IntakeChart from './charts/IntakeChart';
import IntakeModal from './modals/IntakeModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { generatePDF } from '../../common';
import { fetchAndProcessTimeSeriesData } from '../../utills/generalFunc';

const CHART_NAME = "intake-discharge";

const IntakeDischargeDetails: React.FC<IntakeDetailsProps> = ({ heading }) => {
    const dispatch = useAppDispatch();
    const selectedWell = sessionStorage.getItem('selectedWellName') && JSON.parse(sessionStorage.getItem('selectedWellName') || '');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<IntakeDischargeData>({ intake: [], discharge: [] });
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
        key: 'selection',
    });
    const [expandedData, setExpandedData] = useState<IntakeDischargeData>({ intake: [], discharge: [] });

    const [expandedLoading, setExpandedLoading] = useState(false);
    const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);
    const [showLoading, setShowLoading] = useState(true);
    const { userPreference, assetInfo, loading } = useAppSelector((state) => ({
        userPreference: state.controlroom.userPreference?.find(item => item.key === CHART_NAME),
        assetInfo: state.assetInfo.assetDetails,
        loading: state.wellControlroomCharts.loading,
    }));
    const detailsLoading = useAppSelector((state) => state.assetInfo.detailsLoading);
    
    useEffect(() => {
        if (detailsLoading && loading) {
            setShowLoading(true);
        } else {
            const timer = setTimeout(() => setShowLoading(false), 100);
            return () => clearTimeout(timer);
        }
    }, [loading]);
    const hElement = document.getElementById('main-root');
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);

    const intakeDischargeChannelData = {
        intake: useAppSelector(getChannelDataByKey(channelIDsObj.intake || '')),
        discharge: useAppSelector(getChannelDataByKey(channelIDsObj.discharge || '')),
    };

    const intakeDischargeTimeSeriesData = {
        intake: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.intake || '')),
        discharge: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.discharge || '')),
    };

    useEffect(() => {
        if (userPreference?.mappings?.sle && assetInfo?.isSleAsset) {
            setData({
                intake: intakeDischargeTimeSeriesData?.intake?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                discharge: intakeDischargeTimeSeriesData?.discharge?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });
        } else if (userPreference?.mappings?.xspoc && !assetInfo?.isSleAsset) {
            setData({
                intake: intakeDischargeChannelData.intake?.dataPoints.map((item: any) => ({
                    date: new Date(item.date).getTime(),
                    value: Number(item.value),
                })),
                discharge: intakeDischargeChannelData.discharge?.dataPoints.map((item: any) => ({
                    date: new Date(item.date).getTime(),
                    value: Number(item.value),
                })),
            });
        }
    }, [userPreference?.key, JSON.stringify(intakeDischargeChannelData), JSON.stringify(intakeDischargeTimeSeriesData)]);


    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setExpandedLoading(true);

        fetchAndProcessTimeSeriesData({
            dispatch,
            assetName: selectedWell?.assetName,
            startDate: selectedDateRange.startDate.toISOString(),
            endDate: selectedDateRange.endDate.toISOString(),
            userPreference,
            channelIDsObj,
            isSleAsset: assetInfo?.isSleAsset || false,
            chartName: CHART_NAME,
            keys: ['intake', 'discharge']
        }).then((res) => {
            setExpandedData(
                {
                    intake: res.result.intake.map((item: any) => ({ date: new Date(item.date).getTime(), value: Number(item.value) })),
                    discharge: res.result.discharge.map((item: any) => ({ date: new Date(item.date).getTime(), value: Number(item.value) })),
                }
            );
            setExpandedLoading(false);
        })
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    useEffect(() => {
        setExpandedData(data);
    }, [data])

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const printWidgetAsPDF = () => {
        generatePDF(
            [root],
            setIsDropdownOpen,
            currentUser || null,
            "Intake / Discharge",
            { width: 400, hight: 160 }
        )
    };

    return (
        <>
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPreference?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPreference?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName ${((data?.intake === undefined || data?.intake.length === 0) && (data?.discharge === undefined || data?.discharge.length === 0)) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                {/* <button className={`btn btn-default cancelSelectorName ${((data?.intake === undefined || data?.intake.length === 0) && (data?.discharge === undefined || data?.discharge.length === 0)) ? 'btn-disabled' : ''}`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button> */}
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="intakeDischarge-container" className='h-full wellChartSection intakeDischargeChartSection'>
                    {showLoading ? (
                        // <div className='well-production-loader'>
                        <Loader />
                        // </div> 
                    ) : data?.intake?.length > 0 || data?.discharge?.length > 0 ? (
                        <>
                            <IntakeChart chartID={'intakeChartdiv'} chartRef={cardChartRef} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' description="Please adjust your date range or filter criteria
                        and try again."/>
                    )}
                </div>
            </div>
            <IntakeModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Intake / Discharge'}
                loading={expandedLoading || showLoading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal intakeChartModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {expandedLoading || showLoading ? (
                            <Loader />
                        ) : expandedData?.intake?.length > 0 || expandedData?.discharge?.length > 0 ? (
                            <IntakeChart chartID={'intakeChartdiv'} chartRef={modalChartRef} data={expandedData} root={root2} />
                        ) : (
                            <NoData heading='No data found' description="Please adjust your date range or filter criteria
                            and try again."/>
                        )
                        }
                    </>
                }
            />
        </>
    );
};

export default IntakeDischargeDetails;