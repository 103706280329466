import { useEffect, useState } from "react";
import { EquipmentItem } from "../Equipment";
import '../Equipment.scss'
import { slugify } from "../../../../../utilities/Slugify";
import { useNavigate } from "react-router-dom";

interface EquipmentListProps {
  selectedEquipmentIndex: number | null;
  onSelectEquipment: (index: number, equipmentId: string, equipmentName: string) => void;
  equipmentItems: EquipmentItem[];
  currentAsset: any;
}

const EquipmentList: React.FC<EquipmentListProps> = ({ selectedEquipmentIndex, onSelectEquipment, equipmentItems, currentAsset }) => {

  const navigate = useNavigate()
  const defaultIndex = 0
  const [forceUpdate, setForceUpdate] = useState(0)

  useEffect(() => {
    if (selectedEquipmentIndex === null && equipmentItems.length > 0) {
      // Default to the first item if none is selected
      onSelectEquipment(0, equipmentItems[0].value, equipmentItems[0].key);
    } else if (selectedEquipmentIndex !== null && equipmentItems[selectedEquipmentIndex]) {
      const equipment = equipmentItems[selectedEquipmentIndex];
      onSelectEquipment(selectedEquipmentIndex, equipment.value, equipment.key);
    }
  }, [currentAsset.assetName]);

  useEffect(() => {
    const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
    if (currentTabFromQuery) {
      const tabIndex = equipmentItems.findIndex((tab) => slugify(tab.key) === currentTabFromQuery);
      if (tabIndex !== -1) {
        onSelectEquipment(tabIndex, equipmentItems[tabIndex].value, equipmentItems[tabIndex].key);
      } else {
        onSelectEquipment(0, equipmentItems[0].value, equipmentItems[0].key);
        if (selectedEquipmentIndex === defaultIndex)
          setForceUpdate((prev) => prev + 1)
      }
    } else {
      onSelectEquipment(0, equipmentItems[0].value, equipmentItems[0].key);
      if (selectedEquipmentIndex === defaultIndex)
        setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, equipmentItems]);

  useEffect(() => {

    if (selectedEquipmentIndex === null) return;

    const newTabSlug = slugify(equipmentItems[selectedEquipmentIndex].key);
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('innerTab');
    // Only navigate if the tab slug in the URL is differents
    if (currentTabSlug !== newTabSlug) {
      params.set('innerTab', newTabSlug)
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [selectedEquipmentIndex, forceUpdate]);

  return (
    <ul className="equipment-list-container">
      {equipmentItems.map((equipment, index) => (
        <li
          key={index}
          className={`equipment-list-item ${selectedEquipmentIndex === index ? 'active-list' : ''}`}
          onClick={() => onSelectEquipment(index, equipment.value, equipment.key)}
        >
          {equipment.key}</li>

      ))}
    </ul>

  );
};
export default EquipmentList;