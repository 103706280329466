import React, { useEffect } from 'react';
import WellDashboardHeader from './components/well-dashboard-header/WellDashboardHeader';
import WellControlroomCharts from '../well-controlroom-charts/WellControlroomCharts';
import AssetControlRoomHeader from '../asset-control-room-header/components/AssetControlRoomHeader';
import AssetControlRoomDrawer from '../asset-control-room-navigation-drawer/components/AssetControlRoomDrawer';
import './WellControlroomDashboard.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { clearAssetDetails, fetchAssetInfo } from '../asset-info/AssetInfoSlice';
import { fetchUserPrefrence } from './controlroom.slice';
import { ToastContainer } from 'react-toastify';
import { updateAssetControlRoomDrawerProps } from '../asset-control-room-navigation-drawer/AssetControlRoomDrawerSlice';
import { setChartsLoading } from '../well-controlroom-charts/wellControlroomSlice';

const WellControlroomDashboard = () => {
  const assetControlRoomDrawer = useAppSelector((state) => state.assetControlRoomDrawer);
  const selectedWellName = sessionStorage.getItem('selectedWellName');
  const selectedAssetName = selectedWellName && JSON.parse(selectedWellName || '');

  const dispatch = useAppDispatch();

  useEffect(() => {
    const selectedWellName = sessionStorage.getItem('selectedWellName');
    const selectedAssetNames = selectedWellName && JSON.parse(selectedWellName || '');
    dispatch(fetchAssetInfo({ assetName: selectedAssetNames?.assetName })).then((res: any) => {
      if (fetchAssetInfo.rejected.match(res)) {
        dispatch(setChartsLoading(false));
      }
    })
    dispatch(fetchUserPrefrence("asset-control-room"));

    return () => {
      dispatch(clearAssetDetails());
      dispatch(updateAssetControlRoomDrawerProps({ openState: false, selectedComponent: null }))
    }
  }, []);


  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className={`${assetControlRoomDrawer?.openState ? 'main-content asset-controlroom-dashboard openDrawer-content' : 'main-content asset-controlroom-dashboard'}`}>
        <div className={`${assetControlRoomDrawer?.openState ? 'w-1/2' : 'w-[100%]'}`}>
          <WellDashboardHeader headerTitle={selectedAssetName && selectedAssetName?.assetName} />
          <AssetControlRoomHeader />
          <WellControlroomCharts />
        </div>
        <AssetControlRoomDrawer />
      </div>
    </>
  )
}

export default WellControlroomDashboard;