export const setPointParamStandardTypeId = {
    "setpointSpeed": {
        "setpointSpeed": "279"
    },
    "pidSetting": {
        "enable": "1416",
        "processVariable": null,
        "action": "10022",
        "shutdownSetpoint": "10023",
        "restartSetpoint": "10024",
        "deadband": "10025",
        "fixedOutput": "2",
        "pidDelay": "10026",
        "setpoint": "10017",
        "maxFrequency": "10021",
    },
    "rockingStart": {
        "enable": "10027",
        "rockingFrequency": "10028",
        "directionTime": "10029",
        "cyclesAllowed": "10030",
        "cyclesRemaining": "10031",
        "resetDelay": "10043",
    },
    "gasLockRecovery": {
        "enable": "1417",
        "startDelay": "10032",
        "trigerDelay": "10033",
        "attemptsAllowed": "10034",
        "lowAmperageSetpoint": "10035",
        "resetDelay": "10036",
        "step1Frequency": "10037",
        "step1Duration": "10038",
        "step2Frequency": "10039",
        "step2Duration": "10040",
        "step3Frequency": "10041",
        "step3Duration": "10042",
    }
}


export const allowedPocTypes = ['38']


export enum SetPointGroupName {
    SetpointSpeed = "setpointSpeed",
    PIDSetting = "pidSetting",
    RockingStart = "rockingStart",
    GasLockRecovery = "gasLockRecovery"
}

// Setpoint Speed
export enum SetpointVariable {
    SetpointSpeed = "setpointSpeed",
}

export enum PIDSettingVariable {
    // PID Setting
    PidEnable = "enable",
    ProcessVariable = "processVariable",
    Action = "action",
    ShutdownSetpoint = "shutdownSetpoint",
    RestartSetpoint = "restartSetpoint",
    Deadband = "deadband",
    FixedOutput = "fixedOutput",
    PidDelay = "pidDelay",
    Setpoint = "setpoint",
    MaxFrequency = "maxFrequency",
}

export enum RockingStartVariable {
    // Rocking Start
    RockingEnable = "enable",
    RockingFrequency = "rockingFrequency",
    DirectionTime = "directionTime",
    CyclesAllowed = "cyclesAllowed",
    CyclesRemaining = "cyclesRemaining",
    ResetDelay = 'resetDelay'
}

export enum GasLockRecoveryVariable {
    // Gas Lock Recovery
    GasLockEnable = "enable",
    StartDelay = "startDelay",
    TriggerDelay = "trigerDelay",
    AttemptsAllowed = "attemptsAllowed",
    LowAmperageSetpoint = "lowAmperageSetpoint",
    ResetDelay = "resetDelay",
    Step1Frequency = "step1Frequency",
    Step1Duration = "step1Duration",
    Step2Frequency = "step2Frequency",
    Step2Duration = "step2Duration",
    Step3Frequency = "step3Frequency",
    Step3Duration = "step3Duration",
}