import React, { useEffect, useRef } from 'react'
import './ControlRoomDashboard.scss'
import ControlRoomHeader from './components/header/ControlRoomHeader'
import TabWithUrlComponent from '../../components/TabWithUrl';
import ControlRoomWells from './components/Wells/ControlRoomWells';
import { ToastContainer } from 'react-toastify';
import { clearVisibilityWatchListItems, emptyWellWatchList, fetchWellsWatchList } from './components/Wells/ControlRoomWellsSlice';
import { fetchAssetList, fetchAssetListTimeSeries, resetControlRoomWells } from './components/Wells/all-wells-component/AllWellSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import ControlRoomTickets from './components/tickets/components/ControlRoomTickets';

const FIFTEEN_MINUTES = 15 * 60 * 1000;

const ControlRoomDashboard = () => {
    const tabs = [
        { label: 'Wells', component: ControlRoomWells },
        { label: 'Tickets', component: ControlRoomTickets },
        { label: 'Tasks', component: () => <h1>Tasks</h1>, disabled: true },
    ];
    const dispatch = useAppDispatch();

    const abortController = useRef<AbortController | null>(null);
    const abortControllerimeSeries = useRef<AbortController | null>(null);
    const selectedWatchList = useAppSelector((state) => state.wellWatchList.selectedWatchList);

    const fetchData = () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        abortController.current = new AbortController();
        const params = selectedWatchList?.id
            ? { pageSize: 25, pageNumber: 1, watchListId: selectedWatchList?.id, searchQuery: "", includeTimeseriesFields: false, signal: abortController?.current?.signal }
            : { pageSize: 25, pageNumber: 1, searchQuery: "", includeTimeseriesFields: false, signal: abortController?.current?.signal };
        dispatch(fetchAssetList(params)).then(() => fetchDataTimeSeries())
    };

    const fetchDataTimeSeries = () => {
        if (abortControllerimeSeries.current) {
            abortControllerimeSeries.current.abort();
        }
        abortControllerimeSeries.current = new AbortController();
        const params = selectedWatchList?.id
            ? { pageSize: 25, pageNumber: 1, watchListId: selectedWatchList?.id, searchQuery: "", includeTimeseriesFields: true, signal: abortController?.current?.signal }
            : { pageSize: 25, pageNumber: 1, searchQuery: "", includeTimeseriesFields: true, signal: abortController?.current?.signal };
        dispatch(fetchAssetListTimeSeries(params))
    };


    const controlRoomRefreshHandler = () => {
        dispatch(emptyWellWatchList());
        dispatch(resetControlRoomWells());
        dispatch(fetchWellsWatchList({ pageSize: 25, pageNumber: 1 }));
        dispatch(clearVisibilityWatchListItems());
        fetchData();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            controlRoomRefreshHandler();
        }, FIFTEEN_MINUTES);

        return () => clearInterval(interval);
    }, [selectedWatchList])


    return (
        <div className="control-room-component">
            <ToastContainer position='top-right' autoClose={3000} />
            <ControlRoomHeader controlRoomRefreshHandler={controlRoomRefreshHandler} />

            <TabWithUrlComponent className='!px-[32px]' initialTab={{ tab: 'Wells' }} tabs={tabs} value='Wells' />
        </div>
    )
}

export default ControlRoomDashboard