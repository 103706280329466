// EquipmentService.ts
import { http } from '../../../../config/HttpCommon';

export const getEquipment = async (assetId: string) => {
  try {
    const response = await http.get(`GetEquipment/${encodeURIComponent(assetId)}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment data');
  }
};

export const getEquipmentDetails = async (wellName: string, equipmentId: string, cancelToken: any) => {
  try {
    const response = await http.get(`GetEquipmentDetails/${encodeURIComponent(wellName)}/${equipmentId}`, { cancelToken });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment details');
  }
};


export const getEquipmentVolume = async (oracleItemNumber: string) => {
  try {
    const response = await http.get(`GetEquipmentVolume/${oracleItemNumber}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch equipment volume details');
  }
};

