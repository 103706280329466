import React, { useEffect, useState, useRef } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
//import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import ProdChart from './charts/ProdChart';
import { ProductionDetailsProps, WellProductionData } from './interfaces/prod.interfaces';
import ProdModal from './modals/ProdModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { fetchProductionTrendsByWell } from '../../wellControlroomSlice';
import { AppUser } from '../../../user/model/AppUser';
import { generatePDF } from '../../common';

const CHART_NAME = 'production';

const ProductionDetails: React.FC<ProductionDetailsProps> = ({ heading }) => {
    const getLast7DaysDate = () => {
        const today = new Date();
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 6);
        return last7Days;
    };

    //const [isToggleActive, setIsToggleActive] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [data, setData] = useState<WellProductionData>({
        gasData: [],
        oilData: [],
        waterData: [],
    });
    const userPreference = useAppSelector((state) => state.controlroom.userPreference)?.find(item => item.key === CHART_NAME);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);
    //const [detailsData, setDetailsData] = useState<WellData[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: getLast7DaysDate(),
        endDate: new Date(),
        key: 'selection',
    });
    //const storedUserData = localStorage.getItem('loggedInUser');
    //const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
    const hElement = document.getElementById('main-root');
    const dispatch = useAppDispatch();
    const storedUserData = localStorage.getItem('loggedInUser');
    const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
    const selectedWell = JSON.parse(sessionStorage.getItem('selectedWellName') || '{}');
    const [expandedData, setExpandedData] = useState<WellProductionData>({
        gasData: [],
        oilData: [],
        waterData: [],
    });
    const [loading, setLoading] = useState<boolean>(true);
    const [expandedDataLoading, setExpandedDataLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading
        dispatch(fetchProductionTrendsByWell({
            startDate: (selectedDateRange.startDate || new Date()).toISOString(),
            endDate: (selectedDateRange.endDate || new Date()).toISOString(),
            assetName: selectedWell?.assetName
        })).then((response) => {
            if (fetchProductionTrendsByWell.fulfilled.match(response)) {
                transformDataFunc(response.payload.wellProductionTrend, setData);
            } else {
                setData({
                    gasData: [],
                    oilData: [],
                    waterData: [],
                });
            }
            setLoading(false);
        });
    }, [dispatch, storedUser?.id, selectedWell?.assetName]);


    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setExpandedDataLoading(true);
        !isFirstRender.current && dispatch(fetchProductionTrendsByWell({
            startDate: (selectedDateRange.startDate || new Date()).toISOString(),
            endDate: (selectedDateRange.endDate || new Date()).toISOString(),
            assetName: selectedWell?.assetName
        })).then((response) => {
            if (fetchProductionTrendsByWell.fulfilled.match(response)) {
                transformDataFunc(response.payload.wellProductionTrend, setExpandedData);
            } else {
                setExpandedDataLoading(false);
                setExpandedData({
                    gasData: [],
                    oilData: [],
                    waterData: [],
                });
            }
            setExpandedDataLoading(false);
        });
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    useEffect(() => {
        setExpandedData(data);
    }, [data])


    /* const handleToggleChange = (event: any) => {
        event.stopPropagation
        setIsToggleActive(!isToggleActive)
    } */

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //   function setToStartOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    //   }
    //   function setToEndOfDay(date: any) {
    //     return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
    //   }
    // Ensure selectedDateRange.startDate is at the start of the day
    //   const startDate = setToStartOfDay(selectedDateRange.startDate);
    //   // Ensure selectedDateRange.endDate is at the end of the day
    //   const endDate = setToEndOfDay(selectedDateRange.endDate);

    const transformDataFunc = async (
        data: WellProductionData,
        setData: React.Dispatch<React.SetStateAction<WellProductionData>>
    ) => {
        const transformedData: WellProductionData = {
            gasData: data.gasData?.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            oilData: data.oilData?.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
            waterData: data.waterData?.map((item) => ({
                date: new Date(item.date).getTime(),
                value: item.value,
            })),
        };
        setData(transformedData);
    }

    const printWidgetAsPDF = () => {
        generatePDF(
            [root],
            setIsDropdownOpen,
            currentUser || null,
            "Production",
            { width: 500, hight: 140 }
        )
    };

    return (
        <>
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPreference?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPreference?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName ${(data?.gasData?.length && data?.oilData?.length && data?.waterData?.length) === 0 ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                {/* <button className={`btn btn-default cancelSelectorName ${(data?.gasData?.length && data?.oilData?.length && data?.waterData?.length) === 0 ? 'btn-disabled' : ''}`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button> */}
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="wellProductionTrend-container" className='wellChartSection wellProductionTrendsChartSection'>
                    {loading ? (
                        // <div className='well-production-loader'>
                        <Loader />
                        // </div> 
                    ) : data?.gasData?.length > 0 || data?.oilData?.length > 0 || data?.waterData?.length > 0 ? (
                        <>
                            {/*  <div className='switch-block'>
                                <ToggleSwitch checked={isToggleActive} onChange={handleToggleChange} label={'Virtual flow meter'} />
                            </div> */}
                            <ProdChart chartID={'wellProductionTrendsChartdiv'} chartRef={cardChartRef} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' description="Please adjust your date range or filter criteria
                        and try again."/>
                    )}
                </div>
            </div>
            <ProdModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Production'}
                loading={expandedDataLoading || loading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal prodChartModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {
                            expandedDataLoading || loading ? (
                                <Loader />
                            ) : expandedData?.gasData?.length > 0 || expandedData?.oilData?.length > 0 || data?.waterData?.length > 0 ?



                                <ProdChart chartID={'wellProductionTrendsChartdiv'} chartRef={modalChartRef} data={expandedData} root={root2}
                                /> : (
                                    <NoData heading='No data found' description="Please adjust your date range or filter criteria
                                    and try again."/>
                                )}
                    </>
                }
            />
        </>
    );
};

export default ProductionDetails;