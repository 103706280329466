import { useCallback, useEffect, useRef, useState } from "react";
import WatchListCard from "../watchListCard/watchListCard";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { WellWatchList, WellWatchListData } from "../../interfaces/ControlRoomWells.interface";
import { deleteWatchList, fetchWellsWatchList, fetchWellsWatchListCount, removeWatchListById, setSelectedWatchList, setVisibilityWatchListItems, updateWatchListById } from "../ControlRoomWellsSlice";
import Loader from "../../../../common/page-loader/ComponentLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { showToaster } from "../../../../dashboard/components/asset-location/AssetList";
import NoData from "../../../../dashboard/components/no-data/NoData";

interface WatchWellsListProps {
    setIsBuildWatchlistFiltersModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveWell: React.Dispatch<React.SetStateAction<WellWatchList>>;
    activeWell: WellWatchList;
}

const WatchWellsList = (
    { setIsBuildWatchlistFiltersModalOpen, setActiveWell, activeWell }: WatchWellsListProps
) => {
    const dispatch = useAppDispatch();
    const wellWatchList = useAppSelector((state) => state.wellWatchList).wellWatchList.results as WellWatchList[];
    const wellWatchListData = useAppSelector((state) => state.wellWatchList).wellWatchList as WellWatchListData;
    const selectedWatchList = useAppSelector((state) => state.wellWatchList.selectedWatchList);
    const fetchedVisibleWatchListItems = useAppSelector((state) => state.wellWatchList.fetchedVisibleWatchListItems);

    const loading = useAppSelector((state) => state.wellWatchList.loading);
    const wellWatchlistLoading = useAppSelector((state) => state.wellWatchList.wellWatchlistLoading);

    const [wells, setWells] = useState<WellWatchList[]>(wellWatchList);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [visibleItems, setVisibleItems] = useState<string[]>([]);

    useEffect(() => {
        const newItemsToFetch = Array.from(visibleItems).filter(id => !fetchedVisibleWatchListItems.includes(id));
        if (newItemsToFetch.length > 0) {
            dispatch(fetchWellsWatchListCount(newItemsToFetch))
                .then((res) => {
                    if (res?.payload?.success) {
                        res?.payload?.data?.forEach((item: any) => {
                            dispatch(updateWatchListById(item));
                        });
                    }
                })
            dispatch(setVisibilityWatchListItems([...fetchedVisibleWatchListItems, ...newItemsToFetch]));
        }
    }, [visibleItems, dispatch, fetchedVisibleWatchListItems]);


    const observer = useRef<IntersectionObserver | null>(null);
    const visibilityObserver = useRef<IntersectionObserver | null>(null);

    const lastDocumentRef = useCallback((node: HTMLDivElement | null) => {
        if (loading) return;
        if (!wellWatchListData.hasNextPage) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && wellWatchListData.hasNextPage) {
                dispatch(fetchWellsWatchList({
                    pageSize: 25,
                    pageNumber: Math.floor(wells.length / 25) + 1
                }))
            }
        });

        if (node) observer.current.observe(node);
    }, [wells?.length, wellWatchListData.hasNextPage, loading, dispatch]);

    useEffect(() => {
        if (visibilityObserver.current) visibilityObserver.current.disconnect();

        visibilityObserver.current = new IntersectionObserver(
            (entries) => {

                const visibleIds = entries
                    .filter(entry => entry.isIntersecting)
                    .map(entry => entry.target.getAttribute("data-id") || "").filter(entry => entry !== '')
                setVisibleItems(visibleIds)
            },
            { threshold: 0.5 }
        );

        const items = document.querySelectorAll(".watchlist-item");
        items.forEach((item) => visibilityObserver.current?.observe(item));

        return () => visibilityObserver.current?.disconnect();
    }, [wells]);

    const handleCardClick = (well: WellWatchList) => {
        if (selectedWatchList?.id === well.id) {
            dispatch(setSelectedWatchList({} as WellWatchList));
        } else {
            dispatch(setSelectedWatchList(well));
        }
    };

    const handleDropdownClick = (well: WellWatchList) => {
        setActiveDropdownId(well.id);
    };

    const handleCloseDropdown = () => {
        setActiveDropdownId(null);
    };

    useEffect(() => {
        setWells(wellWatchList);
    }, [JSON.stringify(wellWatchList)]);

    const handleDeleteWatchList = (well: WellWatchList) => {
        setActiveWell(well);
        setIsDeleteModalOpen(true);
    };

    const handleEditWatchList = (well: WellWatchList) => {
        setActiveWell(well);
        setIsBuildWatchlistFiltersModalOpen(true);
    };

    const onDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const onDeleteWatchList = async () => {
        if (activeWell.id) {
            setIsDeleteModalOpen(false);
            await dispatch(deleteWatchList(activeWell.id));
            dispatch(removeWatchListById(activeWell.id));
            setActiveWell({} as WellWatchList);
            showToaster("Watchlist deleted successfully", "success");
            dispatch(setSelectedWatchList({} as WellWatchList));
        }
    };

    return (
        <>
            {wellWatchlistLoading && (
                <div className="watch-wells-list-loader">
                    <Loader />
                </div>
            )}

            {(
                <div className="flex items-center min-h-[200px] watch-wells-list-container">
                    {wellWatchList && wellWatchList.length > 0 ? (
                        <>
                            {isDeleteModalOpen && (
                                <DeleteModal onOkClick={onDeleteWatchList} onCloseClick={onDeleteModalClose} />
                            )}
                            <div className={`${loading ? "hidden blur-background pointer-events-none" : ""} flex watch-wells-list`}>
                                {wells?.map((well, index) => (
                                    <div
                                        key={well.id + index}
                                        ref={index === wells.length - 1 ? lastDocumentRef : null}
                                        className={`watchlist-item ${index === 0 ? "sticky-card" : ""}`}
                                        {...(!well?.isDefault ? { "data-id": well.id } : {})}
                                    // data-id={well.id}
                                    >
                                        <WatchListCard
                                            title={well.name}
                                            description={well.description}
                                            count={well.count}
                                            isDropdownOpen={activeDropdownId === well.id}
                                            onClick={handleCardClick}
                                            onDropdownClick={handleDropdownClick}
                                            onCloseDropdown={handleCloseDropdown}
                                            isActive={well.id === selectedWatchList?.id}
                                            handleDeleteWatchList={handleDeleteWatchList}
                                            handleEditWatchList={handleEditWatchList}
                                            well={well}
                                            key={well.id + index}
                                        // loading={well.isDefault ? false : !fetchedVisibleWatchListItems.includes(well.id) ? true : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* <div className="mt-4">
                                <strong>Visible Items:</strong> {visibleItems.join(", ")}
                            </div> */}
                        </>
                    ) : !wellWatchlistLoading && wellWatchList?.length && (
                        <div className="flex flex-column items-center justify-center no-data-found watchlist-no-data">
                            <NoData heading="No data found" />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default WatchWellsList;
