import React, { useEffect, useState } from 'react';
import '../../user/admin/Admin.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks';
import { fetchLookups, updateLookups } from '../supportSlice';
import LookupsList from './components/LookupsList';
import { ConfigurationItem } from '../supportInterface';
import Loader from '../../common/page-loader/ComponentLoader';
import LookupsInfoModal from '../components/LookupsInfoModal';
import { showToaster } from '../../dashboard/components/asset-location/AssetList';
import { AppUser } from '../../user/model/AppUser';

const LookUps: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedLookupId, setSelectedLookupId] = useState<string | null>(null);
  const [selectedLookupRecord, setSelectedLookupRecord] = useState<ConfigurationItem | null>(null);
  const lookups = useAppSelector((state) => state.SupportSlice.lookups);
  const loading = useAppSelector((state) => state.SupportSlice.loading);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const storedUserData = localStorage.getItem('loggedInUser');
  const storedUser: AppUser | null = storedUserData ? JSON.parse(storedUserData) : null;

  useEffect(() => {
    if (selectedLookupId) {
      setSelectedLookupRecord(lookups?.find((lookup) => lookup.id === selectedLookupId) || null);
    }
  }, [selectedLookupId])


  useEffect(() => {
    dispatch(fetchLookups())
  }, [])


  const updateLookupsClickHandler = () => {
    selectedLookupRecord && dispatch(updateLookups({ id: selectedLookupRecord.id, payload: selectedLookupRecord })).then((res) => {
      setIsModalOpen(false);
      if (updateLookups.fulfilled.match(res)) {
        dispatch(fetchLookups());
        showToaster("Lookups updated successfully", "success");
      } else {
        showToaster("Failed to update lookups", "error");
      }
    });
  }


  const onModalClose = () => {
    setIsModalOpen(false);
  }

  return (
    <>
      <LookupsInfoModal recordName={selectedLookupRecord?.key || ""} isModalOpen={isModalOpen} onCloseModal={onModalClose} onClickOk={updateLookupsClickHandler} />
      {loading ? <div className='flex items-center loader-margin'>
        <Loader />
      </div>
        : <div className='admin-profile__container w-100 py-6 px-8'>
          <div className='card profile-card admin-profile m-0'>
            <div className='header header-notify'>
              <div className='admin-header'>
                <p className='admin-title'>Lookups</p>
                <p className='admin-text'>Manage lookups and their values. </p>
              </div>
            </div>
            <div className='body user-details-body'>
              {storedUser?.supportAccess == "write" && <div className='flex justify-end pb-3'>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className='btn btn-primary border border-danger'>Update Lookups</button>
              </div>}
              <div className='adminuser-block'>
                <div className='flex flex-row'>
                  <div className='basis-1/5 admin-first-column'>
                    <LookupsList
                      selectedLookupId={selectedLookupId}
                      setSelectedLookupId={setSelectedLookupId}
                    />
                  </div>
                  <div className='basis-4/5'>
                    <textarea
                      className='custom-input lookups-editable-input'
                      onChange={
                        (e) => {
                          setSelectedLookupRecord((prevRecord) => prevRecord ? {
                            ...prevRecord,
                            value: e.target.value
                          } : null);
                        }

                      }
                      value={selectedLookupRecord?.value || ""}
                      name="lookups-editable-input"
                      id="looksupEditableInput"
                      readOnly={storedUser?.supportAccess !== "write"}
                    >
                    </textarea>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  );
};

export default LookUps;
