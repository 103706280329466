import { useEffect } from "react";
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { useAppSelector } from "../../../../../hooks/storeHooks";
import { iprGraphData } from "./graphs";
import Loader from "../../../../common/page-loader/ComponentLoader";

const IPRData = () => {
    const mainReportData = useAppSelector((state) => state.analysisTab.mainReportData);
    const iprData = iprGraphData(mainReportData?.IPRGraphData || []);
    const loading = useAppSelector((state) => state.analysisTab.mainReportDataLoading);

    useEffect(() => {
        let root:any;
        if (!loading) {
            root = am5.Root.new('IPRChartdiv');
            initialaizeChart(root)
        }
        return () => {
            root?.dispose();
        };
    }, [iprData]);

    const initialaizeChart = (root: am5.Root) => {
        root.setThemes([am5themes_Animated.new(root)]);

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: 'none',
                wheelY: 'none',
                pinchZoomX: false,
                pinchZoomY: false,
            }),
        );

        const xRenderer = am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 100,
        });
        xRenderer.labels.template.set('fill', am5.color('#fff'));
        const xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                autoZoom: false,
                min: 0,
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            }),
        );
        const xAxisLabel = am5.Label.new(root, {
            text: "Liquid rate (STB/D)",
            fill: am5.color("#fff"),
            // rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: 500,
            y: 70,
        });

        xAxis.children.push(xAxisLabel);

        const yRenderer = am5xy.AxisRendererY.new(root, {
            minorGridEnabled: true,
            minGridDistance: 50,
        });
        yRenderer.labels.template.set('fill', am5.color('#fff'));
        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                renderer: yRenderer,
            }),
        );
        const yAxisLabel = am5.Label.new(root, {
            text: 'Flowing bottomhole pressure (PSI)',
            fill: am5.color("#fff"),
            rotation: -90,
            centerX: am5.p100,
            centerY: am5.p100,
            x: 0,
            y: 70,
        });

        yAxis.children.push(yAxisLabel);

        const seriesWiggins = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Wiggins (liquid)',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#FEC84B"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#FEC84B"),
        });
        seriesWiggins.data.setAll(iprData.wiggins);
        seriesWiggins.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesVogel = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Vogel',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#32D583"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#32D583"),
        });
        seriesVogel.data.setAll(iprData.vogel);
        seriesVogel.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        const seriesGilbert = am5xy.SmoothedXYLineSeries.new(root, {
            name: 'Gilbert',
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'Y',
            valueXField: 'X',
            stroke: am5.color("#F97066"),
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: 'horizontal',
                labelText: '{valueY}',
            }),
            fill: am5.color("#F97066"),
        });
        seriesGilbert.data.setAll(iprData.gilbert);
        seriesGilbert.data.processor = am5.DataProcessor.new(root, {
            numericFields: ['X', 'Y'],
        });

        // const seriesComposite = am5xy.SmoothedXYLineSeries.new(root, {
        //     name: 'Composite',
        //     xAxis: xAxis,
        //     yAxis: yAxis,
        //     valueYField: 'Y',
        //     valueXField: 'X',
        //     stroke: am5.color("#4294FF"),
        //     tooltip: am5.Tooltip.new(root, {
        //         pointerOrientation: 'horizontal',
        //         labelText: '{valueY}',
        //     }),
        // fill: am5.color("#4294FF"),
        // });
        // seriesComposite.data.setAll(iprData.composite);
        // seriesComposite.data.processor = am5.DataProcessor.new(root, {
        //     numericFields: ['X', 'Y'],
        // });

        chart.series.push(seriesWiggins);
        chart.series.push(seriesVogel);
        chart.series.push(seriesGilbert);
        // chart.series.push(seriesComposite);

        const legend = am5.Legend.new(root, {
            layout: am5.GridLayout.new(root, {
                maxColumns: 3,
                fixedWidthGrid: false,
            }),
            marginTop: 30,
            useDefaultMarker: true,
        });

        legend.markerRectangles.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
            cornerRadiusBL: 10,
            cornerRadiusBR: 10,
            width: 12,
            height: 12,
          });

        // Set legend labels color
        legend.labels.template.setAll({
            fill: am5.color(0xffffff), // Adjust color as needed
        });

        // Customize or simplify the legend markers
        // legend.markers.template.setAll({
        //     width: 20, // Adjust width as needed
        //     height: 20, // Adjust height as needed
        // });

        // Add the legend to the chart
        chart.bottomAxesContainer.children.push(legend);

        // Link the legend to the series
        legend.data.setAll([seriesWiggins, seriesVogel, seriesGilbert]);
    }

    return (
        <>
            {loading ? (
                <div className='flex items-center justify-content loader-margin'>
                    <Loader />
                </div>
            ) : (
                <div>
                    <p className='capacity-graph-title'>Well inflow performance</p> 
                    <div id="IPRChartdiv" style={{ width: '100%', height: '500px' }}></div>
                </div>
            )
            }
        </>
    )

};

export default IPRData;