import React, { useEffect, useState } from 'react'
import chevronDown from '../../../../images/chevron-down.svg';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import './ScheduleEvent.scss'
import { useManageAsset } from '../hook/ManageAssetHook';
import { GasLockRecoveryVariable, PIDSettingVariable, RockingStartVariable, SetPointGroupName, setPointParamStandardTypeId } from '../../model/ManageAssetModel';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { setChangedSetpointValues } from '../../ManageAssetSlice';
import { useDispatch } from 'react-redux';

interface ScheduleEventprops {
    eventHeading: string,
    selectedEventDropdown: string,
    handleDropdownClick: (eventName: string) => void,
    child: React.ReactNode,
    disabledStatus: boolean,
    groupName: keyof typeof setPointParamStandardTypeId
}
const ScheduleEvent: React.FC<ScheduleEventprops> = ({ eventHeading, selectedEventDropdown, handleDropdownClick, child, disabledStatus, groupName }) => {

    const dispatch = useDispatch()
    const manageAssetSliceData = useAppSelector((state) => state?.manageAsset)
    const { getSetpointValue, getSetpointsDisabledStatus, getSetpointItem } = useManageAsset()
    const [isToggleActive, setIsToggleActive] = useState(getSetpointValue(groupName, 'enable') ? true : false)

    const setPointItem = getSetpointItem(groupName, groupName === SetPointGroupName.PIDSetting ? PIDSettingVariable.PidEnable : groupName === SetPointGroupName.GasLockRecovery ? GasLockRecoveryVariable.GasLockEnable : RockingStartVariable.RockingEnable)
    useEffect(() => {

        if (manageAssetSliceData.deviceValue && setPointItem) {
            const matchedDeviceValue = manageAssetSliceData.deviceValue?.find((item) => item.address.toString() === setPointItem.parameter)
            if (matchedDeviceValue) {
                let matchingOne = null

                if (setPointItem.backUpLookUpValues && setPointItem.backUpLookUpValues?.length > 0) {
                    const matchingOption = setPointItem.backUpLookUpValues.find(
                        (option) => {
                            return option.value === matchedDeviceValue.value
                        }
                    );
                    matchingOne = matchingOption || setPointItem.backUpLookUpValues[0];

                    setIsToggleActive(matchingOne.value === 1 ? true : false)
                } else {
                    setIsToggleActive(matchedDeviceValue.value === 1 ? true : false)

                }
            }
        }
    }, [manageAssetSliceData.deviceReadStatus === true, setPointItem])


    const handleToggleChange = (checked: boolean, event: any) => {
        event.stopPropagation();
        const temp = !isToggleActive
        setIsToggleActive(temp)

        if (setPointItem) {
            const value = temp ? 1 : 0
            const setpointValues = {
                [setPointItem?.parameter]: value,
            };
            dispatch(setChangedSetpointValues(setpointValues))
        }
    }


    return (
        <div className={`schedule-event-container flex flex-col justify-center ${disabledStatus ? 'disabled' : ''}`} >
            <div className='flex flex-row justify-between items-center' onClick={() => handleDropdownClick(eventHeading)}>
                <div className='w-2/3 event-heading'>{eventHeading}</div>
                <div className='flex flex-row w-1/3 justify-between items-center' >
                    <ToggleSwitch
                        checked={isToggleActive}
                        onChange={handleToggleChange}
                        label={'Enable'}
                        disabled={getSetpointsDisabledStatus(groupName, groupName === SetPointGroupName.PIDSetting ? PIDSettingVariable.PidEnable : groupName === SetPointGroupName.GasLockRecovery ? GasLockRecoveryVariable.GasLockEnable : RockingStartVariable.RockingEnable)} />
                    <button className='dropdown-btn manage-asset-dropdown' >
                        <img src={chevronDown} alt='chevron' className={`${eventHeading && selectedEventDropdown === eventHeading ? 'rotate-180' : ''}`} />
                    </button>
                </div>
            </div>
            {eventHeading && selectedEventDropdown === eventHeading ?
                <div className={`schedule-content`}> {child}</div>
                : ''}
        </div>
    )
}

export default ScheduleEvent