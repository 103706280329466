import React, { SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { Notification } from '../../model/Notification';
import './NotificationPreferences.scss';
import {
  fetchUserNotificationByUserId,
  createNotification,
  updateNotificationPreferences,
  fetchUserById,
} from '../../UserSlice';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import Calendar1 from '../../../../images/Calendar1.png';
import { AlarmChannelType } from '../../model/AlarmChannelType';
import { SchedulerTimes } from '../../model/SchedulerTimes';
import DeleteModal from '../../../common/Delete/DeleteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isEqual } from 'lodash';
import { Days } from '../../model/Days';
import SchedulerModal from './SchedulerModal';
import Edit from '../../../../images/edit-05.svg';
import Snooze from '../../../../images/clock-snooze.svg';
import Delete from '../../../../images/trash-01.svg';
import arrowDown from '../../../../images/arrow-down.svg';
import Loader from '../../../common/page-loader/ComponentLoader';

interface TabsProps {
  value?: string | undefined;
}
const NotificationPreferences: React.FC<TabsProps> = ({ value }) => {
  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const storeNotification = useAppSelector((state) => state?.user?.notifcation);
  const loading = useAppSelector((state) => state?.user?.notificationloading);
  const dispatch = useAppDispatch();
  const [notification, setNotification] = useState<Notification | null>(null);
  const [schedulerTimes, setschedulerTimes] = useState<SchedulerTimes | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<number | null>(null);
  const [isSnoozeOptionsOpen, setIsSnoozeOptionsOpen] = useState(false);
  const [isSchduleModalOpen, setIsSchduleModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState<number | null>(null);
  const hElement = document.getElementById('main-root');

  useEffect(() => {
    const fetchData = async (value: string) => {
      await dispatch(fetchUserNotificationByUserId(value));
      currentUser && !currentUser.id && await dispatch(fetchUserById(value));
    };
    if (value) {
       fetchData(value);
    }
  }, [value, dispatch]);

  useEffect(() => {
    setNotification(storeNotification);
  }, [storeNotification]);

  const openDropdown = (index: number) => {
    setIsDropdownOpen((prevIndex) => (prevIndex === index ? null : index));
  };

  const closeDropdown = () => {
    setIsDropdownOpen(null);
  };

  const handleDeleteSchdule = (deletedId: number) => {
    setDeletedId(deletedId);
    setIsDeleteModalOpen(true);
    closeDropdown();
    hElement && hElement?.classList.add('modal-open');
  };

  const handleSnoozeSchdule = () => {
    setIsSnoozeOptionsOpen((prevIsSnoozeOptionsOpen) => !prevIsSnoozeOptionsOpen);
  };

  const handleAddEvent = (schedulerTimes: SchedulerTimes) => {
    setNotification((prevNotification) => {
      const newNotification: SetStateAction<Notification | null> = prevNotification
        ? {
            ...prevNotification,
            schedulerTimes: Array.isArray(prevNotification.schedulerTimes)
              ? [...prevNotification.schedulerTimes, schedulerTimes]
              : [schedulerTimes],
          }
        : {
            id: '',
            userID: currentUser?.id || '',
            schedulerTimes: [schedulerTimes],
            rtuAlarm: [],
            hostAlarm: [],
            facilityTagAlarm: [],
            communicationFailure: [],
            communicationRecovery: [],
            wellNotes: [],
          };
      dispatchActionsToUpsertRecord(newNotification);
      return newNotification as Notification | null;
    });
  };

  const handleEditEvent = (schedulerTimes: SchedulerTimes) => {
    setNotification((prevNotification) => {
      if (prevNotification) {
        const updatedNotification = {
          ...prevNotification,
          schedulerTimes: prevNotification.schedulerTimes.map((schedule) =>
            schedule.scheduleId === schedulerTimes.scheduleId ? { ...schedule, ...schedulerTimes } : schedule,
          ),
        };
        dispatchActionsToUpsertRecord(updatedNotification);
        return updatedNotification;
      }
      return prevNotification;
    });
  };

  const handleSnoozeEvent = (scheduleId: number, snoozeTime: number) => {
    setNotification((prevNotification) => {
      if (prevNotification) {
        const updatedNotification = {
          ...prevNotification,
          schedulerTimes: prevNotification.schedulerTimes.map((schedule) =>
            schedule.scheduleId === scheduleId ? { ...schedule, snoozeTime } : schedule,
          ),
        };
        setIsDropdownOpen(null);
        return updatedNotification;
      }
      return prevNotification;
    });

    if (notification) {
      dispatchActionsToUpsertRecord(notification);
    }
  };

  const handleDeleteModal = (scheduleId: number) => {
    const updatedNotification = notification
      ? {
          ...notification,
          schedulerTimes: notification.schedulerTimes.filter((schedule) => schedule.scheduleId !== scheduleId),
        }
      : null;

    closeDeleteModal();
    setNotification(updatedNotification);

    if (updatedNotification) {
      dispatchActionsToUpsertRecord(updatedNotification);
      toast.success('Delete successful');
    }
  };

  const dispatchActionsToUpsertRecord = (newNotification: Notification) => {
    if (newNotification?.id === '') {
      dispatch(createNotification(newNotification as Notification));
    } else if (newNotification) {
      dispatch(
        updateNotificationPreferences({ id: newNotification.id, notification: newNotification as Notification }),
      );
    }
  };

  const hadleAddSchedule = () => {
    const schedulerTime: SchedulerTimes = {
      scheduleId: 0,
      scheduleStartTime: '',
      scheduleEndTime: '',
      scheduledDays: [],
      snoozeTime: 0,
    };
    setschedulerTimes(schedulerTime);
    setIsSchduleModalOpen(true);
    closeDropdown();
  };

  const handleEditSchdule = (schedulerTimes: SchedulerTimes) => {
    setschedulerTimes(schedulerTimes);
    setIsSchduleModalOpen(true);
    closeDropdown();
    hElement && hElement?.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsSchduleModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };
  const handleSaveModal = (updatedSchedulerTimes: SchedulerTimes) => {
    if (updatedSchedulerTimes.scheduleId === 0) {
      updatedSchedulerTimes.scheduleId = getNewId();
      handleAddEvent(updatedSchedulerTimes);
    } else {
      handleEditEvent(updatedSchedulerTimes);
    }
    closeModal();
  };

  const getNewId = (): number => {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const handleToggleEvent = (keyNumber: number, value: AlarmChannelType) => {
    setNotification((prevNotification) => {
      if (prevNotification) {
        const updatedUserNotification: Notification = { ...(prevNotification as Notification) };
        const propertyValue = updatedUserNotification[getKeyName(keyNumber)] || [];
        const updatedChannels = propertyValue.includes(value)
          ? propertyValue.filter((v) => v !== value)
          : [...propertyValue, value];

        if (!isEqual(propertyValue, updatedChannels)) {
          updatedUserNotification[getKeyName(keyNumber)] = updatedChannels;
          dispatchActionsToUpsertRecord(updatedUserNotification);
          return updatedUserNotification;
        }
      }
      return prevNotification;
    });

    toast.success('Notification preference updated successfully');
  };

  const getKeyName = (keyNumber: number) => {
    return keyNumber == 1
      ? 'rtuAlarm'
      : keyNumber == 2
      ? 'hostAlarm'
      : keyNumber == 3
      ? 'facilityTagAlarm'
      : keyNumber == 4
      ? 'communicationFailure'
      : keyNumber == 5
      ? 'communicationRecovery'
      : 'wellNotes';
  };
  const mapDaysToEnumStrings = (scheduledDays: number[]): string[] => {
    return scheduledDays.map((dayNumber) => Days[dayNumber]);
  };

  const convertTo12HourFormat = (time24: string) => {
    const [hourStr, minuteStr] = time24.split(':');
    let hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const amPm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12 || 12; // Convert hour to 12-hour format
    const minutePadded = minute.toString().padStart(2, '0'); // Ensure minute is two digits

    return `${hour}:${minutePadded} ${amPm}`;
  };
  return (
    <>
      <div className='flex flex-row flex-grow notification-preferences w-100 py-5'>
        <div className='notification-card security-card m-0'>
          <div className='header header-notify'>
            <div className='notification-header'>
              <p className='title'>Set a schedule</p>
              <p className='notification-text'>Control when you would like to receive notifications from us. </p>
            </div>
            <button className='btn btn-primary' onClick={hadleAddSchedule}>
              <span className='btn-icon'>+</span> Add schedule
            </button>
          </div>
          <div className='w-100 p-5'>
            <div className='scheduled-body'>
              {loading ? (
                <div className='flex items-center w-100 my-8'>
                  <Loader />
                </div>
              ) : notification?.schedulerTimes.length ? (
                notification?.schedulerTimes.map((schedulerTime, index) => (
                  <div key={index} className='notification-preferences__profile-block calendar-sechedule-block p-5'>
                    <div className='card security-card  dash-card m-0'>
                      <div className='schedule-block p-5'>
                        <div className='calendar-block'>
                          <div className='calendar-inner'>
                            <img src={Calendar1} alt='' />
                          </div>

                          <div className='d-flex'>
                            <p className='schedule-text'>
                              {schedulerTime?.scheduleStartTime && convertTo12HourFormat(schedulerTime.scheduleStartTime)} -{' '}
                              {schedulerTime?.scheduleEndTime && convertTo12HourFormat(schedulerTime.scheduleEndTime)}
                              <br />
                            </p>
                            <p className='days-text'>
                              On - <span>{mapDaysToEnumStrings(schedulerTime.scheduledDays).join(', ')}</span>
                            </p>
                          </div>
                        </div>
                        <div className='dropdown-container'>
                          <button
                            className={`dropdown-btn ${isDropdownOpen === index ? 'dropdown-active' : ''}`}
                            onClick={() => openDropdown(index)}
                          >
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                          </button>
                          {isDropdownOpen === index && (
                            <div className='dropdown-options'>
                              <p className='dropdown-header'>Schedule options</p>
                              <button onClick={() => handleEditSchdule(schedulerTime)}>
                                <img src={Edit} alt='' />
                                Edit
                              </button>
                              <button
                                onClick={handleSnoozeSchdule}
                                className={isSnoozeOptionsOpen ? 'snooze-active' : ''}
                              >
                                <img src={Snooze} alt='' />
                                Snooze
                                <img
                                  src={arrowDown}
                                  alt='Chevron'
                                  className={isSnoozeOptionsOpen ? 'chevron-active' : 'chevron-down'}
                                />
                              </button>
                              {isSnoozeOptionsOpen && (
                                <div className='snooze-options'>
                                  <button onClick={() => handleSnoozeEvent(schedulerTime.scheduleId, 6)}>
                                    <img src={Snooze} alt='' />6 hours
                                  </button>
                                  <button onClick={() => handleSnoozeEvent(schedulerTime.scheduleId, 12)}>
                                    <img src={Snooze} alt='' />
                                    12 hours
                                  </button>
                                  <button onClick={() => handleSnoozeEvent(schedulerTime.scheduleId, 24)}>
                                    <img src={Snooze} alt='' />
                                    24 hours
                                  </button>
                                </div>
                              )}
                              <button
                                onClick={() => handleDeleteSchdule(schedulerTime.scheduleId)}
                                className='last-btn'
                              >
                                <img src={Delete} alt='' />
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='notification-no-data'>
                  <p>You have no schedules</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='notification-preferences external'>
        <div className='notification-card security-card w-100'>
          <div className='header'>
            <div className='notification-header'>
              <p className='title'>External notifications preferences</p>
              <p className='notification-text'>
                Select the type of channel you wish to receive alarm event notifications for.
              </p>
            </div>
          </div>
          <div className='external-body'>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>RTU alarms</p>
                <p className='notification-text'>
                  RTU alarms which can trigger a well shutdown. These alarms, and the reason for the alarm, display on
                  your Group and Well Statuses.
                </p>
              </div>
              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.rtuAlarm.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(1, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.rtuAlarm.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(1, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.rtuAlarm.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(1, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.rtuAlarm.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(1, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <div className='divider'></div>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>Host alarms</p>
                <p className='notification-text'>
                  Host alarms are alarms that are set for specific properties of individual assets. The conditions are
                  global, but notifications can be edited by the user.
                </p>
              </div>
              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.hostAlarm.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(2, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.hostAlarm.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(2, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.hostAlarm.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(2, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.hostAlarm.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(2, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <div className='divider'></div>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>Facility tag alarms</p>
                <p className='notification-text'>
                  These are notifications for Facility tags, which are configurable nodes in XSPOC that allow you to
                  pull in data from RTU’s, PLC’s, or any other devices that use the Modbus, Allen-Bradly DF1, or OPC
                  protocols.
                </p>
              </div>
              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.facilityTagAlarm.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(3, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.facilityTagAlarm.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(3, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.facilityTagAlarm.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(3, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.facilityTagAlarm.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(3, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <div className='divider'></div>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>Communication failure</p>
                <p className='notification-text'>
                  You will receive notifications when the connection to a well is broken and scans are not able to be
                  preformed
                </p>
              </div>
              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.communicationFailure.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(4, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.communicationFailure.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(4, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.communicationFailure.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(4, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.communicationFailure.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(4, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <div className='divider'></div>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>Communication recovery</p>
                <p className='notification-text'>
                  Receive a notification when the connection to a well is resolved, and communication is recovered
                </p>
              </div>

              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.communicationRecovery.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(5, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.communicationRecovery.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(5, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.communicationRecovery.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(5, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.communicationRecovery.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(5, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <div className='divider'></div>
            <div className='toggle-wrap'>
              <div className='toogle-heading'>
                <p className='title'>Well notes</p>
                <p className='notification-text'>
                  Well notes provide updates for events for a single well. Users are also able to enter comments on
                  these events.
                </p>
              </div>
              <div className='toggle-container'>
                <ToggleSwitch
                  checked={notification?.wellNotes.includes(AlarmChannelType.Email) || false}
                  value={AlarmChannelType.Email.toString()}
                  onChange={() => handleToggleEvent(6, AlarmChannelType.Email)}
                  label='Email'
                />
                <ToggleSwitch
                  checked={notification?.wellNotes.includes(AlarmChannelType.Pager) || false}
                  value={AlarmChannelType.Pager.toString()}
                  onChange={() => handleToggleEvent(6, AlarmChannelType.Pager)}
                  label='Pager'
                />
                <ToggleSwitch
                  checked={notification?.wellNotes.includes(AlarmChannelType.Phone) || false}
                  value='Phone'
                  onChange={() => handleToggleEvent(6, AlarmChannelType.Phone)}
                  label='Phone (mobile phone)'
                />
                <ToggleSwitch
                  checked={notification?.wellNotes.includes(AlarmChannelType.SMS) || false}
                  value='SMS'
                  onChange={() => handleToggleEvent(6, AlarmChannelType.SMS)}
                  label='SMS (mobile phone)'
                />
              </div>
            </div>
            <br />
          </div>
        </div>
        <ToastContainer position='top-right' autoClose={3000} />
      </div>
      <SchedulerModal
        isOpen={isSchduleModalOpen}
        onClose={closeModal}
        onSave={handleSaveModal}
        schedulerTimes={schedulerTimes}
      />
      <DeleteModal
        isDeleteModal={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onDelete={() => handleDeleteModal(deletedId || 0)}
        deletedId={deletedId || 0}
      />
    </>
  );
};

export default NotificationPreferences;
