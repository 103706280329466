import React from 'react';
import Close from '../../../../../../images/x-close.svg';
import CustomModal from '../../../../../dashboard/components/graph/ExpandModel';
import './UpTimeModal.scss'
import UpTimeChartModal from './UpTimeChartModal';

interface UpTimeModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    selectedUptimeData: any;
    heading: string;
    modelWidth?: string;
    modelClass?:string;
}

const UpTimeModal: React.FC<UpTimeModalProps> = ({ 
    isOpen,
    onRequestClose,
    selectedUptimeData,
    heading,
    modelWidth,
    modelClass, 
 }: UpTimeModalProps) => {
    return (
        <CustomModal isOpen={isOpen} onRequestClose={onRequestClose} width={modelWidth} classname={modelClass}>
            <div className='modal-header'>
                <div className='flex'>
                    <div className='grow'>
                        <h3>{heading}</h3>
                    </div>
                    <div className='flex-none'>
                        <button onClick={onRequestClose}>
                        <img src={Close} alt='Expand' />
                        </button>
                    </div>
                </div>
            </div>
            <div className='modal-content h-full'>
                <div className='flex flex-wrap items-center justify-center wellChart-container-dashboard nodata'>
                    <div className='flex w-full h-full upTime-modal-body'>
                       <div  className="w-full"><UpTimeChartModal selectedUptimeData={selectedUptimeData}/></div>
                    </div>           
                </div>
            </div>
        </CustomModal>
    )
}

export default UpTimeModal;