import React from 'react';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import { formatStringDate } from '../../../../common/DateRangeSelector/DefaultRanges';

interface MessageCommentProps {
  id: number;
  attachments: any[];
  author_id: number;
  body: string;
  created_at: string;
}

const MessageCommects: React.FC<{ fetchedComments: MessageCommentProps[] }> = ({ fetchedComments }) => {
  const {
    ticketUsers,
  } = useAppSelector((state) => state.ticketTableSlice);
  return (
    <div>
      {fetchedComments?.map((comment: MessageCommentProps, i: number) => (
        <div className='p-2' key={comment.id || i} >
                    <div className='Message-Commects-body border-b border-[#4A5463]  text-white pb-4'>

            <div className='flex justify-between text-[18px] mb-4 relative'>
          
           <div className='w-80'></div>
              {/* <div className='text-[18px] text-white'>{comment.status}</div> */}
              <div className='flex text-[14px] w-[99%] text-right justify-end text-white'>
                {
                // comment.requestedDate
                formatStringDate(comment.created_at)
                }
              </div>
            </div>
            <p className='text-[14px] mb-3 text-white'>To: {
            // comment.requestor
            ticketUsers.find((user) => user.id === comment.author_id)?.name
            }</p>
            <div className='flex text-[14px] justify-between mb-4'>
              <p className='mb-2 text-[14px] break-all'>{comment.body}</p>
            </div>
            <div>
              {/* <h3 className='text-[14px] font-semibold mb-2 text-[#60BFDA]'>{comment.status}</h3> */}
              <h6 className='text-[14px] font-semibold mb-2 text-[#60BFDA]'>{
              // comment.assignedTo
              ticketUsers.find((user) => user.id === comment.author_id)?.name
              }</h6>
            </div>
            <div className='ticket-comment-attachments'>
              <ul>
                {comment?.attachments && comment?.attachments.map((attachment, index) => (
                  <li key={index} className='ticket-comment-attachment-link'>
                    <button
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = attachment.content_url;
                        link.download = attachment.file_name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                     {attachment?.file_name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MessageCommects;
