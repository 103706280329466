import { apiInstance } from "../../config/HttpCommon";



export const userPreferences = async (dashboard: string, isDefaut = false) => {
    return await apiInstance.get(`/v1/user-preferences/widgets/${dashboard}`, {
        params: {
            isDefaultWidgets: isDefaut
        }
    }
    );
}

