import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getUserById,
  getUsers,
  login,
  updateUserData,
  updateNotificationPreferencesData,
  getUserNotificationByUserId,
  updateUserPermissionData,
  deleteScheduleData,
  createUserNotificationData,
  notifyPasswordChange,
  changeUserStatus,
  getOperators,
  getPrivileges
} from './UserService';

import { User } from './model/User';
import { AppUser } from './model/AppUser';
import { Role } from '../../models/Role';
import { Notification } from './model/Notification';
import { Operator } from './model/Operator';

interface Privilege {
  lookupId: string,
  value: string,
  displayLabel: string,
  description: string,
}
interface UserState {
  operators: Operator[];
  loading: boolean;
  users: User[];
  loggedInUser: AppUser | null;
  currentUser: AppUser | null;
  message: string | null;
  success: boolean;
  isValidUser: boolean;
  passwordError: string | null;
  schedule: Notification[];
  notifcation: Notification | null;
  updateUserCount: number;
  userLoading: boolean;
  userListLoading: boolean;
  usersDataloading: boolean;
  notificationloading: boolean;
  privileges: Privilege[] | null
}

const initialState: UserState = {
  loading: false,
  users: [],
  loggedInUser: null,
  currentUser: null,
  message: null,
  success: false,
  isValidUser: false,
  passwordError: null,
  schedule: [],
  operators: [],
  notifcation: null,
  updateUserCount: 0,
  userLoading: false,
  userListLoading: false,
  usersDataloading: false,
  notificationloading: false,
  privileges: null
};

// Define your async thunk for fetching users
export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await getUsers();
  return response;
});
// Define your async thunk for fetching operators
export const fetchOperators = createAsyncThunk('user/fetchOperator', async () => {
  const response = await getOperators();
  return response;
});
// Define your async thunk for fetching a single user
export const fetchUserById = createAsyncThunk('user/fetchUserById', async (id: string) => {
  const response = await getUserById(id);
  return response;
});

export const fetchUserByIdwithAbortController = createAsyncThunk('user/fetchUserById', async (params: {
  id: string, abortController: AbortController | null
}) => {
  const response = await getUserById(params.id, params.abortController);
  return response;
});


export const fetchUser = createAsyncThunk('user/fetchUser', async (id: string) => {
  const response = await getUserById(id);
  return response;
});

// Define your async thunk for logging in a user
export const loginUser = createAsyncThunk('user/loginUser', async (credentials: { username: string, password: string }) => {
  const response = await login(credentials);
  return response;
});

// Define your async thunk for validating a user
export const validateUser = createAsyncThunk('user/validateUser', async (credentials: { username: string, password: string }) => {
  const response = await login(credentials);
  return response;
});

// Create userNotification
export const createNotification = createAsyncThunk('user/createNotification', async (notification: Notification) => {
  const response = await createUserNotificationData(notification);
  return response;
});

// Define your async thunk for updating a user
export const updateUser = createAsyncThunk('user/updateUser', async ({ id, user }: { id: string, user: AppUser }) => {
  const response = await updateUserData(id, user);
  return response;
});

// Define your async thunk for updating a user
export const updateUserStatus = createAsyncThunk('user/updateUserStatus', async (userStatus: { ids: string[], status: string }) => {
  const response = await changeUserStatus(userStatus);
  return response;
});

export const fetchUserNotificationByUserId = createAsyncThunk('user/fetchUserNotificationByUserId', async (userId: string) => {
  const response = await getUserNotificationByUserId(userId);
  return response;
});

// Async thunk for updating user notification preferences
export const updateNotificationPreferences: any = createAsyncThunk('user/updateNotificationPreferences', async ({ id, notification }: { id: string; notification: Notification }) => {
  return await updateNotificationPreferencesData(id, notification);
});

// UpdateUserPermissions async thunk
export const updateUserPermissions: any = createAsyncThunk('user/updateUserPermissions', async ({ id, roles }: { id: string, roles: Role[] }) => {
  const response = await updateUserPermissionData(id, roles);
  return response;
}
);

// DeleteUserNotification async thunk
export const deleteUserNotification: any = createAsyncThunk('user/deleteUserNotification', async ({ id, deleteNotification }: { id: string, deleteNotification: Notification }) => {
  const response = await deleteScheduleData(id, deleteNotification);
  return response;
}
);
// Reset Password changes
// Add this thunk in UserSlice.ts
export const resetUserPassword = createAsyncThunk('user/resetUserPassword', async (id: string) => {
  const response = await notifyPasswordChange(id);
  return response; // Assuming the response is a boolean value
});

export const fetchPrivileges = createAsyncThunk('user/fetchPrivileges', async () => {
  const response = await getPrivileges();
  return response;
});
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutSuccess: () => {
      return initialState;
    },
    updateUsers: (state, action) => {
      state.users = action.payload
    }
  },
  extraReducers: (builder) => {
    // Handle the fetchUsers fulfilled action
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
      state.usersDataloading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.loading = false;
      state.usersDataloading = false;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.usersDataloading = false;
    });
    builder
      .addCase(fetchOperators.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOperators.fulfilled, (state, action) => {
        state.operators = action.payload.data; // Assuming payload is the list of operators
        state.loading = false;
      })
      .addCase(fetchOperators.rejected, (state, action) => {
        state.loading = false;
        console.error('Failed to fetch operators:', action.error);
      });
    // Handle the fetchUserById fulfilled action
    builder.addCase(fetchUserById.pending, (state) => {
      state.loading = true;
      state.userLoading = true;
    });
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      state.currentUser = {
        ...action.payload.data,
        lastLoginDate: action.payload.data.lastLoginDate ? new Date(action.payload.data.lastLoginDate).toISOString() : null,
      };
      state.loading = false;
      state.userLoading = false;
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
      state.userLoading = false;
    });
    //to get user by id in schedule speed change
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.currentUser = {
        ...action.payload.data,
        lastLoginDate: action.payload.data.lastLoginDate ? new Date(action.payload.data.lastLoginDate).toISOString() : null,
      };
    });


    // Handle the fetchUserNotificationById fulfilled action
    builder.addCase(fetchUserNotificationByUserId.pending, (state) => {
      state.loading = true;
      state.notificationloading = true;
    });
    builder.addCase(fetchUserNotificationByUserId.fulfilled, (state, action) => {
      state.notifcation = { ...action.payload };
      state.loading = false;
      state.notificationloading = false;
    });
    builder.addCase(fetchUserNotificationByUserId.rejected, (state, action) => {
      state.message = action.payload as string;
      state.notifcation = null;
      state.loading = false;
      state.notificationloading = false;
    });

    // Handle the loginUser fulfilled action
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loggedInUser = action.payload.user;
      state.message = action.payload.message;
      state.success = action.payload.message === "Authentication successful";
      state.loading = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the validateUser fulfilled action
    builder.addCase(validateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(validateUser.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.isValidUser = action.payload.message === "Authentication successful";
      state.loading = false;
    });
    builder.addCase(validateUser.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the createNotification fulfilled action
    builder.addCase(createNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNotification.fulfilled, (state) => {
      //state.schedule = [];
      state.loading = false;
    });
    builder.addCase(createNotification.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the loginUser fulfilled action
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loggedInUser = action.payload;
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle the loginUser fulfilled action
    builder.addCase(updateUserStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserStatus.fulfilled, (state, action) => {
      state.updateUserCount = action.payload;
      state.loading = false;
    });
    builder.addCase(updateUserStatus.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });

    // Handle Update Notification Preferences
    builder
      .addCase(updateNotificationPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateNotificationPreferences.fulfilled, (state, action) => {
        state.loading = false;
        if (state.loggedInUser) {
          state.notifcation = action.payload.data;
        }
      })
      .addCase(updateNotificationPreferences.rejected, (state, action) => {
        state.loading = false;
        state.message = action.error.message || 'Failed to update notification preferences';
      });

    // Handle the UpdateUserPermissions action
    builder.addCase(updateUserPermissions.pending, (state) => {
      // Assuming the response contains the updated roles
      state.loading = true
    });

    builder.addCase(updateUserPermissions.fulfilled, (state, action) => {
      state.loading = false
      // Assuming the response contains the updated roles
      if (state.loggedInUser && state.loggedInUser.id === action.meta.arg.id) {
        state.loggedInUser.roles = action.meta.arg.roles;
      }
    });

    // Handle the DeleteUserNotification action
    builder.addCase(deleteUserNotification.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(deleteUserNotification.fulfilled, (state) => {
      state.loading = false;
      // Update your state based on the action.payload if needed
      // Example:
      // state.schedule = state.schedule.filter(item => item.id !== action.payload.deletedItemId);
    });
    // Rest Password changes
    // Add these cases in the extraReducers section of userSlice
    builder.addCase(resetUserPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetUserPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload ? 'Password reset email sent to user.' : 'Failed to reset password.';
    });
    builder.addCase(resetUserPassword.rejected, (state) => {
      state.loading = false;
      state.message = 'Failed to reset password.';
    });


    builder.addCase(deleteUserNotification.rejected, (state) => {
      state.loading = false;
      // Log or handle the error from action.error

    });

    builder.addCase(fetchPrivileges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPrivileges.fulfilled, (state, action) => {
      state.loading = false;
      state.privileges = action.payload[0]?.group ?? null
    });
    builder.addCase(fetchPrivileges.rejected, (state) => {
      state.loading = false;
      state.privileges = null;
    });
  },
});

export const { logoutSuccess, updateUsers } = userSlice.actions;
export default userSlice.reducer;


