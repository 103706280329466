import React, { useEffect, useState } from 'react'


// import Wifi from '../../../images/wifi.svg'
import CheckCircle from '../../../images/check-circle.svg'

import './ManageAsset.scss'
import ScheduleEvent from './schedule-event/ScheduleEvent'
import PIDSetting from './schedule-event/PIDSetting'
import RockingStart from './schedule-event/RockingStart'
import GasLockRecovery from './schedule-event/GasLockRecovery'
import { useAppDispatch, useAppSelector } from '../../../hooks/storeHooks'
import { fetchWebSocketTimeoutValue } from '../../asset/AssetControlSlice'
import { looupKeys } from '../../asset-details/components/asset-details-header/AssetDetailsHeaderService'
import Loader from '../../common/page-loader/ComponentLoader'
import { ControllerDetails } from '../../asset-details/components/asset-details-header/model/ControllerDetails'
import { fetchControllerDetails } from '../../asset-details/components/asset-details-header/AssetDetailsHeaderSlice'
import { toast } from 'react-toastify'
import { selectedWellName } from '../../controlroom/components/well-dashboard-header/WellDashboardHeader'
import AssetControl from '../AssetControl'
import { clearChangedSetpointValues, fetchManageAssetSetpoints, resetReadSetpointStatus } from '../ManageAssetSlice'
import { confirmationModalProps, WebSocketActionType } from '../../asset-details/components/asset-details-header/AssetControlActions'
import { useWebSocket } from '../../../hooks/webSocketHook'
import { showToaster } from '../../dashboard/components/asset-location/AssetList'
import { allowedPocTypes, SetPointGroupName, setPointParamStandardTypeId, SetpointVariable } from '../model/ManageAssetModel'
import { useManageAsset } from './hook/ManageAssetHook'
import ConfirmationModal from '../../asset-details/components/asset-details-header/modal/ConfirmationModal'
import SetpointBackupValue from './SetpointBackupValue'

const ManageAsset = () => {
    const dispatch = useAppDispatch()
    const [selectedEventDropdown, setSelectedDropdown] = useState('')
    const assetControlData = useAppSelector((state) => state?.assetControl)
    const manageAssetSliceData = useAppSelector((state) => state?.manageAsset)
    const { assetDetailLoading, assetDetail } = useAppSelector((state) => state?.assetDetail);
    const { fetchSetPointLoading, setPoints } = useAppSelector((state) => state.manageAsset)
    const wellStatus = assetControlData.wellStatus
    const selectedWellNameLS: any = sessionStorage.getItem('selectedWellName');
    const selectedWellName: selectedWellName | null = selectedWellNameLS ? JSON.parse(selectedWellNameLS) : null;
    const pocType = assetDetail?.assetDetails.pocType || ''
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalProps, setConfirmationModalProps] = useState<confirmationModalProps>({
        action: '',
        heading: '',
        description: '',
        controlType: ''
    })
    const systemControllerPocType = ['529', '38']
    const [setpointAddressLoading, setSetpointAddressLoading] = useState(true)

    const { establishWebSocketConnection } = useWebSocket()

    const { getSetpointItem } = useManageAsset()

    // const assetId = selectedWellName?.assetId ? selectedWellName?.assetId : assetDetail?.assetDetails.assetId ? assetDetail?.assetDetails.assetId : ''
    const assetId = assetDetail?.assetDetails.assetId ? assetDetail?.assetDetails.assetId : ''

    useEffect(() => {

        if (!assetControlData.webSocketTimoutValue) {
            dispatch(fetchWebSocketTimeoutValue(looupKeys.webSocketTimeOut))
        }
    }, []);

    const getManageAssetSetpoints = async () => {
        const resultAction = await dispatch(fetchManageAssetSetpoints({ wellId: assetId, wellName: selectedWellName?.assetName }))

        if (fetchManageAssetSetpoints.fulfilled.match(resultAction)) {
            const setpointDetails = resultAction.payload as any;
            if (!setpointDetails.data.length)
                toast.error('Failed to read setpoint addresses');

        } else {
            toast.error('Failed to read setpoint addresses');
        }
    }

    useEffect(() => {
        if (!assetDetailLoading && !manageAssetSliceData?.setPoints && selectedWellName?.assetName) {

            getManageAssetSetpoints()

            setSetpointAddressLoading(false)
            // dispatch(fetchManageAssetSetpoints({ wellId: assetId, wellName: selectedWellName.assetName }))
            //     .finally(() => setSetpointAddressLoading(false))
        }
    }, [assetDetailLoading, selectedWellName?.assetName])



    useEffect(() => {
        if (setPoints?.length && !fetchSetPointLoading && !assetDetailLoading && !manageAssetSliceData?.readDeviceLoading) {
            const setPointAdress = setPoints?.map((setpoint) => setpoint.parameter)
            if (setPointAdress) {
                establishWebSocketConnection({ webSocketActionType: WebSocketActionType.ReadSetPoint, selectedSetPointAddress: setPointAdress.toString() })
            }
        }

    }, [setPoints, fetchSetPointLoading, assetDetailLoading])


    const handleDropdownClick = (eventName: string) => {
        if (eventName === selectedEventDropdown) setSelectedDropdown('')
        else setSelectedDropdown(eventName)
    }

    const handleSystemController = async () => {
        if (selectedWellName?.assetName) {
            const resultAction = await dispatch(fetchControllerDetails({
                nodeId: selectedWellName?.assetName,
                controllerType: 'System'
            }));

            if (fetchControllerDetails.fulfilled.match(resultAction)) {
                const controllerDetails = resultAction.payload as ControllerDetails;
                if (controllerDetails.ipAddress && controllerDetails.port) {
                    window.open(`${pocType === '38' ? 'https' : 'http'}://${controllerDetails.ipAddress}:${controllerDetails.port}`, '_blank');
                } else {
                    toast.error('Controller details are not available.!');
                }

            } else {
                toast.error('Failed to fetch controller details.!');
            }
        }
    };

    useEffect(() => {
        if (manageAssetSliceData?.readDeviceLoading || manageAssetSliceData?.readDeviceErrorStatus || manageAssetSliceData?.readDeviceSuccessStatus || manageAssetSliceData?.readDeviceReturnErrorStatus) {
            if (manageAssetSliceData?.readDeviceLoading) {
                showToaster(`${manageAssetSliceData?.deviceAction === 'Read' ? 'Reading' : 'Updating'} Device`, 'info', false, '#60BFDA')
            }
            if (manageAssetSliceData?.readDeviceErrorStatus) {
                showToaster(`Device failed to ${manageAssetSliceData?.deviceAction === 'Read' ? 'Read' : 'Update'}`, 'error', 3000)
            }
            if (manageAssetSliceData?.readDeviceReturnErrorStatus) {
                showToaster('Comms timeout', 'error', 3000)
            }
            if (manageAssetSliceData?.readDeviceSuccessStatus) {
                showToaster(`Device successfully ${manageAssetSliceData?.deviceAction === 'Read' ? 'Read' : 'Updated'}`, 'success', 3000)
            }

            if (manageAssetSliceData?.readDeviceSuccessStatus || manageAssetSliceData?.readDeviceErrorStatus || manageAssetSliceData?.readDeviceReturnErrorStatus) {
                dispatch(resetReadSetpointStatus('NotAll'))
            }
        }
    }, [manageAssetSliceData?.readDeviceLoading, manageAssetSliceData?.readDeviceErrorStatus, manageAssetSliceData?.readDeviceSuccessStatus, manageAssetSliceData?.readDeviceReturnErrorStatus])



    const handleReadDeviceSaveClick = () => {
        setConfirmationModalProps({
            action: 'Update',
            heading: 'Update device',
            description: 'Are you sure you want to update the device?',
            controlType: 'Start'
        })
        setIsConfirmationModalOpen(true)

    }

    const handleClearChangesClick = () => {
        setConfirmationModalProps({
            action: 'Clear',
            heading: 'Clear',
            description: 'Are you sure you want to clear the changes?',
            controlType: 'Start'
        })
        setIsConfirmationModalOpen(true)
    }


    const closeConfirmationModal = () => {
        setIsConfirmationModalOpen(false)
    }
    const handleConfirm = () => {
        if (confirmationModalProps.action === 'Update') {
            if (manageAssetSliceData?.changedBackupValues) {
                establishWebSocketConnection({ webSocketActionType: WebSocketActionType.UpdateSetPoint, changedBackupValues: manageAssetSliceData.changedBackupValues })
            }
        } else {
            handleClear()
        }
        setIsConfirmationModalOpen(false)
    }


    const getSetpointInput = (placeholder: string, groupName: keyof typeof setPointParamStandardTypeId, setpointName: string) => {

        const setpointItem = getSetpointItem(groupName, setpointName);

        return setpointItem ? (
            <SetpointBackupValue setPointItem={setpointItem} groupName={groupName} setpointName={setpointName} />
        ) : (
            <input
                type='text'
                className='time-input-field disabled'
                placeholder={placeholder}
            />
        );
    }



    const handleClear = () => {
        dispatch(clearChangedSetpointValues())
        if (setPoints?.length && !fetchSetPointLoading && !assetDetailLoading) {
            const setPointAdress = setPoints?.map((setpoint) => setpoint.parameter)
            if (setPointAdress) {
                establishWebSocketConnection({ webSocketActionType: WebSocketActionType.ReadSetPoint, selectedSetPointAddress: setPointAdress.toString() })
            }
        }
    }

    const dropdownDisabledStatus = !allowedPocTypes?.includes(pocType) || !manageAssetSliceData.deviceReadStatus || manageAssetSliceData.readDeviceLoading || !manageAssetSliceData.setPoints?.length

    return (
        <>

            {/* <ToastContainer position='top-right' autoClose={3000} /> */}

            <div className='manage-asset-container'>
                {
                    (assetControlData?.assetControlLoading || assetDetailLoading || fetchSetPointLoading || setpointAddressLoading) ?
                        <div style={{ height: '90vh' }}>
                            <Loader />
                        </div>
                        :
                        <>
                            <div className='content-block flex-col space-y-5'>
                                <div className='flex justify-end items-center'>
                                    {/* <div className='flex space-x-4'>
                                        <label className={`radio-container`} >
                                            <input
                                                type='radio'
                                                name='speedUnit'
                                                onChange={() => setSelectedSpeedUnit('Hz')}
                                                checked={selectedSpeedunit == 'Hz'}
                                            />
                                            <span className='radio-checkmark'></span>
                                            Hz
                                        </label>
                                        <label className={`radio-container`} >
                                            <input
                                                type='radio'
                                                name='speedUnit'
                                                onChange={() => setSelectedSpeedUnit('RPM')}
                                                checked={selectedSpeedunit == 'RPM'}
                                            />
                                            <span className='radio-checkmark'></span>
                                            RPM
                                        </label>
                                    </div> */}
                                    <AssetControl
                                        supportedControlActionIds={assetControlData?.wellControlActions.map((x) => x.id)}

                                    />


                                </div>

                                <div className='setpoint-block grid grid-cols-2 space-x-4'>
                                    <div className='manage-asset-form-control'>
                                        <label className='label'>Setpoint speed</label>
                                        {
                                            getSetpointInput('0', SetPointGroupName.SetpointSpeed, SetpointVariable.SetpointSpeed)
                                        }
                                    </div>
                                    <div className='manage-asset-form-control'>
                                        <label className='label'>Max recommended speed</label>
                                        <input
                                            type='text'
                                            className='time-input-field disabled'
                                            placeholder='0 Hz'
                                        />
                                        <div className='speed-description'>
                                            <span>Shaft speed limit ---</span>
                                            <span className='pipe'></span>
                                            <span className='amp-capacity'>Max Hz motor amp capacity: ---</span>
                                        </div>
                                    </div>

                                </div>

                                <ScheduleEvent
                                    eventHeading={'PID setting'}
                                    selectedEventDropdown={selectedEventDropdown}
                                    handleDropdownClick={handleDropdownClick}
                                    child={<PIDSetting groupName={SetPointGroupName.PIDSetting} />}
                                    disabledStatus={dropdownDisabledStatus ? true : false}
                                    groupName='pidSetting'
                                />
                                <ScheduleEvent
                                    eventHeading={'Rocking start'}
                                    selectedEventDropdown={selectedEventDropdown}
                                    handleDropdownClick={handleDropdownClick}
                                    child={<RockingStart groupName={SetPointGroupName.RockingStart} />}
                                    disabledStatus={dropdownDisabledStatus ? true : false}
                                    groupName='rockingStart'
                                />
                                <ScheduleEvent
                                    eventHeading={'Gas lock recovery'}
                                    selectedEventDropdown={selectedEventDropdown}
                                    handleDropdownClick={handleDropdownClick}
                                    child={<GasLockRecovery groupName={SetPointGroupName.GasLockRecovery} />}
                                    disabledStatus={dropdownDisabledStatus ? true : false}
                                    groupName='gasLockRecovery'
                                />

                                {/* <div className='event-block disabled-block flex flex-col space-y-5'>
                                    <div className='flex flex-row space-x-4'>
                                        <div className='manage-asset-form-control w-1/2'>
                                            <label className='label'>Event subject</label>
                                            <input
                                                type='text'
                                                className='time-input-field'
                                                placeholder='Subject'
                                            />
                                        </div>
                                        <div className='manage-asset-form-control w-1/2'>
                                            <label className='label'>Event type</label>
                                            <input
                                                type='text'
                                                className='time-input-field'
                                                placeholder='Event type'
                                            />
                                        </div>
                                    </div>
                                    <div className='manage-asset-form-control description-input-field w-full'>
                                        <label className='label'>Description</label>
                                        <textarea
                                            className='time-input-field'
                                            placeholder='Description'
                                        />
                                    </div>
                                </div> */}
                            </div>
                            <div className='button-block flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <button className={`button ${(wellStatus?.enabled !== 1 || !systemControllerPocType?.includes(pocType)) ? 'disabled' : ''}`}
                                        onClick={() => {
                                            if (wellStatus?.enabled === 1 && systemControllerPocType?.includes(pocType))
                                                handleSystemController()
                                        }}
                                    >
                                        <img src={CheckCircle} />Controller
                                    </button>
                                </div>
                                <div className='flex flex-row space-x-3 items-center'>
                                    <button className={`button ${Object.keys(manageAssetSliceData.changedBackupValues || {}).length === 0 ? 'disabled' : ''}`} onClick={handleClearChangesClick}>
                                        Clear
                                    </button>
                                    <button className={`button ${Object.keys(manageAssetSliceData.changedBackupValues || {}).length === 0 ? 'disabled' : ''}`} onClick={handleReadDeviceSaveClick}>
                                        Save
                                    </button>
                                </div>
                            </div>

                        </>
                }

                {
                    isConfirmationModalOpen && (
                        // <div style={{ height: '100vh', position: 'absolute' }}>
                        <ConfirmationModal assetControlProps={confirmationModalProps} isModalClose={closeConfirmationModal} isModalOk={handleConfirm} />
                        // </div>
                    )
                }

            </div>
        </>
    )
}

export default ManageAsset