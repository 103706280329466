import { useEffect, useState } from 'react';
import { fetchFluidTabDetails } from '../AnalysisSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
// import PageLoader from '../../../../common/page-loader/PageLoader';
import { FluidTabDetailsProps } from '../analysisProps';
import '../analysis.scss';
import { updateAssetControlScanStatus } from '../../../../asset/AssetControlSlice';

const FluidTab = () => {
  // const { loading }  = useAppSelector((state)=> state.analysisTab);
  const dispatch = useAppDispatch();
  const [fluidTabDetails, setFluidTabDetails] = useState<FluidTabDetailsProps | null>(null);
  const selectedAsset = useAppSelector((state) => state.assetGroups);
  const assetControlData = useAppSelector((state) => state?.assetControl)

  useEffect(() => {
    const wellName = selectedAsset?.selectedAssetName ?? '';
    if (wellName === '') return;
    dispatch(fetchFluidTabDetails({ projectId: wellName, analysisType: 'fluid' }))
      .unwrap()
      .then((response: FluidTabDetailsProps) => {
        setFluidTabDetails(response);
      })
      .catch((error) => {
        console.error('Failed to fetch fluid tab details:', error);
      });

    if (assetControlData?.assetControlScanStatus)
      dispatch(updateAssetControlScanStatus(false))


  }, [dispatch, selectedAsset?.selectedAssetName, assetControlData?.assetControlScanStatus === true]);

  return (
    <>
      {/* {loading && <PageLoader />} */}
      <div className='analysis-content'>
        <div className='analysis-title'>Fluid</div>
        <div className='cus-details'>
          <div>SpGrWater*</div>
          <div>{fluidTabDetails?.spGrWater}</div>
        </div>

        <div className='cus-details'>
          <div>Watercut</div>
          <div>{fluidTabDetails?.waterCut}</div>
        </div>

        <div className='cus-details'>
          <div>BWPD</div>
          <div>{fluidTabDetails?.bwpd ? `${fluidTabDetails?.bwpd} bpd` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Oil API*</div>
          <div>{fluidTabDetails?.oil_API}</div>
        </div>

        <div className='cus-details'>
          <div>SpGrOil</div>
          <div>{fluidTabDetails?.spGrOil}</div>
        </div>

        <div className='cus-details'>
          <div>Oil cut</div>
          <div>{fluidTabDetails?.oil_Cut}</div>
        </div>

        <div className='cus-details'>
          <div>BOPD</div>
          <div>{fluidTabDetails?.bopd ? `${fluidTabDetails?.bopd} bpd` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Composite SpGr</div>
          <div>{fluidTabDetails?.fluid_Viscosity_Composite_SpGr ? `${fluidTabDetails?.fluid_Viscosity_Composite_SpGr} bpd` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Fluid viscosity</div>
          <div>{fluidTabDetails?.fluid_Viscosity ? `${fluidTabDetails?.fluid_Viscosity} P` : ""}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Gas properties</div>
        <div className='cus-details'>
          <div>Gas availability</div>
          <div>{fluidTabDetails?.gas_Availability == 'True' ? 'yes' : 'no'}</div>
        </div>

        <div className='cus-details'>
          <div>Specific gravity of gas*</div>
          <div>{fluidTabDetails?.specificGravityofGas}</div>
        </div>

        <div className='cus-details'>
          <div>Gas compressibility*</div>
          <div>{fluidTabDetails?.gas_Compressibility}</div>
        </div>

        <div className='cus-details'>
          <div>Bubble point*</div>
          <div>{fluidTabDetails?.bubble_Point ? `${fluidTabDetails?.bubble_Point} psi` : ""}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Gas volume</div>
        <div className='cus-details'>
          <div>Gas production</div>
          <div>{fluidTabDetails?.gas_Production ? `${fluidTabDetails?.gas_Production} mcf` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>GOR</div>
          <div>{fluidTabDetails?.gor ? `${fluidTabDetails?.gor} scf/stb` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>GLR</div>
          <div>{fluidTabDetails?.glr ? `${fluidTabDetails?.glr} scf/stb` : ""}</div>
        </div>
      </div>

      <div className='analysis-content'>
        <div className='analysis-title'>Others</div>
        <div className='cus-details'>
          <div>Bottom hole temp</div>
          <div>{fluidTabDetails?.bottom_Hole_Temp ? `${fluidTabDetails?.bottom_Hole_Temp} °F` : ""}</div>
        </div>

        <div className='cus-details'>
          <div>Surface temperature</div>
          <div>{fluidTabDetails?.surface_Temprature ? `${fluidTabDetails?.surface_Temprature} °F` : ""}</div>
        </div>
      </div>
    </>
  );
};

export default FluidTab;
