import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getEventsById } from './EventsService';
import { EventsDetailsProps } from './EventsDetails';

interface EventState {
  loading: boolean;
  message: string | null;
  events: EventsDetailsProps[];
}

const initialState: EventState = {
  loading: false,
  message: null,
  events: [],
};

export const fetchEventsById = createAsyncThunk(
  'events/fetchEventsById',
  async ({ nodeId,dtStartTime, dtEndTime }: {  nodeId: string; dtStartTime: string; dtEndTime: string; }) => {
    const response = await getEventsById( nodeId, dtStartTime, dtEndTime);
    return response;
  },
);

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle the fetchEventsById fulfilled action
    builder.addCase(fetchEventsById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEventsById.fulfilled, (state, action: PayloadAction<EventsDetailsProps[]>) => {
      state.events = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchEventsById.rejected, (state, action) => {
      state.message = action.payload as string;
      state.loading = false;
    });
  },
});


export default eventsSlice.reducer;
