import React, { useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import Loader from '../../../common/page-loader/ComponentLoader';
import NoData from '../../../dashboard/components/no-data/NoData';
import { AmpsDetailsProps, ampsData } from './interfaces/amps.interfaces';
import AmpsModal from './modals/AmpsModal';
import AmpsRefChart from './charts/AmpsRefChart';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { generatePDF } from '../../common';
import { fetchAndProcessTimeSeriesData } from '../../utills/generalFunc';

const CHART_NAME = 'amps';

const AmpsDetails: React.FC<AmpsDetailsProps> = ({ heading }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const chartRefOne = useRef<HTMLDivElement | null>(null);
    const chartRefTwo = useRef<HTMLDivElement | null>(null);
    const [data, setData] = useState<ampsData>({ overload: [], underload: [] });
    const hElement = document.getElementById('main-root');
    const currentUser = useAppSelector((state) => state?.user?.currentUser);
    const dispatch = useAppDispatch();
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);
    const [showLoading, setShowLoading] = useState(true);
    const [expandedLoading, setExpandedLoading] = useState(false);
    const [expandedData, setExpandedData] = useState<ampsData>({
        overload: [],
        underload: [],
    });

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
        key: 'selection',
    });
    const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
    const selectedWell = JSON.parse(sessionStorage.getItem('selectedWellName') || '{}');

    const { userPreference, assetInfo, loading } = useAppSelector((state) => ({
        userPreference: state.controlroom.userPreference?.find(item => item.key === CHART_NAME),
        assetInfo: state.assetInfo.assetDetails,
        loading: state.wellControlroomCharts.loading,
    }));
    const detailsLoading = useAppSelector((state) => state.assetInfo.detailsLoading);

    useEffect(() => {
        if (detailsLoading && loading) {
            setShowLoading(true);
        } else {
            const timer = setTimeout(() => setShowLoading(false), 100);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    const ampsChannelData = {
        surfaceOverload: useAppSelector(getChannelDataByKey(channelIDsObj.surfaceOverload || '')),
        surfaceUnderload: useAppSelector(getChannelDataByKey(channelIDsObj.surfaceUnderload || ''))
    }

    const ampsTimeSeriesData = {
        surfaceOverload: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.surfaceOverload || '')),
        surfaceUnderload: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.surfaceUnderload || ''))
    }

    useEffect(() => {
        if (userPreference?.mappings?.sle && assetInfo?.isSleAsset) {
            setData({
                overload: ampsTimeSeriesData?.surfaceOverload?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                underload: ampsTimeSeriesData?.surfaceUnderload?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });

        } else if (userPreference?.mappings?.xspoc && !assetInfo?.isSleAsset) {
            setData({
                overload: ampsChannelData?.surfaceOverload?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                underload: ampsChannelData?.surfaceUnderload?.dataPoints.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });
        }
    }, [userPreference?.key, JSON.stringify(ampsChannelData), JSON.stringify(ampsTimeSeriesData)]);

    useEffect(() => {
        setExpandedData(data);
    }, [data])

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setExpandedLoading(true);

        fetchAndProcessTimeSeriesData({
            dispatch,
            assetName: selectedWell?.assetName,
            startDate: selectedDateRange.startDate.toISOString(),
            endDate: selectedDateRange.endDate.toISOString(),
            userPreference,
            channelIDsObj,
            isSleAsset: assetInfo?.isSleAsset || false,
            chartName: CHART_NAME,
            keys: ['surfaceOverload', 'surfaceUnderload'],
        }).then((res) => {
            setExpandedData({
                overload: (res.result as Record<string, any[]>)?.surfaceOverload || [],
                underload: (res.result as Record<string, any[]>)?.surfaceUnderload || []
            });
            setExpandedLoading(false);
        })
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    const openModal = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setModalIsOpen(true);
        hElement && hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement && hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const printWidgetAsPDF = () => {
        generatePDF(
            [root],
            setIsDropdownOpen,
            currentUser || null,
            "Amps",
            { width: 400, hight: 160 }
        )
    };

    return (
        <>
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPreference?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPreference?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName ${((data?.overload === undefined || data?.overload.length === 0) && (data?.underload === undefined || data?.underload.length === 0)) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                {/* <button className={`btn btn-default cancelSelectorName ${((data?.overload === undefined || data?.overload.length === 0) && (data?.underload === undefined || data?.underload.length === 0)) ? 'btn-disabled' : ''}`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button> */}
                            </div>
                        )}
                    </div>}
                </div>
                <hr />

                <div id="amps-container" className='h-full wellChartSection ampsChartSection'>
                    {showLoading ? (
                        <Loader />
                    ) : data?.overload?.length > 0 || data?.underload?.length > 0 ? (
                        <>
                            <AmpsRefChart chartID={`ampsChartdiv`} chartRef={chartRefOne} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' description="Please adjust your date range or filter criteria
                        and try again."/>
                    )}
                </div>
            </div>
            <AmpsModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Amps'}
                loading={expandedLoading || showLoading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal ampsModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {expandedLoading || showLoading ? (
                            <Loader />
                        ) : expandedData?.overload?.length > 0 || expandedData?.underload?.length > 0 ? (
                            <AmpsRefChart chartID={'ampsChartdiv'} chartRef={chartRefTwo} data={expandedData} root={root2} />
                        ) : (
                            <NoData heading='No data found' description="Please adjust your date range or filter criteria
                            and try again."/>
                        )
                        }

                    </>
                }
            />
        </>
    );
};

export default AmpsDetails;