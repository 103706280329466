import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import SpeedDetails from './components/speed-chart/SpeedDetails';
import AmpsDetails from './components/amps-chart/AmpsDetails';
import TemperatureDetails from './components/temperature-chart/TemperatureDetails';
import ProductionDetails from './components/production-chart/ProductionDetails';
import HarmonicDistortionDetails from './components/harmonic-distortion-chart/HarmonicDistortionDetails';
import CasingTubingDetails from './components/casing-tubing-chart/CasingTubingDetails';
import IntakeDischargeDetails from './components/intake-discharge-chart/IntakeDischargeDetails';
import BackPressureDetails from './components/back-pressure-chart/BackPressureDetails';
import './WellControlroomCharts.scss';
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import { clearData, fetchMasterVariablesByWellname, fetchTimeSeriesChannelData, fetchTimeSeriesDataVariables, setChannelIDs, setChannelIDsObj, setVariables } from './wellControlroomSlice';
import { setLayoutItems } from '../controlroom/components/customize-control-room/slices/CustomizeControlroomSlice';
import { UserPreferenceDataItem } from '../controlroom/components/interfaces/userPreference.interface';
import { updateWidgetLayout } from '../controlroom/components/customize-control-room/slices/CustomizeControlroomSlice';
import { showToaster } from '../dashboard/components/asset-location/AssetList';
import Loader from '../common/page-loader/ComponentLoader';
import { setIsToggleGetTriggered, updateUserPreferenceData } from '../controlroom/controlroom.slice';

interface LayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minH?: number;
  minW?: number;
  maxW?: number;
  maxH?: number;
  static?: boolean;
}
interface Layouts {
  lg?: LayoutItem[];
  md?: LayoutItem[];
  [key: string]: LayoutItem[] | undefined
}

const ResponsiveGridLayout = WidthProvider(Responsive);

const WellControlroomCharts: React.FC = () => {
  const isDrawerOpen = useAppSelector((state) => state.assetControlRoomDrawer.openState);
  const { userPreference, userPreferenceLoading, isToggleGetTriggered } = useAppSelector((state) => state.controlroom);
  const [items, setItems] = useState<UserPreferenceDataItem[]>(userPreference);
  const selectedWell = sessionStorage.getItem('selectedWellName') && JSON.parse(sessionStorage.getItem('selectedWellName') || '');
  const assetInfo = useAppSelector((state) => state.assetInfo.assetDetails);
  const [layout, setLayout] = useState<Layouts>({ lg: [] });
  // const [originalLayout, setOriginalLayout] = useState<any>(null)
  const [dynamicWidth, setDynamicWidth] = useState(window.innerWidth);
  const channelIDs = useAppSelector((state) => state.wellControlroomCharts.channelIDs);
  const variables = useAppSelector((state) => state.wellControlroomCharts.variables);
  const masterVariables = useAppSelector((state) => state.wellControlroomCharts.masterVariables);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userPreference.length) {
      dispatch(setLayoutItems(userPreference));
      let channelIDsObj: { [key: string]: string } = {};
      userPreference.forEach((item) => {
        const paramStandardTypeIds = Object.values(item?.mappings?.xspoc || {}).map(item => item);
        const xspocVariables = Object.entries(item?.mappings?.xspoc || {}).reduce((acc: { [key: string]: string }, [key, value]) => {
          acc[value as string] = key;
          return acc;
        }, {});

        const filteredChannelIdsObj = masterVariables.filter(item => paramStandardTypeIds.includes(item.paramStandardType?.legacyId.ParamStandardTypesId || "")).reduce((acc: { [key: string]: string }, item) => {
          const paramId = item.paramStandardType?.legacyId.ParamStandardTypesId;
          if (paramId) {
            acc[xspocVariables[paramId]] = item.channelId;
          }
          return acc;
        }, {});
        channelIDsObj = { ...channelIDsObj, ...filteredChannelIdsObj };

      });
      dispatch(setChannelIDsObj(channelIDsObj));

      userPreference.forEach((item) => {
        const paramStandardTypeIds = Object.values(item?.mappings?.xspoc || {}).map(item => item);
        const filteredChannelIds: any = masterVariables.filter(item => paramStandardTypeIds.includes(item.paramStandardType?.legacyId.ParamStandardTypesId || "")).map(item => item.channelId && item.channelId || null).filter(item => item !== null);
        dispatch(setChannelIDs(filteredChannelIds));
        const variables = Object.values(item?.mappings?.sle || {}).map(item => item ? item : null).filter(item => item !== null);
        dispatch(setVariables(variables));
      });

    }

  }, [JSON.stringify(masterVariables), JSON.stringify(userPreference), JSON.stringify(assetInfo)]);



  useEffect(() => {
    const seveDaysAgo = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString();
    const today = new Date().toISOString();

    if (assetInfo?.isSleAsset == true) {
      if (variables.length > 0) {
        dispatch(fetchTimeSeriesDataVariables({
          assetName: selectedWell && selectedWell.assetName,
          startDate: seveDaysAgo,
          endDate: today,
          variables: variables,
          chartName: 'well-control-room',
          isExpandedView: false
        }));
      }
    } else if (assetInfo?.isSleAsset == false) {
      if (channelIDs.length > 0) {

        dispatch(fetchTimeSeriesChannelData({
          assetName: selectedWell && selectedWell.assetName,
          startDate: seveDaysAgo,
          endDate: new Date().toISOString(),
          channelIds: channelIDs,
          chartName: 'well-control-room',
          isExpandedView: false
        }));
      }
    }

  }, [dispatch, JSON.stringify(variables), JSON.stringify(channelIDs), assetInfo?.assetId]);




  useEffect(() => {
    if (userPreference && userPreference.length > 0) {
      setItems(userPreference);
    }
  }, [JSON.stringify(userPreference)]);


  useEffect(() => {
    if (assetInfo?.isSleAsset === false) {
      dispatch(fetchMasterVariablesByWellname({ wellName: selectedWell && selectedWell.assetName }));
    }
  }, [dispatch, selectedWell?.assetName, JSON.stringify(assetInfo)]);


  useEffect(() => {
    if (items?.length > 0) {
      const newLayouts: any = { lg: [] };
      let currentX = 0;
      let currentY = 0;
      const colWidth = 4; // Default width
      const rowHeight = 4; // Default height

      items.forEach(item => {
        if (item.show) {
          const layoutItem = {
            i: item.key,
            x: isDrawerOpen ? 0 : item.positions?.lg?.x ?? currentX,
            y: item.positions?.lg?.y ?? currentY,
            w: isDrawerOpen ? 12 : item.positions?.lg?.w ?? colWidth,
            h: item.positions?.lg?.h ?? rowHeight,
            minH: item.positions?.lg?.minH ?? 1.7,
            minW: item.positions?.lg?.minW ?? 2.5,
          };

          newLayouts.lg.push(layoutItem);
          currentX += layoutItem.w;

          if (currentX >= 12) {
            currentX = 0;
            currentY += rowHeight;
          }
        }
      });
      newLayouts.lg.sort((a: any, b: any) => {
        if (a.y !== b.y) return a.y - b.y; // Sort by y value
        return a.x - b.x;                 // Secondary sort by x value
      });
      setLayout(newLayouts);
    }
  }, [JSON.stringify(items), isDrawerOpen]);

  useLayoutEffect(() => {
    dispatch(clearData());

  }, [])

  useEffect(() => {

    const handleResize = debounce(() => {
      const newWidth = window.innerWidth;
      if (newWidth !== dynamicWidth) {
        // Update the width based on the calculated value of 100vw - 80px
        setDynamicWidth(newWidth);
      }
    }, 200);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      handleResize.cancel(); // Cancel any pending debounced function
    };

  }, []);


  const debouncedLayoutChangeRef = useRef<any>();

  useEffect(() => {
    debouncedLayoutChangeRef.current = debounce(
      (mergedItems: UserPreferenceDataItem[]) => {
        dispatch(updateWidgetLayout({ dashboard: "asset-control-room", data: mergedItems })).then((res: any) => {
          if (updateWidgetLayout.fulfilled.match(res)) {
            showToaster('Layout updated successfully', 'success');
          } else {
            showToaster('Failed to update layout', 'error');
          }
        });
      },
      5000
    );

    return () => {
      // On component unmount, call the debounced function immediately
      if (debouncedLayoutChangeRef.current) {
        debouncedLayoutChangeRef.current.flush();
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (isToggleGetTriggered && debouncedLayoutChangeRef.current) {
      // Cancel the debounced function if isToggleTriggered is true
      debouncedLayoutChangeRef.current.cancel();
      dispatch(setIsToggleGetTriggered(false));
    }
  }, [isToggleGetTriggered]);

  // useEffect(() => {
  //   if (isDrawerOpen) {
  //     if (layout && layout.lg?.length) {
  //       // Save the original layout when sidebar is opened
  //       setOriginalLayout(layout);
  //       const fullWidthLayout = layout.lg?.map((item) => ({
  //         ...item,
  //         x: 0,
  //         w: 12,
  //       }));

  //       setLayout({ lg: fullWidthLayout });
  //     }
  //   } else if (originalLayout) {
  //     // Revert to the original layout when sidebar is closed
  //     setLayout(originalLayout);
  //   }
  // }, [isDrawerOpen]);


  const onLayoutChange = (updatedLayout: LayoutItem[]) => {
    const mergedItems = items && items?.map(item => {
      const updatedItem = updatedLayout?.find(layout => layout.i === item.key);
      if (updatedItem) {
        return {
          ...item,
          positions: {
            ...item.positions,
            lg: {
              x: updatedItem.x,
              y: updatedItem.y,
              w: updatedItem.w,
              h: updatedItem.h,
              minH: updatedItem.minH ?? 1.7,
              minW: updatedItem.minW ?? 2.5,
            },
          },
        };
      }
      return item;
    });

    if (JSON.stringify(mergedItems) !== JSON.stringify(items)) {
      setItems(mergedItems); // Update local state with new items
      dispatch(updateUserPreferenceData(mergedItems))
      setLayout({ lg: updatedLayout }); // Update layout
      debouncedLayoutChangeRef.current(mergedItems); // Call debounced API update
    }
  };

  return (
    <div className='px-[6px] pt-[17px] pb-[24px] grid-container well-charts-container'>
      {
        userPreferenceLoading ?
          <div className='mt-[25%]'>
            <Loader />
          </div>
          :
          <ResponsiveGridLayout
            className='layout'
            layouts={{
              lg: layout.lg || [],
            }}
            draggableCancel=".cancelSelectorName, .noDrag"
            breakpoints={{ lg: 1200, md: 996 }}
            cols={{ lg: 12, md: 12 }}
            rowHeight={75}
            margin={[24, 24]} // Disable default margins
            onDragStop={!isDrawerOpen ? onLayoutChange : undefined}
            onResizeStop={!isDrawerOpen ? onLayoutChange : undefined}
            isDraggable={!isDrawerOpen}
            isResizable={!isDrawerOpen}
            width={dynamicWidth}
            useCSSTransforms={false}
          >
            {(layout?.lg || [])?.map((layoutItem) => {
              const matchedItem = items.find(item => item.key === layoutItem.i);

              if (matchedItem?.show) {
                return (
                  <div key={matchedItem.key} className='grid-item dashboard-graphs-wrapper wellcontrolroom-graphs' style={{ zIndex: 9 }}>
                    {matchedItem?.title.startsWith('Speed') && <SpeedDetails heading={matchedItem?.title} selectedWell={selectedWell && selectedWell?.assetName} />}
                    {matchedItem?.title.startsWith('Amps') && <AmpsDetails heading={matchedItem?.title} />}
                    {matchedItem?.title.startsWith('Temperature') && <TemperatureDetails heading={matchedItem?.title} selectedWell={selectedWell && selectedWell?.assetName} />}
                    {matchedItem?.title.startsWith('Production') && <ProductionDetails heading={matchedItem?.title} />}
                    {matchedItem?.title.startsWith('Harmonic distortion') && <HarmonicDistortionDetails heading={matchedItem?.title} />}
                    {matchedItem?.title.startsWith('Casing / Tubing') && <CasingTubingDetails heading={matchedItem?.title} />}
                    {matchedItem?.title.startsWith('Intake discharge') && <IntakeDischargeDetails heading={matchedItem?.title} />}
                    {matchedItem?.title.startsWith('Back pressure') && <BackPressureDetails heading={matchedItem?.title} />}
                  </div>
                )
              }
            })}
          </ResponsiveGridLayout>
      }

    </div>
  )
}

export default WellControlroomCharts;