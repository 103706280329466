import React, { useEffect, useRef, useState } from 'react';
import annotation_plus from './../../../../images/expand-icon.svg';
import download from '../../../../../src/images/download-icon.svg';
import NoData from '../../../dashboard/components/no-data/NoData';
import Loader from '../../../common/page-loader/ComponentLoader';
import CasingTubingChart from './charts/CasingTubingChart';
import CasingModal from './modals/CasingModal';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import { casingTubingData, CasingTubingDetailsProps } from './interfaces/casing.interfaces';
import { getChannelDataByKey, getTimeSeriesDataByKey } from '../../wellControlroomSlice';
import { generatePDF } from '../../common';
import { fetchAndProcessTimeSeriesData } from '../../utills/generalFunc';

const CHART_NAME = "casing-tubing";

const CasingTubingDetails: React.FC<CasingTubingDetailsProps> = ({ heading }) => {
    const dispatch = useAppDispatch();
    const cardChartRef = useRef<HTMLDivElement | null>(null);
    const modalChartRef = useRef<HTMLDivElement | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [data, setData] = useState<casingTubingData>({ casing: [], tubing: [] });
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(new Date().setDate(new Date().getDate() - 6)),
        endDate: new Date(),
        key: 'selection',
    });

    const [expandedLoading, setExpandedLoading] = useState(false);
    const [expandedData, setExpandedData] = useState<casingTubingData>({
        casing: [],
        tubing: [],
    });

    const channelIDsObj = useAppSelector((state) => state.wellControlroomCharts.channelIDsObj);
    const currentUser = useAppSelector((state) => state?.user?.currentUser);
    const [showLoading, setShowLoading] = useState(true);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const hElement = document.getElementById('main-root');
    const root = useRef<any>(null);
    const root2 = useRef<any>(null);

    const { userPreference, assetInfo, loading } = useAppSelector((state) => ({
        userPreference: state.controlroom.userPreference?.find(item => item.key === CHART_NAME),
        assetInfo: state.assetInfo.assetDetails,
        loading: state.wellControlroomCharts.loading,
    }));
    const detailsLoading = useAppSelector((state) => state.assetInfo.detailsLoading);

    useEffect(() => {
        if (detailsLoading && loading) {
            setShowLoading(true);
        } else {
            const timer = setTimeout(() => setShowLoading(false), 100);
            return () => clearTimeout(timer);
        }
    }, [loading]);

    const casingTubingChannelData = {
        casingData: useAppSelector(getChannelDataByKey(channelIDsObj.casing || '')),
        tubingData: useAppSelector(getChannelDataByKey(channelIDsObj.tubing || '')),
    };

    const casingTubingTimeSeriesData = {
        casingData: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.casing || '')),
        tubingData: useAppSelector(getTimeSeriesDataByKey(userPreference?.mappings?.sle?.tubing || '')),
    };

    const selectedWell = JSON.parse(sessionStorage.getItem('selectedWellName') || '{}');


    useEffect(() => {
        if (userPreference?.mappings?.sle && assetInfo?.isSleAsset) {
            setData({
                casing: casingTubingTimeSeriesData?.casingData && casingTubingTimeSeriesData?.casingData?.dataPoints?.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                tubing: casingTubingTimeSeriesData?.tubingData && casingTubingTimeSeriesData?.tubingData?.dataPoints?.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });

        } else if (userPreference?.mappings?.xspoc && !assetInfo?.isSleAsset) {
            setData({
                casing: casingTubingChannelData?.casingData && casingTubingChannelData?.casingData?.dataPoints?.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
                tubing: casingTubingChannelData?.tubingData && casingTubingChannelData?.tubingData?.dataPoints?.map(item => ({ date: new Date(item.date).getTime(), value: Number(item.value) })) || [],
            });
        }

    }, [userPreference?.key, JSON.stringify(casingTubingChannelData), JSON.stringify(casingTubingTimeSeriesData)]);

    useEffect(() => {
        setExpandedData(data);
    }, [data])


    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        setExpandedLoading(true);

        fetchAndProcessTimeSeriesData({
            dispatch,
            assetName: selectedWell?.assetName,
            startDate: selectedDateRange.startDate.toISOString(),
            endDate: selectedDateRange.endDate.toISOString(),
            userPreference,
            channelIDsObj,
            isSleAsset: assetInfo?.isSleAsset || false,
            chartName: CHART_NAME,
            keys: ['casing', 'tubing'],
        }).then((res) => {
            setExpandedData({
                casing: (res.result as Record<string, any[]>)?.casing || [],
                tubing: (res.result as Record<string, any[]>)?.tubing || []
            });
            setExpandedLoading(false);
        })
    }, [selectedDateRange.startDate, selectedDateRange.endDate]);

    const openModal = (event: any) => {
        event.preventDefault();
        setModalIsOpen(true);
        hElement?.classList.add('modal-open');
    };

    const handleClose = () => {
        setModalIsOpen(false);
        hElement?.classList.remove('modal-open');
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const printWidgetAsPDF = () => {
        generatePDF(
            [root],
            setIsDropdownOpen,
            currentUser || null,
            "Casing / Tubing",
            { width: 400, hight: 140 }
        )
    };

    return (
        <>
            <div className='grid-item-text h-full graph-card well-graph-card'>
                <div className='table-header-section pie-section'>
                    <div className='title'>{heading}</div>
                    {userPreference?.expandable && <div className='header-icon'>
                        <button className='expand-btn cancelSelectorName' onClick={openModal} style={{ cursor: 'pointer', zIndex: 0 }}>
                            <img src={annotation_plus} alt='Expand' className='img-border' />
                        </button>
                    </div>}
                    {userPreference?.actions.show && <div className='mr-4 dropdown-container' ref={dropdownRef}>
                        <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span className='dot'></span>
                            <span className='dot'></span>
                            <span className='dot'></span>
                        </button>
                        {isDropdownOpen && (
                            <div className='dropdown-options'>
                                <button>Actions</button>
                                <button className={`btn btn-default cancelSelectorName ${((data?.casing === undefined || data?.casing.length === 0) && (data?.tubing === undefined || data?.tubing.length === 0)) ? 'btn-disabled' : ''}`} onClick={printWidgetAsPDF}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download PDF
                                </button>
                                {/* <button className={`btn btn-default cancelSelectorName ${((data?.casing === undefined || data?.casing.length === 0) && (data?.tubing === undefined || data?.tubing.length === 0)) ? 'btn-disabled' : ''}`}>
                                    <img src={download} alt='icon' className='btn-icon' />
                                    Download CSV
                                </button> */}
                            </div>
                        )}
                    </div>}
                </div>
                <hr />
                <div id="casingTubing-container" className='h-full wellChartSection casingTubingChartSection'>
                    {showLoading ? (
                        <Loader />
                    ) : data?.casing?.length > 0 || data?.tubing?.length > 0 ? (
                        <>
                            <CasingTubingChart chartID={'casingTubingChartdiv'} chartRef={cardChartRef} data={data} root={root} />
                        </>
                    ) : (
                        <NoData heading='No data found' description="Please adjust your date range or filter criteria
                        and try again."/>
                    )}
                </div>
            </div>
            <CasingModal
                isOpen={modalIsOpen}
                onRequestClose={handleClose}
                heading={'Casing / Tubing'}
                loading={expandedLoading || showLoading}
                modelWidth={'75rem'}
                modelClass={'wellChartModal casingChartModal'}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
                innerComponent={
                    <>
                        {expandedLoading || showLoading ? (
                            <Loader />
                        ) : expandedData?.casing?.length > 0 || expandedData?.tubing?.length > 0 ? (
                            <CasingTubingChart chartID={'casingTubingChartdiv'} chartRef={modalChartRef} data={expandedData} root={root2} />
                        ) : (
                            <NoData heading='No data found' description="Please adjust your date range or filter criteria
                            and try again."/>
                        )
                        }

                    </>
                }
            />
        </>
    );
};

export default CasingTubingDetails;
