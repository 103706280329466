import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ChevronUp } from "../../../../../images/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../../../../images/chevron-down.svg";
import "./dropdown.scss";

export interface DropdownItems {
  customerId: number;
  customerName: string;
}

interface DropdownProps {
  items?: DropdownItems[];
  defaultOption?: string;
  onChange?: (item: DropdownItems) => void;
  noResultsMessage?: React.ReactNode;
  value: string;
  disabled?: boolean;
}

const CustomerDropdown: React.FC<DropdownProps> = ({
  items,
  defaultOption = "Select a customer",
  onChange,
  noResultsMessage,
  value,
  disabled = false,
}) => {
  // const [selectedItem, setSelectedItem] = useState<DropdownItems | null>(null);
  const [isActive, setIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = (item: DropdownItems) => {
    // setSelectedItem(item);
    setIsActive(false);
    if (onChange) {
      onChange(item);
    }
  };

  const filteredItems = items?.filter((item) =>
    item?.customerName?.toLowerCase().includes(searchTerm.toLowerCase())  // Use customerName here
  );

  const exactMatch = filteredItems?.some(
    (item) => item?.customerName?.toLowerCase() === searchTerm.toLowerCase()  // Use customerName here
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`dropdown ${disabled ? "disabled" : ""}`}
    >
      <div
        onClick={() => !disabled && setIsActive(!isActive)}
        className="dropdown-btn"
      >
        <span className="dropdown-input">
          {/* {selectedItem ? selectedItem.customerName : defaultOption}   */}
          {value || defaultOption}
        </span>
        <span>{isActive ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      {isActive && !disabled && (
        <div
          className="dropdown-content"
          style={{ display: isActive ? "block" : "none" }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="dropdown-search"
            disabled={disabled}
          />
          {searchTerm && !exactMatch && noResultsMessage && (
            <div className="no-results">{noResultsMessage}</div>
          )}
          {filteredItems?.map((item, index) => (
            <div
              key={index}
              // className={`item ${selectedItem?.customerName === item.customerName ? "item-selected" : ""
              //   }`}
                className={`item w-full text-left ${value === item.customerName ? "item-selected text-left" : ""}`}
              onClick={() => handleClick(item)}
            >
              {item.customerName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerDropdown;
