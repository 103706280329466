import React, { useEffect, useRef, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import annotation_plus from '../../../../../images/expand-icon.svg';
import { UptimeDowntime } from '../../../model/UptimeDowntime';
import download from '../../../../../../src/images/download-icon.svg';
import { downloadDashboardCSV } from '../../../DashboardServices';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks';
import UptimeDowntimeModel from './UptimeDowntimeModel';
import { fetchUptimeDowntimeDetails } from '../../../DashboardSlice';
import NoData from '../../no-data/NoData';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../../common/page-loader/ComponentLoader';
import html2canvas from 'html2canvas';
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting';
import moment from 'moment-timezone';
import championx from '../../../../../../src/images/championxLogo.png';

interface UptimeDowntimeGraphProps {
  groupName: string;
}

const UptimeDowntimeGraph: React.FC<UptimeDowntimeGraphProps> = ({ groupName }) => {
  const dispatch = useAppDispatch();
  const uptimeDowntimeCount = useAppSelector((state) => state.dashboard.PerformanceSnapshot?.uptimeDowntimeCount);
  const uptimeDowntime = useAppSelector((state) => state.dashboard.PerformanceSnapshot?.uptimeDowntime);
  const loading = useAppSelector((state) => state.dashboard.performanceLoading);
  const heading = 'Uptime/Downtime';
  const subHeading = 'Total wells';
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hElement = document.getElementById('main-root');

  const currentUser = useAppSelector((state) => state?.user?.currentUser);
  const uptimeDowntimeDetails = useAppSelector((state) => state.dashboard.uptimeDowntimeDetails);
  const upDownchartdiv = useRef<HTMLDivElement | null>(null);
  const root = useRef<am5.Root | null>(null);

  useEffect(() => {
    if (!groupName) return;
    dispatch(fetchUptimeDowntimeDetails({ name: 'uptimedowntime', groupName: groupName }));
  }, [groupName])

  const printWidgetAsPDF = () => {
    const input = document.getElementById('uptimeDowntime-container');

    if (root.current) {
      const exporting = am5plugins_exporting.Exporting.new(root.current, {
        menu: am5plugins_exporting.ExportingMenu.new(root.current, {}),
      });
      Promise.all([
        exporting.getPdfmake(),
        exporting.export('png'),

      ]).then((res) => {
        const pdfMake = res[0];
        const str = moment().format("MMM-DD-YYYY hh:mm:ss A");
        const doc = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          pageMargins: [30, 30, 30, 30],
          footer: {
            columns: [
              {
                text: `Generated By: ${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`,
                alignment: 'left',
                "width": "50%",
                "bold": true,
                "margin": [10, 10],
                "fontSize": 9,
                "color": "black",
                "decoration": 'underline',

              },
              {
                text: "Generated on : " + str, alignment: 'right',
                "width": "50%",
                "bold": true,
                "margin": [10, 10],
                "fontSize": 9,
                "color": "black",
                "decoration": 'underline',

              }
            ]
          },
          content: [] as any[],
          styles: {
            tblheader: {
              fontSize: 12,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'center',
            },
            tblheader1: {
              fontSize: 12,
              color: 'white',
              fillColor: '#01485e',
              alignment: 'left',
            },
            tbody1: {
              alignment: 'center',
            }
          }
        };

        const rect = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 450,
          h: 25,
          linearGradient: ['#01485e', '#01485e']
        };
        const rectHead = {
          type: 'rect',
          x: -32,
          y: -20, //could be negative to account for padding
          w: 150,
          h: 25,
          linearGradient: ['#484848', '#484848']
        };
        doc.content.push({

          "columns": [{

            stack: [
              { canvas: [rect] },
              {
                columns: [
                  {
                    width: rect.w / 2,
                    noWrap: false, //clip at with by wrapping to next line and...
                    maxHeight: rect.h, //don't show the wrapped text
                    "image": championx,
                    "fit": [55, 55],
                    "margin": [5.5, 7.5, 3],
                    "background": 'white',
                  },
                  {
                    "text": "Landing Page",
                    width: rect.w / 2,
                    "alignment": "left",
                    "bold": true,
                    "margin": [35, 5],
                    "fontSize": 13,
                    "color": "white",
                  }],
                relativePosition: {
                  x: rect.x,
                  y: - rect.h
                }
              }
            ]
          },
          {
            stack: [
              { canvas: [rectHead] },
              {
                columns: [
                  {
                    width: rectHead.w,
                    noWrap: false, //clip at with by wrapping to next line and...
                    maxHeight: rect.h, //don't show the wrapped text
                    text: 'LOOKOUT',
                    color: 'white',
                    "bold": true,
                    "alignment": "right",
                    "font-size": 10,

                  }],
                relativePosition: {
                  x: -80,
                  y: - 20
                }
              }
            ]
          }
          ]
        });
        doc.content.push({
          "text": " ",
          "width": "100%",
        });

        doc.content.push(
          {
            text: 'Uptime/Downtime',
            width: "*",
            alignment: "center",
            fontSize: 18,
            margin: [0, 0, 0, 5],
          },
          {
            canvas: [
              {
                type: 'line',
                x1: 200, y1: 0,
                x2: 340, y2: 0,
                lineWidth: 1
              }
            ],
            margin: [0, 0, 0, 20]
          }
        );

        html2canvas(input as HTMLElement, { backgroundColor: "#001023" })
          .then((canvas) => {
            canvas.style.marginTop = '10%';
            const imgData = canvas.toDataURL('image/png');

            doc.content.push({
              "alignment": "center",
              image: imgData,
              width: 450,
              height: 180,
              margin: [0, 0, 0, 20],
            });
            const tblBody = [[
              { text: 'Operator', bold: true, style: 'tblheader1' },
              { text: 'Uptime', bold: true, style: 'tblheader' },
              { text: 'Downtime', bold: true, style: 'tblheader' }]
            ];

            if (uptimeDowntimeDetails) {
              const dynamicHeaders = ['operator', 'uptime', 'downtime'];
              uptimeDowntimeDetails.forEach((item: any) => {
                const row: any = dynamicHeaders.map(key => {
                  if (key === 'operator') {
                    return { text: item[key].toString(), bold: false }; // Convert each value to string
                  }
                  return { text: item[key].toString(), bold: false, style: 'tbody1' }; // Convert each value to string
                });

                tblBody.push(row);
              });

              doc.content.push({
                table: {
                  headerRows: 1,
                  widths: Array(dynamicHeaders.length).fill('*'), body: tblBody,
                },
              });
            }

            const fileName = "Lookout-Landing_Page_UptimeDowntime_" + str + ".pdf"

            pdfMake.createPdf(doc).download(fileName);
          })
          .catch((err) => {
            console.error("Failed to generate PDF", err);
          });

      });
    }
  };

  const handleDownloadCSV = () => {
    toast.success('Uptime Downtime CSV download is in progress.!');
    const currentTime = moment().format("MMM-DD-YYYY hh:mm:ss A");
    const widgetName = 'UptimeDowntime';
    const data = { widgetName, groupName };
    downloadDashboardCSV(data).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Lookout-Landing_Page_UptimeDowntime_' + currentTime + '.csv');
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      if (error?.message !== '') {
        toast.error('Uptime Downtime CSV download is failed. Please try after sometime!');
      }
    });
    setIsDropdownOpen(false);
  };

  const openModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setModalIsOpen(true);
    hElement && hElement?.classList.add('modal-open');
  };

  const handleClose = () => {
    setModalIsOpen(false);
    hElement && hElement?.classList.remove('modal-open');
  };

  const initializeChart = (chartId: string, data: UptimeDowntime[]): am5.Root | undefined => {
    if (upDownchartdiv.current) {
      root.current = am5.Root.new(upDownchartdiv.current);
      // const root = am5.Root.new(chartId);
      root.current.setThemes([am5themes_Animated.new(root.current)]);

      const chart = root.current.container.children.push(
        am5percent.PieChart.new(root.current, {
          layout: root.current.horizontalLayout,
          innerRadius: am5.percent(60),
        }),
      );

      const series = chart.series.push(
        am5percent.PieSeries.new(root.current, {
          name: 'Series',
          valueField: 'value',
          categoryField: 'status',
          legendLabelText: '[#F7F9F9]{category}[/]',
          legendValueText: '[#F7F9F9]{value}[/]',
        }),
      );
      series.slices.template.setAll({
        stroke: am5.color('#FFFFFF'),
        strokeWidth: 2,
      });

      series.data.setAll(data);

      const dataItemsArray = Array.from(series.dataItems.values());
      dataItemsArray.forEach((dataItem) => {
        const status = dataItem.get('category') as string; // Assuming status is stored under 'category' field
        let sliceColor = '';
        if (status === 'Uptime') {
          sliceColor = '#12b76a'; // Green for Uptime
        } else if (status === 'Downtime') {
          sliceColor = '#f97066'; // Red for DownTime
        } else {
          sliceColor = '#4A5463'; // Grey for total wells
        }
        dataItem.set('fill', am5.color(sliceColor));
      });

      // Hide labels and ticks
      series.labels.template.set('forceHidden', true);
      series.ticks.template.set('forceHidden', true);

      const legend = chart.children.push(
        am5.Legend.new(root.current, {
          centerY: am5.percent(50),
          y: am5.percent(50),
          layout: root.current.verticalLayout,
        }),
      );

      legend.valueLabels.template.setAll({ textAlign: 'right', fill: am5.color('#F7F9F9') });
      legend.labels.template.setAll({
        maxWidth: 140,
        width: 80,
        oversizedBehavior: 'wrap',
        fill: am5.color('#F7F9F9'),
      });

      //For making the legend circular
      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        cornerRadiusBL: 10,
        cornerRadiusBR: 10,
        width: 10,
        height: 10,
      });

      legend.markerRectangles.template.setAll({
        y: 4,
      });

      legend.data.setAll(series.dataItems);

      return root.current;
    }
    return undefined
  };

  useEffect(() => {
    let rootInstance: am5.Root | undefined;
    if (uptimeDowntime && uptimeDowntimeCount) {
      rootInstance = initializeChart(`${heading}chartdiv`, uptimeDowntime);
    }
    return () => {
      if (rootInstance) {
        rootInstance.dispose();
      }
    };
  }, [uptimeDowntime, heading, uptimeDowntimeCount]);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <ToastContainer position='top-right' autoClose={3000} />
      <div className='table-header-section pie-section'>
        <div className='title'>{heading}</div>
        <div className='header-icon'>
          <button
            className='expand-btn cancelSelectorName'
            onClick={openModal}
            style={{ cursor: 'pointer', zIndex: 0 }}
          >
            <img src={annotation_plus} alt='Expand' className='img-border' />
          </button>
          <div className='dropdown-container' ref={dropdownRef}>
            <button className='dropdown-btn cancelSelectorName' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <span className='dot'></span>
              <span className='dot'></span>
              <span className='dot'></span>
            </button>
            {isDropdownOpen && (
              <div className='dropdown-options'>
                <button>Actions</button>
                <button className={`btn btn-default cancelSelectorName ${(uptimeDowntime === null || uptimeDowntime === undefined || uptimeDowntime?.length === 0) ? 'btn-disabled' : ''}`}
                  onClick={printWidgetAsPDF}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download PDF
                </button>
                <button className={`btn btn-default cancelSelectorName ${(uptimeDowntime === null || uptimeDowntime === undefined || uptimeDowntime?.length === 0) ? 'btn-disabled' : ''}`} onClick={handleDownloadCSV}>
                  <img src={download} alt='icon' className='btn-icon' />
                  Download CSV
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />

      <div id="uptimeDowntime-container" className='pie-container-dashboard nodata'>
        {loading ? (
          <div className='flex items-center justify-center w-100'>
            <Loader />
          </div>
        ) : uptimeDowntimeCount ? (
          <>
            <div className='total-count'>
              <p>{subHeading}</p>
              <h2>{uptimeDowntimeCount}</h2>
            </div>
            <div id={`${heading}chartdiv`} ref={upDownchartdiv} className='chart-wrapper'></div>
          </>
        ) : !modalIsOpen ? (
          <NoData heading='No data found' />
        ) : (
          ''
        )}

        <UptimeDowntimeModel
          isOpen={modalIsOpen}
          onRequestClose={handleClose}
          heading={heading}
          subHeading={subHeading}
          count={uptimeDowntimeCount}
          uptimeDowntime={uptimeDowntime}
        />
      </div>
    </>
  );
};

export default UptimeDowntimeGraph;
