import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

interface UpTimeChartProps {
  selectedUptimeData: { date: string | number; uptime: number, downtime: number, isDown?: boolean }[];
} 

const UptimeDowntimeChart: React.FC<UpTimeChartProps> = ({selectedUptimeData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
   
   // Filter data to ensure unique dates
   const uniqueData = Array.from(
    new Map(
      selectedUptimeData?.map((item) => [item.date, item]) // Use `date` as the key to ensure uniqueness
    ).values()
  );

   const transformedData = uniqueData?.map(item => ({
    ...item,
    date: new Date(item.date).toLocaleDateString(undefined, { month: "short", day: "numeric" }) // Convert to timestamp
   }));

  useLayoutEffect(() => {
    
    if (!chartRef.current) return;

    const root = am5.Root.new(chartRef.current);
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );
    

    // X-axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "date",
        marginBottom: 50,
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 20,
          cellStartLocation: 0.2, // Gap before the first bar
          cellEndLocation: 0.9, // Gap between bars
        }),
      })
    );

    xAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: 12,
      paddingTop: 10,
    });

    xAxis.get("renderer").grid.template.setAll({
      stroke: am5.color(0x4A5463),
      strokeWidth: 1,
    });

    // Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        strictMinMax: true,
        //numberFormat: "#'%'",
        renderer: am5xy.AxisRendererY.new(root, {}),
        
      })
    );
    

    yAxis.children.unshift(
      am5.Label.new(root, {
          fill: am5.color(0xF7F9F9),
          rotation: -90,
          text: "%",
          y: am5.p50,
          centerX: am5.p50
      })
    );

    
    yAxis.get("renderer").labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: 12,
      text: "{value}", // Remove the percentage symbol
    });

    

    yAxis.get("renderer").grid.template.setAll({
      stroke: am5.color(0x4A5463),
      strokeWidth: 1,
    });
   
    xAxis.data.setAll(transformedData);

    // Uptime Series
    const uptimeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Uptime",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "uptime",
        categoryXField: "date",
        stacked: true,
      })
    );
    uptimeSeries.columns.template.setAll({
      fill: am5.color(0x2ecc71),
      stroke: am5.color(0x2ecc71),
      tooltip: am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        paddingBottom:0,
        paddingTop:0,
        paddingLeft:0,
        paddingRight:0,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0x001023),
          stroke: am5.color(0x001023),
        }),
      }),
      tooltipHTML:
      "<div style='background-color: #001023; color: white; padding: 10px; border-radius: 5px;'>" +
      "<strong>Uptime:</strong> {uptime}%<br>" +
      "<strong>Downtime:</strong> {downtime}%</div>",
      tooltipY: 0,
      width: 57
    });

    // Downtime Series
    const downtimeSeries = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Downtime",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "downtime",
        categoryXField: "date",
        stacked: true,
      })
    );
    downtimeSeries.columns.template.setAll({
      fill: am5.color(0xe74c3c),
      stroke: am5.color(0xe74c3c),
      tooltip: am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        paddingBottom:0,
        paddingTop:0,
        paddingLeft:0,
        paddingRight:0,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0x001023),
        }),
      }),
      tooltipHTML:
      "<div style='background-color: #001023; color: white; padding: 10px; border-radius: 5px;'>" +
      "<strong>Uptime:</strong> {uptime}%<br>" +
      "<strong>Downtime:</strong> {downtime}%</div>",
      tooltipY: 0,
      width: 57
    });

    uptimeSeries.columns.template.adapters.add("visible", (visible, target) => {
      const dataItem: any = target.dataItem;
      return dataItem?.dataContext.uptime > 0; // Only show uptime bar if uptime > 0
    });
  
    downtimeSeries.columns.template.adapters.add("visible", (visible, target) => {
      const dataItem: any = target.dataItem;
      return dataItem?.dataContext.downtime > 0; // Only show downtime bar if downtime > 0
    });

    uptimeSeries.data.setAll(transformedData);
    downtimeSeries.data.setAll(transformedData);

    // Legend
    const legend = chart.children.push(
      am5.Legend.new(root, {
        layout: root.horizontalLayout,
        x: am5.p0,
        y: am5.p100,
        dx: 50, // Adjust spacing between chart and legend
        
      })
    );

    legend.data.setAll([uptimeSeries, downtimeSeries]);

    legend.labels.template.setAll({
      fill: am5.color(0xffffff),
      fontSize: 14,
    });

    legend.markerRectangles.template.setAll({
      //cornerRadius: 5,
      width: 15,
      height: 15,
    });

    return () => {
      root.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "350px" }} />;
};

export default UptimeDowntimeChart;
