import React, { useState, useEffect, useRef } from "react";
import "./addAssetDetails.scss";
import "../../../pageLayout.scss"
import "../../user-details/add-user/addUser.scss"
import moment from "moment-timezone";

import Modal from "../../../common/modal/modal";
import { DropdownItems } from "../../../common/dropdown/dropdown";

import SearchDropdown, {
  searchDropdownItem,
} from "../../../common/dropdown/searchDropdown";
import SuccessfullToast from "../../../common/toast/successfulToast";
import ConfirmationDialog from "../../../common/confirmationDialog/confirmationDialog";
import { ReactComponent as AlertIcon } from "../../../../../images/alert-circle.svg";
import { AssetItem } from "../assetService";
import { useAppSelector } from "../../../../../hooks/storeHooks";

import Loader from "../../../common/loader/loader";
import Tooltip from "../../../common/tooltip/tooltip";
import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import CustomerDropdown from "../../../common/dropdown/dropdown";
import TimeZoneDropdown from "../../../common/dropdown/TimeZoneDropdown";
import plus from '../../../../../images/plus.svg';

interface AddAssetProps {
  addAsset?: (newAsset: AssetItem) => void;
  editAsset?: (updatedAsset: AssetItem) => void;
  assetToEdit?: AssetItem | null;
}

const AddAssetDetails: React.FC<AddAssetProps> = ({ assetToEdit }) => {
  console.log("assetToEdit", assetToEdit)
  // const dispatch = useAppDispatch();
  const searchResults = useAppSelector((state) => state.OnboardingAsset.searchResults);
  const assetSearchLoading = useAppSelector(
    (state) => state.OnboardingAsset.assetSearchLoading
  );
  const assets = useAppSelector((state) => state.OnboardingAsset.asset);
  const customerNames = useAppSelector((state) => state.OnboardingCustomer.customer);

  const assetSaveLoading = useAppSelector(
    (state) => state.OnboardingAsset.assetSaveLoading
  );
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);

  const timeZones: string[] = moment.tz.names();

  const customerItems = customerNames
    .map((customer) => ({
      id: customer.id,
      name: customer.name || "",
    }))
    .sort((a, b) => (a.name || "").localeCompare(b.name || ""));
  const initialFormData = {
    id: "",
    customerId: "",
    customerName: "",
    createdOnDate: "",
    lastModifiedDate: "",
    cnX_WellName: "",
    name: "",
    cnX_SiteId: null,
    cnX_WellId: null,
    envWellId: null,
    apI_UWI: "",
    pocType: {
      id: "",
      legacyId: "",
      lookupType: "",
      description: "",
    },
    portId: "",
    nodeAddress: "",
    isEnabled: false,
    pumpChecker: {
      identifier: "",
      identifierType: "API",
      field: "",
      area: "",
      businessUnit: "",
      methodOfProduction: "",
      latitude: null,
      longitude: null,
      timezone: "",
    },
    applicationId: "",
  };
  const [formData, setFormData] = useState<any>(initialFormData);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchDropdownItems, setSearchDropdownItems] = useState<
    searchDropdownItem[]
  >([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [showToast, setShowToast] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  // const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  // const [textcolor, setTextColor] = useState("");
  // const [dataLoaded, setDataLoaded] = useState(false);
  // const { setOriginalFormData, hasChanges } =
  //   useFormChangeDetection<CustomerItem>(null);
  const productionDateRef = useRef<any>(null);
  const installationDateRef = useRef<any>(null);

  const [formStep, setFormStep] = useState(1)

  useEffect(() => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    isValid =
      formData.name.trim() !== "" &&
      formData.customerName.trim() !== "" &&
      formData.pocType.id.trim() !== ""


    if (
      formData.name &&
      assets.some(
        (asset) =>
          asset.name &&
          asset.name.toLowerCase() === formData.name.toLowerCase() &&
          (!isEditMode || asset.id !== formData.id)
      )
    ) {
      newErrors.name = "Asset name already exists";
    }

    if (
      formData.portId &&
      assets.some(
        (asset) =>
          asset.portId === formData.portId &&
          asset.nodeAddress.trim().toLowerCase() ===
          formData.nodeAddress.trim().toLowerCase() &&
          (!isEditMode || asset.id !== formData.id)
      )
    ) {
      newErrors.portAndAddress = "Address+Port combination already exists";
    }

    // Validation for Field, Area, and Business Unit
    const { field, area, businessUnit } = formData.pumpChecker;
    if (field && (!area || !businessUnit)) {
      isValid = false;
      newErrors.fieldDependency =
        "A Field requires both Area and Business Unit to be provided.";
    }

    if (area && !businessUnit) {
      isValid = false;
      newErrors.areaDependency =
        "An Area requires a Business Unit to be provided.";
    }
    console.log("isfalse", isValid)
    setErrors(newErrors);
    // setIsFormValid(isValid && Object.keys(newErrors).length === 0);
  }, [formData, isEditMode, assets]);

  useEffect(() => {
    setInputValue(formData.cnX_WellName);
  }, [formData.cnX_WellName]);

  useEffect(() => {
    if (typeof inputValue === "string" && inputValue.length > 4) {
      const filteredItems = searchResults.filter((result) =>
        `${result.wellName} (${result.apI_UWI})`
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );
      setSearchDropdownItems(
        filteredItems.map((result, index) => ({
          id: result.id,
          name: result.wellName,
          displayName: result.apI_UWI
            ? `${result.wellName} (${result.apI_UWI})`
            : result.wellName,
          cnX_SiteId: result.siteID,
          envWellID: result.envWellID,
          apI_UWI: result.apI_UWI,
          key: `${result.id}-${index}`,
        }))
      );
    }
  }, [searchResults, inputValue]);



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the ref is valid and the click is outside
      if (productionDateRef.current !== null && !productionDateRef.current.contains(event.target as Node)) {
        try {
          productionDateRef.current.closeCalendar();
        } catch (_) {
          // Ignore the error
        }
      }

      if (installationDateRef.current !== null && !installationDateRef.current.contains(event.target as Node)) {
        try {
          installationDateRef.current.closeCalendar();
        } catch (_) {
          // Ignore the error
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openAssetModal = () => setIsAssetModalOpen(true);
  const closeAssetModal = () => {
    setInputValue("");
    setIsAssetModalOpen(false);
    setFormData(initialFormData);
    setErrors({});
    setIsEditMode(false);
  };

  const openAddUserModal = () => {
    setIsEditMode(false);
    // setOriginalFormData(initialFormData);
    setFormData(initialFormData);
    openAssetModal();
    setSearchDropdownItems([]);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState: any) => {
      let updatedFormData = { ...prevState };

      if (name.startsWith("pumpChecker.")) {
        const pumpCheckerField = name.split(".")[1];
        updatedFormData = {
          ...prevState,
          pumpChecker: {
            ...prevState.pumpChecker,
            [pumpCheckerField]: type === "checkbox" ? checked : value.trim(),
          },
        };
      } else {
        updatedFormData[name] = type === "checkbox" ? checked : value.trim();
      }

      return updatedFormData;
    });
  };

  const handleDropdownChange = (item: DropdownItems, field: string) => {
    setFormData((prevState: any) => {
      const updatedFormData = { ...prevState };

      if (field.startsWith("pumpChecker.")) {
        const pumpCheckerField = field.split(".")[1];
        updatedFormData.pumpChecker = {
          ...prevState.pumpChecker,
          [pumpCheckerField]: item.name,
        };

        if (pumpCheckerField === "timezone") {
          updatedFormData.pumpChecker.timezone = item.id;
        }
      } else {
        updatedFormData[field] = field === "portId" ? item.id : item.name;
        if (field === "customerName") {
          updatedFormData.customerId = item.id;
        }
      }

      if (field === "type") {
        const pocType = {
          id: item.id,
          lookupType: item.lookupType,
          legacyId: item.legacyId,
          description: item.name,
        };
        return {
          ...updatedFormData,
          pocType,
        };
      }

      return updatedFormData;
    });
  };

  const handleSearchDropdownChange = (selectedAsset: searchDropdownItem) => {
    setFormData((prevState: any) => ({
      ...prevState,
      cnX_WellId: selectedAsset.id ? String(selectedAsset.id) : null,
      cnX_WellName: selectedAsset.name,
      name: !isEditMode ? selectedAsset.name : prevState.name,
      cnX_SiteId: selectedAsset.cnX_SiteId
        ? String(selectedAsset.cnX_SiteId)
        : null,
      envWellId: selectedAsset.envWellID
        ? String(selectedAsset.envWellID)
        : null,
      apI_UWI: selectedAsset.apI_UWI ? String(selectedAsset.apI_UWI) : null,
    }));
    setInputValue(selectedAsset.name);
  };



  // const customNoResultsMessage = (
  //   <>
  //     <p className="no-customer-found">Customer not listed?</p>
  //     <span className="add-new-customer">
  //       <AddCustomer addCustomer={addCustomerHandler} triggerType="button" />
  //     </span>
  //   </>
  // );


  const handleConfirmationDialogClose = () =>
    setIsConfirmationDialogOpen(false);

  const handleConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    closeAssetModal();
  };

  return (
    <div className="onboarding-container">
      <button className='btn btn-primary flex gap-x-2' style={{ fontSize: '14px' }} onClick={openAddUserModal}>
        <img src={plus} alt='plus icon' />
        <span>Add well</span>
      </button>

      <Modal isOpen={isAssetModalOpen} onClose={closeAssetModal}>
        <div style={{ position: "relative" }} className="onboard-modal">
          {assetSaveLoading && (
            <div className="spinner-overlay">
              <Loader isOverlay={true} />
            </div>
          )}
          {/* <form className="add-user-form" onSubmit={handleAssetSubmit} > */}
          <form className="add-user-form onboarding-form">
            <div className="onboard-form-row onboard-modal-header">
              <label style={{ fontSize: "20px" }}>
                {isEditMode ? "Edit well" : "Create a new well"}
              </label>
            </div>
            <div className="onboard-modal-content">
              {
                formStep === 1 ?
                  <div className="asset-identity-div">
                    <div className="onboard-division-header">
                      Asset identity
                    </div>
                    <div className="onboard-form-row grid grid-cols-2 gap-4">

                      <div className="onboard-form-group col-auto">
                        <label>CNX name (API UWI number)</label>
                        <SearchDropdown
                          items={searchDropdownItems}
                          defaultOption={formData.cnX_WellName || "Select a well"}
                          onChange={handleSearchDropdownChange}
                          // onInputChange={handleInputChangeLogging}
                          inputValue={inputValue}
                          isLoading={assetSearchLoading}
                          infoMessage="Type 5 or more characters to search"
                        />
                      </div>

                      <div className="onboard-form-group col-auto">
                        <label>
                          <span>Name</span>
                          <span className="required-asterisk">*</span>
                        </label>
                        <div className="alert-icon-container">
                          <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                          {errors.name && (
                            <span className="error-icon">
                              <Tooltip content={errors.name} direction="top">
                                <AlertIcon />
                              </Tooltip>
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="onboard-form-group col-auto">
                        <label>Well API number:</label>
                        <div className="alert-icon-container">
                          <input
                            type="text"
                            name="wellApiNumber"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="onboard-form-group col-auto">
                        <label>
                          <span>Customer name:</span>
                          <span className="required-asterisk">*</span>
                        </label>
                        <CustomerDropdown
                          items={customerItems}
                          defaultOption={
                            formData.customerName || "Select customer"
                          }
                          onChange={(item) =>
                            handleDropdownChange(item, "customerName")
                          }
                          noResultsMessage={""}
                        />
                      </div>

                      <div className="onboard-form-group col-auto">
                        <label>Time series data source:</label>
                        <div className="flex flex-row gap-x-10">
                          <div className='' >
                            <label className={`radio-container`} >
                              <input
                                type='radio'
                                name="timeSeriesDataSource"
                              />
                              <span className='radio-checkmark'></span>
                              {`SLE`}
                            </label>
                          </div>
                          <div className='' >
                            <label className={`radio-container`} >
                              <input
                                type='radio'
                                name="timeSeriesDataSource"
                              />
                              <span className='radio-checkmark'></span>
                              {`XSPOC`}
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  :
                  formStep === 2 ?
                    <div className="location-details-div mt-5">
                      <div className="onboard-division-header">
                        <span>Location details</span>
                      </div>
                      <div className="onboard-form-row grid grid-cols-2 gap-4">
                        <div className="onboard-form-group col-auto">
                          <label>Address:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="address"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>State:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="state"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Country:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="country"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Route:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="route"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Field:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="field"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Formation:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="formation"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Basin:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="basin"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Well time zone:</label>
                          <TimeZoneDropdown items={timeZones} defaultOption={formData?.timeZones} />
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Lattitude:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="lattitude"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="onboard-form-group col-auto">
                          <label>Longitude:</label>
                          <div className="alert-icon-container">
                            <input
                              type="text"
                              name="Longitude"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    formStep === 3 ?
                      <div className="well-details-div mt-5">
                        <div className="onboard-division-header">
                          <span>Well details</span>
                        </div>
                        <div className="onboard-form-row grid grid-cols-2 gap-4">
                          <div className="onboard-form-group col-auto">
                            <label>Pad id:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="padId"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Pad type:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="padType"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>True vertical depth:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="trueVerticalDepth"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Total measurement depth:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="totalMeasurementDepth"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Well direction:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="wellDirection"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Production start date:</label>
                            <div className='date-time-picker'>
                              <div className={`date-time-container`}>
                                <DatePicker
                                  mobileLabels={{ OK: 'Apply', CANCEL: 'Cancel' }}
                                  format='MM/DD/YYYY'
                                  className='date-input-field rmdp-mobile bg-dark left-calendar'
                                  placeholder='MM/DD/YYYY'
                                  weekDays={["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"]}
                                  // value={updatedScheduledDate ? new Date(updatedScheduledDate) : new Date()}
                                  plugins={[<Toolbar position='top' sort={['today']} />]}
                                  minDate={new Date().setHours(0, 0, 0, 0)}
                                  showOtherDays={true}
                                  ref={productionDateRef}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Installation date:</label>
                            <div className='date-time-picker'>
                              <div className={`date-time-container`}>
                                <DatePicker
                                  mobileLabels={{ OK: 'Apply', CANCEL: 'Cancel' }}
                                  format='MM/DD/YYYY'
                                  className='date-input-field rmdp-mobile bg-dark right-calendar'
                                  placeholder='MM/DD/YYYY'
                                  weekDays={["Mo", "Tu", "We", "Th", "Fr", "Sat", "Su"]}
                                  // value={updatedScheduledDate ? new Date(updatedScheduledDate) : new Date()}
                                  plugins={[<Toolbar position='top' sort={['today']} />]}
                                  minDate={new Date().setHours(0, 0, 0, 0)}
                                  showOtherDays={true}
                                  calendarPosition="top-left"
                                  ref={productionDateRef}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      : formStep === 4 &&
                      <div className="communication-details-div mt-5">
                        <div className="onboard-division-header">
                          Communication details
                        </div>
                        <div className="onboard-form-row grid grid-cols-2 gap-4">
                          <div className="onboard-form-group col-auto">
                            <label>Ip address:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="IpAddress"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Device id:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="deviceId"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Device name:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="deviceName"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Device ip:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="deviceIp"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          <div className="onboard-form-group col-auto">
                            <label>Device serial no:</label>
                            <div className="alert-icon-container">
                              <input
                                type="text"
                                name="field"
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
              }

              <div className="onboard-form-row">
                <p className="mandatory-fields-message">
                  Fields marked with<span className="required-asterisk">*</span>{" "}
                  are mandatory.
                </p>
              </div>
            </div>
            <div className="onboard-form-row onboard-modal-footer" >
              <button
                type="button"
                className={`cancel-button ${formStep === 1 ? "disabled" : ""}`}
                disabled={formStep === 1}
                onClick={() => setFormStep((prev) => prev - 1)}
              >
                Back
              </button>
              <button
                type="button"
                className="add-user-button"
                onClick={() => { formStep < 4 && setFormStep((prev) => prev + 1) }}
              // disabled={
              //   isEditMode
              //     ? !isFormValid || !hasChanges(formData)
              //     : !isFormValid
              // }
              >
                {
                  formStep <= 3 ? "Continue" :
                    isEditMode ? "Save Changes" : "Add Well"
                }
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {showToast && (
        <SuccessfullToast
          // message={toastMessage}
          message={""}

          isShowEnabled={showToast}
          setShow={setShowToast}
          // colorText={textcolor}
          colorText={"green"}

        />
      )}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmCancel}
        message="Are you sure you want to cancel? Any unsaved changes will be lost."
      />
    </div>
  );
};

export default AddAssetDetails;
