//Security.tsx
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks';
import './Security.scss';
import { fetchUserById, fetchPrivileges } from '../../UserSlice';
import { Role } from '../../../../models/Role';
import { AppUser } from '../../model/AppUser';
import Loader from '../../../common/page-loader/ComponentLoader';

interface TabsProps {
  value?: string | undefined;
}
const Security: React.FC<TabsProps> = ({ value }) => {
  const dispatch = useAppDispatch();
  const storedUserData = localStorage.getItem('loggedInUser');
  const loading = useAppSelector((state) => state?.user?.loading);
  const storedUser: AppUser = storedUserData ? JSON.parse(storedUserData) : null;
  let currentUser = useAppSelector((state) => state?.user?.currentUser);
  currentUser = currentUser || storedUser

  const privileges = useAppSelector((state) => state?.user?.privileges);


  useEffect(() => {
    const fetchUser = async () => {
      if (value) {
        await dispatch(fetchUserById(value));
      }
    };

    fetchUser();
  }, [dispatch, value]);


  useEffect(() => {
    if (!privileges)
      dispatch(fetchPrivileges())
  }, [])

  const isRoleActive = (role: Role) => {
    if (currentUser?.roles?.length) {
      return Math.min(...currentUser.roles) === role
    }
  }
  return (
    <>
      {loading ? (
        <div className='flex items-center loader-margin'>
          <Loader />
        </div>
      ) : currentUser ? (
        <div className='flex flex-row flex-grow profile-block w-100 py-6 px-8'>

          <div className='card security-card m-0'>
            <div className='header'>
              <p className='title'>Security settings</p>
              <p className='normal-text'>View your account permissions here.</p>
            </div>
            <div className='body'>
              <div className='security-info'>
                <div className='protext'>
                  <div className='text'>Username</div>
                </div>
                <div className='card'>
                  <form className='user-form'>
                    <div className='form-row'>
                      <div className='form-col full-col'>
                        <input
                          type='text'
                          id='userName'
                          className='username-custom-input'
                          value={currentUser?.lookoutUserId}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <br />
              <div className='divider'></div>
              <br />
              <br />
              <div className='profile-info'>
                <div className='protext'>
                  <div className='text'>User security permissions</div>
                </div>
                <div className='card'>
                  <form className='password-form'>
                    <div className='form-row'>

                      {privileges?.map((item) => {
                        const roleKey = item.value.replace(" ", "")
                        const role = Role[roleKey as keyof typeof Role] as number;
                        return (
                          <button
                            className={`btn ${isRoleActive(role) ? 'btn-orange' : 'btn-transparent'}`}
                            style={{ pointerEvents: 'none' }}
                          >
                            {item.value}
                          </button>

                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : ''
      }
    </>
  );
};

export default Security;
