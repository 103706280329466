import React, { useEffect, useRef, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { clearTicketTags, getTicketTags } from '../slices/ticketSlice';
import Loader from '../../../../common/page-loader/ComponentLoader';
import NoData from '../../../../dashboard/components/no-data/NoData';

interface ControlRoomTicketTagsProps {
    activeTicketTag: string,
    setActiveTicketTag: React.Dispatch<React.SetStateAction<string>>,
    activeTicketTagIndex: number,
    setActiveTicketTagIndex: React.Dispatch<React.SetStateAction<number>>,
    handleTicketTagSelect: (index: number, name: string) => void
}

const ControlRoomTicketTags: React.FC<ControlRoomTicketTagsProps> = ({ activeTicketTag, setActiveTicketTag, activeTicketTagIndex, setActiveTicketTagIndex, handleTicketTagSelect }) => {
    const dispatch = useAppDispatch()
    const [isAddTicketTagSectionOpen, setIsAddTicketTagSectionOpen] = useState(false)
    const { ticketTagsData, ticketTagLoading, ticketTagTotalCount, ticketTotalCount, ticketTableLoading, ticketTagListRateLimitedError } = useAppSelector((state) => state.ticketTableSlice)
    const [loading, setLoading] = useState(true)
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const tagListRef = useRef<HTMLDivElement>(null)
    const pageSize = 25;

    const handleTicketTagClick = (index: number, name: string) => {
        handleTicketTagSelect(index, name)
    }

    const closeAddTicketTagSection = () => {
        setIsAddTicketTagSectionOpen(false)
    }

    const fetTicketTags = useCallback((SearchQuery: string, page: number, isLoadMore = false) => {
        if (isLoadMore) {
            setIsFetchingMore(true);
        } else {
            setLoading(true);
        }
        dispatch(getTicketTags({ pageNumber: page, pageSize, SearchQuery }))
            .finally(() => {
                if (isLoadMore) {
                    setIsFetchingMore(false);
                } else {
                    setLoading(false);
                }
            });
    }, [dispatch, pageSize]);

    // Search debounce effect
    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            dispatch(clearTicketTags())
            setActiveTicketTag("")
            setActiveTicketTagIndex(0)
            setPageNumber(1)
            fetTicketTags(searchQuery, 1)
        }, 1000)

        return () => clearTimeout(debounceTimer)
    }, [searchQuery, fetTicketTags])

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {

                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                if (
                    (scrollHeight - scrollTop - clientHeight) < 50 &&
                    (!ticketTagLoading && ticketTagsData?.length < ticketTagTotalCount)
                ) {
                    const updatedPageNumber = pageNumber + 1
                    setPageNumber(updatedPageNumber)
                    fetTicketTags(searchQuery, updatedPageNumber)
                }
            }
        },
        [ticketTagLoading, ticketTagsData?.length, ticketTagTotalCount, pageNumber, searchQuery, fetTicketTags]
    )

    return (
        <div className="ticket-tags">
            <div className="tag-header">
                <div className="flex justify-between items-center h-[62px]">
                    <span className="tag">Tags</span>
                </div>
                {isAddTicketTagSectionOpen && (
                    <div className="add-ticket-tag-section">
                        <input
                            className='custom-text-input'
                            type="text"
                            name="name"
                            placeholder="Enter new tag name"
                            autoComplete="off"
                        />
                        <div className="tag-button-section flex justify-end">
                            <div className="flex space-x-4">
                                <button type="button" className="cancel-btn" onClick={closeAddTicketTagSection}>
                                    Cancel
                                </button>
                                <button type="button" className="create-btn" onClick={closeAddTicketTagSection}>
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="tag-search m-2">
                <input
                    type="text"
                    placeholder="Search tags"
                    className="custom-text-input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className='ticket-tag-container relative'>
                {(loading || ticketTagLoading) && <Loader />}

                <div
                    className={`tag-div clickable alltag ${activeTicketTag === '' ? 'active' : ''}`}
                    onClick={() => handleTicketTagClick(0, '')}
                >
                    <span className="tag">All</span>
                    <span className="tag-count">{(ticketTableLoading && !ticketTotalCount) ? <Loader /> : ticketTotalCount}</span>
                </div>
                <div
                    className={`tag-list ${(loading || ticketTagLoading) ? 'blur-background pointer-events-none' : ''}`}
                    ref={tagListRef}
                    onScroll={() => fetchMoreOnBottomReached(tagListRef.current)}
                >
                    {isFetchingMore && (
                        <div className="absolute top-[55%] left-0 w-full bg-white flex justify-center items-center">
                            <Loader />
                        </div>
                    )}



                    {ticketTagsData?.length === 0 && !(loading || ticketTagLoading) ? (
                        <div className='flex justify-center items-center h-[370px]'>
                            <NoData heading={ticketTagListRateLimitedError ? ticketTagListRateLimitedError : 'No data found'} />
                        </div>
                    ) : (
                        <>

                            {ticketTagsData.map((tagItem, index) => (
                                <div
                                    className={`tag-div clickable ${activeTicketTagIndex === index + 1 ? 'active' : ''}`}
                                    key={index}
                                    onClick={() => handleTicketTagClick(index + 1, tagItem.name)}
                                >
                                    <span className="tag">{tagItem.name}</span>
                                    <span className="tag-count">{tagItem.count}</span>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ControlRoomTicketTags;