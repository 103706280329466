import React, { useEffect, useState } from 'react';
// import '../user-profile/User.scss';
import '../User.scss';
import { useAppSelector } from '../../../../hooks/storeHooks';
import Loader from '../../../common/page-loader/ComponentLoader';
import { slugify } from '../../../../utilities/Slugify';
import { useNavigate } from 'react-router-dom';

interface SearchTerm {
  selectedLookupId: string | null;
  setSelectedLookupId: React.Dispatch<React.SetStateAction<string | null>>;
}

const LookupsList: React.FC<SearchTerm> = ({ selectedLookupId, setSelectedLookupId }) => {
  const lookups = useAppSelector((state) => state.SupportSlice.lookups);
  const lookupsLoading = useAppSelector((state) => state.SupportSlice.lookupsLoading);
  const navigate = useNavigate()
  const [forceUpdate, setForceUpdate] = useState(0)


  useEffect(() => {
    if (!lookups || lookups?.length === 0) return
    const currentTabFromQuery = new URLSearchParams(location.search).get('innerTab');
    if (currentTabFromQuery) {
      const lookup = lookups?.find((tab) => slugify(tab.key) === currentTabFromQuery);
      if (lookup) {
        setSelectedLookupId(lookup.id);
      } else {
        setSelectedLookupId(lookups[0]?.id)
        if (selectedLookupId === lookups[0]?.id)
          setForceUpdate((prev) => prev + 1)
      }
    } else {
      setSelectedLookupId(lookups[0]?.id)
      if (selectedLookupId === lookups[0]?.id)
        setForceUpdate((prev) => prev + 1)
    }
  }, [location.search, lookups]);

  useEffect(() => {
    if (selectedLookupId === null) return;

    const lookup = lookups?.find((tab) => (tab.id) === (selectedLookupId));
    const newTabSlug = lookup ? slugify(lookup?.key) : '';
    const params = new URLSearchParams(location.search);
    const currentTabSlug = params.get('innerTab');
    // Only navigate if the tab slug in the URL is differents
    if (newTabSlug && currentTabSlug !== newTabSlug) {
      params.set('innerTab', newTabSlug)
      if (!currentTabSlug) {
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
      } else {
        navigate(`${location.pathname}?${params.toString()}`);
      }
    }
  }, [selectedLookupId, forceUpdate]);

  return (
    <>
      <div className='user-checklist'>
        <ul>
          {lookupsLoading ? (
            <div className='flex items-center h-full'>
              <Loader />
            </div>
          ) : lookups?.length ? (
            lookups.map((item: any, index: any) => (
              <li
                className={`${item.id === selectedLookupId ? 'active-list' : ''}`}
                key={index}
                onClick={() => {
                  setSelectedLookupId(item.id)
                }}
              >
                <div className='info'>
                  <div className='namedata'>{item?.key}</div>
                  <div className='companyname'>{item?.description}</div>
                </div>
              </li>
            ))
          ) : (
            <li>
              <div className='notfound'>No data found.</div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
export default LookupsList;
