import { http, apiInstance } from "../../../../config/HttpCommon";

export const GetControllerDetails = async (nodeId: string, controllerType: string) => {
    const response = await http.get(`/getControllerDetails/${encodeURIComponent(nodeId)}/${controllerType}`);
    return response.data;
};


export enum looupKeys {
    webSocketTimeOut = 'WS_GET_CURRENT_SETPOINT_TIMEOUT',
    sleAggregationRange = 'SLE_AGGREGATION_RANGE',
    xspocAggregationRnge = 'XSPOC_AGGREGATION_RANGE'
}

export const GetScheduleSpeedChangeStatus = async (wellId: string) => {
    const response = await apiInstance.get(`/v1/schedule-speed-changes/last?WellId=${wellId}`);
    return response.data;
  };

