import { ReactComponent as ChevronUp } from '../../../../../images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../../../../images/chevron-down.svg';
import { useEffect, useRef, useState } from 'react';
import { Status } from '../interfaces/ticketInterface';
import { capitalizeFirstInWord } from '../../../../../utilities/CommonFunctions';
import Loader from '../../../../common/page-loader/ComponentLoader';
import NoData from '../../../../dashboard/components/no-data/NoData';
import './StatusDropdown.scss'


interface StatusDropdownProps {
    options: Status[];
    selectedOption: string;
    handleOptionSelect: (selectedOption: string) => void;
    loading: boolean;
    disabled: boolean;

}

const StatusDropdown = ({ options, selectedOption, handleOptionSelect, loading, disabled }: StatusDropdownProps) => {
    const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutSideClick = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsStatusDropdownOpen(false);
            }
        };
        if (isStatusDropdownOpen) {
            document.addEventListener('mousedown', handleOutSideClick);
        }
        return () => document.addEventListener('mousedown', handleOutSideClick);
    }, [isStatusDropdownOpen]);

    return (
        <div className='dropdown' ref={dropdownRef}>
            <div
                className={`${disabled ? 'pointer-events-none' : ''} dropdown-btn`}
                onClick={() => {
                    setIsStatusDropdownOpen(!isStatusDropdownOpen);
                }}
            >
                <span>{selectedOption ? capitalizeFirstInWord(selectedOption) : ''}</span>
                <span>{isStatusDropdownOpen ? <ChevronUp /> : <ChevronDown />}</span>

            </div>
            {
                isStatusDropdownOpen &&
                <div className='dropdown-content' style={{ display: isStatusDropdownOpen ? 'block' : 'none' }}>
                    {
                        loading ? <Loader />
                            : options.length > 0 ?

                                options?.map((option, index) => {
                                    return (
                                        <div data-testid={option.status} key={index} onClick={() => handleOptionSelect(option.status)} className='item'>
                                            {option.status}
                                        </div>
                                    );
                                })
                                : <NoData heading='No data' />
                    }
                </div>
            }

        </div>
    )
}


export default StatusDropdown