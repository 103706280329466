import React from 'react'
import { useManageAsset } from '../hook/ManageAssetHook'
import { PIDSettingVariable, setPointParamStandardTypeId } from '../../model/ManageAssetModel'
import SetpointBackupValue from '../SetpointBackupValue'

interface PIDSettingProps {
    groupName: keyof typeof setPointParamStandardTypeId
}


const PIDSetting: React.FC<PIDSettingProps> = ({ groupName }) => {

    const { getSetpointItem } = useManageAsset()

    const getSetpointInput = (placeholder: string, groupName: keyof typeof setPointParamStandardTypeId, setpointName: string, isTimeInput?: boolean) => {

        const setpointItem = getSetpointItem(groupName, setpointName);

        return setpointItem ? (
            <SetpointBackupValue setPointItem={setpointItem} groupName={groupName} setpointName={setpointName} isTimeInput={isTimeInput} />
        ) : (
            <input
                type='text'
                className='time-input-field disabled'
                placeholder={placeholder}
            />
        );
    }

    return (
        <div className='pid-setting-container'>
            <div className='flex flex-col space-y-4'>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Process Variable</label>
                        {
                            getSetpointInput('Motor current (A)', groupName, PIDSettingVariable.ProcessVariable)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/2'>
                        <label className='label'>Action</label>
                        {
                            getSetpointInput('Forward', groupName, PIDSettingVariable.Action)
                        }
                    </div>
                </div>

                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Shutdown setpoint</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.ShutdownSetpoint)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Restart setpoint</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.RestartSetpoint)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/4 '>
                        <label className='label'>PID delay</label>
                        {
                            getSetpointInput('00:00:00', groupName, PIDSettingVariable.PidDelay, true)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Setpoint (A)</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.Setpoint)
                        }
                    </div>
                </div>
                <div className='flex flex-row space-x-4'>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Deadbond (A)</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.Deadband)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Fixed output (Hz)</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.FixedOutput)
                        }
                    </div>

                    <div className='manage-asset-form-control w-1/4'>
                        <label className='label'>Max frequency (A)</label>
                        {
                            getSetpointInput('0', groupName, PIDSettingVariable.MaxFrequency)
                        }
                    </div>
                    <div className='manage-asset-form-control w-1/4'>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PIDSetting