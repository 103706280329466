import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserPreferenceDataItem } from "./components/interfaces/userPreference.interface";
import { userPreferences } from "./controlroomService";

interface ControlRoomState {
    userPreference: UserPreferenceDataItem[];
    defaultUserPreference: UserPreferenceDataItem[];
    userPreferenceLoading: boolean;
    loading: boolean;
    error: string | null;
    isToggleGetTriggered: boolean
}

const initialState: ControlRoomState = {
    userPreference: [],
    defaultUserPreference: [],
    userPreferenceLoading: false,
    loading: false,
    error: null,
    isToggleGetTriggered: false
}

export const fetchUserPrefrence = createAsyncThunk(
    'controlRoom/fetchUserPrefrence',
    async (dashboard: string) => {
        return await userPreferences(dashboard);
    }
);

export const fetchDefaultUserPrefrence = createAsyncThunk(
    'controlRoom/fetchDefaultUserPrefrence',
    async (dashboard: string) => {
        return await userPreferences(dashboard, true);
    }
);

const controlroomSlice = createSlice({
    name: 'controlRoom',
    initialState,
    reducers: {
        toggleVisibility: (state, action: PayloadAction<string>) => {
            const item = state.userPreference.find(i => i.key === action.payload);
            if (item) {
                item.show = !item.show;
            }
        },
        updateUserPreferenceData: (state, action) => {
            state.userPreference = action.payload
        },
        setIsToggleGetTriggered: (state, action) => {
            state.isToggleGetTriggered = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserPrefrence.fulfilled, (state, action) => {
            state.userPreference = action.payload.data.data;
            state.loading = false;
            state.userPreferenceLoading = false;

        });
        builder.addCase(fetchUserPrefrence.pending, (state) => {
            state.loading = true;
            state.userPreferenceLoading = true;

        });
        builder.addCase(fetchUserPrefrence.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
            state.userPreferenceLoading = false;
        });
        builder.addCase(fetchDefaultUserPrefrence.pending, (state) => {
            state.loading = true;
            state.userPreferenceLoading = true;

        });
        builder.addCase(fetchDefaultUserPrefrence.fulfilled, (state, action) => {
            state.userPreference = action.payload.data.data;
            state.loading = false;
            state.userPreferenceLoading = false;
        });
        builder.addCase(fetchDefaultUserPrefrence.rejected, (state, action) => {
            state.error = action.payload as string;
            state.loading = false;
            state.userPreferenceLoading = false;
        });
    }
});

export const { toggleVisibility, updateUserPreferenceData, setIsToggleGetTriggered } = controlroomSlice.actions;
export default controlroomSlice.reducer;
//export const controlroomActions = controlroomSlice.actions;